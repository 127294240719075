import React, { useContext, useState } from 'react';
import AppContext from '../../../App/AppContext';
import { PasswordBox, SecondaryButton, DialogContents, DialogTitles, Dialogs } from '../../../Core/FormInput';
import { ResetPassByAdmin } from '../../../Core/Service/admin-service';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import { AppStorage } from '../../../Core/Service/storage-service';

const SetPassByAdminModal = (props) => {
    const { hideModal, showToast, portalSettings } = useContext(AppContext);
    const [state, setState] = useState({
        new: '',
        confirm: '',
        enableChange: false,
        errors: {}
    });
    const user = props.users;
    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };
    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            nst.enableChange = nst.new && nst.confirm && nst.new === nst.confirm;
            return nst;
        });
    };
    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        let minLength = AppStorage.getPortalSettingPassLength();
        let pattern = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&#])[A-Za-z\\d@$!%*?&#]{${minLength},}$`, 'gm');
        if (!pattern.test(state.new)) {
            formIsValid = false;
            errors['new'] = `Password should be complex with ${minLength} char minimum.`;
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };
    const handleResetPass = async (e) => {
        e.preventDefault();
        if (state.new && state.confirm) {
            if (handleValidation()) {
                const res = await ResetPassByAdmin(user.id, state.new, state.confirm);
                if (!res.success) {
                    console.error(`When admin trying to change user password (${user.id})`, res);
                    showToast(res.messages);
                } else {
                    showToast(res.message);
                    props.onFormSubmit(false);
                    hideModal();
                }
            }
        }
        // if (user) {
        //     props.onFormSubmit(false);
        // }
    };
    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="sm">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <DialogTitles>Reset Password</DialogTitles>
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>

            <DialogContents>
                <PasswordBox label="New Password" name="new" onChange={fieldChange} />
                <span className="mandatoryfields">{state.errors?.new}</span>
                <PasswordBox label="Confirm Password" name="confirm" onChange={fieldChange} />
                <SecondaryButton className="Submitbtn" onClick={handleResetPass} disabled={!state.enableChange}>
                    Set Password
                </SecondaryButton>
            </DialogContents>
        </Dialogs>
    );
};

export default SetPassByAdminModal;
