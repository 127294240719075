import React, { useContext, useEffect, useState } from 'react';

import { Grid, InputLabel } from '@material-ui/core';
import { SecondaryButton, TextBox } from '../../../../Core/FormInput';

import { Alert } from '@material-ui/lab';

import { PostAwaitingCollection } from '../../../../Core/Service/PartReq_services/partReturnSteps-services';

const AwaitingCollection = ({ onClose, handleSubmit, partReturnPartReqID, partReturnID, showToast }) => {
    const [state, setState] = useState({
        partReturnSupplierInvoiceNo: ''
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const Onsubmit = async () => {
        setBtnLoader(true);
        let data = {
            partReturnPartReqID: partReturnPartReqID,
            partReturnID: partReturnID,
            partReturnSupplierInvoiceNo: state.partReturnSupplierInvoiceNo || ''
        };
        let res = await PostAwaitingCollection(data);

        if (res.success) {
            showToast(res?.data?.message);
            handleSubmit();
            onClose();
        }
        setBtnLoader(false);
    };
    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Alert severity="info">Do you want to set status of this part to be awaiting collection?</Alert>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>Supplier Invoice Number</InputLabel>
                    <TextBox
                        placeholder="Supplier Invoice Number"
                        name="partReturnSupplierInvoiceNo"
                        value={state.partReturnSupplierInvoiceNo}
                        onChange={(e) => {
                            setState((st) => ({ ...st, partReturnSupplierInvoiceNo: e.target.value }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SecondaryButton fullWidth onClick={Onsubmit} disabled={!state.partReturnSupplierInvoiceNo} isBtnLoader={btnLoader}>
                        Yes{' '}
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default AwaitingCollection;
