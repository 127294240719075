import React, { useContext, useState } from 'react';
import { Grid, Paper, Popover, Popper, Slide, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, withStyles } from '@material-ui/core';
import formatters from '../../../Core/Grid/inputFormatter';
import SearchIcon from '@material-ui/icons/Search';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { PostReqData, SearchReg } from '../../../Core/Service/PartReq_services/partReq-service';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import AppContext from '../../../App/AppContext';
import moment from 'moment';
import { SecondaryButton, SecondaryCheckbox } from '../../../Core/FormInput';

const StyledTableCell = withStyles((theme) => ({
    head: {
        padding: 5,
        background: 'grey',
        color: 'white'
    },
    body: {
        padding: '0px 5px',
        fontWeight: 600
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.3)',
            color: 'white'
        }
    }
}))(TableRow);

const RegTable = ({ data, partList, setPartList, onClose, headerRecordID }) => {
    const { showToast } = useContext(AppContext);
    const [list, setList] = useState([]);

    let handleList = async (obj) => {
        let Parts = [...partList];
        let tempList = [...list];
        tempList.forEach((k) => {
            let index = Parts.findIndex((i) => i.partReqID === k.partReqID);
            if (index > -1) {
                showToast(`This Records already exists.`);
            } else {
                Parts.push({
                    partReqsPartID: null,
                    partReqPartNumber: k.partReqPartNumber,
                    partReqPartDescription: k.partReqPartDescription,
                    partReqQuantity: k.partReqQuantity,
                    isDeleted: false,
                    partReqStatus: null,
                    partReqId: k.partReqID,
                    isFitted: false,
                    partReqsAvailability: 0,
                    partReqsCost: 0,
                    partReqsRetailPrice: 0,
                    partReqsLabourHours: 0,
                    partReqsLabourValue: 0,
                    partReqsOrderNumber: '',
                    partReqDateCreated: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
                    partReqSupplier: '',
                    partReqSurchargedValue: 0,
                    partReqSurcharged: false
                });
            }
        });

        let data = {
            partReqIds: tempList.map((k) => k.partReqID),
            headerRecordID: headerRecordID
        };

        let res = await PostReqData(data);
        if (res.success) {
            showToast(res.data.message);
        } else {
            showToast(res.message);
        }

        setPartList(Parts);
        onClose();
    };

    const handleCheckbox = (entry, index) => (e) => {
        const { checked } = e.target;
        let tempArr = [...list];
        if (checked) {
            entry.background = 'green';
            tempArr.push(entry);
        } else {
            tempArr.splice(index, 1);
            entry.background = 'white';
        }
        setList(tempArr);
    };

    return (
        <div style={{ padding: 5 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left"></StyledTableCell>
                        <StyledTableCell align="left">Part Number</StyledTableCell>
                        <StyledTableCell align="left">Description</StyledTableCell>
                        <StyledTableCell align="right">Quantity</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.length > 0 ? (
                        data.map((j, i) => (
                            <StyledTableRow key={i} style={{ cursor: 'pointer', background: j.background ? j.background : 'white' }}>
                                <StyledTableCell align="left">
                                    <SecondaryCheckbox onChange={handleCheckbox(j, i)} />
                                </StyledTableCell>
                                <StyledTableCell align="left">{j.partReqPartNumber}</StyledTableCell>
                                <StyledTableCell align="left">{j.partReqPartDescription}</StyledTableCell>
                                <StyledTableCell align="right">{j.partReqQuantity}</StyledTableCell>
                            </StyledTableRow>
                        ))
                    ) : (
                        <TableRow>
                            <StyledTableCell colSpan={4} align="center">
                                No Record Found
                            </StyledTableCell>
                        </TableRow>
                    )}
                    {data.length > 0 && (
                        <TableRow>
                            <StyledTableCell colSpan={4} align="center">
                                <SecondaryButton fullWidth onClick={handleList}>
                                    Import Parts
                                </SecondaryButton>
                            </StyledTableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
};

const PartHeader = ({ jobDetails, partList, setPartList, headerRecordID }) => {
    const [state, setState] = useState({
        tableData: [],
        showTable: false
    });
    const handleReg = async () => {
        let Res = await SearchReg(jobDetails?.registration, headerRecordID);
        if (Res.success) {
            setState((st) => ({ ...st, tableData: Res.data.recallParts, showTable: true }));
        }
    };

    const handleClose = () => {
        setState((st) => ({ ...st, showTable: false }));
    };

    return (
        <>
            <Grid item>
                <div style={{ textAlign: 'left', fontSize: 12 }}>Job No.</div>
                <Typography style={{ fontSize: 18, fontWeight: 500, textAlign: 'left' }}>{jobDetails?.wipNumber || 'N/A'}</Typography>
            </Grid>
            <Grid item>
                <div style={{ textAlign: 'left', fontSize: 12 }}>Chassis No.</div>
                <Typography style={{ fontSize: 18, fontWeight: 500, textAlign: 'left' }}>{jobDetails?.chassisNumber || 'N/A'}</Typography>
            </Grid>
            <Grid item>
                <div style={{ textAlign: 'left', fontSize: 12 }}>Reg No. </div>
                <Typography style={{ fontSize: 18, fontWeight: 500, textAlign: 'left', display: 'flex' }}>
                    <span>{jobDetails?.registration}</span>
                    &nbsp;
                    <Tooltip title="Import Recall Parts">
                        <SearchIcon style={{ color: 'green', cursor: 'pointer' }} onClick={handleReg} />
                    </Tooltip>
                </Typography>
                {state.showTable ? (
                    <DialogComp fullWidth maxWidth="md" title={'Import Recall Parts'} onClose={handleClose}>
                        <RegTable data={state.tableData} partList={partList} setPartList={setPartList} onClose={handleClose} headerRecordID={headerRecordID} />
                    </DialogComp>
                ) : null}
            </Grid>
            <Grid item>
                <div style={{ textAlign: 'left', fontSize: 12 }}>Make/Model </div>
                <Typography style={{ fontSize: 18, fontWeight: 500, textAlign: 'left' }}>{jobDetails?.makeModel}</Typography>
            </Grid>
            <Grid item>
                <div style={{ textAlign: 'left', fontSize: 12 }}>Customer</div>
                <Typography style={{ fontSize: 18, fontWeight: 500, textAlign: 'left' }}>{jobDetails?.customerName}</Typography>
            </Grid>
            <Grid item>
                <div style={{ textAlign: 'left', fontSize: 12 }}>Date</div>
                <Typography style={{ fontSize: 18, fontWeight: 500, textAlign: 'left' }}>{formatters.DateFormatter(jobDetails.date)}</Typography>
            </Grid>
        </>
    );
};
export default PartHeader;
