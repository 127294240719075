import React, { useContext, useEffect, useState } from 'react';
import { DateTimePicker, SecondaryButton, SingelSelect } from '../../../../Core/FormInput';
import { Alert } from '@material-ui/lab';
import {
    PostGridPartReqs_MarkedAsPartArrived,
    PostGridReturnToPartDepartment,
    PostReturnToPartDepartment
} from '../../../../Core/Service/PartReq_services/partReturnSteps-services';
import { CircularProgress, Grid, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, FormHelperText } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import AppContext from '../../../../App/AppContext';
import { getWorkflowsByProcessID } from '../../../../Core/Service/workflow-service';
import { getByWorkflowIdAndHeaderId } from '../../../../Core/Service/returnReason-service';
const StyledTableCell = withStyles((theme) => ({
    head: {
        // padding: ' 5px',
        fontSize: 15,
        fontWeight: 600,
        backgroundColor: '#bdbdbd82'
    },
    body: {
        fontSize: 12
        // padding: ' 5px'
        // fontSize: 11,
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'grey',
            color: 'white'
        },
        cursor: 'pointer'
    }
}))(TableRow);

const MarkedAsPartArrived = (props) => {
    const { showToast } = useContext(AppContext);
    const [btnLoader, setBtnLoader] = useState(false);
    const [state, setState] = useState({
        partReqIds: [],
        partArrivedDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
        workFlowList: [],
        reasonsList: [],
        workflowVal: {},
        returnReasonVal: {},
        errors: {}
    });
    useEffect(async () => {
        const res = await getWorkflowsByProcessID(1, 'Y');
        if (res.success) {
            setState((st) => ({
                ...st,
                workFlowList: res.data.map((q) => ({ id: q.id, name: q.workflowName }))
            }));
        }
        if (props.selectedRecords.length > 0) {
            setState((st) => ({ ...st, partReqIds: props.selectedRecords.map((q) => q.partReqID) }));
        }
    }, []);

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!state.partArrivedDate) {
            formIsValid = false;
            errors['partArrivedDate'] = 'Please set part arrived date';
        }
        // if (!state.workflowVal?.id) {
        //     formIsValid = false;
        //     errors['workflowVal'] = 'Please select workflow';
        // }
        // if (!state.returnReasonVal?.id) {
        //     formIsValid = false;
        //     errors['returnReasonVal'] = 'Please select return reason';
        // }

        setState((prevState) => ({
            ...prevState,
            errors: errors
        }));
        return formIsValid;
    };

    const Onsubmit = async () => {
        if (handleValidation()) {
            setBtnLoader(true);
            let data = {
                partReqIds: state.partReqIds,
                workflowID: state.workflowVal?.id,
                returnReasonID: state.returnReasonVal?.id,
                partArrivedDate: state.partArrivedDate
            };

            let res = await PostGridPartReqs_MarkedAsPartArrived(data);

            if (res.success) {
                showToast(res?.data?.message);
                props.onClose && props.onClose(true);
            }
            setBtnLoader(false);
        }
    };

    const handleInputPart = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            let newSt = { ...st };
            newSt.partArrivedDate = value;
            return newSt;
        });
    };

    const singleSelectChange = (nm) => async (ev, val) => {
        if (nm === 'workflowVal') {
            state.returnReasonVal = {};
            if (val?.id) {
                const result = await getByWorkflowIdAndHeaderId(val.id);
                state.reasonsList = result.data.returnReasons.map((q) => ({
                    id: q.returnReasonID,
                    name: q.returnReasonDescription
                }));
            }
        }

        setState((st) => {
            let newSt = { ...st };
            newSt[nm] = val;
            return newSt;
        });
    };

    return (
        <div style={{ padding: 10 }}>
            {state.partReqIds.length <= 0 ? (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity="info">Please Select parts </Alert>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                        <InputLabel required shrink>
                            Part Arrived Date
                        </InputLabel>
                        <DateTimePicker name="partReqETA" value={state.partArrivedDate} onChange={handleInputPart} />
                        <FormHelperText error>{state.errors?.partArrivedDate}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel shrink style={{ marginBottom: '-8px' }}>
                            Set Workflow
                        </InputLabel>
                        <SingelSelect options={state.workFlowList} value={state.workflowVal} onChange={singleSelectChange('workflowVal')} name="workflowVal" />
                        <FormHelperText error>{state.errors?.workflowVal}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel shrink style={{ marginBottom: '-8px' }}>
                            Set Return Reason
                        </InputLabel>
                        <SingelSelect
                            options={state.reasonsList}
                            value={state.returnReasonVal}
                            onChange={singleSelectChange('returnReasonVal')}
                            name="returnReasonVal"
                        />
                        <FormHelperText error>{state.errors?.returnReasonVal}</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <Alert severity="info">Marked these parts as arrived?</Alert>
                    </Grid>
                    <Grid item xs={12}>
                        {btnLoader ? (
                            <SecondaryButton fullWidth>
                                <CircularProgress size={25} />
                            </SecondaryButton>
                        ) : (
                            <SecondaryButton fullWidth onClick={Onsubmit} isBtnLoader={btnLoader}>
                                Submit{' '}
                            </SecondaryButton>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer style={{ height: '300px' }}>
                            <Table size="small" width="550px" stickyHeader>
                                <TableHead>
                                    <TableRow className="header">
                                        <StyledTableCell>Reg No</StyledTableCell>
                                        <StyledTableCell>Job No</StyledTableCell>
                                        <StyledTableCell>Customer</StyledTableCell>
                                        <StyledTableCell>Main Part</StyledTableCell>
                                        <StyledTableCell>Supplier</StyledTableCell>
                                        <StyledTableCell>ETA for parts ordered</StyledTableCell>
                                        {/* <StyledTableCell>Returned By</StyledTableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.selectedRecords.map((q) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell>{q.regNo}</StyledTableCell>
                                                <StyledTableCell>{q.wipNo}</StyledTableCell>
                                                <StyledTableCell>{q.cust}</StyledTableCell>
                                                <StyledTableCell>{q.mainPart}</StyledTableCell>
                                                <StyledTableCell>{q.partReqSupplier}</StyledTableCell>
                                                <StyledTableCell>{moment(q.partReqETA).format('DD-MMM-YYYY HH:mm')}</StyledTableCell>
                                                {/* <StyledTableCell>{q.returnedBy}</StyledTableCell> */}
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default MarkedAsPartArrived;
