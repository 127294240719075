export const isEmailValid = (value) => {
    let errors = {
        showError: false,
        message: ''
    };
    const emailRegexp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!value || typeof value !== 'string' || value.trim() === '') {
        errors.showError = true;
        errors.message = 'This field Cannot be empty';
        return errors;
    } else if (!emailRegexp.test(value)) {
        errors.showError = true;
        errors.message = 'This enter a valid email address ';
    }

    return errors;
};
