import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import formatters from './../../../Core/Grid/inputFormatter';
import AppContext from '../../../App/AppContext';
import DialogComp from './../../../Core/Modal/dialogModal';
import ReturnToPartDept from './PartReturnStatus/ReturnToPartDept';
import AwaitingCollection from './PartReturnStatus/AwaitingCollection';
import ReceivedByMainSite from './PartReturnStatus/ReceivedByMainSite';
import Collected from './PartReturnStatus/Collected';
import CreditReceived from './PartReturnStatus/CreditReceived';
import CollectedByMainSite from './PartReturnStatus/CollectedByMainSite';
import { SecondaryButton } from '../../../Core/FormInput';
import ReplayIcon from '@material-ui/icons/Replay';
import { Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    button: {
        marginRight: theme.spacing(1)
    },
    backButton: {
        marginRight: theme.spacing(1)
    },
    completed: {
        display: 'inline-block'
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    isButton: {
        float: 'right'
    }
}));

export default function PartReturnSteps({
    List,
    partReturnPartReqID,
    partReturnID,
    pullStepperData,
    pullData,
    partReturnStatusID,
    PullPartData,
    handleRetenToStock,
    partReturnToStock
}) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState();
    const [compID, setCompID] = React.useState(null);
    const [label, setLabel] = React.useState(null);

    console.log(partReturnToStock, 'partReturnToStock');

    let NewList = [...List];

    useEffect(() => {
        let index = NewList.findIndex((k) => k.id == partReturnStatusID);
        if (index > -1) {
            setActiveStep(index + 1);
        }
    }, [List, partReturnStatusID]);

    const { showToast } = useContext(AppContext);

    const handleStep = (step, id, label) => () => {
        setActiveStep(step);
        setCompID(id);
        setLabel(label);
    };

    const handleNext = () => {
        const newActiveStep = activeStep + 1;
        setActiveStep(newActiveStep);
        pullStepperData();
        pullData();
        PullPartData();
    };

    const handleCloseComp = () => {
        setCompID(null);
        setLabel(null);
    };

    let ComponentList = {
        2: (
            <ReturnToPartDept
                handleSubmit={handleNext}
                onClose={handleCloseComp}
                partReturnPartReqID={partReturnPartReqID}
                partReturnID={partReturnID}
                showToast={showToast}
            />
        ),

        3: (
            <AwaitingCollection
                handleSubmit={handleNext}
                onClose={handleCloseComp}
                partReturnPartReqID={partReturnPartReqID}
                partReturnID={partReturnID}
                showToast={showToast}
            />
        ),
        4: (
            <CollectedByMainSite
                handleSubmit={handleNext}
                onClose={handleCloseComp}
                partReturnPartReqID={partReturnPartReqID}
                partReturnID={partReturnID}
                showToast={showToast}
            />
        ),
        5: (
            <ReceivedByMainSite
                handleSubmit={handleNext}
                onClose={handleCloseComp}
                partReturnPartReqID={partReturnPartReqID}
                partReturnID={partReturnID}
                showToast={showToast}
            />
        ),
        6: (
            <Collected
                handleSubmit={handleNext}
                onClose={handleCloseComp}
                partReturnPartReqID={partReturnPartReqID}
                partReturnID={partReturnID}
                showToast={showToast}
            />
        ),
        7: (
            <CreditReceived
                handleSubmit={handleNext}
                onClose={handleCloseComp}
                partReturnPartReqID={partReturnPartReqID}
                partReturnID={partReturnID}
                showToast={showToast}
            />
        )
    };

    const handleStocks = () => {
        setActiveStep(-1);
        handleRetenToStock();
    };

    return (
        <div className={classes.root}>
            {partReturnToStock ? (
                <Alert severity="info">This part has been returned to the stock</Alert>
            ) : (
                <SecondaryButton size="small" className={classes.isButton} onClick={handleStocks}>
                    <Tooltip title="Return To Stock" arrow>
                        <ReplayIcon />
                    </Tooltip>
                </SecondaryButton>
            )}
            <br />
            <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                {List.map((label, index) => {
                    return (
                        <Step key={label.name} disabled={partReturnToStock}>
                            <StepButton
                                onClick={index == activeStep && !label.createdAt ? handleStep(index, label.id, label.name) : () => {}}
                                completed={label.createdAt}
                            >
                                <div style={{ fontSize: 12, fontWeight: 600, color: label.createdAt ? 'green' : 'black' }}>{label.name}</div>
                                {label.createdAt && (
                                    <div style={{ color: 'grey' }}>
                                        (<small>{label.createdBy}</small>
                                        &nbsp;<small>{formatters.DateTimeFormatter(label.createdAt)}</small>)
                                    </div>
                                )}
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
            {compID
                ? compID != 1 && (
                      <DialogComp title={label} onClose={handleCloseComp} maxWidth="md">
                          {' '}
                          {ComponentList[compID]}
                      </DialogComp>
                  )
                : null}
        </div>
    );
}
