import React, { useContext, useEffect, useState } from 'react';
import { getPartReqRenaultStatus } from '../../../../Core/Service/PartReq_services/partReq-service';
import { Grid } from '@material-ui/core';
import TabComponent from '../../../../Core/Controls/Tabs';
import RenaultPartReqsList from '../RenaultPartReqsList';
import { changeLanguage } from 'i18next';
import AppContext from '../../../../App/AppContext';

const RenaultPartReqsTabs = (props) => {
    const { portalSettings } = useContext(AppContext);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let indexID = params.get('id') || 0;
    let branch = params.get('bn') || '';

    const [state, setState] = useState({ dropDownRes: [], selectedIndex: 0 });

    const PullDate = async () => {
        let dropDownRes = await getPartReqRenaultStatus();
        if (dropDownRes.success) {
            let index = dropDownRes.data.partReqStatus.findIndex((k) => indexID == k.id);
            setState((st) => ({ ...st, dropDownRes: dropDownRes.data.partReqStatus, selectedIndex: index > -1 ? index : 0 }));
        }
    };

    useEffect(async () => {
        PullDate();
    }, []);

    const tabs = state.dropDownRes.map((q) => {
        return {
            label: `${q.name}`,
            body: <RenaultPartReqsList code={q.id} bn={branch} />
        };
    });

    return (
        <Grid container>
            <Grid item xs={12}>
                <TabComponent tabList={tabs} showWarning={state.isValueChange} selectedIndex={+state.selectedIndex} />
            </Grid>
        </Grid>
    );
};

export default RenaultPartReqsTabs;
