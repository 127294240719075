import { Card, CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useEffect } from 'react';
import { GetPartsDashboard } from '../../Service/dashbordService';
import { useState } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useWindowSize } from '../../Controls/ScreenResolution';
import './PartsDashboardStyle.scss';
import { Bar, BarChart, CartesianGrid, Cell, LabelList, Legend, Pie, PieChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import { useHistory } from 'react-router';
import { CircularProgressbar } from 'react-circular-progressbar';

const COLORS = Array(150)
    .fill(0)
    .map(() => '#' + Math.floor(Math.random() * 16777215).toString(16));

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: '#f5f5f5',
        border: '2px solid #f5f5f5 ',
        Opacity: 0.9,
        minWidth: 110,
        padding: '5px 0px',
        margin: 3,
        borderRadius: 3
    },
    CardRoot: {
        width: '100%',
        margin: 3,
        border: '2px solid white ',
        Opacity: 0.9,
        minWidth: 120,
        padding: '5px 0px',
        background: 'white',
        borderRadius: 10
    }
}));

const CustomLabel = (props) => {
    return (
        <g>
            {/* <rect x={props.viewBox.x} y={props.viewBox.y} fill="#aaa" width={100} height={30} /> */}
            <text x={props.viewBox.x} y={props.viewBox.y} dy={-3} dx={0} fontSize={11} fontWeight={600}>
                {' '}
                {props.value}
            </text>
        </g>
    );
};

let newARR = [];

const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b, 0);
const valueAccessor = (item) => (item ? item.total : 0);
function scrollbarVisible(element) {
    return element?.scrollWidth > element?.clientWidth;
}

const scrollNext = (id) => {
    document.getElementById('WorkFlow-Btn').scrollBy({ left: 100, top: 0, behavior: 'smooth' });
};

const scrollPrev = (id) => {
    document.getElementById('WorkFlow-Btn').scrollBy({ left: -100, top: 0, behavior: 'smooth' });
};

const scrollJCNext = (id) => {
    document.getElementById('JC-Btn').scrollBy({ left: 100, top: 0, behavior: 'smooth' });
};

const scrollJCPrev = (id) => {
    document.getElementById('JC-Btn').scrollBy({ left: -100, top: 0, behavior: 'smooth' });
};
const PartsDashboardDetailScreen = (props) => {
    const history = useHistory();
    let WorkFlowBtnElementscrollWidth = document.getElementById('WorkFlow-Btn');
    let JCBtnElementscrollWidth = document.getElementById('JC-Btn');

    const [WindowWidths, WindowHeights] = useWindowSize();

    useEffect(() => {
        setState((st) => ({
            ...st,
            isShowScrollArrow: scrollbarVisible(WorkFlowBtnElementscrollWidth)
        }));
    }, [WindowWidths, WorkFlowBtnElementscrollWidth?.scrollWidth]);

    useEffect(() => {
        setState((st) => ({
            ...st,
            isShowScrollForJC: scrollbarVisible(JCBtnElementscrollWidth)
        }));
    }, [WindowWidths, JCBtnElementscrollWidth?.scrollWidth]);
    const classes = useStyles();
    const [state, setState] = useState({
        createdByList: [],
        chartdata: [],
        byBranchList: [],
        sortedByYearMonthDepotList: [],
        uniqueDepotListForStackedGraph: [],
        sumOfAllCreatedByJobs: 0,
        sortedWorkflowBranchList: [],
        BranchDropDownArray: [],
        workflowBranchList: [],
        branchID: 'Default',
        FilterData: [],
        showLoader: true
    });

    const handleBranch = (val) => {
        setState((st) => ({ ...st, branchID: val.target.value }));
    };

    const pullPartsDashboardData = async () => {
        let res = await GetPartsDashboard();
        let workflowJobOverAllTotal = 0;
        let workflowBranchList = [...res.data.workflowBranchList];
        let sortedWorkflowBranchList = [...res.data.workflowBranchList]
            .reduce((acc, obj) => {
                var index = acc.find((item) => item.workflowID === obj.workflowID);
                if (index) {
                    index.total += obj.total;
                    return acc;
                }

                acc.push({ ...obj });
                return acc;
            }, [])
            .map((p) => {
                workflowJobOverAllTotal += p.total;
                return { ...p };
            });
        let sumOfAllCreatedByJobs = 0;
        [...res.data.createdBy].forEach(function (item) {
            sumOfAllCreatedByJobs += item.total;
        });

        let tempChartData = [...res.data.createdBy].map((p) => {
            let CalPer = (p?.total / sumOfAllCreatedByJobs) * 100;
            return {
                ...p,
                DayTotalPercentage: CalPer,
                DayTotalPerFormated: CalPer ? CalPer.toFixed(2) : '0.00',
                name: p.createdBy
            };
        });
        let tempSortedByYearMonthDepotList = [];
        let tempUniqueDepot = [...res.data.byYearMonth].reduce((acc, obj) => {
            var index = acc.find((item) => item.bc === obj.bc);
            if (index) {
                index.total += obj.total;
                return acc;
            }

            acc.push({ ...obj });
            return acc;
        }, []);
        //.map((item) =>
        // return {bc:}
        // item.bc))];
        [...res.data.byYearMonth].forEach((q) => {
            let IsObjIndex = tempSortedByYearMonthDepotList.findIndex((k) => k.name == moment([q.year, q.month - 1]).format('MMM-YY'));
            if (IsObjIndex > -1) {
                tempSortedByYearMonthDepotList[IsObjIndex][q.bc] = q.total;
            } else {
                tempSortedByYearMonthDepotList.push({
                    name: moment([q.year, q.month - 1]).format('MMM-YY'),
                    [q.bc]: q.total,
                    total: 1200
                });
            }
        });

        let BranchDropDownArray = workflowBranchList.reduce((acc, obj) => {
            var index = acc.find((item) => item.branchID === obj.branchID);
            if (index) {
                index.total += obj.total;
                return acc;
            }

            acc.push({ ...obj });
            return acc;
        }, []);

        setState((st) => ({
            ...st,
            sumOfAllCreatedByJobs: sumOfAllCreatedByJobs,
            createdByList: [...res.data.createdBy],
            chartdata: [...tempChartData],
            byBranchList: [...res.data.byBranch],
            uniqueDepotListForStackedGraph: tempUniqueDepot,
            sortedByYearMonthDepotList: tempSortedByYearMonthDepotList,
            sortedWorkflowBranchList: sortedWorkflowBranchList,
            workflowJobOverAllTotal: workflowJobOverAllTotal,
            workflowBranchList: workflowBranchList,
            BranchDropDownArray: BranchDropDownArray,
            showLoader: false
        }));
    };

    useEffect(() => {
        let array = [];
        let newBranchDropDownArray = state.workflowBranchList.reduce((acc, obj) => {
            var index;
            index = acc.find((item) => item.workflowID === obj.workflowID);
            if (index) {
                index.total += obj.total;
                return acc;
            }

            acc.push({ ...obj });
            return acc;
        }, []);

        let filterList = [...state.workflowBranchList]
            .filter((k) => {
                return k.branchID == state.branchID;
            })
            .reduce((acc, obj) => {
                var index;
                index = acc.find((item) => item.workflowID === obj.workflowID);
                if (index) {
                    index.total += obj.total;
                    return acc;
                }

                acc.push({ ...obj });
                return acc;
            }, []);

        if (state.branchID === 'Default') {
            array.push(...newBranchDropDownArray);
        } else {
            array.push(...filterList);
        }

        setState((st) => ({
            ...st,
            FilterData: array
        }));
    }, [state.branchID, state.workflowBranchList]);

    useEffect(() => {
        pullPartsDashboardData();
    }, []);

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name, value }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 1.8;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: 12 }}>
                {name}
            </text>
        );
    };
    const workFlowButtonClicked = (param) => {
        localStorage.setItem('FiltterParams', JSON.stringify({ PartsWorkflowID: param }));
        history.push({
            pathname: '/PartsByCreator',
            search: `?workflowID=${param}&Time=${0}&ProcessId=${props.processID}`
        });
    };

    const createdByButtonClicked = (param) => {
        localStorage.setItem('FiltterParams', JSON.stringify({ cb: param.createdBy, dueTime: 1 }));
        history.push({
            pathname: '/PartsByCreator',
            search: `?DueTime=${1}&ProcessId=${props.processID}`
        });
    };

    const DailyGraphClick = (param) => {
        localStorage.setItem('FiltterParams', JSON.stringify({ branchShortName: param.bsn, dueTime: 1 }));
        history.push({
            pathname: '/PartsByCreator',
            search: `?bn=${param.bsn}&DueTime=${1}&ProcessId=${props.processID}`
        });
    };

    const CreatedByGraphClick = (param) => {
        localStorage.setItem('FiltterParams', JSON.stringify({ cb: param.createdBy, dueTime: 1 }));
        history.push({
            pathname: '/PartsByCreator',
            search: `?&DueTime=${1}&ProcessId=${props.processID}`
        });
    };
    const JobHistororyGraphClick = (param) => {
        localStorage.setItem('FiltterParams', JSON.stringify({ branchShortName: param.bsn, dueTime: 1 }));
        history.push({
            pathname: '/PartsByCreator',
            search: `?&DueTime=${1}&ProcessId=${props.processID}`
        });
    };
    const ByBranchCardClick = (param) => {
        if (state.branchID !== 'Default' || state.branchID) {
            let findBranchObj = state.BranchDropDownArray.filter((m) => m.branchID == state.branchID);
            localStorage.setItem('FiltterParams', JSON.stringify({ branchShortName: findBranchObj[0]?.branchName, PartsWorkflowID: param.workflowID }));
        } else {
            localStorage.setItem('FiltterParams', JSON.stringify({ PartsWorkflowID: param.workflowID }));
        }
        history.push({
            pathname: '/PartsByCreator',
            search: `?workflowID=${param.workflowID}&ProcessId=${props.processID}`
        });
    };
    return (
        <div
            className="Parts_Dash_Main_Container custom-scroll"
            style={{ padding: '10px', overflow: 'auto', height: 'calc(100vh - 185px)', background: '#f5f5f5' }}
        >
            {state.showLoader ? (
                <div
                    style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        width: '100%'
                    }}
                >
                    <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                </div>
            ) : (
                <>
                    {state.sortedWorkflowBranchList.length > 0 && (
                        <Grid container>
                            <div style={{ overflow: 'hidden', display: 'flex', width: '100%' }}>
                                {state.isShowScrollArrow && (
                                    <span onClick={() => scrollPrev()} className="ScrollArrow-Container">
                                        <ChevronLeftIcon />
                                    </span>
                                )}
                                <div style={{ overflow: 'auto', display: 'flex', width: '100%' }} className={`btn-container`} id="WorkFlow-Btn">
                                    {state.sortedWorkflowBranchList.map((m, i) => {
                                        return (
                                            <div
                                                item
                                                style={{ padding: state.isShowScrollArrow ? '0px 5px 0px 0px' : `0px 5px 0px ${i === 0 ? '10px' : '0px'}` }}
                                            >
                                                <Card
                                                    className={classes.root}
                                                    key={i}
                                                    style={{
                                                        background: 'white',
                                                        color: 'black',
                                                        padding: 0
                                                    }}
                                                    onClick={() => workFlowButtonClicked(m.workflowID)}
                                                >
                                                    <div
                                                        style={{
                                                            textAlign: 'left',
                                                            cursor: 'pointer',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            // color: 'rgb(255, 71, 87)',
                                                            overflow: 'hidden',
                                                            padding: '5px 20px'
                                                        }}
                                                    >
                                                        <span style={{ fontSize: 14, marginBottom: 6 }}> {m?.name}</span>
                                                        <div style={{ fontSize: 30, fontWeight: 600 }}> {m?.total}</div>
                                                    </div>
                                                </Card>
                                            </div>
                                        );
                                    })}
                                    <div item style={{ padding: '0px 5px 0px 0px' }}>
                                        <Card
                                            className={classes.root}
                                            style={{
                                                background: state.total === 'total' ? '#BA4546' : 'white',
                                                color: state.total === 'total' ? 'white' : 'black',
                                                padding: 0
                                            }}
                                            onClick={() => workFlowButtonClicked('All')}
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'left',
                                                    cursor: 'pointer',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    paddingBottom: 10,
                                                    padding: '5px 20px'
                                                }}
                                            >
                                                <span style={{ fontSize: 14, marginBottom: 6 }}> Total</span>
                                                <div style={{ fontSize: 30, fontWeight: 600 }}>{state.workflowJobOverAllTotal}</div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                {state.isShowScrollArrow && (
                                    <span onClick={() => scrollNext()} className="ScrollArrow-Container">
                                        <ChevronRightIcon />
                                    </span>
                                )}
                            </div>
                        </Grid>
                    )}

                    <Grid container>
                        {state.createdByList.length > 0 && (
                            <>
                                <Grid item xs={12}>
                                    <div style={{ textAlign: 'left', marginLeft: -5 }}>
                                        <Typography varient="h5" color="secondary" className="dashHeading">
                                            CALL CENTRE JOB CREATION % TODAY
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <div style={{ overflow: 'hidden', display: 'flex', width: '100%' }}>
                                        {state.isShowScrollForJC && (
                                            <span onClick={() => scrollJCPrev()} className="ScrollArrow-Container">
                                                <ChevronLeftIcon />
                                            </span>
                                        )}
                                        <div style={{ overflow: 'auto', display: 'flex', width: '100%' }} className={`btn-container`} id="JC-Btn">
                                            {state.createdByList.map((m, i) => {
                                                return (
                                                    <div
                                                        item
                                                        style={{
                                                            padding: state.isShowScrollForJC ? '0px 5px 0px 0px' : `0px 5px 0px ${i === 0 ? '10px' : '0px'}`
                                                        }}
                                                    >
                                                        <Card
                                                            className={classes.root}
                                                            key={i}
                                                            style={{
                                                                background: 'white',
                                                                color: 'black',
                                                                padding: 0
                                                            }}
                                                            onClick={() => createdByButtonClicked(m)}
                                                        >
                                                            <div
                                                                style={{
                                                                    textAlign: 'left',
                                                                    cursor: 'pointer',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    // color: 'rgb(255, 71, 87)',
                                                                    overflow: 'hidden',
                                                                    padding: '5px 20px'
                                                                }}
                                                            >
                                                                <span style={{ fontSize: 14, marginBottom: 6 }}> {m?.createdBy}</span>
                                                                <div style={{ fontSize: 30, fontWeight: 600 }}> {m?.total}</div>
                                                            </div>
                                                        </Card>
                                                    </div>
                                                );
                                            })}
                                            <div item style={{ padding: '0px 5px 0px 0px' }}>
                                                <Card
                                                    className={classes.root}
                                                    style={{
                                                        background: state.total === 'total' ? '#BA4546' : 'white',
                                                        color: state.total === 'total' ? 'white' : 'black',
                                                        padding: 0
                                                    }}
                                                    // onClick={() => workFlowButtonClicked(  'All' )}
                                                >
                                                    <div
                                                        style={{
                                                            textAlign: 'left',
                                                            // cursor: 'pointer',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            paddingBottom: 10,
                                                            padding: '5px 20px'
                                                        }}
                                                    >
                                                        <span style={{ fontSize: 14, marginBottom: 6 }}> Total</span>
                                                        <div style={{ fontSize: 30, fontWeight: 600 }}>{state.sumOfAllCreatedByJobs}</div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                        {state.isShowScrollForJC && (
                                            <span onClick={() => scrollJCNext()} className="ScrollArrow-Container">
                                                <ChevronRightIcon />
                                            </span>
                                        )}
                                    </div>
                                </Grid>
                            </>
                        )}

                        {state.chartdata.length > 0 && (
                            <Grid item xs={12} sm={12} md={5} lg={6} xl={6} container spacing={2}>
                                <Grid item xs={12} sm={12} md={7} lg={7} xl={8}>
                                    <div className="pieChartSize">
                                        <ResponsiveContainer>
                                            <PieChart>
                                                <Pie
                                                    data={state.chartdata}
                                                    cx={190}
                                                    cy={90}
                                                    paddingAngle={3}
                                                    outerRadius={60}
                                                    innerRadius={40}
                                                    fill="#8884d8"
                                                    dataKey="DayTotalPercentage"
                                                    label={renderCustomizedLabel}
                                                >
                                                    {state.chartdata.map((entry, index) => (
                                                        <>
                                                            <Cell
                                                                key={`cell-${index}`}
                                                                fill={COLORS[index % COLORS.length]}
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => CreatedByGraphClick(entry)}
                                                            />
                                                        </>
                                                    ))}
                                                </Pie>
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </div>
                                </Grid>
                                <Grid item style={{ marginTop: WindowWidths >= 480 ? 21 : 0 }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: WindowWidths >= 480 ? 'left' : 'center',
                                            justifyContent: WindowWidths >= 480 ? 'left' : 'center',
                                            Height: '180px',
                                            overflowY: 'auto',
                                            overflowX: 'hidden'
                                        }}
                                        className="custom-scroll"
                                    >
                                        <div>
                                            {state?.chartdata.map((m, i) => {
                                                let color = COLORS[i];
                                                return (
                                                    <div style={{ padding: 0 }}>
                                                        <div
                                                            style={{
                                                                fontSize: 13,
                                                                maxWidth: 210,
                                                                minWidth: 110,
                                                                display: 'flex',
                                                                justifyContent: 'flex-start'
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    background: color,
                                                                    height: 10,
                                                                    width: 10,
                                                                    marginTop: 4
                                                                }}
                                                            ></div>
                                                            <div style={{ fontWeight: 700 }}>&nbsp;{m.DayTotalPerFormated}</div>
                                                            <div>&nbsp;{m.createdBy}</div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography varient="h5" color="secondary" className="dashHeading">
                                        DEPOT TOTAL DAILY JOBS BY DEPOT
                                    </Typography>
                                    <ResponsiveContainer width="100%" height={350}>
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={state.byBranchList}
                                            margin={{
                                                top: 20,
                                                right: 0,
                                                left: 10,
                                                bottom: 5
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="bc" style={{ fontSize: 10 }} />
                                            <YAxis style={{ fontSize: 10 }} />
                                            <Tooltip />
                                            {/* <Legend /> */}
                                            <ReferenceLine y={0} stroke="#000" />
                                            <Bar dataKey="total" maxBarSize={20} label={{ position: 'top', fontSize: 16 }}>
                                                {state.byBranchList.map((entry, index) => (
                                                    <Cell
                                                        key={`cell-${index}`}
                                                        fill={COLORS[index % COLORS.length]}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => DailyGraphClick(entry)}
                                                    />
                                                ))}
                                            </Bar>
                                        </BarChart>
                                    </ResponsiveContainer>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <Typography varient="h5" color="secondary" className="dashHeading">
                                        JOBS CREATED: HISTORICAL
                                    </Typography>
                                    <ResponsiveContainer width="100%" height={350}>
                                        <BarChart
                                            width={'100%'}
                                            height={'100%'}
                                            data={state.sortedByYearMonthDepotList}
                                            margin={{
                                                top: 20,
                                                right: 30,
                                                left: 20,
                                                bottom: 5
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" interval={0} style={{ fontSize: 10 }} />
                                            <YAxis style={{ fontSize: 10 }} />
                                            <Tooltip />
                                            <Legend wrapperStyle={{ fontSize: '10px' }} />
                                            {state.uniqueDepotListForStackedGraph.map((u, i) => {
                                                return (
                                                    <Bar
                                                        dataKey={`${u.bc}`}
                                                        stackId={'bar'}
                                                        fill={COLORS[i]}
                                                        maxBarSize={25}
                                                        // label={{ position: 'right', fontSize: 16 }}
                                                        label={{
                                                            position: 'top',
                                                            fontSize: 11,
                                                            fill: 'black',
                                                            fontWeight: 600,
                                                            offset: 10,
                                                            interval: -10,
                                                            angle: 0
                                                        }}
                                                        // label={CustomLabel}
                                                    >
                                                        {/* <LabelList
                                                            fill="#000"
                                                            position="right"
                                                            valueAccessor={valueAccessor(u.bc)}
                                                            // valueAccessor={(entry, i) =>
                                                            //     i === state.sortedByYearMonthDepotList.length - 1 ? console.log(entry[u.bc]) : ''
                                                            // }
                                                        /> */}
                                                        {/* <LabelList dataKey="total" fill="black" position="top" /> */}
                                                        {/* <LabelList
                                                            dataKey={`${u.bc}`}
                                                            position="right"
                                                            fill="black"
                                                            angle={0}
                                                            offset={10}
                                                            interval={0}
                                                            style={{ fontSize: 10, fontWeight: 600, color: 'red', padding: 10 }}
                                                        /> */}
                                                        {/* <LabelList
                                                            dataKey={`${u.bc}`}
                                                            position="right"
                                                            fill="black"
                                                            angle={0}
                                                            offset={10}
                                                            interval={0}
                                                            style={{ fontSize: 10, fontWeight: 600, color: 'red', padding: 10 }}
                                                        /> */}
                                                        <Cell
                                                            key={`cell-${i}`}
                                                            fill={COLORS[i % COLORS.length]}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => JobHistororyGraphClick(u)}
                                                        />
                                                    </Bar>
                                                );
                                            })}
                                            {/* <Bar dataKey="uv" stackId="a" fill="#82ca9d" /> */}
                                        </BarChart>
                                    </ResponsiveContainer>
                                </Grid>
                            </Grid>
                        </Grid>
                        {state.sortedWorkflowBranchList.length > 0 && (
                            <Grid item xs={12}>
                                <Grid container justifyContent="space-between" spacing={0}>
                                    <Grid item xs={12} sm={6}>
                                        <div
                                            style={{
                                                margin: '0px 20px',
                                                textAlign: 'left'
                                            }}
                                        >
                                            By Branch (WorkFlows)
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <div style={{ float: 'right', marginRight: 40 }}>
                                            <select onChange={handleBranch} className="selectclass" value={state.byTimeBranch}>
                                                <option value={'Default'}>All Branches</option>
                                                {state.BranchDropDownArray.map((btn, i) => {
                                                    return (
                                                        <option key={i} value={btn.branchID}>
                                                            {btn.branchName}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </Grid>
                                </Grid>

                                <div style={{ padding: '0px 30px' }}>
                                    <hr
                                        style={{
                                            flexShrink: 0,
                                            borderWidth: '0px 0px thin',
                                            borderStyle: 'solid',
                                            borderColor: 'rgba(58, 53, 65, 0.12)',
                                            margin: '1.1rem 0px'
                                        }}
                                    />
                                </div>
                                <Grid container spacing={0} style={{ padding: '0px 20px' }} justifyContent="flex-start">
                                    {state.FilterData.map((g) => {
                                        return (
                                            <div>
                                                <Grid item style={{ padding: '5px 0px' }}>
                                                    <div
                                                        style={{
                                                            borderLeft: '5px solid #88EBA4',
                                                            padding: '5px 0px 0px 20px',
                                                            width: '100%',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => ByBranchCardClick(g)}
                                                    >
                                                        <div style={{ paddingRight: 20, paddingBottom: 5, textAlign: 'left' }}>
                                                            <span className="lableFont">{g.name}</span>
                                                            <div className="valueFont">{g.total}</div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </div>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </>
            )}
        </div>
    );
};

export default PartsDashboardDetailScreen;
