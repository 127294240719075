import React, { useEffect, useState, useContext } from 'react';
import { Grid, FormLabel, Typography, makeStyles, Stepper, Hidden, StepButton, Step } from '@material-ui/core';
import { SecondaryButton, SelectBox, TextBox, YesNoButton } from '../../../Core/FormInput';
import AppContext from '../../../App/AppContext';
import { getPartReturnByPartReqID, addPartReturnByPartReqID, getPartReturnStatus } from '../../../Core/Service/PartReq_services/partReq-service';
import CircularIndeterminate from '../ReportsScreens/ReportsScreens/BudgetCore/loader';
import PartHeader from './partHeader';
import PartReturnSteps from './PartReturnStepper';

const PartReturnAddUpdate = (props) => {
    const [state, setState] = useState({
        partNumber: '',
        description: '',
        partID: props.partID,
        errors: {},
        isActive: true,
        partReturnStatusList: [],
        partReturnStatusID: null,
        showLoader: true,
        isDisabled: true,
        partReturnPartReqID: null,
        partReturnID: null,
        partReturnToStock: false
    });

    const { showToast, showModal } = useContext(AppContext);
    const { jobDetails, selectedData } = props;

    const pullStepperData = async () => {
        let screendropres = await getPartReturnStatus(state.partReturnPartReqID);
        if (screendropres.success) {
            setState((st) => ({ ...st, partReturnStatusList: screendropres?.data?.list }));
        }
    };

    const handleRetenToStock = () => {
        setState((st) => ({
            ...st,
            partReturnToStock: true
        }));
    };

    useEffect(() => {
        if (state.partReturnPartReqID) {
            pullStepperData();
        }
    }, [state.partReturnPartReqID]);

    const pullData = async () => {
        if (selectedData) {
            let res = await getPartReturnByPartReqID(selectedData.partReqID);
            if (res.success) {
                let data = res.data?.list[0];
                setState((st) => ({
                    ...st,
                    partReturnPartReqID: data?.partReturnPartReqID,
                    partReturnQuantity: data?.partReturnQuantity || selectedData.partReqQuantity,
                    partReturnStatusID: data?.partReturnStatusID,
                    partReturnSupplierInvoiceNo: data?.partReturnSupplierInvoiceNo,
                    partReturnReason: data?.partReturnReason,
                    partReturnReturnedBy: data?.partReturnReturnedBy,
                    partReturnReceivedBy: data?.partReturnReceivedBy,
                    partReturnCreditNo: data?.partReturnCreditNo,
                    partReturnID: data?.partReturnID,
                    showLoader: false,
                    isDisabled: data?.partReturnStatusID == 1,
                    partReturnInternalInvoiceNo: data.partReturnInternalInvoiceNo,
                    partReturnInternalCreditNo: data.partReturnInternalCreditNo,
                    partReturnToStock: data.partReturnToStock
                }));
            }
        }
    };

    useEffect(async () => {
        pullData();
    }, []);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const handleValidation = () => {
        let fields = state.fields;
        let errors = {};
        let formIsValid = true;

        if (!state.partReturnReason) {
            formIsValid = false;
            errors['partReturnReason'] = 'Reasnon cannot be empty';
        }
        if (!state.partReturnStatusID) {
            formIsValid = false;
            errors['partReturnStatusID'] = 'Status cannot be empty';
        }
        if (!state.partReturnReturnedBy) {
            formIsValid = false;
            errors['+partReturnReturnedBy'] = 'This field cannot be empty';
        }
        if (state.partReturnQuantity > selectedData.partReqQuantity) {
            formIsValid = false;
            errors.partReturnQuantity = 'Quantity is higher than the requested part quantity.';
        }
        // if (state.partReturnStatusID === 7) {
        //     formIsValid = false;
        //     errors.CreditNo = 'This field cannot be empty';
        // }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (handleValidation()) {
            let data = {
                partReturnPartReqID: state?.partReturnPartReqID,
                partReturnQuantity: state?.partReturnQuantity,
                partReturnStatusID: state?.partReturnStatusID,
                partReturnSupplierInvoiceNo: state?.partReturnSupplierInvoiceNo,
                partReturnReason: state?.partReturnReason,
                partReturnReturnedBy: state?.partReturnReturnedBy,
                partReturnCreditNo: state?.partReturnCreditNo,
                partReturnID: state?.partReturnID,
                partReturnReceivedBy: state?.partReturnReceivedBy,
                partReturnInternalInvoiceNo: state.partReturnInternalInvoiceNo,
                partReturnInternalCreditNo: state.partReturnInternalCreditNo,
                partReturnToStock: state.partReturnToStock
            };
            let res = await addPartReturnByPartReqID(data);
            if (res.success) {
                showToast(res.data?.message || `Parts Added Successfully`);
                props.onClose(true);
            } else {
                console.error(res.message);
            }
        }
    };

    return (
        <div>
            {state.showLoader ? (
                <Grid container alignItems="center" justifyContent="center" style={{ height: '100%', minHeight: 400, minWidth: 550 }}>
                    <Grid item>
                        <CircularIndeterminate />
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid container spacing={2} justifyContent="space-between">
                        <Grid item>
                            <div style={{ textAlign: 'left', fontSize: 12 }}>Job No.</div>
                            <Typography style={{ fontSize: 18, fontWeight: 500, textAlign: 'left' }}>{jobDetails?.wipNumber || 'N/A'}</Typography>
                        </Grid>
                        <Grid item>
                            <div style={{ textAlign: 'left', fontSize: 12 }}>Reg No. </div>
                            <Typography style={{ fontSize: 18, fontWeight: 500, textAlign: 'left' }}>{jobDetails?.registration}</Typography>
                        </Grid>
                        <Grid item>
                            <div style={{ textAlign: 'left', fontSize: 12 }}>Branch</div>
                            <Typography style={{ fontSize: 18, fontWeight: 500, textAlign: 'left' }}>{jobDetails?.bn || 'N/A'}</Typography>
                        </Grid>
                        <Grid item>
                            <div style={{ textAlign: 'left', fontSize: 12 }}>Customer</div>
                            <Typography style={{ fontSize: 18, fontWeight: 500, textAlign: 'left' }}>{jobDetails?.customerName || 'N/A'}</Typography>
                        </Grid>
                        <Grid item>
                            <div style={{ textAlign: 'left', fontSize: 12 }}>Part Number</div>
                            <Typography style={{ fontSize: 18, fontWeight: 500, textAlign: 'left' }}>{selectedData?.partReqPartNumber || 'N/A'}</Typography>
                        </Grid>
                        <Grid item>
                            <div style={{ textAlign: 'left', fontSize: 12 }}>Supplier</div>
                            <Typography style={{ fontSize: 18, fontWeight: 500, textAlign: 'left' }}>{selectedData?.partReqSupplier || 'N/A'}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <PartReturnSteps
                            List={state?.partReturnStatusList || []}
                            partReturnPartReqID={state.partReturnPartReqID}
                            partReturnID={state.partReturnID}
                            pullStepperData={pullStepperData}
                            pullData={pullData}
                            partReturnStatusID={state.partReturnStatusID}
                            PullPartData={props.PullPartData}
                            handleRetenToStock={handleRetenToStock}
                            partReturnToStock={state.partReturnToStock}
                        />
                    </Grid>

                    {/* <Grid item xs={12} sm={6}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Status
                        </FormLabel>
                        <SelectBox
                            style={{ marginTop: 8 }}
                            onChange={handleInput}
                            value={state.partReturnStatusID}
                            name="partReturnStatusID"
                            List={state?.partReturnStatusList || []}
                        />
                        <span className="mandatoryfields">{state.errors?.partReturnStatusID}</span>
                    </Grid> */}
                    <Grid item xs={12} sm={12}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Reason
                        </FormLabel>
                        <TextBox multiline rows={4} name="partReturnReason" onChange={handleInput} value={state.partReturnReason} />
                        <span className="mandatoryfields">{state.errors?.partReturnReason}</span>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormLabel component="legend">Quantity</FormLabel>
                        <TextBox name="partReturnQuantity" onChange={handleInput} value={state.partReturnQuantity} autoFocus={true} />
                        <span className="mandatoryfields">{state.errors?.partReturnQuantity}</span>
                    </Grid>
                    <Grid item xs={12} sm={4} className="mandatoryfields">
                        <FormLabel component="legend">Return By</FormLabel>
                        <TextBox name="partReturnReturnedBy" onChange={handleInput} value={state.partReturnReturnedBy} />
                        <span className="mandatoryfields">{state.errors?.partReturnReturnedBy}</span>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormLabel component="legend">Received By</FormLabel>
                        <TextBox name="partReturnReceivedBy" onChange={handleInput} value={state.partReturnReceivedBy} />
                        <span className="mandatoryfields">{state.errors?.partReturnReceivedBy}</span>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormLabel component="legend">Supplier Invoice No</FormLabel>
                        <TextBox name="partReturnSupplierInvoiceNo" onChange={handleInput} value={state.partReturnSupplierInvoiceNo} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormLabel component="legend">Credit No</FormLabel>
                        <TextBox name="partReturnCreditNo" onChange={handleInput} value={state.partReturnCreditNo} />
                        <span className="mandatoryfields">{state?.errors?.CreditNo}</span>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormLabel component="legend">Internal Invoice No</FormLabel>
                        <TextBox name="partReturnInternalInvoiceNo" onChange={handleInput} value={state.partReturnInternalInvoiceNo} />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormLabel component="legend">Internal Credit No</FormLabel>
                        <TextBox name="partReturnInternalCreditNo" onChange={handleInput} value={state.partReturnInternalCreditNo} />
                        <span className="mandatoryfields">{state?.errors?.CreditNo}</span>
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: 20 }}>
                        <SecondaryButton fullWidth onClick={handleSubmit} disabled={state.isDisabled}>
                            Save
                        </SecondaryButton>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default PartReturnAddUpdate;
