import { Card, CircularProgress, Grid, Hidden, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { getBranchAndFranchise } from '../../Service/branch-screen-service';
import { Alert } from '@material-ui/lab';

export default function PartReqCommon({ header, state, array, classes, handleChangeByPartsReq, List, handleClick }) {
    return (
        <Grid item container xs={12} sm={12} md={12} lg={12} xl={6} style={{ padding: 5, boxSize: 'none' }}>
            {state.showHeaderLoader ? (
                <div className={classes.root}>
                    <div className="pieChartSize" style={{ display: 'flex' }}>
                        <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto', alignSelf: 'center' }} />
                    </div>
                </div>
            ) : (
                <Card className={classes.root}>
                    <Hidden only={['xs']}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={8}>
                                <Typography
                                    vraient="h5"
                                    color="secondary"
                                    className="dashHeading"
                                    style={{
                                        margin: '10px 10px',
                                        textAlign: 'left'
                                    }}
                                >
                                    {header}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div
                                    style={{
                                        float: 'right',
                                        margin: 10
                                    }}
                                >
                                    <select onChange={handleChangeByPartsReq} className="selectclass" value={array.byTimeBranch}>
                                        <option value={'Default'}>All Branches</option>
                                        {state.chartdata.map((btn, i) => {
                                            return (
                                                <option key={i} value={btn.name}>
                                                    {btn.name}{' '}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Typography
                                    vraient="h5"
                                    color="secondary"
                                    className="dashHeading"
                                    style={{
                                        margin: '10px 10px',
                                        textAlign: 'left'
                                    }}
                                >
                                    {header}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <div
                                    style={{
                                        margin: '0px 20px',
                                        textAlign: 'left'
                                    }}
                                >
                                    <span className="lableFont">Branch</span>
                                    <select onChange={handleChangeByPartsReq} className="selectclass" value={array.byTimeBranch}>
                                        <option value={'Default'}>All Branches</option>
                                        {state.chartdata.map((btn, i) => {
                                            return (
                                                <option key={i} value={btn.name}>
                                                    {btn.name}{' '}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </Grid>
                        </Grid>
                    </Hidden>

                    <Grid item xs={12} style={{ padding: '5px 5px' }}>
                        <div
                            style={{
                                textAlign: 'left',
                                padding: '0px 20px',
                                fontFamily: ` Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
                                fontWeight: 500
                            }}
                        ></div>
                    </Grid>
                    <div style={{ padding: '0px 30px' }}>
                        <hr
                            style={{
                                flexShrink: 0,
                                borderWidth: '0px 0px thin',
                                borderStyle: 'solid',
                                borderColor: 'rgba(58, 53, 65, 0.12)',
                                margin: '1.1rem 0px'
                            }}
                        />
                    </div>
                    <Grid container spacing={1} style={{ padding: '0px 20px' }}>
                        {List.map((item, index) => {
                            return (
                                <Grid
                                    item
                                    xs={6}
                                    sm={3}
                                    style={{ padding: '5px 5px' }}
                                    onClick={() =>
                                        handleClick(
                                            {
                                                workflow: array.byTimeObj?.workflow,
                                                bn: array.byTimeObj?.bn,
                                                id: item.code || '',
                                                statusID: item.statusid || '',
                                                label: item.label
                                            },
                                            true
                                        )
                                    }
                                >
                                    <div
                                        style={{
                                            borderLeft: item.BarColor,
                                            padding: '5px 0px 0px 20px',
                                            width: '100%',
                                            cursor: 'pointer'
                                        }}
                                        // onClick={() => buttonClicked(m)}
                                    >
                                        <div
                                            style={{
                                                paddingRight: 20,
                                                paddingBottom: 5,
                                                textAlign: 'left'
                                            }}
                                        >
                                            <span className="lableFont">{item.label}</span>
                                            <div className="valueFont">{item.value}</div>
                                        </div>
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Card>
            )}
        </Grid>
    );
}
