import React, { useEffect, useState, useMemo } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import Grid from '@material-ui/core/Grid';
import { TextBox } from '../FormInput';
import { some } from 'lodash';
import { FormLabel, makeStyles } from '@material-ui/core';

let searchTimer;

const useStyles = makeStyles((theme) => ({
    root: {
        // '& .MuiDataGrid-renderingZone': {
        //     backgroundColor: 'red',
        //     maxHeight: 'none !important'
        // },
        '& .MuiDataGrid-renderingZone': {
            maxHeight: 'none !important'
        },
        '& .MuiDataGrid-cell': {
            lineHeight: 'unset !important',
            maxHeight: 'none !important',
            whiteSpace: 'normal !important'
        },
        '& .MuiDataGrid-row': {
            maxHeight: 'none !important'
        },
        '& .MuiDataGrid-virtualScroller': {
            overflowX: 'hidden'
        }
    }
}));

export default function DataGridComp(props) {
    const classes = useStyles();
    const [state, setState] = useState({
        rows: [],
        columns: [],
        filteredRows: [],
        filterText: ''
    });

    useEffect(() => {
        const rows = props.rows || [];
        const headerHeight = props.headerHeight || 30;
        const rowHeight = props.rowHeight || 30;
        setState((st) => ({ ...st, rows: rows, headerHeight: headerHeight, rowHeight: rowHeight }));
    }, [props.rows, props.headerHeight, props.rowHeight]);

    const setFilterText = (e) => {
        const vl = e.target.value;
        setState((st) => ({ ...st, filterText: vl }));
        searchTimer && clearTimeout(searchTimer);
        searchTimer = setTimeout(() => {
            setFilteredRow();
        }, 500);
    };

    const setFilteredRow = () => {
        setState((st) => {
            const newSt = { ...st };
            const { rows, filterText } = newSt;
            let filterd = [];
            filterd = [...rows];
            if (filterText.trim().length > 0) {
                filterText
                    .trim()
                    .split(' ')
                    .map((t) => t.trim().toLowerCase())
                    .forEach((src) => {
                        filterd = filterd.filter((rw) => some(rw, (vl) => `${vl}`.toLowerCase().includes(src)));
                    });
            }
            newSt.filteredRows = filterd;
            return newSt;
        });
    };

    const gridStyle = useMemo(() => {
        return {
            height: props.height || props.offset ? `calc(100vh - ${props.offset}px)` : 'calc(100vh - 230px)',
            width: props.width || 'auto',
            minWidth: props.minWidth || 'auto',
            marginTop: props.enableSearch ? 10 : 20
        };
    }, [props.height, props.width, props.enableSearch]);

    useEffect(() => {
        setFilteredRow();
    }, [state.rows]);

    const CustomNoRowsOverlay = props.CustomNoRowsOverlay;

    return (
        <div style={{ margin: props.enableSearch ? '10px' : '' }}>
            <Grid container className="roots" spacing={1} alignItems="center">
                {props.enableSearch && (
                    <Grid item xs={12} sm={6}>
                        <FormLabel component="legend">Search</FormLabel>
                        <TextBox autoFocus={true} required name="Search" onChange={setFilterText} value={state.filterText} />

                        {/* <TextBox autoFocus={true} required type="number" onChange={setFilterText} value={state.filterText} name="Search" /> */}
                    </Grid>
                )}
                {props.buttonComp && (
                    <Grid item xs={6} sm={6}>
                        {props.buttonComp}
                    </Grid>
                )}
                <Grid item xs={12} sm={12} style={{ overflow: 'auto' }}>
                    <div style={gridStyle}>
                        <DataGrid
                            className={classes.root}
                            rows={state.filteredRows}
                            columns={props.columns}
                            components={{
                                NoRowsOverlay: CustomNoRowsOverlay,
                                Footer: props.FooterComp
                            }}
                            headerHeight={state.headerHeight}
                            rowHeight={state.rowHeight}
                            // hideFooter={true}
                            hideFooter={!props.showFooter}
                            // pageSize={100}
                            disableColumnMenu={true}
                            showColumnRightBorder={true}
                            onRowSelected={props.onRowSelected}
                            onCellDoubleClick={props.onCellDoubleClick}
                            onRowDoubleClick={props.onRowDoubleClick}
                            onRowClick={props.onRowClick}
                            hideFooterPagination={props.hideFooterPagination || false}
                            hideFooterSelectedRowCount={props.hideFooterSelectedRowCount || false}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}
