import React, { useContext, useEffect, useState } from 'react';
import {
    GetInternalDashHeaderRecords,
    GetInternalDashReturnReasons,
    GetInternalDashTechnicianJobs,
    GetInternalDashClockEntries,
    GetInternalDashReturn,
    GetInternalDashPartReq,
    GetUserPreferenceWorkflowsList
} from '../Service/dashbordService';
import { Card, Grid, Typography, Avatar, CircularProgress, Button, Fab, ButtonGroup, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ChartCommonComp } from '../Chart/barChart';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { PieChart, Pie, Sector, LabelList } from 'recharts';
import { CodeSharp, ContactsOutlined, CheckBox } from '@material-ui/icons';
import HomeIcon from '@material-ui/icons/Home';
import FilterListIcon from '@material-ui/icons/FilterList';
import _ from 'lodash';
import GijgoGrid from '../../Components/internal/dashboard';
import AppContext from '../../App/AppContext';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ReactDOM from 'react-dom';
import DashboardCharts from './dashboardCharts';
import GridFilterModal from '../../Components/internal/dashboard/FilterModal';
import AssignTech from '../../Components/internal/assign-technician/assignTechnModal';
import DialogComp from '../Modal/dialogModal';
import { getGridFilterList } from '../Service/workflow-service';
import Grow from '@material-ui/core/Grow';
import { useHistory } from 'react-router-dom';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useWindowSize } from '../Controls/ScreenResolution';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import FlagIcon from '@material-ui/icons/Flag';
import { async } from 'q';
import { WorkFlowDragNDropComponent } from './WorkFlowDragNDropComponent';
import FilterModalMainTabComponent from '../../Components/internal/dashboard/FilterModalMainTabComp';
import { SpeedDial, SpeedDialAction, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: '#f5f5f5',
        border: '2px solid #f5f5f5 ',
        Opacity: 0.9,
        minWidth: 110,
        padding: '5px 0px',
        margin: 3,
        borderRadius: 3
    }
}));

const FilterButton = ({ isActive, onClick, label }) => {
    return (
        <Button
            style={{
                fontSize: 10,
                color: isActive ? 'green' : 'black'
            }}
            onClick={onClick}
        >
            {isActive ? <CheckCircleIcon style={{ color: 'green', fontSize: 13 }} /> : null}
            &nbsp; {label}
        </Button>
    );
};

let config = {
    // act: {
    //     from: '',
    //     to: '',
    //     getSequence: 'actionSequence',
    //     postSequence: 'actionSequence'
    // },
    wipNumber: {
        from: 'displayWIPNo',
        to: 'userGridColumnDisplayWIPNo',
        getSequence: 'wipNoSequence',
        postSequence: 'WIPNoSequence'
    },
    registration: {
        from: 'displayVehicleReg',
        to: 'userGridColumnDisplayVehicleReg',
        getSequence: 'vehicleRegSequence',
        postSequence: 'VehicleRegSequence'
    },
    makeModel: {
        from: 'displayModel',
        to: 'userGridColumnDisplayModel',
        getSequence: 'modelSequence',
        postSequence: 'modelSequence'
    },
    customerName: {
        from: 'displayCustomer',
        to: 'userGridColumnDisplayCustomer',
        getSequence: 'customerSequence',
        postSequence: 'customerSequence'
    },
    dateDueIn: {
        from: 'displayDateDueIn',
        to: 'userGridColumnDisplayDateDueIn',
        getSequence: 'dateDueInSequence',
        postSequence: 'dateDueInSequence'
    },
    dateDueOut: {
        from: 'displayDateDueOut',
        to: 'userGridColumnDisplayDateDueOut',
        getSequence: 'dateDueOutSequence',
        postSequence: 'dateDueOutSequence'
    },
    returnReason: {
        from: 'displayReturnReason',
        to: 'userGridColumnDisplayReturnReason',
        getSequence: 'returnReasonSequence',
        postSequence: 'returnReasonSequence'
    },
    branchShortName: {
        from: 'displayBranch',
        to: 'userGridColumnDisplayBranch',
        getSequence: 'branchSequence',
        postSequence: 'branchSequence'
    },
    franchiseName: {
        from: 'displayFranchise',
        to: 'userGridColumnDisplayFranchise',
        getSequence: 'franchiseSequence',
        postSequence: 'franchiseSequence'
    },
    st: {
        from: 'displayJobDescription',
        to: 'userGridColumnDisplayJobDescription',
        getSequence: 'jobDescriptionSequence',
        postSequence: 'jobDescriptionSequence'
    },
    accountNo: {
        from: 'displayAccount',
        to: 'userGridColumnDisplayAccount',
        getSequence: 'accountSequence',
        postSequence: 'accountSequence'
    },
    cb: {
        from: 'displayCreatedBy',
        to: 'userGridColumnDisplayCreatedBy',
        getSequence: 'createdBySequence',
        postSequence: 'createdBySequence'
    },
    assigned: {
        from: 'displayAssignedDate',
        to: 'userGridColumnDisplayAssignedDate',
        getSequence: 'assignedDateSequence',
        postSequence: 'assignedDateSequence'
    },
    taken: {
        from: 'displayTimeTaken',
        to: 'userGridColumnDisplayTimeTaken',
        getSequence: 'timeTakenSequence',
        postSequence: 'timeTakenSequence'
    },
    status: {
        from: 'displayStatus',
        to: 'UserGridColumnDisplayStatus',
        getSequence: 'statusSequence',
        postSequence: 'statusSequence'
    },
    assignedDays: {
        from: 'displayAssignedDays',
        to: 'UserGridColumnDisplayAssignedDays',
        getSequence: 'assignedDaysSequence',
        postSequence: 'assignedDaysSequence'
    },
    owningOperator: {
        from: 'displayOwningOperator',
        to: 'UserGridDisplayColumnOwningOperator',
        getSequence: 'owningOperatorSequence',
        postSequence: 'owningOperatorSequence'
    },
    jobAssignedUser: {
        from: 'displayJobAssignedUser',
        to: 'UserGridColumnDisplayJobAssignedUser',
        getSequence: 'jobAssignedUserSequence',
        postSequence: 'jobAssignedUserSequence'
    },
    chassisNumber: {
        from: 'displayChassisNumber',
        to: 'UserGridColumnDisplayChassisNumber',
        getSequence: 'chassisNumberSequence',
        postSequence: 'chassisNumberSequence'
    },
    courtesyCarReq: {
        from: 'displayCourtesyCarReq',
        to: 'UserGridColumnDisplayCourtesyCarReq',
        getSequence: 'courtesyCarReqSequence',
        postSequence: 'courtesyCarReqSequence'
    },
    customerWaiting: {
        from: 'displayCustomerWaiting',
        to: 'UserGridColumnDisplayCustomerWaiting',
        getSequence: 'customerWaitingSequence',
        postSequence: 'customerWaitingSequence'
    },
    wipAge: {
        from: 'displayWIPAge',
        to: 'UserGridColumnDisplayWIPAge',
        getSequence: 'wipAgeSequence',
        postSequence: 'wipAgeSequence'
    },
    isFMOrWarranty: {
        from: 'displayIsFMOrWarranty',
        to: 'UserGridColumnDisplayIsFMOrWarranty',
        getSequence: 'isFMOrWarrantySequence',
        postSequence: 'isFMOrWarrantySequence'
    },
    isBreakdown: {
        from: 'displayIsBreakdown',
        to: 'UserGridColumnDisplayIsBreakdown',
        getSequence: 'isBreakdownSequence',
        postSequence: 'isBreakdownSequence'
    },
    isNightShift: {
        from: 'displayIsNightShift',
        to: 'UserGridColumnDisplayIsNightShift',
        getSequence: 'isNightShiftSequence',
        postSequence: 'isNightShiftSequence'
    },
    isHighPriority: {
        from: 'displayIsHighPriority',
        to: 'UserGridColumnDisplayIsHighPriority',
        getSequence: 'isHighPrioritySequence',
        postSequence: 'isHighPrioritySequence'
    }
};

const isFalseValue = (val) => {
    if (val == false) {
        return false;
    } else {
        return true;
    }
};

function scrollbarVisible(element) {
    return element?.scrollWidth > element?.clientWidth;
}

const scrollNext = (id) => {
    document.getElementById('WorkFlow-Btn').scrollBy({ left: 100, top: 0, behavior: 'smooth' });
};

const scrollPrev = (id) => {
    document.getElementById('WorkFlow-Btn').scrollBy({ left: -100, top: 0, behavior: 'smooth' });
};

const Dashbord = (props) => {
    let WorkFlowBtnElementscrollWidth = document.getElementById('WorkFlow-Btn');

    const classes = useStyles();
    const { portalSettings, getUserPreference, isAppReaload } = useContext(AppContext);

    let history = useHistory();
    const [WindowWidths, WindowHeights] = useWindowSize();
    const [state, setState] = useState({
        isShowScrollArrow: false,
        hideWorkflowScrollbar: false,
        getUrl: `WorkflowJobs/GetWorkflow`,
        workflowData: [],
        chartdata: [],
        headerRecord: [],
        tempHeaderRecord: [],
        rawHeaderData: [],
        returnReasonBarData: [],
        returnReasons: [],
        allHeaderDataKeys: [],
        allHeaderData: [],
        workFlowDataBarData: [],
        workFlowDatakeys: [],
        sumOfAllWorkflows: [],
        btnID: 'home',
        tempObj: {},
        showGrid: false,
        total: '',
        headervalbyBranch: [],
        returnReasonColors: [],
        selectListForRR: [],
        selectedBranch: 'Default',
        showLoader: true,
        clockedHours: [],
        showClokedHours: true,
        showHeaderLoader: true,
        showReturnReasonLoader: true,
        showTechnicianLoader: true,
        technicianJobsData: [],
        UserPreferenceWorkflowsList: [],
        ReturnReasonList: [],
        partReqList: [],
        returnList: [],
        partReqBranchID: JSON.parse(localStorage.getItem('_dashboard_'))?.BranchPartReq || 'Default'
    });

    const [parts, setParts] = useState({
        partReqList: [],
        returnList: []
    });

    let getBtnParams = JSON.parse(localStorage.getItem('FiltterParams'));

    const [grpBtn, setGrpBtn] = useState({
        priority: getBtnParams?.isHighPriority == 'yes' ? true : false,
        FM: getBtnParams?.isFMOrWarranty == 'yes' ? true : false,
        Night: getBtnParams?.isNightShift == 'yes' ? true : false,
        Breakdown: getBtnParams?.isBreakdown == 'yes' ? true : false
    });

    const handleButtonFlag = (name) => {
        setGrpBtn((prevState) => {
            const updatedState = {
                ...prevState,
                [name]: !prevState[name]
            };
            const updatedParams = { ...getBtnParams };
            if (name === 'priority') {
                updatedParams.isHighPriority = updatedState.priority ? 'yes' : '';
            } else if (name === 'FM') {
                updatedParams.isFMOrWarranty = updatedState.FM ? 'yes' : '';
            } else if (name === 'Breakdown') {
                updatedParams.isBreakdown = updatedState.Breakdown ? 'yes' : '';
            } else if (name === 'Night') {
                updatedParams.isNightShift = updatedState.Night ? 'yes' : '';
            }
            localStorage.setItem('FiltterParams', JSON.stringify(updatedParams));

            return updatedState;
        });
    };

    const pullPartReq = async () => {
        try {
            const [partReqRes, returnRes] = await Promise.all([
                GetInternalDashPartReq(grpBtn.priority, grpBtn.FM, grpBtn.Night, grpBtn.Breakdown),
                GetInternalDashReturn(grpBtn.priority, grpBtn.FM, grpBtn.Night, grpBtn.Breakdown)
            ]);

            if (partReqRes.success && returnRes.success) {
                const formattedPartReqList = partReqRes.data.list.map((item) => ({
                    ...item,
                    BarColor: `5px solid ${item.bgColor}`
                }));

                const formattedReturnList = returnRes.data.list.map((item) => ({
                    ...item,
                    BarColor: `5px solid ${item.bgColor}`
                }));

                setState((prevState) => ({
                    ...prevState,
                    partReqList: formattedPartReqList,
                    returnList: formattedReturnList
                }));
            } else {
                console.error('API requests failed');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const getPartReq = async (branchId) => {
        let partReqList = [...state.partReqList];
        let returnList = [...state.returnList];

        if (branchId === 'Default') {
            const allPartReq = partReqList.reduce((acc, obj) => {
                const index = acc.find((item) => item.label === obj.label);
                index ? (index.value += obj.value) : acc.push({ ...obj });
                return acc;
            }, []);
            const allpartReqturn = returnList.reduce((acc, obj) => {
                const index = acc.find((item) => item.label === obj.label);
                index ? (index.value += obj.value) : acc.push({ ...obj });
                return acc;
            }, []);
            setParts((prevState) => ({
                ...prevState,
                partReqList: allPartReq,
                returnList: allpartReqturn
            }));
        } else {
            let newPartReqList = partReqList
                .map((cond) => {
                    if (cond.bn === branchId) {
                        return {
                            ...cond,
                            value: cond.value
                        };
                    } else {
                        return {
                            ...cond,
                            value: 0,
                            BarColor: cond.BarColor,
                            bn: branchId
                        };
                    }
                })
                .reduce((acc, obj) => {
                    const index = acc.find((item) => item.label === obj.label);
                    index ? (index.value += obj.value) : acc.push({ ...obj });
                    return acc;
                }, []);

            let newReturnList = returnList
                .map((cond) => {
                    if (cond.bn === branchId) {
                        return {
                            ...cond,
                            value: cond.value
                        };
                    } else {
                        return {
                            ...cond,
                            value: 0,
                            BarColor: cond.BarColor,
                            bn: branchId
                        };
                    }
                })
                .reduce((acc, obj) => {
                    const index = acc.find((item) => item.label === obj.label);
                    index ? (index.value += obj.value) : acc.push({ ...obj });
                    return acc;
                }, []);

            console.log(newPartReqList, 'newPartReqList');
            setParts((prevState) => ({
                ...prevState,
                partReqList: newPartReqList,
                returnList: newReturnList
            }));
        }
    };

    const pullGridFilterList = async () => {
        let data = getUserPreference();
        if (data) {
            // i18n.changeLanguage(data.details[0].languageCode);
            setState((st) => ({
                ...st,
                appbarLocation: data.details[0].appbarLocation || 'T',
                languageCode: data.details[0].languageCode,
                languageData: data.languages,
                tempObj: data.details[0] || {},
                displayPagination: isFalseValue(data.details[0]?.displayPagination),
                hideWorkflowScrollbar: isFalseValue(data.details[0]?.hideWorkflowScrollbar)
            }));
        }
    };

    const GetClockedHours = async () => {
        let clockedHoursFromAPI = await GetInternalDashClockEntries(props.processID, grpBtn.priority, grpBtn.FM, grpBtn.Night, grpBtn.Breakdown);
        if (clockedHoursFromAPI.success) {
            let clockedHours = [...clockedHoursFromAPI.data?.clockedHours] || [];
            let hoursRemaining = [...clockedHoursFromAPI.data?.hoursRemaining] || [];
            let allClockedHours = [];
            clockedHours.forEach((ch) => {
                let shouldAdd = true;
                allClockedHours.forEach((ach) => {
                    if (ch.workflow == ach.workflow) {
                        ach.hoursTaken += ch.hoursTaken;
                        ach.hoursAllowed += ch.hoursAllowed;
                        shouldAdd = false;
                    }
                });
                if (shouldAdd)
                    allClockedHours.push({
                        bn: 'Default',
                        workflow: ch.workflow,
                        workflowID: ch.workflowID,
                        hoursTaken: ch.hoursTaken,
                        hoursAllowed: ch.hoursAllowed
                    });
            });

            let allhoursRemaining = [];
            hoursRemaining.forEach((ch) => {
                let shouldAdd = true;
                allhoursRemaining.forEach((ach) => {
                    if (ch.workflow == ach.workflow) {
                        ach.hoursRemaining += ch.hoursRemaining;
                        shouldAdd = false;
                    }
                });
                if (shouldAdd) allhoursRemaining.push({ bn: 'Default', workflow: ch.workflow, workflowID: ch.workflowID, hoursRemaining: ch.hoursRemaining });
            });
            setState((st) => ({
                ...st,
                clockedHours: [...clockedHours, ...allClockedHours],
                hoursRemaining: [...allhoursRemaining, ...hoursRemaining],
                showClokedHours: false
            }));
        }
    };
    const GetHeaderRecords = async () => {
        // let GetHeaderRecordsFromAPI = await GetInternalDashHeaderRecords();
        let GetHeaderRecordsFromAPI = await GetInternalDashHeaderRecords(grpBtn.priority, grpBtn.FM, grpBtn.Night, grpBtn.Breakdown);
        let headerRecord = [...GetHeaderRecordsFromAPI?.data?.headerRecord];
        let userPreferenceWorkflowsSequence = [...GetHeaderRecordsFromAPI?.data?.userPreferenceWorkflows];

        if (headerRecord.length > 0) {
            // Headers WorkFlows Data...........
            const headerWorkflowData = headerRecord.reduce((acc, obj) => {
                const index = acc.find((item) => item.workflow === obj.workflow);
                index ? (index.total += obj.total) : acc.push({ ...obj });
                return acc;
            }, []);

            //workflow pieData............
            const workflowData = headerWorkflowData.map((m) => {
                return { name: m.workflow, value: m.total, workflowID: m.workflowID };
            });
            let sumOfAllWorkflows = 0;
            let totalCount = [...workflowData].forEach(function (item) {
                sumOfAllWorkflows += item.value;
            });
            // branch pieData
            var branchArr = headerRecord.reduce((acc, obj) => {
                const index = acc.find((item) => item.bn === obj.bn);
                index ? (index.total += obj.total) : acc.push({ ...obj });
                return acc;
            }, []);

            let chartdata = branchArr.map((m) => ({
                name: m.bn,
                value: m.total
            }));
            // ............sum OF By Time For ALL
            const sumOFByTimeForALL = headerRecord.reduce((acc, obj) => {
                var index = acc.find((item) => item.bn === obj?.bn);
                if (index) {
                    index.lessThan24HoursOld += obj?.lessThan24HoursOld;
                    index.between1And5DaysOld += obj?.between1And5DaysOld;
                    index.between5And10DaysOld += obj?.between5And10DaysOld;
                    index.between10And20DaysOld += obj?.between10And20DaysOld;
                    index.between21And30DaysOld += obj?.between21And30DaysOld;
                    index.over30DaysOld += obj?.over30DaysOld;
                    index.preBooking += obj?.preBooking;
                    index.between1to10Days += obj?.between1to10Days;
                    index.between11And20Days += obj?.between11And20Days;
                    index.between21And30Days += obj?.between21And30Days;
                    index.overdueOver30DaysOld += obj?.overdueOver30DaysOld;
                    index.overdueArchived += obj?.overdueArchived;
                    // index.total += obj.total;
                    return acc;
                }
                acc.push({ ...obj });
                return acc;
            }, []);

            // ..........By branch and Time BarChart.............
            let headerByTimeBarchart = headerRecord.reduce((acc, obj) => {
                var index = acc.find((item) => item.workflow === obj?.workflow && item.bn === obj?.bn);
                if (index) {
                    index.lessThan24HoursOld += obj?.lessThan24HoursOld;
                    index.between1And5DaysOld += obj?.between1And5DaysOld;
                    index.between5And10DaysOld += obj?.between5And10DaysOld;
                    index.between10And20DaysOld += obj?.between10And20DaysOld;
                    index.between21And30DaysOld += obj?.between21And30DaysOld;
                    index.over30DaysOld += obj?.over30DaysOld;
                    index.total += obj?.total;

                    return acc;
                }
                acc.push({ ...obj });
                return acc;
            }, []);
            const chartDataForHeader = headerByTimeBarchart.map((p) => ({
                name: p.bn,
                '<24hr': p.lessThan24HoursOld,
                '1-5 days': p.between1And5DaysOld,
                '5-10 days': p.between5And10DaysOld,
                '10-20 days': p.between10And20DaysOld,
                '21-30 days': p.between21And30DaysOld,
                '>30 days': p.over30DaysOld,
                workflow: p.workflow,
                workflowID: p.workflowID
            }));
            const result = chartDataForHeader.map((m) => {
                const newObj = chartDataForHeader.filter((n) => n.name === m.name);
                newObj.bn = m.name;
                return newObj;
            });
            let newResult = Object.values(result.reduce((acc, cur) => Object.assign(acc, { [cur.bn]: cur }), {}));
            let getAllHeaderKeys = newResult.map((m, i) => {
                let keys = m.map((p, i) => Object.keys(p));
                return keys;
            });
            setState((st) => ({
                ...st,
                workflowData: workflowData,
                chartdata: chartdata,
                headerRecord: headerWorkflowData,
                userPreferenceWorkflowsSequence: userPreferenceWorkflowsSequence,
                allHeaderDataKeys: getAllHeaderKeys.length > 0 ? getAllHeaderKeys[0][0] : [],
                allHeaderData: newResult,
                sumWorkflows: sumOfAllWorkflows,
                sumOFByTimeForALL: sumOFByTimeForALL,
                rawHeaderData: GetHeaderRecordsFromAPI?.data?.headerRecord,
                showHeaderLoader: false
            }));
        }
    };

    const pullReturnReason = async () => {
        let responses = await GetInternalDashReturnReasons(grpBtn.priority, grpBtn.FM, grpBtn.Night, grpBtn.Breakdown);
        if (responses.success) {
            setState((st) => ({ ...st, ReturnReasonList: [...responses?.data?.returnReasons], showReturnReasonLoader: false }));
        }
    };
    const GetReturnReason = async (workflow, branchName) => {
        if (state.ReturnReasonList.length > 0) {
            let returnReason = [...state.ReturnReasonList];
            // .............by returnReasonsdata.at..........
            if (workflow && branchName) {
                const reduceReturnResonArray = returnReason.reduce((acc, obj) => {
                    const index = acc.find((item) => item.bn === obj.bn && item.rr === obj.rr);
                    index ? (index.total += obj.total) : acc.push({ ...obj });
                    return acc;
                }, []);

                let RetrunResonArray = [...reduceReturnResonArray].map((m) => {
                    let newObj = [...reduceReturnResonArray].filter((n) => n.bn === m.bn && n.name === m.name && m);
                    newObj.name = m.bn;
                    newObj.workflow = m.name;
                    newObj.workflowID = m.workflowID;
                    return newObj;
                });

                let returnReasonsdata = RetrunResonArray.filter((m) => m.name === branchName && m.workflow == workflow).map((m) => {
                    let newObj = {};
                    newObj.name = m.name;
                    m.forEach((n) => {
                        newObj.workflow = n.name;
                        newObj[n.rr] = n.total;
                        newObj.workflowID = m.workflowID;
                    });
                    return newObj;
                });

                let groupbyRetunreson = Object.values(returnReasonsdata.reduce((acc, cur) => Object.assign(acc, { [cur.workflow]: cur }), {}));
                let mergeAllarray = returnReasonsdata.map((m, i) => Object.keys(m)).flat(1);
                let values = [...new Set(mergeAllarray)];
                let RetrunResoncolor = returnReason.map((m) => ({ rr: m.rr, color: m.cc }));
                setState((st) => ({
                    ...st,
                    workFlowDataBarData: groupbyRetunreson,
                    workFlowDatakeys: values,
                    returnReasonColors: RetrunResoncolor,
                    showReturnReasonLoader: false
                }));
            }
            // For add Branches...................
            else {
                let RetrunResoncolor = returnReason.map((m) => ({ rr: m.rr, color: m.cc }));

                let workflowRetunReasonReduce = returnReason.reduce((acc, obj) => {
                    var index = acc.find((item) => item.name === obj.name && item.rr === obj.rr);
                    if (index) {
                        index.total += obj.total;
                        return acc;
                    }
                    acc.push({ ...obj });
                    return acc;
                }, []);

                let workflowArray = [...workflowRetunReasonReduce].map((m) => {
                    let newObj = [...workflowRetunReasonReduce].filter((n) => n.name === m.name);
                    newObj.name = m.name;
                    return newObj;
                });

                let workflowGroupReturnReason = Object.values(workflowArray.reduce((acc, cur) => Object.assign(acc, { [cur.name]: cur }), {}));

                let workflowRetunReasonData = workflowGroupReturnReason.map((m) => {
                    let newObj = { workflow: m.name };
                    m.forEach((n) => {
                        newObj[n.rr] = n.total;
                        newObj.workflowID = n.workflowID;
                    });
                    return newObj;
                });

                let workflowKeys = workflowRetunReasonData.map((m) => Object.keys(m));

                // MERGE KEY ALL ARRAY........
                let mergeWorkFlowarray = workflowKeys.flat();
                // GET REMOVE SAME VALUES  IN ARRAY
                let workFlowvalues = [...new Set(mergeWorkFlowarray)];

                setState((st) => ({
                    ...st,
                    workFlowDataBarData: workflowRetunReasonData,
                    workFlowDatakeys: workFlowvalues,
                    returnReasonColors: RetrunResoncolor
                }));
            }
        }
    };
    const GetTechnicianData = async () => {
        let pros = [];
        // pros.push(GetInternalDashTechnicianJobs());
        pros.push(GetInternalDashTechnicianJobs(grpBtn.priority, grpBtn.FM, grpBtn.Night, grpBtn.Breakdown));
        let responses = await Promise.all(pros);
        if (responses[0].success) {
            let technicianJobs = [...responses[0]?.data?.technicianJobs];
            if (technicianJobs) {
                let result = technicianJobs.map((m) => {
                    let newObj;
                    newObj = technicianJobs.filter((n) => {
                        if (n.bn === m.bn) {
                            return m;
                        }
                    });
                    newObj.bn = m.bn;
                    return newObj;
                });
                let newResult = Object.values(result.reduce((acc, cur) => Object.assign(acc, { [cur.bn]: cur }), {}));

                setState((st) => ({
                    ...st,
                    technicianJobsData: newResult,
                    showTechnicianLoader: false
                }));
            }
        }
    };

    let RefreshGrid = () => {
        GetClockedHours();
        GetHeaderRecords();
        GetTechnicianData();
        pullPartReq();
        pullReturnReason();
    };
    useEffect(() => {
        RefreshGrid();
    }, [grpBtn.priority, grpBtn.FM, grpBtn.Night, grpBtn.Breakdown]);

    let [isRefresh, setIsrefresh] = useState(false);

    useEffect(() => {
        let RefreshTime = portalSettings.portalSettingGridRefreshTime * 1000;
        if (isRefresh) {
            setIsrefresh(true);
            return 0;
        }
        let interval = setInterval(RefreshGrid, RefreshTime);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        let btTimeData = JSON.parse(localStorage.getItem('FiltterParams'));
        let tempData = JSON.parse(localStorage.getItem('_dashboard_'));
        var workflow = tempData?.workflowReturnReason;
        var branchName = btTimeData?.branchShortName;
        GetReturnReason(workflow, branchName);
    }, [state.selectedBranch, state.selectedWorkFlow, state.ReturnReasonList]);

    useEffect(() => {
        if (props?.location?.query) {
            setState((st) => ({
                ...st,
                returnReasonID: props.location.query.returnReasonID,
                filterText: props.location.query.returnReason,
                btnSelectedId: props.location.query?.workFlowId,
                hasQueryValues: true
            }));
        }
    }, []);

    useEffect(() => {
        if (state.partReqBranchID) {
            getPartReq(state.partReqBranchID);
        }
    }, [state.partReqList, state.returnList, state.partReqBranchID]);

    const pullHeaderWorkFlowData = (params) => {
        if (params !== 'Default') {
            if (state.rawHeaderData.length > 0) {
                let indexObj = [...state.rawHeaderData].filter((inx) => inx.bn === params);

                let WorkflowTotal = indexObj.reduce((a, b) => {
                    var index = a.find((x) => x.workflow === b.workflow);
                    if (index) {
                        index.total += b.total;
                        return a;
                    }
                    a.push({ ...b });
                    return a;
                }, []);

                let overallTotal = indexObj.reduce((a, b) => {
                    var index = a.find((x) => x.bn === b.bn);
                    if (index) {
                        index.total += b.total;
                        return a;
                    }
                    a.push({ ...b });
                    return a;
                }, []);

                setState((st) => ({
                    ...st,
                    tempHeaderRecord: WorkflowTotal.filter((p) => {
                        let tmpObj = state.userPreferenceWorkflowsSequence.find((o) => o.workflowID == p.workflowID);
                        if (!_.isEmpty(tmpObj)) {
                            return {
                                ...p,
                                ...tmpObj
                            };
                        }
                    }).sort((a, b) => {
                        return a.orderSequence - b.orderSequence;
                    }),
                    sumOfAllWorkflows: overallTotal[0]?.total
                }));
            }
        } else {
            setState((st) => ({
                ...st,
                tempHeaderRecord: state.headerRecord
                    .filter((p) => {
                        let tmpObj = state.userPreferenceWorkflowsSequence.find((o) => o.workflowID == p.workflowID);
                        if (!_.isEmpty(tmpObj)) {
                            return {
                                ...p,
                                ...tmpObj
                            };
                        }
                    })
                    .sort((a, b) => {
                        return a.orderSequence - b.orderSequence;
                    }),
                sumOfAllWorkflows: state.sumWorkflows
            }));
        }
    };

    useEffect(() => {
        if (state.selectedBranch) {
            pullHeaderWorkFlowData(state.selectedBranch);
        }
    }, [state.rawHeaderData, state.selectedBranch]);

    useEffect(() => {
        pullGridFilterList();
    }, [isAppReaload]);

    useEffect(() => {
        if (!state.hideWorkflowScrollbar) {
            setState((st) => ({
                ...st,
                isShowScrollArrow: scrollbarVisible(WorkFlowBtnElementscrollWidth)
            }));
        } else {
            setState((st) => ({
                ...st,
                isShowScrollArrow: false
            }));
        }
    }, [WindowWidths, WorkFlowBtnElementscrollWidth?.scrollWidth, state.hideWorkflowScrollbar]);

    const handleBtnClcik = (val) => {
        props.history.push(`/Job/${val.headerRecordID}/${val.workflowID}`);
    };

    const getbranchByOnchange = (val, params) => {
        setState((st) => ({
            ...st,
            selectedBranch: val,
            selectedWorkFlow: params
        }));
    };

    const handleAssignmentBtnClick = (val) => {
        setState((st) => ({
            ...st,
            jobId: val.headerRecordID,
            showTechAssign: true
        }));
    };

    const editButton = (id, val) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container>
                <Grid style={{ marginLeft: '13px' }}>
                    <IconButton size="small" onClick={() => handleBtnClcik(val)}>
                        <ArrowForwardIosIcon
                            style={{
                                color: val.tcc
                            }}
                            fontSize="small"
                        />
                    </IconButton>
                </Grid>
                <Grid>
                    {val.technicians && (
                        <IconButton title={`${val.technicians}`} size="small" onClick={() => state.currentWorkflowId == 8 && handleAssignmentBtnClick(val)}>
                            <AssignmentIndIcon
                                style={{
                                    color: val.tcc
                                }}
                                fontSize="small"
                            />
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const baseColumns = [
        {
            title: 'Action',
            field: 'act',
            renderer: editButton,
            width: 90,
            filterable: false
        },
        { field: 'wipNo', sortable: true, title: `${portalSettings.portalSettingGridWIPNoLabel || 'WIP No'}`, width: 70 },
        { field: 'registration', sortable: true, title: `${portalSettings.portalSettingGridVehicleRegLabel || 'Vehicle Reg'}`, width: 90 },
        { field: 'makeModel', sortable: true, title: `${portalSettings.portalSettingGridModelLabel || 'Model'}`, width: 200 },
        { field: 'customerName', sortable: true, title: `${portalSettings.portalSettingGridCustomerNameLabel || 'Customer Name'}`, width: 200 },
        {
            field: 'dateDueIn',
            sortable: true,
            title: `${portalSettings.portalSettingGridDateDueInLabel || 'Date Due In'}`,
            // type: 'date',
            type: 'dateTime',
            // flex: 0.6
            width: 135,
            renderer: function (value, record) {
                if (record.dateDueIn != null) {
                    return moment(record.dateDueIn).format('DD-MMM-YYYY HH:mm');
                }
                return '';
            }
        },
        {
            field: 'dateDueOut',
            sortable: true,
            title: `${portalSettings.portalSettingGridDateDueOutLabel || 'Date Due Out'}`,
            // type: 'date',
            type: 'dateTime',
            width: 135,
            renderer: function (value, record) {
                if (record.dateDueOut != null) {
                    return moment(record.dateDueOut).format('DD-MMM-YYYY HH:mm');
                }
                return '';
            }
        },
        { field: 'returnReason', sortable: true, title: `${portalSettings.portalSettingGridReturnReasonLabel || 'Return Reason'}`, width: 190 },
        { field: 'chassisNumber', sortable: true, title: 'Chassis Number', width: 180 },
        { field: 'branchShortName', sortable: true, title: `${portalSettings.portalSettingGridBranchLabel || 'Branch'}`, width: 120 },
        { field: 'franchiseName', sortable: true, title: `${portalSettings.portalSettingGridFranchiseLabel || 'Franchise'}`, width: 160 },
        { field: 'serviceText', sortable: true, title: `${portalSettings.portalSettingGridJobDescriptionLabel || 'Job Description'}`, width: 170 },
        { field: 'accountNo', sortable: true, title: `${portalSettings.portalSettingGridAccountLabel || 'Account'}`, width: 80 },
        { field: 'createdBy', sortable: true, title: `${portalSettings.portalSettingGridCreatedByLabel || 'Created By'}`, width: 120 },
        {
            field: 'assigned',
            sortable: true,
            title: `${portalSettings.portalSettingGridAssignedDateLabel || 'Assigned Date'}`,
            width: 120,
            renderer: function (value, record) {
                if (record.assigned != null) {
                    return moment(record.assigned).format('DD-MMM-YYYY HH:mm');
                }
                return '';
            }
        },
        {
            field: 'taken',
            sortable: true,
            title: `${portalSettings.portalSettingGridTimeTakenLabel || 'Time Taken'}`,
            width: 100
        },
        {
            field: 'status',
            sortable: true,
            title: `${portalSettings.portalSettingGridStatusLabel || 'Status'}`,
            width: 100
        },
        {
            field: 'assignedDays',
            sortable: true,
            title: `${portalSettings.portalSettingGridAssignedDaysLabel || 'Assigned Days'}`,
            width: 100
        },
        {
            field: 'owningOperator',
            sortable: true,
            title: `${portalSettings.portalSettingGridOwningOperatorLabel || 'Owning Operator'}`,
            width: 100
        },
        {
            field: 'jobAssignedUser',
            sortable: true,
            title: `${portalSettings.portalSettingGridJobAssignedUserLabel || 'Assigned User'}`,
            width: 100
        }
    ]
        .map((c) => {
            let obj = {};
            if (c.title !== 'Action') {
                obj.events = {
                    click: function (e) {
                        props.history.push(`/Job/${e.data.record.headerRecordID}/${e.data.record.workflowID}`);
                    }
                };
                if (Object.keys(state.tempObj).length > 0) {
                    obj.sequenceNo = state.tempObj[config[c.field]?.getSequence] || 9999999;
                    obj.hidden = !state.tempObj[config[c.field]?.from];
                } else {
                    obj.hidden = false;
                }
            }
            if (c.title == 'Action') {
                obj.sequenceNo = -1;
            }
            return {
                ...c,
                ...obj,
                headerCssClass: 'gridHeader'
            };
        })
        .sort((a, b) => {
            return a.sequenceNo - b.sequenceNo;
        });

    const buttonClicked = async (btn, fromPage) => {
        // let tempData = {};
        let tempData = JSON.parse(localStorage.getItem('FiltterParams'));
        if (fromPage) {
            localStorage.setItem(
                'FiltterParams',
                JSON.stringify({ returnReason: btn.returnReason, branchShortName: btn.bn, branchTime: btn.time, dueTime: btn.dueTime })
            );
        } else {
            localStorage.setItem('FiltterParams', JSON.stringify({ ...tempData, branchShortName: tempData.branchShortName }));
        }
        localStorage.setItem('currentWorkflowId', btn.workflowID);
        history.push(`/dashgrid`);
    };

    const handleOpen = () => {
        let tempArr = [...baseColumns]
            .map((q) => {
                return {
                    field: q.field,
                    title: q.title,
                    hidden: !q.hidden,
                    fieldName: config[q.field]?.to,
                    postSequenceFieldName: config[q.field]?.postSequence
                };
            })
            .filter((p) => (p.title != 'Action' ? p : null));

        //.filter((p) => (p.fieldName !== undefined ? p : null));
        // tempArr.push({
        //     field: 'pagination',
        //     title: 'Pagination',
        //     hidden: state.displayPagination,
        //     fieldName: 'UserGridColumnDisplayPagination'
        // });

        setState((st) => ({ ...st, showFilter: true, filterList: tempArr }));
    };
    const handleFlagOpen = () => {
        setState((st) => ({ ...st, showFlag: true }));
    };

    const closeDialog = (res) => {
        setState((st) => ({ ...st, showFilter: false, showTechAssign: false }));
        res && pullGridFilterList();
        res && GetHeaderRecords();
    };

    const closeTechAssignDialog = (res) => {
        setState((st) => ({ ...st, showTechAssign: false, isReload: res ? new Date() : null }));
    };

    return (
        <div style={{ padding: 10, background: '#f5f5f5' }}>
            {/* {state.showLoader ? (
                <div
                    style={{
                        width: '100%'
                    }}
                >
                    <div
                        style={{
                            height: 'calc(100vh - 300px)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                    </div>
                </div>
            ) : ( */}
            <Grid container spacing={1} className="mainDashboard-container">
                <Avatar
                    style={{
                        background: '#BA4546',
                        color: 'white',
                        height: 35,
                        width: 35,
                        position: 'absolute',
                        right: 12,
                        bottom: 12,
                        border: '6px solid #daa3a6',
                        cursor: 'pointer',
                        zIndex: 100
                    }}
                    onClick={handleOpen}
                >
                    <FilterListIcon />
                </Avatar>

                <Hidden only={['sm', 'xs']}>
                    <ButtonGroup
                        aria-label="outlined primary button group"
                        style={{
                            position: 'absolute',
                            right: 12,
                            top: 110,
                            cursor: 'pointer'
                        }}
                    >
                        <FilterButton isActive={grpBtn.priority} onClick={() => handleButtonFlag('priority')} label="Cash Job" />
                        <FilterButton isActive={grpBtn.FM} onClick={() => handleButtonFlag('FM')} label="Is FM Or Warranty Job" />
                        <FilterButton isActive={grpBtn.Breakdown} onClick={() => handleButtonFlag('Breakdown')} label="Is Breakdown Job" />
                        <FilterButton isActive={grpBtn.Night} onClick={() => handleButtonFlag('Night')} label="Is Night Shift Job" />
                    </ButtonGroup>
                </Hidden>
                <Hidden only={['md', 'lg', 'xl']}>
                    <Avatar
                        style={{
                            background: '#006cf2',
                            color: 'white',
                            height: 35,
                            width: 35,
                            position: 'absolute',
                            right: 70,
                            bottom: 12,
                            border: '6px solid #acd1ff',
                            cursor: 'pointer',
                            zIndex: 100
                        }}
                        onClick={handleFlagOpen}
                    >
                        <FlagIcon />
                    </Avatar>
                </Hidden>

                {state.showFlag && (
                    <DialogComp title="" onClose={() => setState((st) => ({ ...st, showFlag: false }))} maxWidth="md">
                        <ButtonGroup aria-label="outlined primary button group" color="primary" style={{}}>
                            <FilterButton isActive={grpBtn.priority} onClick={() => handleButtonFlag('priority')} label="High Priority" />
                            <FilterButton isActive={grpBtn.FM} onClick={() => handleButtonFlag('FM')} label="Is FM Or Warranty Job" />
                        </ButtonGroup>
                        <ButtonGroup aria-label="outlined primary button group" color="primary" style={{}}>
                            <FilterButton isActive={grpBtn.Breakdown} onClick={() => handleButtonFlag('Breakdown')} label="Is Breakdown Job" />
                            <FilterButton isActive={grpBtn.Night} onClick={() => handleButtonFlag('Night')} label="Is Night Shift Job" />
                        </ButtonGroup>
                    </DialogComp>
                )}

                <div style={{ overflow: 'hidden', display: 'flex', width: '100%' }}>
                    {state.isShowScrollArrow && (
                        <span onClick={() => scrollPrev()} className="ScrollArrow-Container">
                            <ChevronLeftIcon />
                        </span>
                    )}
                    <div
                        style={{ overflow: 'auto', display: 'flex', width: '100%', flexWrap: !state.hideWorkflowScrollbar ? 'nowrap' : 'wrap' }}
                        className={`btn-container`}
                        id="WorkFlow-Btn"
                    >
                        {state.tempHeaderRecord.map((m, i) => {
                            if (m?.workflow !== 'Archived') {
                                return (
                                    <div
                                        item
                                        style={{
                                            padding: state.isShowScrollArrow
                                                ? '0px 5px 0px 0px'
                                                : state.hideWorkflowScrollbar
                                                ? `0px 5px 0px 0px`
                                                : `0px 5px 0px ${i === 0 ? '10px' : '0px'}`
                                        }}
                                    >
                                        <Card
                                            className={classes.root}
                                            key={i}
                                            style={{
                                                background: state.btnID === m.workflowID ? '#BA4546' : 'white',
                                                color: state.btnID === m.workflowID ? 'white' : 'black',
                                                padding: 0
                                            }}
                                            onClick={() => buttonClicked(m)}
                                        >
                                            <div
                                                style={{
                                                    textAlign: 'left',
                                                    cursor: 'pointer',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    // color: 'rgb(255, 71, 87)',
                                                    overflow: 'hidden',
                                                    padding: '5px 20px'
                                                }}
                                            >
                                                <span style={{ fontSize: 14, marginBottom: 6 }}> {m?.workflow}</span>
                                                <div style={{ fontSize: 30, fontWeight: 600 }}> {m?.total}</div>
                                            </div>
                                        </Card>
                                    </div>
                                );
                            }
                        })}
                        <div item style={{ padding: '0px 5px 0px 0px' }}>
                            <Card
                                className={classes.root}
                                style={{
                                    background: state.total === 'total' ? '#BA4546' : 'white',
                                    color: state.total === 'total' ? 'white' : 'black',
                                    padding: 0
                                }}
                                onClick={() => buttonClicked({ workflowID: 'All' })}
                            >
                                <div
                                    style={{
                                        textAlign: 'left',
                                        cursor: 'pointer',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        paddingBottom: 10,
                                        padding: '5px 20px'
                                    }}
                                >
                                    <span style={{ fontSize: 14, marginBottom: 6 }}> Total</span>
                                    <div style={{ fontSize: 30, fontWeight: 600 }}>{state.sumOfAllWorkflows}</div>
                                </div>
                            </Card>
                        </div>
                    </div>
                    {state.isShowScrollArrow && (
                        <span onClick={() => scrollNext()} className="ScrollArrow-Container">
                            <ChevronRightIcon />
                        </span>
                    )}
                </div>
                <DashboardCharts
                    state={state}
                    onChartClickHandler={buttonClicked}
                    getbranchByOnchange={getbranchByOnchange}
                    parts={parts}
                    getPartReq={getPartReq}
                    hideWorkflowScrollbar={state.hideWorkflowScrollbar}
                />
            </Grid>
            {/* )} */}
            {state.showFilter ? (
                <DialogComp title="My Preferences" maxWidth="md" fullWidth onClose={() => closeDialog(false)} overflow="auto">
                    <FilterModalMainTabComponent
                        languageCode={state.languageCode}
                        appbarLocation={state.appbarLocation}
                        filterConfig={config}
                        filterList={state.filterList}
                        handleCancel={closeDialog}
                        languageData={state.languageData}
                        isPagination={state.displayPagination}
                        ishideWorkflowScrollbar={state.hideWorkflowScrollbar}
                    />
                    {/* <GridFilterModal
                        UserPreferenceWorkflowsList={state.UserPreferenceWorkflowsList}
                        languageCode={state.languageCode}
                        appbarLocation={state.appbarLocation}
                        filterConfig={config}
                        filterList={state.filterList}
                        handleCancel={closeDialog}
                        languageData={state.languageData}
                    /> */}
                </DialogComp>
            ) : null}
            {state.showTechAssign ? (
                <DialogComp title="Assigned Technician" maxWidth="sm" onClose={() => closeTechAssignDialog(false)} fullWidth>
                    <AssignTech jobId={state.jobId} handleCancel={closeTechAssignDialog} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default Dashbord;
