import { Button, CircularProgress, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { CloudUpload, Delete, Edit, Check } from '@material-ui/icons';
import { useContext, useEffect, useMemo, useState } from 'react';
import formatters from '../Grid/inputFormatter';
import { TextBox } from '../FormInput';
import { AppStorage } from '../Service/storage-service';
import { CustomIconButton, PrimaryButton, SecondaryButton } from '../FormInput/AppButton';
import DataGridComp from '../Grid';
import DialogComp from '../Modal/dialogModal';
import { deleteUploadedFile, downloadUploadedFile, getFilesForConfig, updateName, uploadFileUsignUploaderConfig } from '../Service/FileUploaderService';
import { getConfigIdFromName } from './FileUploaderConfig';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SingleDocumentViewer from './DocumentViewer';
import GetAppIcon from '@material-ui/icons/GetApp';
import AppContext from '../../App/AppContext';
import moment from 'moment';

const basicColumns = [
    {
        field: 'FileName',
        headerName: 'Name',
        flex: 2
    },
    // {
    //     field: 'FileExt',
    //     headerName: 'Type',
    //     flex: 1
    // },
    {
        field: 'FileSize',
        headerName: 'Size',
        // flex: 1
        width: 120,
        renderCell: (params) => <div style={{ display: 'flex', alignItems: 'center' }}>{params.value}</div>
    }
    // {
    //     field: 'DownloadCount',
    //     headerName: 'Downloads',
    //     flex: 1
    // },
    // {
    //     field: 'UploadedBy',
    //     headerName: 'Uploaded By',
    //     flex: 1
    // },
    // {
    //     field: 'UploadedAt',
    //     headerName: 'Uploaded At',
    //     flex: 2,
    //     formatter: formatters.Date_DDMMMYYYY_HHMM
    // }
];

const UploaderModal = ({ recordId, configName, showToastRef, gridRelaod, onClose, fileUploaderModalClose, docIDPassed }) => {
    const AppContextRef = useContext(AppContext);
    let showToast = showToastRef || AppContextRef.showToast;

    const [state, setState] = useState({
        fileName: '',
        selectedFile: undefined,
        loading: true,
        uploading: false,
        inEdit: false,
        inDelete: false,
        fileToAction: undefined,
        currentFiles: [],
        fileUploaderModalClose: fileUploaderModalClose || false,
        slectedFileName: '',
        slectedFileExt: ''
    });
    const getFiles = (isReload) => {
        getFilesForConfig(getConfigIdFromName(configName), recordId, docIDPassed).then((res) => {
            if (res.success) {
                setState((st) => ({ ...st, loading: false, currentFiles: res.data.map((docList) => ({ ...docList, id: docList.UniqueFileName })) }));
                isReload && gridRelaod && gridRelaod();
            }
        });
    };
    useEffect(() => {
        getFiles();
    }, []);

    const onFileSelect = (e) => {
        const { files } = e.target;
        if (files.length) {
            setState((st) => ({ ...st, fileName: files[0].name, selectedFile: files[0] }));
        }
    };

    const uploadSelectedFile = async () => {
        if (state.fileName && state.selectedFile) {
            setState((st) => ({ ...st, uploading: true }));
            const formData = new FormData();
            formData.append(state.fileName, state.selectedFile);
            let res = await uploadFileUsignUploaderConfig(getConfigIdFromName(configName), recordId, formData, docIDPassed);
            if (res.success) {
                showToast(res.message);
                setState((st) => ({ ...st, uploading: false, fileName: '', selectedFile: undefined }));
                !state.fileUploaderModalClose && getFiles(true);
                state.fileUploaderModalClose && onClose(true);
            } else {
                showToast(res.message);
                setState((st) => ({ ...st, uploading: false }));
            }
        }
    };

    const showDeleteConfirm = (row) => (e) => {
        e.stopPropagation();
        setState((st) => ({
            ...st,
            inDelete: true,
            fileToAction: {
                uniqFileName: row.UniqueFileName,
                fileName: row.FileName
            }
        }));
    };

    const editName = (row) => (e) => {
        e.stopPropagation();
        setState((st) => ({
            ...st,
            inEdit: true,
            fileToAction: {
                uniqFileName: row.UniqueFileName,
                fileName: row.FileName
            }
        }));
    };

    const hideModal = (type) => () => {
        setState((st) => ({ ...st, [type]: false, fileToAction: undefined, imageList: '', slectedFileName: '', slectedFileExt: '' }));
    };

    const modifyCurrentFileName = (e) => {
        const { value } = e.target;
        setState((st) => ({ ...st, fileToAction: { ...st.fileToAction, fileName: value } }));
    };

    const updateFileToServer = async () => {
        let res = await updateName(getConfigIdFromName(configName), recordId, state.fileToAction.uniqFileName, state.fileToAction.fileName, docIDPassed);
        if (res.success) {
            showToast(res.message);
            setState((st) => ({ ...st, inEdit: false, fileToAction: undefined }));
            getFiles(true);
        } else {
            showToast(res.message);
        }
    };

    const confirmDelete = async () => {
        let res = await deleteUploadedFile(getConfigIdFromName(configName), recordId, state.fileToAction.uniqFileName);
        if (res.success) {
            showToast(res.message);
            setState((st) => ({ ...st, inDelete: false, fileToAction: undefined }));
            getFiles(true);
        } else {
            showToast(res.message);
        }
    };

    const downloadFile = (row) => async (e) => {
        let success = await downloadUploadedFile(
            getConfigIdFromName(configName),
            recordId,
            row.UniqueFileName,
            `${row.FileName}${row.FileExt}`,
            docIDPassed,
            true,
            row.FileExt
        );
        if (success) {
            getFiles(true);
        } else {
            showToast(`Something went wrong, can't download file.`);
        }
    };

    const ViewDownloadFile = (row) => async (e) => {
        setState((st) => ({ ...st, showDocument: true }));
        let success = await downloadUploadedFile(
            getConfigIdFromName(configName),
            recordId,
            row.UniqueFileName,
            `${row.FileName}${row.FileExt}`,
            docIDPassed,
            false,
            row.FileExt
        );
        if (success) {
            setState((st) => ({ ...st, imageList: success, slectedFileExt: row.FileExt, slectedFileName: row.FileName }));
        } else {
            showToast(`Something went wrong, can't download file.`);
        }
    };

    const ActionButtons = (params) => {
        return (
            <>
                <Tooltip title="Edit" aria-label="add">
                    <IconButton size="small" onClick={editName(params.rowData)}>
                        <Edit color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Download" aria-label="Download">
                    <IconButton size="small" onClick={downloadFile(params.rowData)}>
                        <GetAppIcon color="secondary" fontSize="small" />
                    </IconButton>
                </Tooltip>

                {AppStorage.getCanDeleteVehicleDocs() && (
                    <Tooltip title="Delete" aria-label="Delete">
                        <IconButton size="small" onClick={showDeleteConfirm(params.rowData)}>
                            <Delete color="secondary" fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )}
            </>
        );
    };

    const gridColumns = useMemo(() => {
        const cols = [...basicColumns];

        cols.push({
            field: ``,
            headerName: '',
            width: 100,
            renderCell: (params) => <ActionButtons rowData={params.row} />
        });

        const fn = cols.find((c) => c.field === 'FileName');
        if (fn) {
            fn.renderCell = (params) => {
                return (
                    <div style={{ width: '100%', padding: '5px' }}>
                        <div>
                            <a href="javascript:0" onClick={ViewDownloadFile(params.row)}>
                                {params.row.FileName}
                            </a>
                        </div>
                        <div
                            style={{
                                fontSize: '12px',
                                color: 'gray',
                                textAlign: 'right'
                            }}
                        >
                            {params.row.UploadedBy} @ {moment(params.row.UploadedAt).format('LLL')}
                        </div>
                    </div>
                );
            };
        }
        return cols;
    }, []);

    return (
        <div
        // style={{ width: '80vw' }}
        >
            {AppStorage.getCanUploadVehicleDocs() && (
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm>
                        <TextBox disabled value={state.fileName || 'No file selected'} label="File to Upload" />
                    </Grid>
                    <Grid item>
                        <Button color="secondary" variant="contained" component="label">
                            Select File
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={onFileSelect}
                                accept="image/*, application/pdf, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button disabled={!state.selectedFile || state.uploading} onClick={uploadSelectedFile} color="secondary" variant="outlined">
                            {state.uploading ? <CircularProgress size={24} color="secondary" /> : <CloudUpload />}
                        </Button>
                    </Grid>
                </Grid>
            )}

            <DataGridComp loadingData={state.loading} rows={state.currentFiles} columns={gridColumns} minWidth={550} />
            {state.inEdit && (
                <DialogComp title="Rename" onClose={hideModal('inEdit')}>
                    <TextBox label="New Name" value={state.fileToAction.fileName} onChange={modifyCurrentFileName} autoFocus={true}></TextBox>
                    <SecondaryButton className="mt-20" onClick={updateFileToServer}>
                        <Check /> Save
                    </SecondaryButton>
                </DialogComp>
            )}
            {state.inDelete && (
                <DialogComp title="Confirm Delete" onClose={hideModal('inDelete')}>
                    <Typography variant="h5" color="secondary">
                        Are you sure you want to delete?
                    </Typography>
                    <PrimaryButton className="mt-20" onClick={hideModal('inDelete')}>
                        No, Close
                    </PrimaryButton>
                    <SecondaryButton className="mt-20 mb-20" onClick={confirmDelete}>
                        <Delete /> Yes, Delete
                    </SecondaryButton>
                </DialogComp>
            )}
            {state.showDocument && (
                <DialogComp title={`Document - ${state.slectedFileName}`} onClose={hideModal('showDocument')} maxWidth="xl" fullWidth fullScreen>
                    <SingleDocumentViewer imageList={state.imageList || []} slectedFileExt={state.slectedFileExt} />
                </DialogComp>
            )}
        </div>
    );
};

export default UploaderModal;
