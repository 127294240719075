import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { login } from '../../../Core/Service/login-service';
import AppContext from '../../../App/AppContext';
import CloseIcon from '@material-ui/icons/Close';
import { Button, IconButton, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { TextBox, PasswordBox, SecondaryButton } from '../../../Core/FormInput';
import { Grid, Link } from '@material-ui/core';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { postAuthenticationValidateCode } from '../../../Core/Service/authenticator-service';
import { AppStorage } from '../../../Core/Service/storage-service';
import ExpiryResetPasswordModal from '../../../Core/Modal/ExpiryPasswordReset';
import AuthenticationScreen from '../../../Core/Authenticator';
import DialogComp from '../../../Core/Modal/dialogModal';
import { getUploaderConfig } from '../../../Core/Service/FileUploaderService';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(0)
    }
}));

function LoginForm(props) {
    const [state, setState] = useState({
        userName: '',
        password: '',
        showAuth: false,
        showExpiryModal: false,
        temporaryToken: '',
        isLoginLoading: false,
        showAuthModal: false,
        tempLoginData: {}
    });
    const { showToast, showModal, portalSettings } = useContext(AppContext);

    const userNameUpdated = (e) => {
        const { value } = e.target;
        setState((st) => ({ ...st, userName: value }));
    };

    const handleInput = (e) => {
        const { value } = e.target;
        setState((st) => ({ ...st, code: value }));
    };

    const passwordUpdated = (e) => {
        const { value } = e.target;
        setState((st) => ({ ...st, password: value }));
    };
    const handleClose = () => {
        props.history.push('/');
    };

    const handleModal = () => {
        setState((st) => ({ ...st, showAuth: !st.showAuth }));
    };

    const performLoginAndReload = async (data) => {
        AppStorage.performLogin(data);
        await getUploaderConfig();
        props.history.push('/');
        window.location.reload();
    };

    const handleSubmitClick = async (e) => {
        e.preventDefault();
        if (state.userName && state.password) {
            setState((st) => ({ ...st, isLoginLoading: true }));
            const res = await login(state.userName, state.password);
            if (!res.success) {
                if (res.message === '2FA_ENABLED') {
                    handleModal();
                } else {
                    showToast(res.message);
                }
            } else if (res.success && portalSettings.portalSettingsMFAEnabled) {
                setState((st) => ({ ...st, temporaryToken: res?.data?.data?.token, showAuthModal: true, tempLoginData: res?.data }));
            } else if (res.success && res.data.data.passwordExpired === 'Y') {
                setState((st) => ({ ...st, temporaryToken: res?.data?.data?.token, showExpiryModal: true }));
            } else {
                performLoginAndReload(res.data);
            }
            setState((st) => ({ ...st, isLoginLoading: false }));
        } else {
            showToast('User Name and Password both required.');
        }
    };

    const handlePasswordForgot = () => {
        props.history.push('/');
        showModal('ForgotPassword');
    };

    const handleAuthval = async (e) => {
        e.preventDefault();
        let res = await postAuthenticationValidateCode(state.code);
        showToast(res.message);
        if (res.success) {
            // props.history.push('/');
            // window.location.reload();
            performLoginAndReload(res.data);
        }
    };

    const expiryModalHandleClose = () => {
        setState((st) => ({ ...st, showExpiryModal: false }));
    };

    const AuthenticationScreenClose = (flag) => {
        setState((st) => ({ ...st, showAuthModal: false }));
        flag && performLoginAndReload(state.tempLoginData);
        flag && AppStorage.setTwoFlag(true);
    };

    const classes = useStyles();
    return (
        <>
            <Dialog open={true} maxWidth="sm" fullWidth>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>
                        <DialogTitle>{state.showAuth ? 'Authentication Code' : 'Sign in'} </DialogTitle>
                    </Box>
                    <Box>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                {!state.showAuth ? (
                    <DialogContent>
                        <form className={classes.form} noValidate>
                            <TextBox
                                autoFocus={true}
                                required
                                placeholder="Email"
                                type="email"
                                onChange={userNameUpdated}
                                value={state.userName}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        handleSubmitClick(event);
                                    }
                                }}
                            />
                            <PasswordBox
                                required
                                placeholder="Password"
                                onChange={passwordUpdated}
                                value={state.password}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        handleSubmitClick(event);
                                    }
                                }}
                            />
                            <SecondaryButton className="Submitbtn" fullWidth onClick={handleSubmitClick} isBtnLoader={state.isLoginLoading}>
                                Sign In
                            </SecondaryButton>
                            <Grid container>
                                <Grid item xs>
                                    <Link component="button" onClick={handlePasswordForgot} variant="body2">
                                        Forgot password?
                                    </Link>
                                    &nbsp;&nbsp;
                                    <span style={{ cursor: 'none' }}>{`(version ${window._ENV_.app_version})`}</span>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                ) : (
                    <DialogContent>
                        <Typography>
                            Please enter six digit code displayed in the registered authentication app and click the Submit button{' '}
                            {/* <Button onClick={handleModal}>Go Back </Button> */}
                        </Typography>{' '}
                        <TextBox
                            autoFocus={true}
                            required
                            placeholder="Six digit code"
                            type="email"
                            onChange={handleInput}
                            value={state.code}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    handleAuthval(event);
                                }
                            }}
                        />
                        <Grid container spacing={2} style={{ marginTop: 5, marginBottom: 5 }}>
                            <Grid item xs={6}>
                                <SecondaryButton fullWidth onClick={handleAuthval} style={{ background: 'green' }}>
                                    SUBMIT
                                </SecondaryButton>
                            </Grid>
                            <Grid item xs={6}>
                                <SecondaryButton fullWidth onClick={handleModal}>
                                    Go Back{' '}
                                </SecondaryButton>
                            </Grid>
                        </Grid>
                        {/* <SecondaryButton onClick={handleModal}>Go Back</SecondaryButton> */}
                    </DialogContent>
                )}
            </Dialog>
            {state.showExpiryModal && <ExpiryResetPasswordModal onClose={expiryModalHandleClose} temporaryToken={state.temporaryToken} />}
            {/* {state.showAuthModal && <AuthenticationScreen />} */}
            {state.showAuthModal ? (
                <DialogComp
                    title={`TWO-FACTOR AUTHENTICATION`}
                    maxWidth="lg"
                    // onClose={() => AuthenticationScreenClose(false)}
                    fullWidth
                >
                    <AuthenticationScreen onClose={AuthenticationScreenClose} temporaryToken={state.temporaryToken} />
                </DialogComp>
            ) : null}
        </>
    );
}

export default LoginForm;
