import React, { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { DateTimePicker, PrimaryButton, SecondaryButton, SecondaryCheckbox, SelectBox, SingelSelect, TextBox } from '../../../Core/FormInput';
import { Grid, IconButton, InputAdornment, Typography } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DialogComp from '../../../Core/Modal/dialogModal';
import { AddSupplier } from './Supplier';
import { getPartReqSupplierDropDown } from '../../../Core/Service/PartReq_services/partReq-service';
import AppContext from '../../../App/AppContext';
import CircularIndeterminate from '../ReportsScreens/ReportsScreens/BudgetCore/loader';

import { forEach } from 'lodash';
import moment from 'moment';

const useStyles = makeStyles({
    table: {
        minWidth: 1280
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        padding: 5
    },
    body: {
        padding: 5
    }
}))(TableCell);

let costObj = {
    1: 'partReqSupplierGenunieUnitPrice',
    2: 'partReqSupplierOEMUnitPrice',
    3: 'partReqSupplierRefurbhishedUnitPrice'
};

const PartSupplier = (props) => {
    const { setSupplierPart, supplierPart, rows, partList, setPartList, onClose, jobDetails } = props;

    const [showModal, setShowModal] = useState(false);
    const classes = useStyles();
    const [state, setState] = useState({
        partTypes: [],
        suppliers: [],
        selectedRowId: null,
        partObj: rows,
        isDisabled: true,
        isReload: true
    });

    let AddRowSupplier = useCallback(() => {
        setSupplierPart((st) => {
            return [
                ...st,
                {
                    partReqSupplierID: null,
                    partReqSupplierPartReqID: rows.partReqID ? rows.partReqID : null,
                    partReqSupplierSupplierID: '',
                    partReqSupplierGenunieUnitPrice: 0,
                    partReqSupplierOEMUnitPrice: 0,
                    partReqSupplierRefurbhishedUnitPrice: 0,
                    partReqSupplierSurcharged: false,
                    partReqSupplierSelectedPartType: 0,
                    partReqSupplierAvailability: '',
                    partReqSupplierSelectedSupplier: false,
                    partReqSupplierDateCreated: rows.partReqID ? moment().format('YYYY-MM-DDTHH:mm:ss.SSS') : state?.partObj?.partReqDateCreated,
                    partReqSupplierSurchargedValue: 0
                }
            ];
        });
    }, []);

    const removeRowSupplier = (index) => {
        let list = [...supplierPart];
        list.splice(index, 1);
        setSupplierPart(list);
    };

    const handleInputSupplier = useCallback((index) => (e) => {
        const { name, value } = e.target;
        if (value != 'btn') {
            setSupplierPart((st) => {
                let newSt = [...st];
                newSt[index][name] = value;
                return newSt;
            });
        }
    });

    const handleInputSupplierCheck = (index) => (e) => {
        const { name, checked } = e.target;
        setState((st) => ({ ...st, selectedRowId: index }));
        setSupplierPart((st) => {
            let newSt = [...st];
            if (!checked && name == 'partReqSupplierSurcharged') {
                newSt[index]['partReqSupplierSurchargedValue'] = 0;
            }

            newSt[index][name] = checked;
            return newSt;
        });
    };

    const handleRadioCheck = (index) => (e) => {
        const { name, checked } = e.target;
        let newList = supplierPart.map((j, i) => {
            if (
                (rows.partReqID && j.partReqSupplierPartReqID === rows.partReqID) ||
                (!rows.partReqID && j.partReqSupplierDateCreated === rows.partReqDateCreated)
            ) {
                return {
                    ...j,
                    partReqSupplierSelectedSupplier: i === index
                };
            } else {
                return j;
            }
        });

        setSupplierPart(newList);

        if (name == 'partReqSupplierSelectedSupplier') {
            setState((st) => ({ ...st, selectedRowId: index, isDisabled: !checked }));
        }
    };

    const handleModalToggle = (res) => {
        setShowModal(!showModal);
        res && pullDropDown();
    };

    const pullDropDown = async () => {
        setState((prev) => ({ ...prev, isReload: true }));
        try {
            const res = await getPartReqSupplierDropDown(jobDetails.branchID);
            if (res.success) {
                setState((prev) => ({
                    ...prev,
                    partTypes: res.data.partTypes,
                    suppliers: [...res.data.suppliers, { id: 'btn', name: <AddSupplierButton /> }],
                    isReload: false
                }));
            } else {
                setState((prev) => ({ ...prev, isReload: false }));
            }
        } catch (error) {
            console.error(error);
            setState((prev) => ({ ...prev, isReload: false }));
        }
    };

    const AddSupplierButton = () => (
        <PrimaryButton size="small" fullWidth onClick={handleModalToggle}>
            <AddBoxIcon /> &nbsp;&nbsp;Add
        </PrimaryButton>
    );

    useEffect(() => {
        pullDropDown();
    }, []);

    const handleSubmit = () => {
        let tempPart = { ...state.partObj };
        let index = supplierPart.findIndex(
            (k) =>
                (rows.partReqID && k.partReqSupplierSelectedSupplier && k.partReqSupplierPartReqID === rows.partReqID) ||
                (!rows.partReqID && k.partReqSupplierDateCreated === rows.partReqDateCreated)
        );

        let selectedRowIndex = state.selectedRowId ?? (index > -1 ? index : null);
        let isSelect = supplierPart.findIndex((k) => k.partReqSupplierSelectedSupplier);

        if (isSelect > -1 && selectedRowIndex !== null) {
            let selectedPart = supplierPart[selectedRowIndex];
            let partTypeID = selectedPart?.partReqSupplierSelectedPartType;
            let getTypeValue = costObj[partTypeID];

            const findPartListIndex = (d) => (rows.partReqID ? d.partReqID === rows.partReqID : d.partReqDateCreate == rows.partReqDateCreate);
            let partListIndex = partList.findIndex(findPartListIndex);
            if (partListIndex !== -1) {
                let newList = [...partList];
                let supplier = state.suppliers.find((k) => k.id === selectedPart?.partReqSupplierSupplierID)?.name;
                Object.assign(tempPart, {
                    partReqSupplier: supplier,
                    partReqsAvailability: selectedPart.partReqSupplierAvailability,
                    partReqsCost: selectedPart[getTypeValue] || 0
                });

                Object.assign(newList[partListIndex], {
                    partReqSupplier: tempPart.partReqSupplier,
                    partReqsAvailability: tempPart.partReqsAvailability,
                    partReqsCost: tempPart.partReqsCost,
                    partReqSurchargedValue: selectedPart.partReqSupplierSurchargedValue,
                    partReqSurcharged: selectedPart.partReqSupplierSurcharged
                });

                setPartList(newList);
            }
        }

        onClose();
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item>
                    <div style={{ textAlign: 'left', fontSize: 12 }}>Part Number</div>
                    <Typography style={{ fontSize: 18, fontWeight: 500, textAlign: 'left' }}>{rows.partReqPartNumber || 'N/A'}</Typography>
                </Grid>
                <Grid item>
                    <div style={{ textAlign: 'left', fontSize: 12 }}>Description</div>
                    <Typography style={{ fontSize: 18, fontWeight: 500, textAlign: 'left' }}>{rows.partReqPartDescription || 'N/A'}</Typography>
                </Grid>
                <Grid item xs={12} style={{ minHeight: 450 }}>
                    {state.isReload ? (
                        <CircularIndeterminate />
                    ) : (
                        <TableContainer style={{ maxHeight: 450 }}>
                            <TableContainer className={classes.table} aria-label="simple table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left" width="5%" style={{ color: 'red' }}>
                                            <IconButton size="small" onClick={AddRowSupplier}>
                                                <AddCircleIcon style={{ color: 'green' }} />
                                            </IconButton>
                                        </StyledTableCell>
                                        <StyledTableCell align="left" width="20%">
                                            Supplier
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="10%">
                                            Genuine Unit Price
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="10%">
                                            OEM Unit Price
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="10%">
                                            Refurbished Unit Price
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="11%">
                                            Surcharged
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="11%">
                                            Selected Parts Type
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="11%">
                                            Availability
                                        </StyledTableCell>
                                        <StyledTableCell align="center" width="11%">
                                            Selected Supplier
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {supplierPart.map((row, index) => {
                                        if (
                                            (rows.partReqID && row.partReqSupplierPartReqID === rows.partReqID) ||
                                            (!rows.partReqID && row.partReqSupplierDateCreated === rows.partReqDateCreated)
                                        ) {
                                            return (
                                                <TableRow key={index}>
                                                    <StyledTableCell>
                                                        <IconButton size="small" onClick={() => removeRowSupplier(index)}>
                                                            <RemoveCircleIcon style={{ color: 'red' }} />
                                                        </IconButton>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={10}>
                                                                <SelectBox
                                                                    List={state.suppliers}
                                                                    onChange={handleInputSupplier(index)}
                                                                    name="partReqSupplierSupplierID"
                                                                    value={row?.partReqSupplierSupplierID}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <TextBox
                                                            type="number"
                                                            onChange={handleInputSupplier(index)}
                                                            name="partReqSupplierGenunieUnitPrice"
                                                            value={row?.partReqSupplierGenunieUnitPrice}
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <TextBox
                                                            type="number"
                                                            onChange={handleInputSupplier(index)}
                                                            name="partReqSupplierOEMUnitPrice"
                                                            value={row?.partReqSupplierOEMUnitPrice}
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <TextBox
                                                            type="number"
                                                            onChange={handleInputSupplier(index)}
                                                            name="partReqSupplierRefurbhishedUnitPrice"
                                                            value={row?.partReqSupplierRefurbhishedUnitPrice}
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={3}>
                                                                <SecondaryCheckbox
                                                                    onChange={handleInputSupplierCheck(index)}
                                                                    name="partReqSupplierSurcharged"
                                                                    checked={row.partReqSupplierSurcharged}
                                                                />
                                                            </Grid>
                                                            {row.partReqSupplierSurcharged && (
                                                                <Grid item xs={9}>
                                                                    <TextBox
                                                                        placeholder="Value"
                                                                        value={row?.partReqSupplierSurchargedValue}
                                                                        name="partReqSupplierSurchargedValue"
                                                                        type="number"
                                                                        onChange={handleInputSupplier(index)}
                                                                    />
                                                                </Grid>
                                                            )}
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <SelectBox
                                                            List={state.partTypes}
                                                            onChange={handleInputSupplier(index)}
                                                            name="partReqSupplierSelectedPartType"
                                                            value={row?.partReqSupplierSelectedPartType}
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <DateTimePicker
                                                            onChange={handleInputSupplier(index)}
                                                            name="partReqSupplierAvailability"
                                                            value={row.partReqSupplierAvailability}
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <SecondaryCheckbox
                                                            onChange={handleRadioCheck(index)}
                                                            name="partReqSupplierSelectedSupplier"
                                                            checked={row.partReqSupplierSelectedSupplier}
                                                        />
                                                    </StyledTableCell>
                                                </TableRow>
                                            );
                                        }
                                    })}
                                </TableBody>
                            </TableContainer>
                        </TableContainer>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton fullWidth onClick={handleSubmit}>
                        Submit
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton fullWidth onClick={onClose}>
                        Cancel
                    </SecondaryButton>
                </Grid>
                {showModal ? (
                    <DialogComp title="Add Supplier" onClose={() => handleModalToggle(false)} maxWidth="lg">
                        <AddSupplier onClose={() => handleModalToggle(true)} branchID={jobDetails.branchID} />
                    </DialogComp>
                ) : null}
            </Grid>
        </div>
    );
};

export default PartSupplier;
