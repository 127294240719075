import React, { useContext, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { SecondaryButton, SecondaryCheckbox, TextBox } from '../../../../Core/FormInput';
import { Grid, IconButton } from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddIcon from '@material-ui/icons/Add';
import AppContext from '../../../../App/AppContext';

const ordinalNumbers = [
    '1st',
    '2nd',
    '3rd',
    '4th',
    '5th',
    '6th',
    '7th',
    '8th',
    '9th',
    '10th',
    '11th',
    '12th',
    '13th',
    '14th',
    '15th',
    '16th',
    '17th',
    '18th',
    '19th',
    '20th'
];

const StyledTableCell = withStyles((theme) => ({
    head: {
        padding: 5
    },
    body: {
        fontSize: 14,
        padding: 5
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {}
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: '500px'
    }
});

export default function FaultCode({ list, handleFaultCodeRow, removeFaultCodeRow, handleFaultCodeInput, onClose, isReadOnly, setInputList }) {
    const classes = useStyles();
    const { showToast, showModal } = useContext(AppContext);
    const handleSave = () => {
        if (list.length > 0) {
            let index = list.findIndex((k) => {
                return k.technicianNotesItemsFaultCode == '';
            });
            if (index > -1) {
                showToast(` ${ordinalNumbers[index]} row cannot be empty. Please fill in the required information.`);
            } else {
                onClose();
            }
        } else {
            onClose();
        }
    };

    return (
        <div style={{ padding: 10 }}>
            <TableContainer style={{ height: 320 }}>
                <Table className={classes.table} aria-label="customized table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell width={'10%'} align="left">
                                <IconButton
                                    onClick={handleFaultCodeRow}
                                    style={{ marginLeft: 4, padding: 0, background: isReadOnly ? 'grey' : 'green', borderRadius: 5 }}
                                    disabled={isReadOnly}
                                >
                                    <AddIcon style={{ fontSize: 21, color: 'white' }} />
                                </IconButton>
                            </StyledTableCell>
                            <StyledTableCell width={'35%'}>Code</StyledTableCell>
                            <StyledTableCell width={'45%'}>Description </StyledTableCell>
                            <StyledTableCell width={'10%'}>Is Active? </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map(
                            (row, index) =>
                                !row.isDeleted && (
                                    <StyledTableRow key={row.name}>
                                        <StyledTableCell>
                                            <Grid container spacing={1} alignItems="flex-start">
                                                <Grid item>
                                                    <IconButton size="small" onClick={() => removeFaultCodeRow(index)} disabled={isReadOnly}>
                                                        <RemoveCircleIcon style={{ color: isReadOnly ? 'grey' : 'red' }} />
                                                    </IconButton>
                                                </Grid>
                                                {/* )} */}
                                            </Grid>
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            <TextBox
                                                name="technicianNotesItemsFaultCode"
                                                value={row.technicianNotesItemsFaultCode}
                                                onChange={(e) => handleFaultCodeInput(e, index)}
                                                disabled={isReadOnly}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <TextBox
                                                name="technicianNotesItemsFaultDescription"
                                                value={row.technicianNotesItemsFaultDescription}
                                                onChange={(e) => handleFaultCodeInput(e, index)}
                                                disabled={isReadOnly}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <SecondaryCheckbox
                                                name="isActive"
                                                checked={row.isActive}
                                                onChange={(e) => handleFaultCodeInput(e, index)}
                                                disabled={isReadOnly}
                                            />
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SecondaryButton fullWidth onClick={handleSave} disabled={isReadOnly}>
                        Save
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton fullWidth onClick={onClose}>
                        Close
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
}
