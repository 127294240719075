import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import ReportGijgoGrid from '../ReportsScreens/GijgoForReportsScreen';

const PartsList = (props) => {
    const baseColumns = [
        { field: 'reference', sortable: false, title: `Reference`, filterable: false, width: 140 },
        { field: 'partDescription', sortable: true, title: `Description`, showColumnTotal: true, filterable: false },
        { field: 'netValue', sortable: true, title: `Net Price.`, showColumnTotal: true, align: 'right', filterable: false, width: 100 },
        { field: 'discPerc', sortable: true, title: `Disc %`, showColumnTotal: true, align: 'right', filterable: false, width: 100 },
        { field: 'discValue', sortable: true, title: `Disc Price`, showColumnTotal: true, align: 'right', filterable: false, width: 100 },
        { field: 'bin', sortable: true, title: `Bin`, showColumnTotal: true, align: 'right', filterable: false, width: 100 },
        { field: 'askQuantity', sortable: true, title: 'Ask Qty ', showColumnTotal: true, align: 'right', filterable: false, width: 100 },
        { field: 'servedQuantity', sortable: true, title: 'Srv Q', showColumnTotal: true, align: 'right', filterable: false, width: 100 },
        { field: 'reserveQty', title: 'Q Res', showColumnTotal: true, align: 'right', filterable: false, sortable: false, width: 100 },
        { field: 'unallocatedTotal1', title: 'Q Ord', showColumnTotal: true, align: 'right', filterable: false, sortable: false, width: 100 },
        { field: 'rwi', title: 'RWI', showColumnTotal: true, align: 'right', filterable: false, sortable: false, width: 100 },
        {
            field: 'customerPrice',
            title: 'Cust Net Amt',
            showColumnTotal: true,
            align: 'right',
            filterable: false,
            sortable: false,
            width: 140,
            renderer: (val, record) => {
                return record.currency !== null && record.currency !== undefined ? `${record.customerPrice} ${record.currency}` : '';
            }
        },
        { field: 'codeImputation', title: 'Corrective.', showColumnTotal: true, align: 'right', filterable: false, sortable: false, width: 100 }
    ];

    return (
        <Grid item container spacing={1} xs={12}>
            <Grid item xs={12}>
                <ReportGijgoGrid
                    dataConverter={(res) => ({
                        records: props.List,
                        total: props.List.length
                    })}
                    columns={baseColumns}
                    hidePagination={true}
                    hideFilterRow={true}
                />
            </Grid>
        </Grid>
    );
};

export default PartsList;
