import React, { useEffect, useState, useContext } from 'react';
import { getAdminPartById, AddParts } from '../../../Core/Service/admin-service';
import { Grid, FormLabel } from '@material-ui/core';
import { SecondaryButton, SelectBox, TextBox, YesNoButton } from '../../../Core/FormInput';
import AppContext from './../../../App/AppContext';
import { getBranchAndFranchise } from '../../../Core/Service/branch-screen-service';

const PartsAddUpdate = (props) => {
    const [state, setState] = useState({
        partNumber: '',
        description: '',
        partID: props.partID,
        errors: {},
        isActive: true,
        branchList: [],
        branchID: null
    });

    const { showToast, showModal } = useContext(AppContext);

    useEffect(() => {
        const fetchData = async () => {
            if (props.partID) {
                let res = await getAdminPartById(props.partID);
                if (res.success) {
                    let data = res.data?.table[0];
                    setState((st) => ({
                        ...st,
                        partNumber: data?.partNumber,
                        partID: data?.partID,
                        description: data?.description,
                        isActive: data?.isActive,
                        branchID: data?.branchID
                    }));
                }
            }
        };

        fetchData();
    }, [props.partID]);

    useEffect(() => {
        const fetchBranchAndFranchise = async () => {
            let res = await getBranchAndFranchise();
            if (res.success) {
                let list = res.data.branches;
                setState((st) => ({
                    ...st,
                    branchList: list.map((val) => ({ ...val, name: val.branchName, id: val.branchID }))
                }));
            }
        };

        fetchBranchAndFranchise();
    }, []);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        let data = {
            partID: state.partID,
            partNumber: state.partNumber,
            isActive: state.isActive,
            description: state.description,
            branchID: state.branchID
        };
        let res = await AddParts(data);
        if (res.success) {
            showToast(res.data?.message || `Parts Added Successfully`);
            props.onClose(true);
        } else {
            console.error(res.message);
        }
    };
    const handleClick = (name, val) => {
        setState((st) => {
            const nst = { ...st, [name]: val };
            return nst;
        });
    };
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <FormLabel component="legend" className="mandatoryfields">
                        Part Number
                    </FormLabel>
                    <TextBox name="partNumber" onChange={handleInput} value={state.partNumber} autoFocus={true} />
                    <span className="mandatoryfields">{state.errors?.partNumber}</span>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormLabel component="legend" className="mandatoryfields">
                        Description
                    </FormLabel>
                    <TextBox name="description" onChange={handleInput} value={state.description} />
                    <span className="mandatoryfields">{state.errors?.description}</span>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormLabel component="legend" className="mandatoryfields" style={{ marginBottom: 10 }}>
                        Branch
                    </FormLabel>
                    <SelectBox name="branchID" onChange={handleInput} value={state.branchID} List={state.branchList} />
                    <span className="mandatoryfields">{state.errors?.description}</span>
                </Grid>
                <Grid item xs={12} sm={12} md={12} container>
                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                        <FormLabel>Is Active?</FormLabel>
                    </Grid>
                    <YesNoButton
                        state={state.isActive}
                        name="isActive"
                        onYesClick={() => handleClick('isActive', true)}
                        onNoClick={() => handleClick('isActive', false)}
                    />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <SecondaryButton fullWidth onClick={handleSubmit}>
                        Save
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default PartsAddUpdate;
