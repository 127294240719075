import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import AppContext from '../../../App/AppContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getPartReqDetails, getPartReqHistory, getPartReqRenaultStatus, postPartReq } from '../../../Core/Service/PartReq_services/partReq-service';
import { getPartListByPartNumber } from '../../../Core/Service/partRequisitions-service';

export const PartSupplierContext = createContext();

export const PartSupplierProvider = (props) => {
    const { showToast, userRoles } = useContext(AppContext);
    const urlParams = new URLSearchParams(window.location.search.toLowerCase());
    const fromStep = urlParams.get('fromstep') === 'true';
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const isInternal = userRoles.includes('internal');
    const isTechnician = userRoles.includes('technician');

    const history = useHistory();

    const [status, setStatus] = useState([]);

    const [state, setState] = useState({
        jobDetails: {},
        headerRecordID: props?.match?.params?.jobId,
        showSupplier: false,
        costRow: {},
        mode: 'R',
        date: moment().format('DD/MM/YYYY'),
        isReload: 'true',
        selectedData: {},
        showPartReturn: false,
        RowID: '',
        loading: true,
        partIndex: -1,
        partReqStockStatus: [],
        partReqIssueStatus: [],
        showSignatureModal: false,
        partSignIndex: null,
        partSignObj: {},
        showImage: false,
        showHistory: false,
        historyList: [],
        isHistory: true,
        isShowETA: false,
        autoFocus: false,
        isReadOnly: isInternal ? false : true,
        newDate: null,
        statusList: [],
        submitLoader: false,
        shoWApproval: false
    });

    const [partList, setPartList] = useState([
        {
            partReqsPartID: null,
            partReqPartNumber: '',
            partReqPartDescription: '',
            partReqQuantity: null,
            isDeleted: false,
            partReqStatus: null,
            partReqID: 0,
            isFitted: false,
            partReqsAvailability: '',
            partReqsCost: null,
            partReqsRetailPrice: null,
            partReqsLabourHours: null,
            partReqsLabourValue: null,
            partReqsOrderNumber: '',
            partReqDateCreated: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
            partReqSupplier: '',
            partReqSurchargedValue: '',
            partReqSurcharged: false,
            partReqStockStatusId: '',
            partReqIssuedQuantity: '',
            issueStatusID: 0,
            requestorSignature: null,
            approverSignature: null,
            partReqETA: '',
            partReqFMWarranty: false,
            associatedMaterials: []
        }
    ]);
    const [errors, setErrors] = useState([]);

    const [supplierPart, setSupplierPart] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorE2, setAnchorE2] = useState(null);

    const PullData = useCallback(async () => {
        if (state.headerRecordID) {
            try {
                const res = await getPartReqDetails(state.headerRecordID);
                if (res.success) {
                    setState((st) => ({
                        ...st,
                        jobDetails: res?.data?.jobDetails[0],
                        partRequstorSignature: res?.data?.partReqs[0]?.requestorSignature,
                        isReload: false
                    }));

                    setPartList(
                        res.data?.partReqs.length > 0
                            ? res.data?.partReqs.map((n) => ({
                                  partReqsPartID: n.partReqsPartID,
                                  partReqPartNumber: n.partReqPartNumber,
                                  partReqPartDescription: n.partReqPartDescription,
                                  partReqQuantity: n.partReqQuantity,
                                  isDeleted: n.isDeleted || false,
                                  partReqStatus: n.partReqStatus,
                                  partReqID: n.partReqID,
                                  isFitted: n.isFitted,
                                  partReqsAvailability: n.partReqsAvailability,
                                  partReqsCost: n.partReqsCost,
                                  partReqsRetailPrice: n.partReqsRetailPrice,
                                  partReqsLabourHours: n.partReqsLabourHours,
                                  partReqsLabourValue: n.partReqsLabourValue,
                                  partReqsOrderNumber: n.partReqsOrderNumber,
                                  partReqDateCreated: n.partReqDateCreated,
                                  partReqSupplier: n.partReqSupplier,
                                  partReqSurcharged: n?.partReqSurcharged || false,
                                  partReqSurchargedValue: n.partReqSurchargedValue || '',
                                  partReqStockStatusId: n.partReqStockStatusId,
                                  partReqIssuedQuantity: n.partReqIssuedQuantity,
                                  issueStatusID: n.issueStatusID,
                                  requestorSignature: n.requestorSignature !== '' ? n.requestorSignature : null,
                                  approverSignature: n.approverSignature !== '' ? n.approverSignature : null,
                                  requestor: n.requestor,
                                  approver: n.approver,
                                  issuer: n.issuer,
                                  issuerSignature: n.issuerSignature,
                                  returnStatus: n.returnStatus,
                                  photosUploaded: n.photosUploaded,
                                  partReqETA: n.partReqETA || '',
                                  partReqFMWarranty: n.partReqFMWarranty || false,
                                  associatedMaterials: n.associatedMaterials || [],
                                  isfittedDisabled: n.isFitted || false
                              }))
                            : [
                                  {
                                      partReqsPartID: null,
                                      partReqPartNumber: '',
                                      partReqPartDescription: '',
                                      partReqQuantity: null,
                                      isDeleted: false,
                                      partReqStatus: null,
                                      partReqID: 0,
                                      isFitted: false,
                                      partReqsAvailability: '',
                                      partReqsCost: null,
                                      partReqsRetailPrice: null,
                                      partReqsLabourHours: null,
                                      partReqsLabourValue: null,
                                      partReqsOrderNumber: '',
                                      partReqDateCreated: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
                                      partReqSupplier: '',
                                      partReqSurchargedValue: '',
                                      partReqSurcharged: false,
                                      partReqStockStatusId: '',
                                      partReqIssuedQuantity: '',
                                      issueStatusID: 0,
                                      requestorSignature: null,
                                      approverSignature: null,
                                      partReqETA: '',
                                      partReqFMWarranty: false,
                                      associatedMaterials: []
                                  }
                              ]
                    );

                    setSupplierPart(
                        res.data?.partsSupplierVM?.length > 0
                            ? res.data?.partsSupplierVM.map((n) => ({
                                  partReqSupplierID: n.partReqSupplierID,
                                  partReqSupplierPartReqID: n.partReqSupplierPartReqID,
                                  partReqSupplierSupplierID: n.partReqSupplierSupplierID,
                                  partReqSupplierGenunieUnitPrice: n.partReqSupplierGenunieUnitPrice,
                                  partReqSupplierOEMUnitPrice: n.partReqSupplierOEMUnitPrice,
                                  partReqSupplierRefurbhishedUnitPrice: n.partReqSupplierRefurbhishedUnitPrice,
                                  partReqSupplierSurcharged: n.partReqSupplierSurcharged,
                                  partReqSupplierSelectedPartType: n.partReqSupplierSelectedPartType,
                                  partReqSupplierAvailability: n.partReqSupplierAvailability,
                                  partReqSupplierSelectedSupplier: n.partReqSupplierSelectedSupplier,
                                  partReqSupplierDateCreated: n.partReqSupplierDateCreated,
                                  partReqSupplierSurchargedValue: n?.partReqSupplierSurchargedValue || 0
                              }))
                            : []
                    );
                }
            } catch (error) {
                console.error('Error fetching part request details:', error);
            }
        }
    }, [state.headerRecordID]);

    const fetchData = useCallback(async () => {
        try {
            const dropDownRes = await getPartReqRenaultStatus();
            if (dropDownRes.success) {
                setState((st) => ({ ...st, statusList: dropDownRes.data.partReqStatus }));
            } else {
                console.error(dropDownRes.message);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, []);

    useEffect(() => {
        PullData();
        fetchData();
    }, []);

    useEffect(() => {
        if (partList.length > 0 && state.statusList.length > 0) {
            let partIdArray = new Set(partList.map((obj) => obj.partReqStatus));

            let Res = [...state.statusList];
            Res = Res.reduce((acc, status) => {
                if (partIdArray.has(status.id)) {
                    if (isInternal && !status.visibleToInternal) {
                        acc.push({ ...status, visibleToInternal: true });
                    }
                    if (isTechnician && !status.visibleToTech) {
                        acc.push({ ...status, visibleToTech: true });
                    }
                }
                acc.push(status);
                return acc;
            }, []);

            const FilterList = Res.filter((status) => {
                return (isInternal && status.visibleToInternal) || (isTechnician && status.visibleToTech);
            });

            const uniqueData = Array.from(new Set(FilterList.map((status) => status.id))).map((id) => FilterList.find((status) => status.id === id));

            setStatus(uniqueData);
        }
    }, [isInternal, isTechnician, partList, state.statusList]);

    useEffect(() => {
        const fetchPartHistory = async () => {
            if (props.partHistoryId) {
                try {
                    let res = await getPartReqHistory(props.partHistoryId);
                    setState((st) => ({ ...st, historyList: res.data.list, isHistory: false }));
                } catch (error) {
                    console.error('Error fetching part history:', error);
                    setState((st) => ({ ...st, historyList: [], isHistory: false }));
                }
            }
        };

        fetchPartHistory();
    }, [props.partHistoryId]);

    const onImagemodalClose = useCallback(
        (res) => {
            setState((st) => ({ ...st, showImage: false, partId: null, desc: '' }));

            res && PullData();
        },
        [PullData]
    );

    const handleInputPart = (index, row) => (e) => {
        const { name, value } = e.target;
        setPartList((st) => {
            let newSt = [...st];
            newSt[index][name] = value;
            return newSt;
        });
    };

    const handleStatusInputPart = (index, row) => (e) => {
        const { name, value } = e.target;
        switch (value) {
            case 1:
                setState((st) => ({
                    ...st,
                    showSignatureModal: true,
                    message: 'Please provide the requestor signature.',
                    partSignIndex: index,
                    partSignObj: row
                }));
                break;

            case 9:
                setState((st) => ({
                    ...st,
                    isShowETA: true,
                    partSignIndex: index,
                    partSignObj: row
                }));
                break;

            default:
                setState((st) => ({
                    ...st,

                    message: '',
                    partSignIndex: index,
                    partSignObj: row
                }));
                break;
        }

        setPartList((st) => {
            let newSt = [...st];
            newSt[index][name] = value;
            return newSt;
        });
    };

    const onPartReturnSubmit = useCallback(() => {
        setState((prevState) => ({
            ...prevState,
            showPartReturn: false
        }));
        PullData();
    }, [PullData]);

    const handleCost = (row, index) => {
        setState((st) => ({
            ...st,
            showSupplier: true,
            costRow: row,
            index: index
        }));
    };

    const handleClose = (res) => {
        setState((st) => ({
            ...st,
            showSupplier: false,
            showPartReturn: false
        }));
    };

    const handleETAModal = (index, row) => {
        setState((st) => ({
            ...st,
            isShowETA: !st.isShowETA,
            partSignIndex: index,
            partSignObj: row
        }));
    };

    let AddRow = () => {
        setPartList((st) => {
            return [
                {
                    partReqsPartID: null,
                    partReqPartNumber: '',
                    partReqPartDescription: '',
                    partReqQuantity: '',
                    isDeleted: false,
                    partReqStatus: null,
                    partReqID: 0,
                    isFitted: false,
                    partReqsAvailability: '',
                    partReqsCost: '',
                    partReqsRetailPrice: '',
                    partReqsLabourHours: '',
                    partReqsLabourValue: '',
                    partReqsOrderNumber: '',
                    partReqDateCreated: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
                    partReqSupplier: '',
                    partReqSurchargedValue: '',
                    partReqSurcharged: false,
                    partReqStockStatusId: '',
                    partReqIssuedQuantity: '',
                    issueStatusID: 0,
                    requestorSignature: '',
                    approverSignature: '',
                    partReqETA: '',
                    partReqFMWarranty: false,
                    associatedMaterials: []
                },
                ...st
            ];
        });
    };

    const handleInputSupplierCheck = (index) => (e) => {
        const { name, checked } = e.target;
        setPartList((st) => {
            let newSt = [...st];
            if (!checked && name == 'partReqSurcharged') {
                newSt[index]['partReqSurchargedValue'] = 0;
            }
            newSt[index][name] = checked;
            return newSt;
        });
    };

    const handleSignatureModal = (index, obj) => () => {
        setState((st) => ({
            ...st,
            shoWApproval: false,
            showSignatureModal: !st.showSignatureModal,
            partSignIndex: index,
            partSignObj: obj
        }));
    };

    const handleMainClose = useCallback(() => {
        const message = navigateToDashboard ? 'navigate-dashboard' : 'close-modal';

        if (fromStep) {
            setTimeout(() => {
                window.top.postMessage({ type: message }, '*');
            }, 1000);
        } else {
            history.goBack();
        }
    }, [navigateToDashboard, fromStep, history]);

    const checkField = (newList, field, errorField, errorMessage, errors) => {
        newList.forEach((list, index) => {
            if (!list.isDeleted && list.partReqStatus !== 1) {
                if (!list[field]) {
                    errors.push({ index: index, message: errorMessage });
                    newList[index][errorField] = errorMessage;
                } else {
                    newList[index][errorField] = '';
                }
            }
        });
    };

    const isValidation = useCallback(() => {
        let isValid = true;
        let errors = [];
        let newList = [...partList];
        checkField(newList, 'partReqPartNumber', 'ErrorPartNumber', 'Part Number is required*', errors);
        checkField(newList, 'partReqPartDescription', 'ErrorDescription', 'Description is required*', errors);
        checkField(newList, 'partReqQuantity', 'ErrorQuantity', 'Quantity is required*', errors);
        setPartList(newList);
        if (errors.length > 0) {
            isValid = false;
        }

        let ids = newList.filter((index) => index.partReqStatus === 2 && index.approverSignature == null);
        if (ids.length > 0) {
            isValid = false;
            setState((st) => ({
                ...st,
                shoWApproval: true
            }));
        }

        setPartList(newList);
        return isValid;
    }, [partList, setPartList]);

    const handleSubmit = useCallback(async () => {
        if (isValidation()) {
            setState((st) => ({ ...st, submitLoader: true }));

            const data = {
                partReqTechnicianSignature: '',
                partRequstorSignature: '',
                mode: state.mode,
                partsSupplierVM: supplierPart,
                renaultPartReqsAddVM: partList.map((m) => ({
                    ...m,
                    partReqQuantity: m.partReqQuantity || null,
                    partReqsCost: m.partReqsCost || null,
                    partReqSurchargedValue: m.partReqSurchargedValue || null,
                    partReqsRetailPrice: m.partReqsRetailPrice || null,
                    partReqsLabourValue: m.partReqsLabourValue || null,
                    partReqsLabourHours: m.partReqsLabourHours || null
                }))
            };
            const res = await postPartReq(data, state.headerRecordID);
            if (res.success) {
                showToast(res?.message);
                handleMainClose();
            } else if (res.errors) {
                for (const [key, value] of Object.entries(res.errors)) {
                    showToast(`${key}: ${value}`);
                }
            } else {
                showToast(res?.message);
            }
            setState((st) => ({ ...st, submitLoader: false }));
        }
    }, [isValidation, state.mode, supplierPart, partList, state.headerRecordID, handleMainClose, showToast]);

    const closeApproval = useCallback(
        (res) => {
            setState((st) => ({
                ...st,
                shoWApproval: false
            }));
            if (res) {
                handleSubmit();
            }
        },
        // eslint-disable-next-line no-use-before-define
        [handleSubmit]
    );
    const handleRowSelect = useCallback(
        (params) => {
            if (state.partIndex > -1) {
                setPartList((prevList) => {
                    const updatedList = [...prevList];
                    updatedList[state.partIndex].partReqPartDescription = params.description;
                    updatedList[state.partIndex].partReqPartNumber = params.partNumber;
                    return updatedList;
                });
            }

            setState((prevState) => ({
                ...prevState,
                loading: false,
                matchingPartNumber: []
                // partIndex: -1 // Uncomment if needed
            }));

            setAnchorEl(null);
            setAnchorE2(null);
        },
        [state.partIndex, setPartList, setState, setAnchorEl, setAnchorE2]
    );
    const pullDataByPartNumber = useCallback(
        (val, type, index) => async (event) => {
            let res;
            if (type === 'part') {
                setAnchorEl(event.currentTarget);
                res = await getPartListByPartNumber(val, null, state.jobDetails.branchID);
                if (res.success) {
                    setState((st) => ({
                        ...st,
                        matchingPartNumber: res.data.partDetails,
                        loading: false,
                        partIndex: index,
                        isFocusFlag: new Date()
                    }));
                }
            } else {
                setAnchorE2(event.currentTarget);
                res = await getPartListByPartNumber(null, val, state.jobDetails.branchID);
                if (res.success) {
                    setState((st) => ({
                        ...st,
                        matchingPartNumber: res.data.partDetails,
                        loading: false,
                        partIndex: index,
                        isFocusFlag: new Date()
                    }));
                }
            }
        },
        [state.jobDetails.branchID]
    );

    const handleClosePOP = () => {
        setAnchorEl(null);
        setAnchorE2(null);
    };

    const removeRow = (index) => {
        setPartList((st) => {
            let newSt = [...st];
            newSt[index].isDeleted = true;
            return newSt;
        });
    };

    const showPartReturnModal = (value) => {
        setState((st) => {
            let newSt = { ...st };
            if (value) {
                newSt.selectedData = value;
            }
            newSt.showPartReturn = true;
            return newSt;
        });
    };

    const handleImage = (row) => () => {
        setState((st) => ({
            ...st,
            partId: row.partReqID,
            desc: row.partReqPartDescription,
            showImage: true
        }));
    };
    const handleHistory = (row) => () => {
        setState((st) => ({
            ...st,
            partId: row.partReqID,
            showHistory: true
        }));
    };

    const onHistoryClose = useCallback(
        (res) => {
            setState((st) => ({ ...st, showHistory: false, partId: null }));
            res && PullData();
        },
        [PullData]
    );

    // const handleAssociated = (index) => {
    //     setState((st) => ({
    //         ...st,
    //         isShowAssociate: !st.isShowAssociate,
    //         partSignIndex: index
    //     }));
    // };

    // const handleAssociatedRow = () => {
    //     let TempList = [...partList[state.partSignIndex].associatedMaterials];
    //     TempList.unshift({
    //         partReqID: null,
    //         technicianNotesItemID: null,
    //         partNumber: '',
    //         partDescription: '',
    //         isDeleted: false,
    //         partQty: '',
    //         dateCreated: partList[state.partSignIndex].dateCreated
    //     });

    //     setPartList((st) => {
    //         let newSt = [...st];
    //         newSt[state.partSignIndex].associatedMaterials = [...TempList];
    //         return newSt;
    //     });
    // };

    // const removeAssociatedRow = (index) => {
    //     setPartList((prevState) => {
    //         const newState = [...prevState];
    //         const updatedAssociatedMaterials = [...newState[state.partSignIndex].associatedMaterials];
    //         if (updatedAssociatedMaterials[index]?.partReqID) {
    //             updatedAssociatedMaterials[index].isDeleted = true;
    //         } else {
    //             updatedAssociatedMaterials.splice(index, 1);
    //         }
    //         newState[state.partSignIndex].associatedMaterials = updatedAssociatedMaterials;
    //         return newState;
    //     });
    // };

    // const handleAssociateInput = (e, index) => {
    //     const { name, value } = e.target;
    //     setPartList((st) => {
    //         let newSt = [...st];
    //         let updatedNestedArray = [...st[state.partSignIndex].associatedMaterials];
    //         updatedNestedArray[index][name] = value;
    //         return newSt;
    //     });
    // };

    const contextValue = useMemo(
        () => ({
            state,
            setState,
            partList,
            setPartList,
            supplierPart,
            setSupplierPart,
            anchorEl,
            anchorE2,
            status,
            PullData,
            handleInputPart,
            onPartReturnSubmit,
            handleCost,
            handleClose,
            AddRow,
            handleInputSupplierCheck,
            handleSignatureModal,
            handleSubmit,
            handleMainClose,
            urlParams,
            fromStep,
            navigateToDashboard,
            handleRowSelect,
            pullDataByPartNumber,
            handleClosePOP,
            removeRow,
            showPartReturnModal,
            handleImage,
            handleHistory,
            onHistoryClose,
            handleETAModal,
            onImagemodalClose,
            handleStatusInputPart,
            errors,

            closeApproval
        }),
        [
            state,
            partList,
            supplierPart,
            anchorEl,
            anchorE2,
            status,
            PullData,
            onPartReturnSubmit,
            handleSubmit,
            handleMainClose,
            urlParams,
            fromStep,
            navigateToDashboard,
            handleRowSelect,
            pullDataByPartNumber,
            onHistoryClose,
            onImagemodalClose,
            errors,

            closeApproval
        ]
    );

    return <PartSupplierContext.Provider value={contextValue}>{props.children}</PartSupplierContext.Provider>;
};
