import React, { useContext, useEffect, useState } from 'react';
import { DateTimePicker, SecondaryButton } from '../../../../Core/FormInput';
import { Alert } from '@material-ui/lab';
import { PostGridPartReqs_MarkedAsFitted } from '../../../../Core/Service/PartReq_services/partReturnSteps-services';
import { CircularProgress, Grid, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';

import AppContext from '../../../../App/AppContext';
const StyledTableCell = withStyles((theme) => ({
    head: {
        // padding: ' 5px',
        fontSize: 15,
        fontWeight: 600,
        backgroundColor: '#bdbdbd82'
    },
    body: {
        fontSize: 12
        // padding: ' 5px'
        // fontSize: 11,
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'grey',
            color: 'white'
        },
        cursor: 'pointer'
    }
}))(TableRow);

const MarkAsFitted = (props) => {
    const { showToast } = useContext(AppContext);
    const [btnLoader, setBtnLoader] = useState(false);
    const [state, setState] = useState({ partReqIds: [] });
    useEffect(() => {
        if (props.selectedRecords.length > 0) {
            setState((st) => ({ ...st, partReqIds: props.selectedRecords.map((q) => q.partReqID) }));
        }
    }, []);

    const Onsubmit = async () => {
        setBtnLoader(true);
        let data = {
            partReqIds: state.partReqIds
            // partArrivedDate: state.partArrivedDate
        };

        let res = await PostGridPartReqs_MarkedAsFitted(data);

        if (res.success) {
            showToast(res?.data?.message);
            props.onClose && props.onClose(true);
        }
        setBtnLoader(false);
    };

    const handleInputPart = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            let newSt = { ...st };
            newSt.partArrivedDate = value;
            return newSt;
        });
    };

    return (
        <div style={{ padding: 10 }}>
            {state.partReqIds.length <= 0 ? (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity="info">Please Select parts </Alert>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TableContainer style={{ height: '300px' }}>
                            <Table size="small" width="550px" stickyHeader>
                                <TableHead>
                                    <TableRow className="header">
                                        <StyledTableCell>Reg No</StyledTableCell>
                                        <StyledTableCell>Job No</StyledTableCell>
                                        <StyledTableCell>Customer</StyledTableCell>
                                        <StyledTableCell>Main Part</StyledTableCell>
                                        <StyledTableCell>Supplier</StyledTableCell>
                                        <StyledTableCell>ETA for parts ordered</StyledTableCell>
                                        {/* <StyledTableCell>Returned By</StyledTableCell> */}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.selectedRecords.map((q) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell>{q.regNo}</StyledTableCell>
                                                <StyledTableCell>{q.wipNo}</StyledTableCell>
                                                <StyledTableCell>{q.cust}</StyledTableCell>
                                                <StyledTableCell>{q.mainPart}</StyledTableCell>
                                                <StyledTableCell>{q.partReqSupplier}</StyledTableCell>
                                                <StyledTableCell>{q.partReqETA ? moment(q.partReqETA).format('DD-MMM-YYYY HH:mm') : ''}</StyledTableCell>
                                                {/* <StyledTableCell>{q.returnedBy}</StyledTableCell> */}
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={12}>
                        <Alert severity="info">Are you sure to book selected parts?</Alert>
                    </Grid>
                    <Grid item xs={12}>
                        {btnLoader ? (
                            <SecondaryButton fullWidth>
                                <CircularProgress size={25} />
                            </SecondaryButton>
                        ) : (
                            <SecondaryButton fullWidth onClick={Onsubmit} isBtnLoader={btnLoader}>
                                Submit{' '}
                            </SecondaryButton>
                        )}
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default MarkAsFitted;
