import { Avatar, Card, CircularProgress, Grid, Hidden, Typography } from '@material-ui/core';
import { ColorsDash } from '../color';
import DateRangeIcon from '@material-ui/icons/DateRange';

export default function JobByTechnician({ state, array, handleTechCalender, classes, handleTechOnClick, handleChangeByTime }) {
    return (
        <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 5, boxSize: 'none' }}>
            {state.showTechnicianLoader ? (
                <div className={classes.root} style={{ width: '100%', minHeight: '110px', display: 'flex' }}>
                    <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto', alignSelf: 'center' }} />
                </div>
            ) : (
                <Card className={classes.root}>
                    <Hidden only={['xs']}>
                        <Grid container xs={12}>
                            <Grid item xs={12} sm={6}>
                                <Typography vraient="h5" color="secondary" className="dashHeading">
                                    {'Jobs By Technicians'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <div style={{ float: 'right', marginRight: 40, display: 'flex' }}>
                                    <div
                                        style={{
                                            padding: '2px 12px 0px 0px',
                                            fontSize: 20,
                                            fontWeight: 600,
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <span
                                            onClick={() =>
                                                handleTechCalender({
                                                    tid: '',
                                                    bn: array.selectedValueByTech,
                                                    name: '',
                                                    bid: ''
                                                })
                                            }
                                        >
                                            <DateRangeIcon />
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            padding: '2px 12px 0px 0px',
                                            fontSize: 20,
                                            fontWeight: 600,
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <span
                                            onClick={() =>
                                                handleTechOnClick({
                                                    tid: '',
                                                    bn: array.selectedValueByTech,
                                                    name: '',
                                                    bid: ''
                                                })
                                            }
                                        >
                                            Total : {array.techJobsTotal}
                                        </span>
                                    </div>
                                    <div>
                                        <select onChange={handleChangeByTime} className="selectclass" value={array.selectedValueByTech}>
                                            {state.chartdata.map((btn, i) => {
                                                return (
                                                    <option key={i} value={btn.name}>
                                                        {btn.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={12} sm={8} md={8} lg={10}>
                                <Typography variant="h7" color="secondary" style={{ fontWeight: 600 }}>
                                    <Typography vraient="h5" color="secondary" className="dashHeading">
                                        Jobs By Technicians &nbsp; &nbsp;Total : {array.techJobsTotal}
                                    </Typography>
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <div
                                    style={{
                                        margin: '0px 20px',
                                        textAlign: 'left'
                                    }}
                                >
                                    <span className="lableFont">Branch</span>
                                    <select onChange={handleChangeByTime} className="selectclass" value={array.selectedValueByTech}>
                                        {state.chartdata.map((btn, i) => {
                                            return (
                                                <option key={i} value={btn.name}>
                                                    {btn.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </Grid>
                        </Grid>
                    </Hidden>

                    <Grid item xs={12} style={{ padding: '5px 5px ' }}>
                        <div
                            style={{
                                textAlign: 'left',
                                padding: '0px 20px',
                                fontFamily: ` Inter, sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
                                fontWeight: 500
                            }}
                        ></div>
                    </Grid>
                    <div style={{ padding: '0px 30px' }}>
                        <hr
                            style={{
                                flexShrink: 0,
                                borderWidth: '0px 0px thin',
                                borderStyle: 'solid',
                                borderColor: 'rgba(58, 53, 65, 0.12)',
                                margin: '1.1rem 0px'
                            }}
                        />
                    </div>
                    <Grid container spacing={2} style={{ minHeight: '110px', overflow: 'auto' }} justifyContent="flex-start">
                        {array.selectedFilterTechData.map((p, i) => {
                            return (
                                <Grid
                                    item
                                    xs={6}
                                    sm={4}
                                    md={4}
                                    lg={3}
                                    xl={2}
                                    onClick={() => handleTechOnClick(p)}
                                    style={{ cursor: 'pointer' }}
                                    key={`color${i}`}
                                >
                                    <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <Avatar
                                                style={{
                                                    backgroundColor: ColorsDash[i],
                                                    border: `4px solid `,
                                                    borderOpacity: 0.1,
                                                    boxShadow: `3px 3px 11px ${ColorsDash[i]}`
                                                }}
                                            >
                                                {p.name[0]}
                                            </Avatar>
                                        </Grid>
                                        <Grid item>&nbsp;</Grid>
                                        <Grid item>
                                            <div
                                                style={{
                                                    paddingBottom: 10,
                                                    textAlign: 'left'
                                                }}
                                            >
                                                <span className="lableFont"> {p.name}</span>
                                                <div className="valueFont">{p.total}</div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Card>
            )}
        </Grid>
    );
}
