import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import {
    TextBox,
    SecondaryButton,
    SecondaryCheckbox,
    DialogContents,
    CustomButton,
    Multiselect,
    PrimaryButton,
    SelectBox,
    SingelSelect
} from '../../../Core/FormInput';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import {
    Badge,
    IconButton,
    Container,
    makeStyles,
    Grow,
    Fade,
    ButtonGroup,
    Popover,
    Paper,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Table,
    TableBody,
    InputAdornment,
    FormHelperText
} from '@material-ui/core';
import DisplayGrid from './DisplayGrid';
import Tooltip from '@material-ui/core/Tooltip';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import MenuItem from '@material-ui/core/MenuItem';
import { TechWriteProvider, TechWriteScreenContext } from './TechWriteProvider';
import Button from '@material-ui/core/Button';
import './TechnicianStyle.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import { InputLabel } from '@material-ui/core';
import ConfirmationModal from '../../../Core/Controls/Dialog/ConfirmationModal';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AppContext from '../../../App/AppContext';
import FaultCode from './FaultandAssociate/faultCode';
import AssociatedMaterial from './FaultandAssociate/assciateMaterial';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { withStyles } from '@material-ui/core/styles';
import { getPartListByPartNumber } from '../../../Core/Service/partRequisitions-service';
import { CustomIconButton } from '../../../Core/FormInput/AppButton';
import SearchIcon from '@material-ui/icons/Search';
import IsFittedwarnigModal from './FaultandAssociate/isFittedWarningModal';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { AppStorage } from '../../../Core/Service/storage-service';

const StyledTableRow = withStyles((theme) => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover
        // }
    }
}))(TableRow);

const SimpleCell = withStyles((theme) => ({
    head: {
        fontSize: 15,
        fontWeight: 600,
        backgroundColor: '#bdbdbd82',
        border: '1px solid black'
    },
    body: {
        fontSize: 12,
        border: '1px solid black'
    }
}))(TableCell);

const useStyles = makeStyles({
    inFoButton: {
        padding: 1,
        cursor: 'pointer',
        textAlign: 'left',
        fontSize: 13,
        '& :hover': {
            color: 'green',
            fontWeight: 600
        }
    }
});

const MatchingPartList = ({ list, loading, handleRowSelect, selectedRowIndex, name }) => {
    return (
        <Paper style={{ borderRadius: '5px', maxHeight: '350px', overflow: 'auto', width: '350px' }}>
            <TableContainer>
                <Table size="small" width="550px" stickyHeader>
                    <TableHead>
                        <TableRow className="header">
                            <SimpleCell>Part No</SimpleCell>
                            <SimpleCell>Description</SimpleCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.map((j, i) => (
                            <StyledTableRow className={`row${j.isSelected ? ' is-selected-j' : ''}`} onClick={() => handleRowSelect(j, selectedRowIndex, name)}>
                                <SimpleCell>{j.partNumber}</SimpleCell>
                                <SimpleCell>{j.description}</SimpleCell>
                            </StyledTableRow>
                        ))}
                        {list?.length === 0 && (
                            <TableRow>
                                <TableCell colSpan="4">{loading ? 'Loading...' : 'No record found.'}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

const PopoverWrapper = (props) => {
    const open = Boolean(props.anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={props.anchorEl}
            onClose={props.handleClosePOP}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
        >
            <MatchingPartList
                list={props.matchingPartNumber || []}
                loading={false}
                handleRowSelect={props.handleRowSelect}
                selectedRowIndex={props.selectedRowIndex}
                name={props.name}
            />
        </Popover>
    );
};

const TechWriteUp = (props) => {
    const classes = useStyles();

    const {
        printDocument,
        DownloadSTDListDocument,
        TechBtnClick,
        fromStep,
        masterData,
        state,
        selectedData,
        clear,
        DisplayGridState,
        GetTechnicianSign,
        handleSubmit,
        handleCheck,
        fieldChange,
        AutoWorkAccomplish,
        GlobalCss,
        AutoFrChange,
        handleAddClick,
        handleRemoveClick,
        handleInputChange,
        getStandartdFromChild,
        pullAndUpdateState,
        inputList,
        portalSettings,
        showConfirmationModal,
        hideConfirmationModal,
        zoomOutFun,
        zoomInFun,
        clearSig,
        isTechnician,
        isInternal,
        canAmendTechWriteUpFlag,
        saveButtonConfirmationValidation,
        hideSaveConfirmModal,
        handleFault,
        handleAssociated,
        closeModal,
        handleAssociatedRow,
        removeAssociatedRow,
        handleAssociateInput,
        handleFaultCodeRow,
        removeFaultCodeRow,
        handleFaultCodeInput,
        setInputList,
        handleRowExpand,
        singleSelectChange,
        screenDropdown,
        yesNoHandle,
        submitChildAssocate,
        pullDataByPartNumber,
        handleClosePOP,
        anchorEl,
        handleRowSelect,
        isFittedWarnig,
        closeIsFittedWarnigModal,
        handleLinkRows,
        handleAssociatedCount
    } = useContext(TechWriteScreenContext);
    const { showToast } = useContext(AppContext);

    const [ScreenWidth, screenHeight] = useWindowSize();

    const hideBrace = state.technicianName || state.technicianDateSigned;
    const hideBrace2 = state.teamLeaderName || state.teamLeaderDateSigned;
    const ShowSubmit = portalSettings.portalSettingDisplaySubmitbutton && isTechnician && state.isTechReadonly !== true && state.status != 'TS'; //imgteamleadersign-Workshop controller Signature
    const ShowSave =
        portalSettings.portalSettingsShowTechWriteUpSaveBtn &&
        ((isTechnician && state.isTechReadonly !== true) || (isInternal && canAmendTechWriteUpFlag)) &&
        state.status !== 'TS'; //imgteamleadersign-Workshop controller Signature

    const copyToClipboard = (value) => {
        navigator.clipboard.writeText(AppStorage.getCanCopyUpperCaseWriteUps() ? value.toUpperCase() : value);
        showToast('Copied!');
    };

    const IsYesNOButton = ({ typeID, value, onClick, name, index, disabled = false }) => {
        return (
            <>
                <br />
                <div style={{ marginTop: '2px' }}>
                    <ButtonGroup color="primary" aria-label="outlined primary button group" size="small" style={{ float: 'left' }} disabled={disabled}>
                        <Button size="small" variant={value ? 'contained' : 'outlined'} style={{ fontSize: 12 }} onClick={() => onClick(name, true, index)}>
                            Yes
                        </Button>
                        <Button
                            size="small"
                            // onClick={() => buttonClick(typeName, false)}
                            variant={value ? 'outlined' : 'contained'}
                            style={{ fontSize: 12 }}
                            onClick={() => onClick(name, false, index)}
                        >
                            No
                        </Button>
                    </ButtonGroup>
                </div>
            </>
        );
    };

    const TabBody = (width) => {
        return (
            <>
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={12} sm={4} md={4}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Date
                        </FormLabel>
                        <TextBox
                            id="date"
                            type="date"
                            name="date"
                            value={state.date}
                            autoComplete="new-password"
                            onChange={fieldChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                            disabled={state.isTechReadonly}
                        />
                        <FormHelperText error shrink>
                            {state.errors['date']}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Odometer Reading
                        </FormLabel>
                        <TextBox
                            disabled={state.isTechReadonly}
                            required
                            type="number"
                            name="readingtaken"
                            value={state.readingtaken}
                            onChange={fieldChange}
                            autoFocus={true}
                        />
                        <FormHelperText error shrink>
                            {state.errors['readingtaken']}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <FormLabel component="legend" className="mandatoryfields">
                            Odometer Reading Unit
                        </FormLabel>
                        <TextBox
                            disabled={state.isTechReadonly}
                            select
                            value={state.technicianNotesReadingUnit}
                            name="technicianNotesReadingUnit"
                            onChange={fieldChange}
                            rows={2}
                        >
                            {state.odometerUnitsVM?.length > 0 &&
                                state.odometerUnitsVM.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                        </TextBox>
                        <FormHelperText error shrink>
                            {state.errors?.technicianNotesReadingUnit}
                        </FormHelperText>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        {inputList.map((x, i) => {
                            let assoPartsCount =
                                x.associatedMaterials?.filter((k) => k.isDeleted === false)?.length +
                                state.partsToLink?.filter(
                                    (j) =>
                                        j.isLinked &&
                                        (x.dateCreated == j.dateCreated ||
                                            (x.technicianNotesItemID == j.technicianNotesItemID && j.technicianNotesItemID != null))
                                )?.length;
                            return (
                                !x.isDeleted && (
                                    <Grid
                                        container
                                        spacing={1}
                                        style={{
                                            border: i % 2 ? '1px dashed black' : '1px dashed  #2f38dd',
                                            background: i % 2 ? '#f5f2c5' : '#ECEFF1',
                                            marginBottom: 10,
                                            padding: 5
                                        }}
                                    >
                                        <Grid item xs={12} sm={4} md={4}>
                                            <FormLabel component="legend" className="mandatoryfields">
                                                Complaint{' '}
                                                {x.technicianNotesItemFault && (
                                                    <FileCopyIcon
                                                        style={{ fontSize: '15px', cursor: 'pointer' }}
                                                        onClick={() => copyToClipboard(x.technicianNotesItemFault)}
                                                    />
                                                )}
                                                <Tooltip title="Select Standard Write-Up" aria-label="add">
                                                    <IconButton
                                                        onClick={() => {
                                                            DisplayGridState(i);
                                                        }}
                                                        style={{
                                                            padding: '1px',
                                                            marginTop: '-9px',
                                                            marginLeft: '10px'
                                                        }}
                                                        disabled={state.isTechReadonly}
                                                    >
                                                        <NoteAddIcon color="secondary" fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            </FormLabel>
                                            <TextBox
                                                name="technicianNotesItemFault"
                                                // autoFocus={true}
                                                value={x.technicianNotesItemFault}
                                                onChange={(e) => handleInputChange(e, i)}
                                                multiline
                                                disabled={state.isTechReadonly}
                                                rows={portalSettings.portalSettingTechnWriteupHeight || 4}
                                            />
                                            <FormHelperText error shrink>
                                                {state.errors[i]?.technicianNotesItemFault}
                                            </FormHelperText>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4}>
                                            <FormLabel component="legend" className="mandatoryfields">
                                                Cause{' '}
                                                {x.technicianNotesItemCause && (
                                                    <FileCopyIcon
                                                        style={{ fontSize: '15px', cursor: 'pointer' }}
                                                        onClick={() => copyToClipboard(x.technicianNotesItemCause)}
                                                    />
                                                )}
                                            </FormLabel>
                                            <TextBox
                                                name="technicianNotesItemCause"
                                                disabled={state.isTechReadonly}
                                                value={x.technicianNotesItemCause}
                                                onChange={(e) => handleInputChange(e, i)}
                                                multiline
                                                rows={portalSettings.portalSettingTechnWriteupHeight || 4}
                                            />
                                            <FormHelperText error shrink>
                                                {state.errors[i]?.technicianNotesItemCause}
                                            </FormHelperText>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4}>
                                            <FormLabel component="legend" className="mandatoryfields">
                                                Cure{' '}
                                                {x.technicianNotesItemDiagnosis && (
                                                    <FileCopyIcon
                                                        style={{ fontSize: '15px', cursor: 'pointer' }}
                                                        onClick={() => copyToClipboard(x.technicianNotesItemDiagnosis)}
                                                    />
                                                )}
                                            </FormLabel>
                                            {!state.isTechReadonly && inputList.filter((a) => !a.isDeleted)?.length > 1 && (
                                                <Tooltip title="Remove Standard Write-Up" aria-label="remove">
                                                    <IconButton
                                                        disabled={state.isTechReadonly}
                                                        onClick={() => handleRemoveClick(i, x.technicianNotesItemID)}
                                                        className="rightButton"
                                                    >
                                                        <RemoveCircleIcon style={{ color: state.isTechReadonly ? 'grey' : 'red' }} fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                            {!state.isTechReadonly && (
                                                <Tooltip title="Add Standard Write-Up" aria-label="add">
                                                    <IconButton
                                                        disabled={state.isTechReadonly}
                                                        onClick={handleAddClick}
                                                        className="rightButton"
                                                        style={{
                                                            marginRight: '5px'
                                                        }}
                                                    >
                                                        <AddBoxIcon style={{ color: state.isTechReadonly ? 'grey' : 'green' }} fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            )}

                                            <TextBox
                                                name="technicianNotesItemDiagnosis"
                                                disabled={state.isTechReadonly}
                                                value={x.technicianNotesItemDiagnosis}
                                                onChange={(e) => handleInputChange(e, i)}
                                                multiline
                                                rows={portalSettings.portalSettingTechnWriteupHeight || 4}
                                            />
                                            <FormHelperText error shrink>
                                                {state.errors[i]?.technicianNotesItemDiagnosis}
                                            </FormHelperText>
                                        </Grid>
                                        {/* {i !== state.expandRowIndex && (
                                            <Grid item xs={12} className={classes.inFoButton}>
                                                <div
                                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
                                                    onClick={() => handleRowExpand(i)}
                                                >
                                                    <div>
                                                        {' '}
                                                        <ExpandMoreIcon style={{ marginTop: 5 }} />
                                                    </div>{' '}
                                                    <div>More Details</div>
                                                </div>
                                            </Grid>
                                        )} */}
                                        {/* {i === state.expandRowIndex && ( */}
                                        {/* <> */}

                                        {state.isFMOrWarranty && (
                                            <Grid item xs={12} sm={4} md={4} lg={2} xl={2} className="multiSelect">
                                                <FormLabel component="legend" error>
                                                    Claim Type*
                                                </FormLabel>
                                                <SingelSelect
                                                    onChange={singleSelectChange('claimTypeID', i)}
                                                    options={screenDropdown.claimTypes || []}
                                                    value={x.claimTypeIDTemp || {}}
                                                />
                                                <FormHelperText error shrink>
                                                    {state.errors[i]?.claimTypeID}
                                                </FormHelperText>
                                            </Grid>
                                        )}

                                        <Grid item xs={12} sm={4} md={4} lg={2}>
                                            <FormLabel component="legend" error>
                                                Time Taken (Hours)*
                                            </FormLabel>
                                            <TextBox
                                                name="timeTaken"
                                                type="number"
                                                disabled={state.isTechReadonly}
                                                value={x.timeTaken}
                                                onChange={(e) => handleInputChange(e, i)}
                                            />
                                            <FormHelperText error shrink>
                                                {state.errors[i]?.timeTaken}
                                            </FormHelperText>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} lg={2}>
                                            <FormLabel component="legend">&nbsp;</FormLabel>
                                            <PrimaryButton fullWidth size="small" style={{ marginTop: 5 }} onClick={() => handleAssociated(i, x.dateCreated)}>
                                                <Badge color="secondary" badgeContent={assoPartsCount} showZero>
                                                    <span style={{ fontSize: 12 }}>Parts &nbsp;</span>
                                                </Badge>
                                            </PrimaryButton>
                                            <FormHelperText error shrink>
                                                {state.errors[i]?.isimageExist}
                                            </FormHelperText>
                                        </Grid>

                                        {x.claimTypeIDTemp?.displayCausalParts && (
                                            <React.Fragment>
                                                <Grid item xs={12} sm={8} md={8} lg={6} xl={3} className="multiSelect">
                                                    <FormLabel component="legend" error>
                                                        Defect Code*
                                                    </FormLabel>
                                                    <SingelSelect
                                                        onChange={singleSelectChange('defectCode', i)}
                                                        options={screenDropdown.defectCodeList || []}
                                                        value={x.defectCodeTemp || {}}
                                                        disabled={state.isTechReadonly}
                                                    />
                                                    <FormHelperText error shrink>
                                                        {state.errors[i]?.defectCode}
                                                    </FormHelperText>
                                                </Grid>

                                                <Grid item xs={12} sm={4} md={4} lg={2} xl={1}>
                                                    <FormLabel component="legend">&nbsp;</FormLabel>
                                                    <PrimaryButton
                                                        fullWidth
                                                        size="small"
                                                        style={{ marginTop: 5 }}
                                                        onClick={() => handleFault(i, x.dateCreated)}
                                                    >
                                                        <Badge
                                                            color="secondary"
                                                            badgeContent={x.faultCodes?.filter((k) => k.isDeleted === false)?.length}
                                                            showZero
                                                        >
                                                            <span style={{ fontSize: 12 }}>Fault Codes &nbsp;</span>
                                                        </Badge>
                                                    </PrimaryButton>
                                                </Grid>

                                                <Grid item xs={12} sm={4} md={4} lg={2}>
                                                    <FormLabel component="legend" error>
                                                        Causal Part No.*
                                                    </FormLabel>
                                                    <TextBox
                                                        name="causualPartNumber"
                                                        disabled={state.isTechReadonly}
                                                        value={x.causualPartNumber}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                        autoFocus={true}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <CustomIconButton
                                                                        tabIndex={-1}
                                                                        disabled={state.isTechReadonly}
                                                                        onClick={pullDataByPartNumber(x.causualPartNumber, 'causualPartNumber', i, true)}
                                                                    >
                                                                        <SearchIcon />
                                                                    </CustomIconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                    <FormHelperText error shrink>
                                                        {state.errors[i]?.causualPartNumber}
                                                    </FormHelperText>
                                                    <PopoverWrapper
                                                        handleClosePOP={handleClosePOP}
                                                        anchorEl={anchorEl.causualPartNumber}
                                                        matchingPartNumber={state.matchingPartNumber}
                                                        handleRowSelect={handleRowSelect}
                                                        selectedRowIndex={i}
                                                        name={'causual'}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={4} lg={2}>
                                                    <FormLabel component="legend" error>
                                                        Causal Description*
                                                    </FormLabel>
                                                    <TextBox
                                                        name="causualPartDescription"
                                                        disabled={state.isTechReadonly}
                                                        value={x.causualPartDescription}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <CustomIconButton
                                                                        tabIndex={-1}
                                                                        disabled={state.isTechReadonly}
                                                                        onClick={pullDataByPartNumber(
                                                                            x.causualPartDescription,
                                                                            'causualPartDescription',
                                                                            i,
                                                                            false
                                                                        )}
                                                                    >
                                                                        <SearchIcon />
                                                                    </CustomIconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                    <FormHelperText error shrink>
                                                        {state.errors[i]?.causualPartDescription}
                                                    </FormHelperText>
                                                    <PopoverWrapper
                                                        handleClosePOP={handleClosePOP}
                                                        anchorEl={anchorEl.causualPartDescription}
                                                        matchingPartNumber={state.matchingPartNumber}
                                                        handleRowSelect={handleRowSelect}
                                                        selectedRowIndex={i}
                                                        name={'causual'}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={4} md={4} lg={2}>
                                                    <FormLabel component="legend" error>
                                                        New Part No.*
                                                    </FormLabel>
                                                    <TextBox
                                                        name="newPartNumber"
                                                        disabled={state.isTechReadonly}
                                                        value={x.newPartNumber}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <CustomIconButton
                                                                        tabIndex={-1}
                                                                        disabled={state.isTechReadonly}
                                                                        onClick={pullDataByPartNumber(x.newPartNumber, 'newPartNumber', i, true)}
                                                                    >
                                                                        <SearchIcon />
                                                                    </CustomIconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                    <FormHelperText error shrink>
                                                        {state.errors[i]?.newPartNumber}
                                                    </FormHelperText>
                                                    <PopoverWrapper
                                                        handleClosePOP={handleClosePOP}
                                                        anchorEl={anchorEl.newPartNumber}
                                                        matchingPartNumber={state.matchingPartNumber}
                                                        handleRowSelect={handleRowSelect}
                                                        selectedRowIndex={i}
                                                        name={'new'}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={4} lg={2}>
                                                    <FormLabel component="legend">Old Serial No.</FormLabel>
                                                    <TextBox
                                                        name="causualPartSerialNumber"
                                                        disabled={state.isTechReadonly}
                                                        value={x.causualPartSerialNumber}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                    />
                                                    {/* <FormHelperText error shrink>
                                                        {state.errors[i]?.causualPartSerialNumber}
                                                    </FormHelperText> */}
                                                </Grid>
                                                <Grid item xs={12} sm={4} md={4} lg={2}>
                                                    <FormLabel component="legend">New Serial No.</FormLabel>
                                                    <TextBox
                                                        name="newPartSerialNumber"
                                                        disabled={state.isTechReadonly}
                                                        value={x.newPartSerialNumber}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                    />
                                                    {/* <FormHelperText error shrink>
                                                        {state.errors[i]?.newPartSerialNumber}
                                                    </FormHelperText> */}
                                                </Grid>

                                                {/* <Grid item xs={12} sm={4} md={4} lg={2}>
                                                    <FormLabel component="legend" error>
                                                        New Description*
                                                    </FormLabel>
                                                    <TextBox
                                                        name="newPartDescription"
                                                        disabled={state.isTechReadonly}
                                                        value={x.newPartDescription}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <CustomIconButton
                                                                        tabIndex={-1}
                                                                        disabled={state.isTechReadonly}
                                                                        onClick={pullDataByPartNumber(x.newPartDescription, 'newPartDescription', i, false)}
                                                                    >
                                                                        <SearchIcon />
                                                                    </CustomIconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                    <FormHelperText error shrink>
                                                        {state.errors[i]?.newPartDescription}
                                                    </FormHelperText>
                                                    <PopoverWrapper
                                                        handleClosePOP={handleClosePOP}
                                                        anchorEl={anchorEl.newPartDescription}
                                                        matchingPartNumber={state.matchingPartNumber}
                                                        handleRowSelect={handleRowSelect}
                                                        selectedRowIndex={i}
                                                        name={'new'}
                                                    />
                                                </Grid> */}

                                                {/* <Grid item xs={12}>
                                                    <Grid container spacing={2}> */}

                                                <Grid item xs={12} sm={6} md={'auto'} lg={3} xl={3}>
                                                    <FormLabel component="legend" shrink>
                                                        Material Located in Designated Area
                                                    </FormLabel>
                                                    <IsYesNOButton
                                                        disabled={state.isTechReadonly}
                                                        name="materialLocatedInDesignatedArea"
                                                        value={x.materialLocatedInDesignatedArea}
                                                        index={i}
                                                        onClick={yesNoHandle}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={'auto'} lg={3} xl={3}>
                                                    <FormLabel component="legend" shrink>
                                                        Parts in Quarantine
                                                    </FormLabel>
                                                    <IsYesNOButton
                                                        disabled={state.isTechReadonly}
                                                        name="partInQuarantine"
                                                        value={x.partInQuarantine}
                                                        index={i}
                                                        onClick={yesNoHandle}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={'auto'} lg={3} xl={3}>
                                                    <FormLabel component="legend" shrink>
                                                        Causual Parts Labelled
                                                    </FormLabel>
                                                    <IsYesNOButton
                                                        disabled={state.isTechReadonly}
                                                        name="causalPartsLabelled"
                                                        value={x.causalPartsLabelled}
                                                        index={i}
                                                        onClick={yesNoHandle}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={'auto'} lg={3} xl={3}>
                                                    <FormLabel component="legend" shrink>
                                                        Material in original Boxes
                                                    </FormLabel>
                                                    <IsYesNOButton
                                                        disabled={state.isTechReadonly}
                                                        name="materialInOrignalBoxes"
                                                        value={x.materialInOrignalBoxes}
                                                        index={i}
                                                        onClick={yesNoHandle}
                                                    />
                                                </Grid>
                                                {/* </Grid>
                                                </Grid> */}
                                            </React.Fragment>
                                        )}

                                        {/* <Grid item xs={12} sm={1}>
                                            <FormLabel component="legend">&nbsp;</FormLabel>
                                            <PrimaryButton fullWidth size="small" style={{ marginTop: 5 }} onClick={() => handleAssociated(i, x.dateCreated)}>
                                                <Badge
                                                    color="secondary"
                                                    badgeContent={x.associatedMaterials?.filter((k) => k.isDeleted === false)?.length}
                                                    showZero
                                                >
                                                    <PhotoCameraIcon />
                                                </Badge>
                                            </PrimaryButton>
                                        </Grid> */}
                                    </Grid>
                                )
                            );
                        })}
                        <Grid container item xs={12}></Grid>
                    </Grid>
                    {!portalSettings.hideWorkAccomplishedAndFailureReasons && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <FormLabel component="legend">Work Accomplished</FormLabel>
                                <Multiselect
                                    label="Work Accomplished"
                                    options={masterData.workaccomplishcodes}
                                    value={selectedData.selectedWorkAccomplishCodes}
                                    onChange={AutoWorkAccomplish}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormLabel component="legend">Failure Reasons</FormLabel>
                                <Multiselect
                                    label="Failu Rereason"
                                    options={masterData.failureCodes}
                                    value={selectedData.selectedFailureCodes}
                                    onChange={AutoFrChange}
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} className="gridcontainer">
                        <div>
                            <Typography style={{ float: 'left' }}>Diagnostic Road Test</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={3}>
                                    <FormLabel component="legend">Mileage In</FormLabel>
                                    <TextBox disabled={state.isTechReadonly} value={state.mileagein} type="number" name="mileagein" onChange={fieldChange} />
                                </Grid>
                                <Grid item xs={12} sm={3} className="marginBottom">
                                    <FormLabel component="legend">Mileage Out</FormLabel>
                                    <TextBox disabled={state.isTechReadonly} value={state.mileageout} type="number" name="mileageout" onChange={fieldChange} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormLabel component="legend">Time In</FormLabel>
                                    <TextBox
                                        disabled={state.isTechReadonly}
                                        name="timein"
                                        value={state.timein}
                                        type="time"
                                        onChange={fieldChange}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        inputProps={{
                                            step: 300 // 5 min
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} className="marginBottom">
                                    <FormLabel component="legend">Time Out</FormLabel>
                                    <TextBox
                                        disabled={state.isTechReadonly}
                                        name="timeout"
                                        type="time"
                                        value={state.timeout}
                                        onChange={fieldChange}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        inputProps={{
                                            step: 300 // 5 min
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox
                                    disabled={state.isTechReadonly}
                                    checked={state.smoketest}
                                    onChange={handleCheck}
                                    value={state.smoketest}
                                    name="smoketest"
                                />
                            }
                            label="Smoke Test Performed"
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox
                                    disabled={state.isTechReadonly}
                                    checked={state.serviceindicator}
                                    onChange={handleCheck}
                                    value={state.serviceindicator}
                                    name="serviceindicator"
                                />
                            }
                            label="Service Indicator Reset"
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox
                                    disabled={state.isTechReadonly}
                                    checked={state.radiocode}
                                    onChange={handleCheck}
                                    name="radiocode"
                                    value={state.radiocode}
                                />
                            }
                            label="Radio Code Reset"
                        />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <FormControlLabel
                            control={
                                <SecondaryCheckbox
                                    disabled={state.isTechReadonly}
                                    checked={state.timeclock}
                                    onChange={handleCheck}
                                    name="timeclock"
                                    value={state.timeclock}
                                />
                            }
                            label="Time Clock Reset"
                        />
                    </Grid>
                    {/* {!isInternal && ( */}
                    <Grid item xs={12} sm={6}>
                        <CustomeSignPad
                            onChange={GetTechnicianSign}
                            disabled={isInternal || state.isTechReadonly}
                            content={
                                <FormLabel
                                    component="legend"
                                    className="mandatoryfields"
                                    style={{
                                        marginBottom: '9px',
                                        marginTop: '5px'
                                    }}
                                >
                                    Technician {hideBrace ? <span> ({`${state.technicianName || ''} ${state.technicianDateSigned}`})</span> : ' Signature'}
                                </FormLabel>
                            }
                            sign={state.imgtechniciansign}
                            name="techniciansign"
                            // hideBtn={!isTechnician}
                            // !ShowSubmit ? !(isTechnician && !state.isTechReadonly) :
                            hideBtn={!ShowSubmit}
                            clearSig={clearSig}
                        />
                        <FormHelperText error shrink>
                            {state.errors['techniciansign']}
                        </FormHelperText>
                    </Grid>
                    {/* )} */}

                    {state.imgteamleadersign && (
                        <Grid item xs={12} sm={6}>
                            <CustomeSignPad
                                disabled={state.isTechReadonly}
                                content={
                                    <FormLabel
                                        component="legend"
                                        className="mandatoryfields"
                                        style={{
                                            marginBottom: '9px',
                                            marginTop: '5px'
                                        }}
                                    >
                                        Workshop Controller
                                        {hideBrace2 ? <span> ({`${state.teamLeaderName || ''} ${state.teamLeaderDateSigned}`})</span> : ' Signature'}
                                    </FormLabel>
                                }
                                sign={state.imgteamleadersign}
                                name="teamleadersign"
                                hideBtn={true}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} sm={6} style={{ marginTop: state.imgtechniciansign ? 10 : 5 }}>
                        <Grid container spacing={2} item>
                            {ShowSave && (
                                // !state.imgteamleadersign &&
                                <Grid item xs={6}>
                                    <InputLabel>{'\u{2800}'}</InputLabel>
                                    {state.submitLoader ? (
                                        <SecondaryButton fullWidth className="btn_add setFullWidth">
                                            <CircularProgress size={25} />
                                        </SecondaryButton>
                                    ) : (
                                        <SecondaryButton className="btn_add setFullWidth" onClick={saveButtonConfirmationValidation}>
                                            Save
                                        </SecondaryButton>
                                    )}
                                </Grid>
                            )}

                            {ShowSubmit && (
                                <Grid item xs={6}>
                                    <InputLabel>{'\u{2800}'}</InputLabel>

                                    <SecondaryButton className="btn_add setFullWidth" onClick={showConfirmationModal}>
                                        Submit
                                    </SecondaryButton>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    {/* )} */}
                </Grid>
                {state.showConfirmModal ? (
                    <DialogComp title="Confirmation" onClose={hideConfirmationModal} maxWidth="sm">
                        <ConfirmationModal
                            message={`By submitting you will not be able to make any further changes to the form and it transferred to the next queue. If you need to make further changes please click cancel and select the save option.`}
                            handleSubmit={() => handleSubmit('S')}
                            handleCancel={hideConfirmationModal}
                        />
                    </DialogComp>
                ) : null}
                {state.showSaveConfirmModal ? (
                    <DialogComp title="Confirmation" onClose={hideSaveConfirmModal} maxWidth="sm">
                        <ConfirmationModal
                            message={`Signature is only allowed once you have completed write up and click the Submit button`}
                            handleCancel={hideSaveConfirmModal}
                        />
                    </DialogComp>
                ) : null}
            </>
        );
    };

    return (
        <>
            {state.showGrid ? <DisplayGrid onFormSubmit={pullAndUpdateState} onStandardWriteup={getStandartdFromChild} /> : null}
            <GlobalCss />
            {!fromStep && (
                <Breadcrumbs separator=">>" aria-label="breadcrumb">
                    <Link color="inherit" to={'/'}>
                        Technician App
                    </Link>
                    <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                        Technician Write-up
                    </Typography>
                </Breadcrumbs>
            )}
            <div>
                <DialogContents className="mt-11" style={{ background: 'white' }}>
                    <Grid container spacing={1} style={{ position: 'relative' }} alignItems="center" justifyContent="flex-start">
                        <Grid item xs={12} sm={6} md={4} lg={3} style={{ textAlign: 'left' }}>
                            Registration Number:&nbsp; <b> {state.registration}</b>
                        </Grid>
                        <Grid item xs={12} sm={3} md={2} lg={2} style={{ textAlign: 'left' }}>
                            WIP No:&nbsp; <b> {state.wipNumber}</b>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4} style={{ textAlign: 'left' }}>
                            Customer:&nbsp; <b> {state.customerName}</b>
                        </Grid>
                        <Grid item sm={3} style={{ position: 'absolute', top: '-4px', right: '20px' }} id="zommContainer">
                            {/* <b>Zoom</b>
                            <CustomButton
                                color="secondary"
                                disabled={state.fontResize == 16}
                                icon={ZoomOutIcon}
                                onClick={zoomOutFun}
                                toolTipTitle="Zoom Out"
                                iconFontSize={28}
                            />
                            <CustomButton
                                color="secondary"
                                disabled={state.fontResize == 28}
                                icon={ZoomInIcon}
                                onClick={zoomInFun}
                                toolTipTitle="Zoom In"
                                iconFontSize={28}
                            /> */}
                            <CustomButton
                                IconColor="green"
                                icon={CloudDownloadIcon}
                                onClick={DownloadSTDListDocument}
                                toolTipTitle="Download writeup by date created"
                                iconFontSize={28}
                            />
                            <CustomButton
                                color="secondary"
                                icon={CloudDownloadIcon}
                                onClick={printDocument}
                                toolTipTitle="Download this document"
                                iconFontSize={28}
                            />
                            {/* <CircularProgress size={20} /> */}
                        </Grid>
                        <Grid item sm={12}></Grid>
                        {state.showCircularLoader ? (
                            <Grid item sm={12}>
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <>
                                {state.technicians.map((m) => (
                                    <div className="tech-btn-group">
                                        <Button
                                            className={state.technicianNotesJobID == m.technicianJobID ? 'active-btn' : 'all-btn'}
                                            onClick={() => TechBtnClick(m.technicianJobID)}
                                        >
                                            {m.technician}
                                        </Button>
                                    </div>
                                ))}

                                {TabBody(ScreenWidth)}
                            </>
                        )}
                    </Grid>
                </DialogContents>
                {state.showAssociated ? (
                    <DialogComp title={'Parts'} maxWidth="lg" hideClose={true} fullWidth overflow="auto">
                        <AssociatedMaterial
                            onClose={closeModal}
                            list={[...inputList[state.modalIndex].associatedMaterials]}
                            selectedWriteUp={{ ...inputList[state.modalIndex] }}
                            handleAssociatedRow={handleAssociatedRow}
                            removeAssociatedRow={removeAssociatedRow}
                            handleAssociateInput={handleAssociateInput}
                            isReadOnly={state.isTechReadonly}
                            setInputList={setInputList}
                            modalIndex={state.modalIndex}
                            submitChildAssocate={submitChildAssocate}
                            screenDropdown={screenDropdown}
                            userlinkList={[...state.partsToLink] || []}
                            handleLinkRows={handleLinkRows}
                            handleAssociatedCount={handleAssociatedCount}
                        />
                    </DialogComp>
                ) : null}
                {state.showFault ? (
                    <DialogComp title={'Fault Codes'} maxWidth="md" hideClose={true} fullWidth>
                        <FaultCode
                            onClose={closeModal}
                            inputList={inputList}
                            list={inputList[state.modalIndex].faultCodes}
                            handleFaultCodeRow={handleFaultCodeRow}
                            removeFaultCodeRow={removeFaultCodeRow}
                            handleFaultCodeInput={handleFaultCodeInput}
                            isReadOnly={state.isTechReadonly}
                        />
                    </DialogComp>
                ) : null}
                {isFittedWarnig.show ? (
                    <DialogComp title={'Warning'} maxWidth="md" onClose={closeIsFittedWarnigModal} fullWidth>
                        <IsFittedwarnigModal
                            list={isFittedWarnig.list || []}
                            onClose={() => closeIsFittedWarnigModal()}
                            handleSubmit={() => handleSubmit('S')}
                        />
                    </DialogComp>
                ) : null}
            </div>
        </>
    );
};

const Screen = (props) => {
    return (
        <TechWriteProvider {...props}>
            <TechWriteUp {...props} />
        </TechWriteProvider>
    );
};
export default Screen;
