import { Grid } from '@material-ui/core';
import React from 'react';
import ReportGijgoGrid from '../ReportsScreens/GijgoForReportsScreen';

const baseColumns = [
    { field: 'lne', sortable: false, title: `Lne`, filterable: false, width: 90 },
    { field: 'opc', sortable: true, title: `OPC`, filterable: false, width: 140 },
    { field: 'description', sortable: true, title: `Description`, filterable: false },
    { field: 'qty', sortable: true, title: `QTY`, align: 'right', filterable: false, width: 80 },
    { field: 'usp', sortable: true, title: `USP`, align: 'right', filterable: false, width: 100 },
    { field: 'timeSpent', sortable: true, title: 'Time Spent', align: 'right', filterable: false, width: 120 },
    {
        field: 'amountInv',
        sortable: true,
        title: 'Amount Inv',
        align: 'right',
        filterable: false,
        width: 120
    },
    {
        field: 'amount',
        title: 'Amount',
        align: 'right',
        filterable: false,
        sortable: false,
        width: 120,
        renderer: (val, record) => {
            return record.currency !== undefined ? `${record.amount} ${record.currency}` : '';
        }
    },
    { field: 'ct', title: 'CT', align: 'right', filterable: false, sortable: false, width: 60 },

    // { field: 'davcmopccod', sortable: true, title: `Breakdown`, showColumnTotal: true, align: 'right', filterable: false },
    // { field: 'amount', sortable: true, title: `Amt cst currency`, showColumnTotal: true, align: 'right', filterable: false },
    { field: 'type', sortable: true, title: 'Type', showColumnTotal: true, align: 'center', filterable: false, width: 60 }
    // { field: 'fourMonthsAndOverTotal', sortable: true, title: 'WorkCode', showColumnTotal: true, align: 'right', filterable: false },
    // { field: 'fourMonthsAndOverTotal1', sortable: true, title: 'Corrective des %', showColumnTotal: true, align: 'right', filterable: false },
    // { field: 'unallocatedTotal', title: 'Campaign', showColumnTotal: true, align: 'right', filterable: false, sortable: false }
];
const Labour = (props) => {
    return (
        <Grid item container spacing={1} xs={12}>
            <Grid item xs={12}>
                <ReportGijgoGrid
                    dataConverter={(res) => ({
                        records: props.List,
                        total: props.List.length
                    })}
                    columns={baseColumns}
                    hidePagination={true}
                    hideFilterRow={true}
                />
            </Grid>
        </Grid>
    );
};

export default Labour;
