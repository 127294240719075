import React, { useEffect, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, Tooltip, CircularProgress } from '@material-ui/core';
import AppContext from '../../../App/AppContext';
import { SecondaryButton } from '../../../Core/FormInput';
import { PartReqPhotoDeleteByPhotoId, getPhotosByPartReqID, postPhotosBySupplierPartReqID } from '../../../Core/Service/partRequisitions-service';
import { Backup } from '@material-ui/icons';
import getMultiImgResized from '../../../Core/CommonFunctions/multiImgResizer';

function PartReqSupplierPhotoModal({ partId, onFormSubmit, desc, onClose, partDateCreated, getImageDateList, disabled }) {
    const [state, setState] = useState({
        id: null,
        Photos: [],
        errors: {},
        showLoader: true,
        hideSubmit: false,
        hideUploadBtn: false
    });

    const { showToast } = useContext(AppContext);

    useEffect(() => {
        getPhotos();
    }, []);

    const getPhotos = async () => {
        if (partId) {
            let res = await getPhotosByPartReqID(partId);
            if (res.success) {
                let data = res?.data?.list;
                setState((st) => ({
                    ...st,
                    Photos: [...data],
                    showLoader: false,
                    hideSubmit: data.length > 0 ? true : false,
                    hideUploadBtn: data.length > 0 ? true : false
                }));
            } else {
                setState((st) => ({
                    ...st,
                    showLoader: false
                }));
            }
        } else {
            setState((st) => ({
                ...st,
                showLoader: false
            }));
        }
    };

    const upload = async (e) => {
        getMultiImgResized(e.target.files, (fileUrl) => {
            let data = fileUrl.map((m, i) => {
                return { partReqPhotoID: null, partReqPhotoFile: m };
            });
            setState((st) => ({
                ...st,
                Photos: [...st.Photos, ...data]
            }));
        });
    };

    const handleRemoveClick = async (index, tempIndex) => {
        if (index) {
            try {
                let res = await PartReqPhotoDeleteByPhotoId(index);
                if (res.success) {
                    setState((st) => ({
                        ...st,
                        Photos: [],
                        showLoader: true
                    }));
                    showToast('Image Deleted successfully');
                    if (onFormSubmit) onFormSubmit();
                    getPhotos();
                } else {
                    showToast(res.message);
                }
            } catch (error) {
                console.error('Error deleting photo:', error);
                showToast('Error deleting photo');
            }
        } else {
            setState((st) => ({
                ...st,
                Photos: st.Photos.filter((_, idx) => idx !== tempIndex)
            }));
        }
    };

    const submitHandler = async () => {
        if (partId) {
            let data = {
                partReqPhotoID: null,
                partReqPhotoPartReqID: partId,
                partReqPhotoFile: state.Photos.filter((k) => k.partReqPhotoID == null).map((k) => k.partReqPhotoFile)
            };
            let res = await postPhotosBySupplierPartReqID(data);
            if (res.success) {
                showToast('Photos saved successfully.');
                partId && onFormSubmit && onFormSubmit();
                onClose(true);
            } else {
                showToast('Failed to upload photos.  Please try again.');
            }
        } else {
            let dataList = [];
            let newData = {
                partReqID: null,
                partReqPhotoFile: state.Photos.filter((k) => k.partReqPhotoID == null).map((k) => k.partReqPhotoFile),
                dateCreated: partDateCreated
            };
            dataList.push(newData);

            getImageDateList(dataList);
        }
    };

    return (
        <div>
            <Grid container spacing={2} className="main-container">
                {state.showLoader ? (
                    <Grid item sm={12} container alignItems="center" justify="center" style={{ width: 'calc(100vh - 230px)', height: 640 }}>
                        <CircularProgress />
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12} className="mandatory-fields">
                            <div>
                                <InputLabel fullWidth> {desc} </InputLabel>
                            </div>
                        </Grid>
                        <Grid item xs={12} container alignContent="flex-start" style={{ width: 'calc(100vh - 230px)', height: 'calc(100vh - 230px)' }}>
                            {/* {!state.hideUploadBtn && ( */}
                            {!disabled && (
                                <Grid item xs={12}>
                                    <Tooltip title="Upload Document">
                                        <label>
                                            <Backup color="secondary" />
                                            <input
                                                name="branchStampImage"
                                                multiple
                                                type="file"
                                                onChange={(e) => upload(e)}
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                            />
                                        </label>
                                    </Tooltip>
                                </Grid>
                            )}
                            {/* )} */}
                            <Grid item xs={12} spacing={2} style={{ height: '85%' }}>
                                <Grid container spacing={2} style={{ overflow: 'auto', height: '100%' }}>
                                    {state.Photos.map((item, i) => (
                                        <Grid xs={12} sm={3} md={3} lg={3} item key={i} className="image-item" style={{ marginTop: 15 }}>
                                            <img src={item.partReqPhotoFile} alt="No images" width="100%" height="150px" />
                                            <div className="image-item__btn-wrapper">
                                                {/* {item.partReqPhotoID && ( */}
                                                <SecondaryButton fullWidth onClick={() => handleRemoveClick(item.partReqPhotoID, i)} disabled={disabled}>
                                                    Remove
                                                </SecondaryButton>
                                                {/* )} */}
                                            </div>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            {/* {!state.hideSubmit && ( */}
                            <Grid item xs={12} clasName="submit_btn">
                                <SecondaryButton fullWidth onClick={submitHandler} disabled={disabled}>
                                    {partId ? 'Submit' : 'Save'}
                                </SecondaryButton>
                            </Grid>
                            {/* )} */}
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
}
//
export default PartReqSupplierPhotoModal;
