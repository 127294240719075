import { Checkbox, FormControlLabel, FormHelperText, Grid, InputLabel } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { SecondaryButton, SecondaryCheckbox, TextBox } from '../../../Core/FormInput';
import { postSupplier } from '../../../Core/Service/PartReq_services/partReq-service';
import AppContext from '../../../App/AppContext';
import JobDetails from './../../customer/getJobList';
import { isEmailValid } from '../../../Core/Controls/validationByRegExp.js';

export const AddSupplier = (props) => {
    const { showToast } = useContext(AppContext);
    const [state, setState] = useState({
        supplierID: null,
        supplierName: '',
        supplierAddress1: '',
        supplierAddress2: '',
        supplierAddress3: '',
        supplierCity: '',
        supplierCounty: '',
        supplierPhone: '',
        supplierContactName: '',
        supplierEmail: '',
        supplierIsInternal: false
    });

    const [errors, setErrors] = useState({});

    const handleInputPart = (e) => {
        const { name, value, checked } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };
    const handleCheckedPart = (e) => {
        const { name, value, checked } = e.target;
        setState((st) => ({ ...st, [name]: checked }));
    };

    const validation = () => {
        let isValid = true;
        let { supplierName, supplierAddress1, supplierCity, supplierEmail } = state;
        let errors = {};

        let isEmailValidate = isEmailValid(supplierEmail);

        if (!supplierName) {
            isValid = false;
            errors.supplierName = 'This field is required';
        }
        if (!supplierAddress1) {
            isValid = false;
            errors.supplierAddress1 = 'This field is required';
        }
        if (!supplierCity) {
            isValid = false;
            errors.supplierCity = 'This field is required';
        }
        if (isEmailValidate.showError) {
            isValid = false;
            errors.supplierEmail = isEmailValidate.message || '';
        }
        setErrors(errors);
        return isValid;
    };

    let handleSubmit = async () => {
        if (validation()) {
            let data = {
                supplierID: state.supplierID,
                supplierName: state.supplierName,
                supplierAddress1: state.supplierAddress1,
                supplierAddress2: state.supplierAddress2,
                supplierAddress3: state.supplierAddress3,
                supplierCity: state.supplierCity,
                supplierCounty: state.supplierCounty,
                supplierPhone: state.supplierPhone,
                supplierContactName: state.supplierContactName,
                supplierEmail: state.supplierEmail,
                supplierIsInternal: state.supplierIsInternal || false,
                SupplierBranchID: props.branchID
            };

            let res = await postSupplier(data);
            if (res.success) {
                showToast(res.data.message);
                props.onClose(true);
            }
        }
    };
    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel error>Name</InputLabel>
                    <TextBox onChange={handleInputPart} name="supplierName" value={state.supplierName} />
                    <FormHelperText error shrink style={{ marginTop: 0 }}>
                        {errors.supplierName}
                    </FormHelperText>
                </Grid>

                <Grid item sm={12} lg={4}>
                    <InputLabel error>Address 1</InputLabel>
                    <TextBox onChange={handleInputPart} name="supplierAddress1" value={state.supplierAddress1} />
                    <FormHelperText error shrink style={{ marginTop: 0 }}>
                        {errors.supplierAddress1}
                    </FormHelperText>
                </Grid>
                <Grid item sm={6} lg={4}>
                    <InputLabel>Address 2</InputLabel>
                    <TextBox onChange={handleInputPart} name="supplierAddress2" value={state.supplierAddress2} />
                </Grid>
                <Grid item sm={6} lg={4}>
                    <InputLabel>Address 3</InputLabel>
                    <TextBox onChange={handleInputPart} name="supplierAddress3" value={state.supplierAddress3} />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel>County</InputLabel>
                    <TextBox onChange={handleInputPart} name="supplierCounty" value={state.supplierCounty} />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel error>City</InputLabel>
                    <TextBox onChange={handleInputPart} name="supplierCity" value={state.supplierCity} />
                    <FormHelperText error shrink style={{ marginTop: 0 }}>
                        {errors.supplierCity}
                    </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel error>Email</InputLabel>
                    <TextBox onChange={handleInputPart} name="supplierEmail" value={state.supplierEmail} />
                    <FormHelperText error shrink style={{ marginTop: 0 }}>
                        {errors.supplierEmail}
                    </FormHelperText>
                </Grid>

                <Grid item xs={4}>
                    <InputLabel>Contact Name</InputLabel>
                    <TextBox onChange={handleInputPart} name="supplierContactName" value={state.supplierContactName} />
                </Grid>
                <Grid item xs={4}>
                    <InputLabel>Phone</InputLabel>
                    <TextBox onChange={handleInputPart} name="supplierPhone" value={state.supplierPhone} />
                </Grid>
                <Grid item xs={4}>
                    <FormControlLabel
                        style={{ marginTop: 20 }}
                        control={<Checkbox checked={state.supplierIsInternal} onChange={handleCheckedPart} name="supplierIsInternal" />}
                        label="Is Internal ?"
                    />
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton fullWidth onClick={handleSubmit}>
                        Submit
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton fullWidth onClick={() => props.onClose(false)}>
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};
