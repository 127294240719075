import React, { useEffect, useMemo, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';

import './reportGijgoGrid.scss';
import ReactDOM from 'react-dom';

import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import { AppStorage } from '../../../../Core/Service/storage-service';
import { ExpBaseURL } from '../../../../Core/Service/http-calls';
import formatters from '../../../../Core/Grid/inputFormatter';
import MailIcon from '@material-ui/icons/Mail';
import { SquareButton } from '../../../../Core/FormInput/AppButton';
import AppContext from '../../../../App/AppContext';
import { GetExcelFileFromEndpoint } from '../../../../Core/Service/reportService';

var grid1;
var $ = window.$;

let addCommas = (val) => {
    if (val === '') {
        return '';
    } else {
        return val ? `£${val?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '£0.00';
    }
};

const ReportGijgoGrid = (props) => {
    let isAction = props.columns.find((c) => c.field === 'action');

    const useStyles = makeStyles((theme) => ({
        // gijgoGrid: {
        //     '& .gj-grid-md th:first-of-type': {
        //         // backgroundColor: 'red',
        //         padding: (props) => {
        //             return props.isAction ? '12px 5px 12px 5px' : '12px 15px 12px 10px';
        //         }
        //     }
        // }
    }));

    const classes = useStyles({ isAction: isAction });
    let token = AppStorage.getToken();
    const [state, setState] = useState({
        dropDownList: [],
        dropDownColNames: [],
        recordId: props.recordId,
        fileCount: `${props.fileCount || 0}`,
        configName: props.fileUploaderConfigName,
        columns: []
    });
    const [gridReload, setGridReload] = useState(new Date());

    const { hideModal, showToast } = useContext(AppContext);

    const gridFileCountRefresh = () => {
        setGridReload((st) => ({ ...st, gridReload: new Date() }));
    };

    useEffect(async () => {
        let dropDownColNames = [];
        let dropDownList = [];
        let finalColumns = [...props.columns].map((c) => {
            let tempObj = {
                ...c,
                // headerCssClass: 'gridHeader'
                align: c.align ? c.align : 'left'
            };
            let currencyRenderer = c.isCurrency;
            if (currencyRenderer) {
                tempObj.renderer = function (value, record) {
                    return '<span title="' + value + '"  >' + formatters.CurrencyThousandSeparator(value) + '</span>';
                };
            }
            if (c.isDropDown) {
                dropDownColNames.push(c);
            }
            return { ...tempObj };
        });

        if (dropDownColNames.length > 0) {
            dropDownList = typeof props.dropDownListProvider === 'function' ? await props.dropDownListProvider() : props.dropDownListProvider;
        }

        setState((st) => ({ ...st, columns: finalColumns, dropDownList: dropDownList, dropDownColNames: dropDownColNames }));
    }, [props.columns, gridReload, props.dropDownListProvider]);

    useEffect(async () => {
        if (state.columns.length > 0) {
            pullArchivedJobsAndUpdateState();
        }
    }, [state.columns, props.isReload, props.getUrl]);

    var GijgoFilterExtension = (function () {
        function DropdownFilter(grid, inputProperty, dropdown, changeValueConversion) {
            var id = $(grid).attr('id');
            var rowXPath = '#' + id + ' thead tr[data-role="filter"]';
            var filterRow = $(rowXPath);
            if ($(filterRow).length == 1) {
                var colXPath = 'th [data-field="' + inputProperty.field + '"]';
                var filterInput = $(filterRow).find(colXPath);
                if ($(filterInput).length) {
                    $(filterInput).parent().addClass('grid-filter-select-th');
                    $(dropdown).attr('data-field', inputProperty.field);
                    $(filterInput).replaceWith(dropdown);
                    $(dropdown).on('change', function () {
                        ChangeFilter(grid, inputProperty, $(this), changeValueConversion);
                    });
                }
            }
        }

        function ChangeFilter(grid, inputProperty, ddl, changeValueConversion) {
            if (changeValueConversion) {
                var propArr = changeValueConversion(ddl, inputProperty.field, grid);
                $(propArr).each(function (i, obj) {
                    grid.data().params[obj.inputProperty.field] = obj.value;
                });
                if (propArr.length) {
                    grid.reload();
                }
            } else {
                grid.data().params[`${inputProperty.queryParamName || inputProperty.field}`] = $(ddl).val();
                grid.reload();
            }
        }

        return {
            Dropdown: DropdownFilter
        };
    })();

    const setDataSource = (gridInstance, getUrl) => {
        const onSuccessFunc = function (response) {
            gridInstance.render(props.dataConverter(response));
        };
        if (getUrl) {
            gridInstance.data().dataSource = {
                url: `${ExpBaseURL}/api/${getUrl}`,
                headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
                success: onSuccessFunc
            };
        } else {
            gridInstance.data().dataSource = onSuccessFunc();
        }
    };

    const pullArchivedJobsAndUpdateState = async () => {
        window.$(`#${'key1'}`).grid('destroy', true, true);

        // const onSuccessFunc = function (response) {
        //     grid1.render(props.dataConverter(response));
        // };

        grid1 = window.$(`#${'key1'}`).grid({
            primaryKey: 'Id',
            dataSource: [],
            // dataSource: {
            //     url: `${ExpBaseURL}/api/${props.getUrl}`,
            //     headers: { Authorization: `Bearer ${token}`, domainName: window.location.host.includes('localhost') ? 'localhost' : window.location.host },
            //     success: onSuccessFunc
            // },
            ...(props.minWidth !== null && { minWidth: 1800 }),
            ...(props.isGroupingId && { grouping: { groupBy: `${props.isGroupingId}` } }),
            // fixedHeader: true,
            // uiLibrary: 'bootstrap4',
            ...(!props.hideHeaderFilter && {
                headerFilter: {
                    type: 'onchange'
                }
            }),
            ...(props.selectionType && { selectionType: props.selectionType }),
            ...(props.selectionMethod && { selectionMethod: props.selectionMethod }),
            // selectionType: 'multiple',
            // selectionMethod: 'checkbox',
            ...(props.detailTemplate && { detailTemplate: props.detailTemplate() }),
            columns: state.columns,
            ...(!props.hidePagination && { pager: { limit: 100, sizes: [20, 50, 100, 500, 1000, 2000, 5000] } })
        });

        setTimeout(() => {
            setDataSource(grid1, props.getUrl);
            grid1.reload();
        });

        if (state.dropDownColNames.length > 0) {
            state.dropDownColNames.forEach((q) => {
                var ddl = $('<select class="grid-filter-select"><option value="" >All</option></select>');

                state.dropDownList[`${q.listIdFromAPIResponse}`]?.forEach((k) => {
                    let value = k[q.dropDownValueField || 'id'];
                    if (value === undefined || value === null) {
                        console.error(`${q.dropDownValueField} Invalid Property`);
                    }
                    ddl.append(`<option value="${value}" >${k[q.dropDownLableField || '']}</option>`);
                });
                GijgoFilterExtension.Dropdown(grid1, q, ddl);
            });
        }

        let storedFiltterValue = props.FilterdParamsValues;
        props.columns.forEach((itm) => {
            if (storedFiltterValue) {
                var id = $(grid1).attr('id');
                var rowXPath = '#' + id + ' thead tr[data-role="filter"]';
                var filterRow = $(rowXPath);
                if ($(filterRow).length == 1) {
                    var colXPath = 'th [data-field="' + `${itm.field}` + '"]';
                    var filterInput = $(filterRow).find(colXPath);
                    if ($(filterInput).length && storedFiltterValue[`${itm.field}`]) {
                        $(filterInput).val(storedFiltterValue[`${itm.field}`]);
                        grid1.data().params[itm.field] = storedFiltterValue[`${itm.field}`];
                    }
                }
            }
        });

        grid1.on('dataBound', function (e, records, totalRecords) {
            let $tfoot = grid1.children('tfoot');
            let $thead = grid1.children('thead');
            if (props.hidePagination) {
                $tfoot.children('tr[data-role="pager"]').remove();
            }
            if (props.hideFilterRow) {
                $thead.children('tr[data-role="filter"]').remove();
            }
            if (props.isShowTotal) {
                let cal = state.columns.map((p) => {
                    if (p.showColumnTotal) {
                        let total = 0;
                        records.forEach((u) => {
                            total += +u[`${p.field}`];
                        });
                        return total;
                    } else {
                        return '';
                    }
                });
                $tfoot.children('tr[data-role="TotalAmount"]').remove();
                $tfoot.prepend(
                    `<tr data-role="TotalAmount" class="total_ft"> 
                    ${cal.map((y, i) => {
                        if (i == 0) {
                            return `<th style="text-align:left;font-weight:bold;${props.downloadName && 'padding-left:20px;'}">Total</th>`;
                        }

                        return `<th style="text-align:right;font-weight:bold;">${addCommas(y)}</th>`;
                    })}
                    </tr>`
                );
            }

            if (props.showCheckboxColFilterBtn) {
                //     var SendMulitpleInvoiceslink = `<a href="#SendMulipleInvoicesPopup" id="lnkSendSelected" style="top:0px" title="Email Invoices"
                // data-url="@Url.Action("SendInvoices", "Invoices", new { Area = "InternalUser" })">
                // <button>val</button>
                // </a>`;
                //     $('#key1').find('thead tr[data-role="filter"] > th:nth-child(1)').html(SendMulitpleInvoiceslink);
            }
            if (props.showCheckboxColFilterBtn) {
                $('#key1')
                    .find('thead tr[data-role="filter"] > th:nth-child(1)')
                    .html(() => props.showCheckboxColFilterBtn('#key1', records));
            }
            if (props.AddBtn) {
                $('#key1')
                    .find('thead tr[data-role="filter"] > th:nth-child(1)')
                    .html(() => props.AddBtn('#key1', records));
            }
        });

        grid1.on('rowDataBound', function (e, $row, id, record) {
            if (props.setRowStyleOnCondition) {
                let resColor = props.setRowStyleFunction(record);
                if (resColor) {
                    for (var key in resColor) {
                        $row.css(`${key}`, `${resColor[key]}`);
                    }
                }
            }
        });

        grid1.on('cellDataBound', function (e, $displayEl, id, column, record) {
            if (props.setColStyleOnCondition) {
                let resColor = props.setColStyleFunction(record);
                if (resColor) {
                    for (var key in resColor) {
                        $displayEl.css(`${key}`, `${resColor[key]}`);
                    }
                }
            }
        });
    };

    const downloadCSVWrapper = async () => {
        showToast('Collating Data... This may take a while.');

        const data = grid1.data().params;

        let downloadURL = `${props.getUrl}${props.getUrl.includes('?') ? '&' : '?'}fileType=excel`;

        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                const value = data[key];
                downloadURL += `&${key}=${value}`;
            }
        }

        await GetExcelFileFromEndpoint(downloadURL, props.downloadName);
    };

    return (
        <Grid container>
            <Grid container className="report-screen-gijgo" item xs={12} sm={12} style={{ margin: '10px auto', overflow: 'auto', position: 'relative' }}>
                {props.downloadName && (
                    <Grid item style={{ bottom: '3px', left: '16px', zIndex: 99, position: 'absolute' }}>
                        <Tooltip title="Download Excel" arrow>
                            <IconButton aria-label="delete" style={{ padding: '0px' }}>
                                <CloudDownloadIcon fontSize="large" onClick={downloadCSVWrapper} style={{ color: 'red' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}

                <table id={`${'key1'}`}></table>
            </Grid>
        </Grid>
    );
};

export default ReportGijgoGrid;
