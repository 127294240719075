import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import ReactDOM from 'react-dom';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import BreadCrumbs from '../../../../Core/Controls/Breadcrumb';
import ReportGijgoGrid from '../../ReportsScreens/GijgoForReportsScreen';
import { SquareButton } from '../../../../Core/FormInput/AppButton';
import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import DialogComp from '../../../../Core/Controls/Dialog/DialogComp';
import MarkedAsPartArrived from './MarkedAsPartArrived';
import MarkAsFitted from './MarkAsFitted';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import BuildIcon from '@material-ui/icons/Build';
import AppContext from '../../../../App/AppContext';

var $ = window.$;

let styleObj = {
    backgroundColor: '#183B68',
    borderRadius: '20%',
    width: '32px',
    height: '32px',
    textAlign: 'center',
    padding: '0px 0px 0px 0px'
};

const crumbs = (desc) => {
    let list = [
        { name: 'Home', path: '/' },
        { name: desc, active: true }
    ];
    return list;
};

const RenaultPartReqsList = (props) => {
    const { portalSettings } = useContext(AppContext);
    const [state, setState] = useState({ selectedRecords: [] });
    const history = useHistory();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let desc = decodeURIComponent(params.get('description') || '');
    let code = params.get('code') || '';

    const baseColumns = [
        {
            field: 'action',
            title: `Action`,
            width: '5%',
            filterable: false,
            sortable: false
        },
        {
            field: 'regNo',
            title: `Reg No`,
            filterable: true,
            sortable: true,
            width: '5%'
        },
        {
            field: 'wipNo',
            title: `Job No`,
            filterable: true,
            sortable: true,
            width: '5%'
        },
        {
            field: 'cust',
            title: `Customer`,
            filterable: true,
            sortable: true,
            width: '15%'
        },
        {
            field: 'mainPart',
            title: `Main Part`,
            filterable: true,
            sortable: true,
            width: '14%'
        },
        {
            field: 'partNo',
            title: `Part No`,
            filterable: true,
            sortable: true,
            width: '7%'
        },
        {
            field: 'partReqSupplier',
            title: `Supplier`,
            filterable: true,
            sortable: true
            // width: '5%'
        },
        {
            field: 'partReqETA',
            title: `ETA for parts ordered`,
            filterable: true,
            sortable: true,
            width: '10%'
        },
        {
            field: 'dc',
            title: `Date Requested`,
            filterable: true,
            sortable: true,
            width: '8%'
        },
        {
            field: 'workflow',
            title: `Workflow`,
            filterable: true,
            sortable: true,
            width: '10%'
        },
        {
            field: 'bn',
            title: `Branch`,
            filterable: true,
            sortable: true,
            width: '10%'
        }
        // {
        //     field: 'partReqsLabourHours',
        //     title: `Labour Hours`,
        //     filterable: true,
        //     sortable: true,
        //     width: '10%'
        // },
        // {
        //     field: 'partReqsLabourValue',
        //     title: `Labour Value`,
        //     filterable: true,
        //     sortable: true,
        //     width: '10%'
        // },
        // {
        //     field: 'partReqSurchargedValue',
        //     title: `Surcharge`,
        //     filterable: true,
        //     sortable: true,
        //     width: '10%'
        // },
        // {
        //     field: 'requestor',
        //     title: `Requestor`,
        //     filterable: true,
        //     sortable: true,
        //     width: '10%'
        // }
    ];
    const goToJobDetails = (value) => {
        history.push(`/PartReqSupplier/${value.partReqHeaderRecordID}?fromStep=false`);
    };

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="space-evenly">
                <SquareButton toolTipTitle="Get Part Details" style={styleObj} onClick={() => goToJobDetails(record)}>
                    <EditIcon fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const wipNoColRendere = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <div
                style={{ fontWeight: 'bold', color: 'blue', cursor: 'pointer' }}
                onClick={() => {
                    localStorage.setItem('currentWorkflowId', record.partWorkflowID);
                    history.push(`/Job/${record.partReqHeaderRecordID}/${record.partWorkflowID}?ProcessId=${record.partPid}`);
                }}
            >
                {value}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.field === 'action');
        if (actionCol) {
            actionCol.renderer = editButton({});
        }
        let wipNoCol = tempCols.find((element) => element.field === 'wipNo');
        if (wipNoCol) {
            wipNoCol.renderer = wipNoColRendere;
        }
        return tempCols;
    }, []);

    const getVal = async (gridRef, recList) => {
        var val = [];
        $(`${gridRef} tbody :checkbox:checked`).each(function (i) {
            val[i] = $(this).val();
        });

        let newList = val.map((m, i) => {
            let getValues = m - 1;
            return recList[getValues];
        });

        setState((st) => ({
            ...st,
            ShowPartAsAwaitingCollModal: +props.code == 9 ? true : false,
            showFittedModal: +props.code == 15 ? true : false,
            selectedRecords: newList
        }));
    };

    const mailButton = (gridRef, valueList) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="center" style={{ paddingLeft: '15px' }}>
                <SquareButton
                    style={{
                        backgroundColor: 'green',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => getVal(gridRef, valueList)}
                >
                    <abbr title={+props.code == 9 ? 'Set parts arrived' : 'Set parts as booked'}>
                        {+props.code == 9 ? (
                            <LocalShippingIcon fontSize="small" style={{ color: 'white' }} />
                        ) : (
                            <BuildIcon fontSize="small" style={{ color: 'white' }} />
                        )}
                    </abbr>
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const closeCommonModal = (res) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.ShowPartAsAwaitingCollModal = false;
            newSt.showFittedModal = false;
            newSt.selectedRecords = [];
            if (res) newSt.isReload = new Date();
            return newSt;
        });
    };

    const isMultipleSelection = +props.code === 9 || +props.code === 15;
    const commonProps = isMultipleSelection
        ? {
              selectionType: 'multiple',
              selectionMethod: 'checkbox',
              showCheckboxColFilterBtn: mailButton
          }
        : {};

    const RefreshGrid = () => {
        setState((st) => {
            let newSt = { ...st };
            newSt.isReload = new Date();
            return newSt;
        });
    };

    let [isRefresh, setIsrefresh] = useState(false);

    useEffect(() => {
        let RefreshTime = portalSettings.portalSettingGridRefreshTime * 1000;
        if (isRefresh) {
            setIsrefresh(true);
            return 0;
        }
        let interval = setInterval(RefreshGrid, RefreshTime);
        return () => clearInterval(interval);
    }, []);

    const baseUrl = `RenaultPartReqs/PartReqs_By_Status?StatusID=${props.code}&bn=${props.bn}`;

    return (
        <div>
            <div className="report-screen-container">
                {/* <BreadCrumbs crumbs={crumbs(desc)} /> */}
                <Grid item container spacing={1} xs={12}>
                    <Grid item xs={12}>
                        <ReportGijgoGrid
                            dataConverter={(res) => ({
                                records: res?.data?.list,
                                total: res?.data?.total
                            })}
                            columns={columns}
                            getUrl={baseUrl}
                            isReload={state.isReload}
                            {...commonProps}
                            // selectionType={(+props.code == 9 && 'multiple') || (+props.code == 15 && 'multiple')}
                            // selectionMethod={(+props.code == 9 && 'checkbox') || (+props.code == 15 && 'checkbox')}
                            // showCheckboxColFilterBtn={(+props.code == 9 && mailButton) || (+props.code == 15 && mailButton)}
                        />
                    </Grid>
                </Grid>
            </div>
            {state.ShowPartAsAwaitingCollModal ? (
                <DialogComp
                    // title={modalTitle}
                    title={`Mark parts as arrived`}
                    maxWidth="md"
                    onClose={() => closeCommonModal(false)}
                    fullWidth
                >
                    <MarkedAsPartArrived selectedRecords={state.selectedRecords} onClose={closeCommonModal} />
                </DialogComp>
            ) : null}
            {state.showFittedModal ? (
                <DialogComp
                    // title={modalTitle}
                    title={`Mark parts as Booked`}
                    maxWidth="md"
                    onClose={() => closeCommonModal(false)}
                    fullWidth
                >
                    <MarkAsFitted selectedRecords={state.selectedRecords} onClose={closeCommonModal} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default RenaultPartReqsList;
