import React, { useContext, useState, useEffect, useMemo, useRef } from 'react';
import AppContext from '../../../App/AppContext';
import { TextBox, SecondaryButton, DialogContents, DialogTitles, Dialogs, UploadWithBadge, SecondaryCheckbox, SelectBox } from '../../../Core/FormInput';
import '../../../Core/Modal/model.css';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { getPartReqs, getScreenDropdownPartReqID, requestPartReq } from '../../../Core/Service/partRequisitions-service';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import './TechnicianStyle.scss';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import { isInteger, some } from 'lodash';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import ConfirmationModal from '../../../Core/Controls/Dialog/ConfirmationModal';
import { withStyles } from '@material-ui/core/styles';
import PartReqPhotoModal from './PartReqPhotoUploadmodal';
import InfoIcon from '@material-ui/icons/Info';
import SearchIcon from '@material-ui/icons/Search';
import PartReqOtherInfo from './RequestorApproverIssuerInfo';
import { postPhotosByPartReqID, getPartListByPartNumber } from '../../../Core/Service/partRequisitions-service';
import {
    CircularProgress,
    FormHelperText,
    IconButton,
    InputAdornment,
    Paper,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import moment from 'moment';
import { NewUploadWithBadge } from '../../../Core/FormInput/AppButton';

const useStyles = makeStyles({
    table: {
        minWidth: 1150
    }
});

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'grey',
            color: 'white'
        },
        cursor: 'pointer'
    }
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        padding: 5
    },
    body: {
        padding: 5
        // background: 'white'
    }
}))(TableCell);

const MatchingPartList = ({ list, loading, handleRowSelect }) => {
    return (
        <Paper style={{ borderRadius: '5px', maxHeight: '350px', overflow: 'auto', width: '350px' }}>
            <TableContainer>
                <Table size="small" width="550px" stickyHeader>
                    <TableHead>
                        <TableRow className="header">
                            <StyledTableCell>Number</StyledTableCell>
                            <StyledTableCell>Description</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.map((j, i) => (
                            <StyledTableRow className={`row${j.isSelected ? ' is-selected-j' : ''}`} onClick={() => handleRowSelect(j)}>
                                <StyledTableCell>{j.partNumber}</StyledTableCell>
                                <StyledTableCell>{j.description}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                        {list?.length === 0 && (
                            <TableRow>
                                <TableCell colSpan="4">{loading ? 'Loading...' : 'No record found.'}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

const PartRequisitionsModal = (props) => {
    const { showToast, hideTopNavAndMenu, userRoles } = useContext(AppContext);
    const isTechnician = userRoles.includes('technician');
    const isInternal = userRoles.includes('internal');
    const isAuditor = userRoles.includes('auditor');
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    const [windowWidths, windowHeight] = useWindowSize();
    const classes = useStyles();
    const Qtyref = useRef(null);

    const technicianJobId = props.match.params?.technicianJobId;
    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    const [btnLoader, setBtnLoader] = useState({ btn1: false, btn2: false });
    const [width, height] = useWindowSize();

    const [state, setState] = useState({
        part: '',
        quantity: undefined,
        rows: [],
        partReq: [],
        isUpdate: false,
        partId: null,
        technicianJobID: technicianJobId,
        createdUserID: null,
        createdUserList: [],
        wipDetails: [],
        partRequstorSignature: null,
        errors: {},
        imgPreview: false,
        signature: null,
        checkIssued: null,
        showImage: false,
        isHide: false,
        modalIndex: null,
        partIndex: null,
        isFocusFlag: null,
        partReqStatus: [],
        tempPartReqStatus: []
    });
    const Id = props.match.params.jobId;
    useEffect(() => {
        if (Qtyref.current && state.isFocusFlag) {
            setTimeout(() => {
                Qtyref.current?.focus();
            }, 1000);
        }
    }, [state.isFocusFlag]);

    const GlobalCss = withStyles({
        '@global': {
            '.MuiOutlinedInput-inputMarginDense': {
                paddingTop: '4.1px',
                paddingBottom: '4.1px'
            },
            '.MuiOutlinedInput-adornedEnd ': {
                paddingRight: '0px'
            }
        }
    })(() => null);

    const setImgPreview = (val, data = null, date) => {
        setState((st) => ({ ...st, imgPreview: val, signature: data, sigDate: date }));
    };

    const changeQantity = (id, des, name) => (e) => {
        let changedArray = [...state.rows];
        let objIndex = state.rows.findIndex((x) => x.id === id);
        changedArray[objIndex][name] = e.target.value;
        setState((st) => ({
            ...st,
            rows: changedArray
        }));
    };
    const onChangeCheckbox = (id, des, name) => (e) => {
        let changedArray = [...state.rows];
        let objIndex = changedArray.findIndex((x) => x.id === id);

        changedArray[objIndex][name] = e.target.checked;

        setState((st) => ({
            ...st,
            rows: changedArray
        }));
    };

    const onImagemodalClose = (res) => {
        let tempRows = [...state.rows];
        let Index = tempRows.findIndex((k) => k.id == state.partId);
        tempRows[Index]['isImageErrorMessage'] = null;

        setState((st) => ({ ...st, showImage: false, partId: null, desc: '', rows: tempRows }));
        makeEmptyPhotos('oldPhoto');
        res && pullWorkflowAndUpdateState(state.createdUserID);
    };
    const [data, setData] = useState({ showInfo: false });
    const setShowInfo = (val) => {
        if (val.requestor) {
            setData((st) => ({
                ...st,
                showInfo: true,
                requestor: val.requestor,
                requestorSign: val.requestorSignature,
                requestorDate: val.requestedTime,
                issuer: val.issuer,
                issuerSign: val.issuerSignature,
                issuerDate: val.issuedTime,
                approver: val.approver,
                approverSign: val.approverSignature,
                approverDate: val.approvedTime
            }));
        }
    };

    const TechBtnClick = (val) => {
        setState((st) => ({
            ...st,
            technicianJobID: val.technicianJobID,
            createdUserID: val.createdUserID
            // isHide: val.isHide == 'Y'
        }));
        pullWorkflowAndUpdateState(val.createdUserID);
    };
    const getScreenDropdown = async () => {
        let result = await getScreenDropdownPartReqID(Id, 'R');
        let Res = [...result?.data?.partReqStatus];
        setState((st) => {
            const nst = { ...st };
            nst.tempPartReqStatus = Res;
            nst.wipDetails = result?.data?.wipDetails[0];
            nst.createdUserList = result?.data?.technician;
            if (st.createdUserID == null) {
                // nst.createdUserID = result?.data?.technician[0]?.createdUserID;
                nst.technicianJobID = result?.data?.technician[0]?.technicianJobID;
                nst.createdUserID = result?.data?.technician[0]?.createdUserID;
                nst.isHide = result?.data?.wipDetails[0]?.allowEdit == 'N';
            }
            return nst;
        });
    };

    useEffect(() => {
        if (state.tempPartReqStatus.length > 0 || state.rows.length > 0) {
            let partIdArray = new Set(state.rows.map((obj) => obj.partReqStatus));
            let newRes = state.tempPartReqStatus.reduce((acc, status) => {
                if (partIdArray.has(status.partReqStatusID)) {
                    if (isInternal && !status.visibleToInternal) {
                        acc.push({ ...status, visibleToInternal: true });
                    }
                    if (isTechnician && !status.visibleToTech) {
                        acc.push({ ...status, visibleToTech: true });
                    }
                }
                acc.push(status);
                return acc;
            }, []);

            const FilterList = newRes.filter((status) => {
                return (isInternal && status.visibleToInternal) || (isTechnician && status.visibleToTech);
            });
            const uniqueData = Array.from(new Set(FilterList.map((status) => status.partReqStatusID))).map((id) =>
                FilterList.find((status) => status.partReqStatusID === id)
            );

            setState((st) => {
                const nst = { ...st };
                nst.partReqStatus = uniqueData.map((n) => ({
                    ...n,
                    id: n.partReqStatusID,
                    name: n.partReqStatusDescription,
                    isImageReq: n.photoRequired
                }));
                return nst;
            });
        }
    }, [state.tempPartReqStatus, state.rows]);

    useEffect(async () => {
        setState((st) => ({
            ...st,
            technicianJobID: technicianJobId
        }));
    }, [technicianJobId]);

    useEffect(() => {
        getScreenDropdown();
        pullWorkflowAndUpdateState();
    }, []);

    const pullWorkflowAndUpdateState = async (createdUserID) => {
        let result = await getPartReqs(
            Id,
            // createdUserID,
            'R',
            state.technicianJobID
        );
        const Result = result.data?.partReqs?.map((row) => ({
            id: row.partReqID,
            partReqPartDescription: row.partReqPartDescription,
            partReqQuantity: row.partReqQuantity,
            isDeleted: false,
            disabled: row.issuerSignature && row.approverSignature,
            isApproved: row.isApproved,
            qnIssued: row.partReqIssuedQuantity,
            requestor: row.requestor,
            approver: row.approver,
            partReqStatusDescription: row.partReqStatusDescription,
            issuer: row.issuer,
            issueStatus: row.issueStatus,
            requestorSignature: row.requestorSignature,
            issuerSignature: row.issuerSignature,
            approverSignature: row.approverSignature,
            partReqId: row.partReqID,
            requestedTime: row.requestedTime,
            issuedTime: row.issuedTime,
            approvedTime: row.approvedTime,
            photosUploaded: row.photosUploaded,
            isFitted: row.isFitted,
            isReturnToPartsDepo: row.isReturnToPartsDepo || false,
            partReturnStatusID: row.partReturnStatusID,
            partReqPartNumber: row.partReqPartNumber,
            Photos: [],
            oldPhoto: [],
            partReqStatus: +row.partReqStatus || null
        }));
        if (result.success) {
            setState((st) => {
                let newSt = { ...st };

                newSt.rows =
                    Result.length > 0
                        ? Result
                        : [
                              {
                                  partReqQuantity: '',
                                  partReqPartDescription: '',
                                  isDeleted: false,
                                  partReqStatus: 1,
                                  // partReqId: null,
                                  isFitted: '',
                                  partReqPartNumber: '',
                                  photosUploaded: 0
                              }
                          ];

                newSt.checkIssued = some(result.data.partReqs, (r) => r.qnIssued);

                return newSt;
            });
        }
        if (!result.success) {
            showToast(result.message);
        }
    };

    const validations = (mode) => {
        const { partRequstorSignature } = state;
        let formIsValid = true;
        let errors = {};
        let tempRows = [...state.rows];
        let tempErrors = [];

        let isimageExist = state.rows.findIndex((k) => k.isImageErrorMessage);
        state.rows.forEach((list, index) => {
            if (!list.partReqQuantity && list.partReqStatus !== 1) {
                tempErrors.push({ index: index });
                tempRows[index].ErrorpartReqQuantity = 'this Field is Required';
            } else {
                tempRows[index].ErrorpartReqQuantity = '';
            }
            if (!list.partReqPartDescription && list.partReqStatus !== 1) {
                tempErrors.push({ index: index });
                tempRows[index].ErrorDesc = 'this Field is Required';
            } else {
                tempRows[index].ErrorDesc = '';
            }
            if (!list.partReqPartNumber && list.partReqStatus !== 1) {
                tempErrors.push({ index: index });
                tempRows[index].ErrorPartNo = 'this Field is Required';
            } else {
                tempRows[index].ErrorPartNo = '';
            }
        });

        setState((st) => ({ ...st, rows: tempRows }));

        console.log(tempRows, 'tempRows');
        console.log(tempErrors, 'tempErrors');

        if (!partRequstorSignature) {
            errors.partRequstorSignature = 'Signature is required';
            formIsValid = false;
        }

        if (isimageExist > -1) {
            formIsValid = false;
        }
        if (tempErrors.length > 0) {
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handelAddPartToServer = async (mode) => {
        if (isTechnician) {
            if (state.rows?.length > 0) {
                addPart(mode);
            } else {
                showToast('Please add least one part');
            }
        } else {
            addPart(mode);
        }
    };
    const addPart = async (mode) => {
        if (validations()) {
            mode == 'save' ? setBtnLoader((st) => ({ ...st, btn2: true })) : setBtnLoader((st) => ({ ...st, btn1: true }));
            const data = {
                partRequstorSignature: state.partRequstorSignature,
                partReqsAddVM: state.rows.map((c) => ({
                    partReqQuantity: c.partReqQuantity,
                    partReqPartDescription: c.partReqPartDescription,
                    isDeleted: c.isDeleted,
                    partReqId: c.partReqId,
                    isFitted: c.isFitted,
                    partReqPartNumber: c.partReqPartNumber,
                    isReturnToPartsDepo: c.isReturnToPartsDepo || false,
                    partReqStatus: c.partReqStatus || null
                })),
                mode: mode
            };

            let res1 = await postPhotosByPartReqID(state.rows);

            let res = await requestPartReq(Id, data, state.technicianJobID);

            if (res.success) {
                showToast(res?.message);
                if (fromStep) {
                    setTimeout(() => {
                        window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
                    }, 1000);
                }
            }
            if (res.errors) {
                for (const [key, value] of Object.entries(res?.errors)) {
                    showToast(`${key}: ${value}`);
                }
            } else {
                showToast(res?.message);
            }

            mode == 'save' ? setBtnLoader((st) => ({ ...st, btn2: false })) : setBtnLoader((st) => ({ ...st, btn1: false }));
        }
    };

    const showConfirmationModal = () => {
        if (validations()) {
            setState((st) => ({ ...st, showConfirmModal: true }));
        }
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return { ...st, [name]: value };
        });
    };

    const signChange = (e) => {
        setState((st) => {
            return { ...st, ...e };
        });
    };

    const clearSig = (val) => {
        setState((st) => ({
            ...st,
            partRequstorSignature: null
        }));
    };

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No parts available</div>
            </GridOverlay>
        );
    }

    const gridheight = `calc(100vh - ${isTechnician ? '348px' : '274px'})`;

    const inputFile = useRef(null);

    const upload = async (e) => {
        const files = Array.from(e.target.files);

        files.map((file) => {
            const reader = new FileReader();
            reader.readAsText(file);

            reader.onload = () => {
                const res = reader.result.split('\n');
                res.splice(0, 1);

                res.map((row) => {
                    const parts = row.split('|');

                    const partDesc = parts[0] + ' - ' + parts[1] + ' - ' + parts[5];
                    const quantity = parts[2];

                    if (row == '' || quantity == '') return;

                    let newPartToAdd = {
                        partReqPartDescription: partDesc,
                        partReqQuantity: quantity % 1 == 0 ? Math.trunc(quantity) : quantity,
                        qnIssued: '-'
                    };

                    setState((st) => {
                        newPartToAdd.id = st.rows.length === 0 ? 1 : Math.max(...st.rows.map((c) => c.id)) + 1;
                        return {
                            ...st,
                            rows: [...st.rows, newPartToAdd],
                            part: '',
                            quantity: '',
                            isUpdate: false,
                            partId: undefined
                        };
                    });
                });
            };

            reader.onerror = () => {
                console.log('Error reading .xfr File');
            };
        });
    };

    const makeEmptyPhotos = (name) => {
        let changedArray = [...state.rows];
        let objIndex = state.rows.findIndex((x) => x.partReqId === state.partId);
        changedArray[objIndex][name] = [];
        setState((st) => ({ ...st, rows: changedArray }));
    };
    const setPhotos = (name) => {
        setState((st) => ({ ...st, name }));
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorE2, setAnchorE2] = React.useState(null);

    const handleRowSelect = (params) => {
        if (state.partIndex > -1) {
            setState((st) => {
                let newSt = { ...st };
                let changedArray = [...st.rows];
                changedArray[state.partIndex].partReqPartDescription = params.description;
                changedArray[state.partIndex].partReqPartNumber = params.partNumber;
                newSt.rows = [...changedArray];
                return newSt;
            });
        }

        setState((st) => {
            const nst = {
                ...st,
                loading: false,
                matchingPartNumber: []
                // partIndex: -1
            };
            return nst;
        });

        setAnchorEl(null);
        setAnchorE2(null);
    };
    const pullDataByPartNumber = async (e, type, value, index) => {
        let res;
        if (type == 'P') {
            setAnchorEl(e.currentTarget);
            res = await getPartListByPartNumber(value, null, state?.wipDetails?.branchID);
            if (res.success) {
                setState((st) => ({ ...st, matchingPartNumber: res.data.partDetails, loading: false, partIndex: index, isFocusFlag: new Date() }));
            }
        } else {
            setAnchorE2(e.currentTarget);
            res = await getPartListByPartNumber(null, value, state?.wipDetails?.branchID);
            if (res.success) {
                setState((st) => ({ ...st, matchingPartNumber: res.data.partDetails, loading: false, partIndex: index, isFocusFlag: new Date() }));
            }
        }
    };
    const handlePoperClose = () => {
        setAnchorEl(null);
        setAnchorE2(null);
    };

    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorE2);
    const id = open ? 'simple-popover' : undefined;
    const id2 = open2 ? 'simple-popover2' : undefined;

    console.log(state.rows, 'rowsssss');

    let AddRow = () => {
        let tempRows = [...state.rows];
        tempRows.push({
            partReqQuantity: '',
            partReqPartDescription: '',
            isDeleted: false,
            partReqStatus: 1,
            isFitted: '',
            partReqPartNumber: ''
        });
        setState((st) => ({ ...st, rows: tempRows }));
    };

    const removeRow = (index) => {
        let tempRows = [...state.rows];
        tempRows[index].isDeleted = true;
        setState((st) => ({ ...st, rows: tempRows }));
    };

    const handleInputSupplierCheck = (index) => (e) => {
        const { name, checked } = e.target;
        let tempRows = [...state.rows];
        if (!checked && name == 'partReqSurcharged') {
            tempRows[index]['partReqSurchargedValue'] = 0;
        }
        tempRows[index][name] = checked;
        setState((st) => ({ ...st, rows: tempRows }));
    };

    const handleInputPart = (index) => (e) => {
        const { name, value } = e.target;

        let tempRows = [...state.rows];
        if (name === 'partReqStatus') {
            let isPhotoREQ = state.partReqStatus.find((k) => value === k.id)?.isImageReq;
            if (isPhotoREQ) {
                tempRows[index]['isImageErrorMessage'] = 'Image is Required';
            } else {
                tempRows[index]['isImageErrorMessage'] = '';
            }
        }
        tempRows[index][name] = value;
        setState((st) => ({ ...st, rows: tempRows }));
    };
    return (
        <div style={{ maxWidth: '100%' }}>
            <GlobalCss />
            {!fromStep && (
                <Breadcrumbs separator=">>" aria-label="breadcrumb">
                    <Link color="inherit" to={'/'}>
                        Technician App
                    </Link>
                    <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                        Part Requisitions
                    </Typography>
                </Breadcrumbs>
            )}

            <DialogContents>
                <Grid container spacing={1} justifyContent="space-between">
                    {data.showInfo && <PartReqOtherInfo data={data} onClose={() => setData((st) => ({ ...st, showInfo: false }))} />}
                    {state.showImage && (
                        <DialogComp title="Image Upload" onClose={() => onImagemodalClose(false)} fullWidth maxWidth="lg">
                            <PartReqPhotoModal
                                partId={state.partId}
                                onFormSubmit={() => pullWorkflowAndUpdateState()}
                                desc={state.desc}
                                onClose={onImagemodalClose}
                                onImageChange={setPhotos}
                                rows={state.rows}
                            />
                        </DialogComp>
                    )}
                    {state.imgPreview ? (
                        <DialogComp title="Signature" onClose={() => setImgPreview(false)} fullWidth maxWidth="md">
                            <div style={{ position: 'relative' }}>
                                <div style={{ position: 'absolute', bottom: 10, right: 0 }}>{state.sigDate}</div>
                                <img src={state.signature} alt={'stamp'} width="500" height="300" />
                            </div>
                        </DialogComp>
                    ) : null}
                    <Grid item xs={12} sm={8} style={{ fontSize: 21, textAlign: 'left' }}>
                        Registration Number:&nbsp; <b> {state.wipDetails?.registration}</b> &nbsp; WIP No:&nbsp; <b> {state.wipDetails?.wipNumber}</b>
                    </Grid>
                    {!state.isHide ? (
                        <>
                            <Grid item xs={6} sm={4} md={2}>
                                {btnLoader.btn2 ? (
                                    <SecondaryButton fullWidth>
                                        <CircularProgress size={25} />
                                    </SecondaryButton>
                                ) : (
                                    <SecondaryButton isBtnLoader={btnLoader.btn2} onClick={() => handelAddPartToServer('save')} className="setFullWidth">
                                        Save
                                    </SecondaryButton>
                                )}
                            </Grid>
                        </>
                    ) : null}

                    <Grid item xs={12} style={{ minHeight: 450 }}>
                        <TableContainer style={{ maxHeight: 450 }}>
                            <Table className={classes.table} aria-label="simple table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left " width="35%">
                                            Part Description
                                            <IconButton size="small" onClick={AddRow} disabled={state.isHide}>
                                                <AddCircleIcon style={{ color: !state.isHide ? 'green' : 'grey' }} />
                                            </IconButton>
                                        </StyledTableCell>
                                        <StyledTableCell align="left" width="20%">
                                            Part Number{' '}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" width="10%">
                                            Quantity
                                        </StyledTableCell>
                                        <StyledTableCell align="left" width="10%">
                                            Status
                                        </StyledTableCell>
                                        {/* {row.partReqID && ( */}
                                        <StyledTableCell align="left" width="10%">
                                            Part Returned?
                                        </StyledTableCell>
                                        {/* )} */}
                                        <StyledTableCell align="left" width="10%">
                                            Issue Status
                                        </StyledTableCell>
                                        <StyledTableCell align="right" width="10%"></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {state.rows.map((row, index) => {
                                        if (!row.isDeleted) {
                                            return (
                                                <TableRow key={index}>
                                                    <StyledTableCell>
                                                        <TextBox
                                                            placeholder="Description"
                                                            name="partReqPartDescription"
                                                            value={row.partReqPartDescription}
                                                            onChange={handleInputPart(index)}
                                                            disabled={state.isHide}
                                                            error={row.ErrorDesc}
                                                            autoFocus={true}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Tooltip title={'Fetch Part Details By Description'} arrow>
                                                                            <Grid>
                                                                                <IconButton
                                                                                    tabIndex={-1}
                                                                                    style={{ padding: '0px' }}
                                                                                    onClick={(e) =>
                                                                                        pullDataByPartNumber(e, 'D', row.partReqPartDescription, index)
                                                                                    }
                                                                                    size="small"
                                                                                    disabled={state.isHide}
                                                                                >
                                                                                    <SearchIcon />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </Tooltip>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                        <Popover
                                                            open={open2}
                                                            id="simple-popover2"
                                                            anchorEl={anchorE2}
                                                            onClose={handlePoperClose}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'center'
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'center'
                                                            }}
                                                            style={{ left: '-380px' }}
                                                        >
                                                            <MatchingPartList
                                                                list={state.matchingPartNumber || []}
                                                                loading={state.loading}
                                                                handleRowSelect={handleRowSelect}
                                                            />
                                                        </Popover>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <TextBox
                                                            placeholder="Part Number"
                                                            name="partReqPartNumber"
                                                            value={row.partReqPartNumber}
                                                            onChange={handleInputPart(index)}
                                                            disabled={state.isHide}
                                                            error={row.ErrorPartNo}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <Tooltip title={'Fetch Part Details By Description'} arrow>
                                                                            <Grid>
                                                                                <IconButton
                                                                                    tabIndex={-1}
                                                                                    style={{ padding: '0px' }}
                                                                                    onClick={(e) => pullDataByPartNumber(e, 'P', row.partReqPartNumber, index)}
                                                                                    size="small"
                                                                                    disabled={state.isHide}
                                                                                >
                                                                                    <SearchIcon />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </Tooltip>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                        />
                                                        <Popover
                                                            open={open}
                                                            id="simple-popover"
                                                            anchorEl={anchorEl}
                                                            onClose={handlePoperClose}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'center'
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'center'
                                                            }}
                                                            style={{ left: '-50px' }}
                                                        >
                                                            <MatchingPartList
                                                                list={state.matchingPartNumber || []}
                                                                loading={state.loading}
                                                                handleRowSelect={handleRowSelect}
                                                            />
                                                        </Popover>
                                                    </StyledTableCell>

                                                    <StyledTableCell>
                                                        <TextBox
                                                            placeholder="Quantity"
                                                            name="partReqQuantity"
                                                            value={row.partReqQuantity}
                                                            onChange={handleInputPart(index)}
                                                            disabled={state.isHide}
                                                            error={row.ErrorpartReqQuantity}
                                                            inputRef={state.partIndex == index ? Qtyref : null}
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <SelectBox
                                                            List={state.partReqStatus}
                                                            name="partReqStatus"
                                                            value={row.partReqStatus}
                                                            disabled={state.isHide}
                                                            onChange={handleInputPart(index)}
                                                        />
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {row.id && row.partReturnStatusID >= 1 && (
                                                            // {true && (
                                                            <SecondaryCheckbox
                                                                onChange={handleInputSupplierCheck(index)}
                                                                name="isReturnToPartsDepo"
                                                                checked={row.isReturnToPartsDepo}
                                                                disabled={state.isHide || row.partReturnStatusID >= 2}
                                                            />
                                                        )}
                                                    </StyledTableCell>
                                                    <StyledTableCell>{row.issueStatus}</StyledTableCell>
                                                    <StyledTableCell>
                                                        <Grid container spacing={1} alignContent="center" justifyContent="flex-end">
                                                            {row.id ? (
                                                                <>
                                                                    <Grid item xs={4}>
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={() =>
                                                                                row.photosUploaded >= 0 &&
                                                                                setState((st) => ({
                                                                                    ...st,
                                                                                    partId: row.id,
                                                                                    desc: row.partReqPartDescription,
                                                                                    showImage: true
                                                                                }))
                                                                            }
                                                                            // disabled={state.isHide}
                                                                        >
                                                                            <NewUploadWithBadge
                                                                                state={row.photosUploaded > 0 || 0}
                                                                                count={row.photosUploaded || 0}
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    color: 'black'
                                                                                }}
                                                                            />
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <IconButton size="small" onClick={() => setShowInfo(row)}>
                                                                            <InfoIcon
                                                                                disabled={state.isHide}
                                                                                style={{ cursor: 'pointer', color: 'black' }}
                                                                                className="doneIcon"
                                                                            />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </>
                                                            ) : (
                                                                <Grid item xs={4}>
                                                                    <IconButton size="small" onClick={() => removeRow(index)} disabled={state.isHide}>
                                                                        <RemoveCircleIcon style={{ color: state.isHide ? 'grey' : 'red' }} />
                                                                    </IconButton>
                                                                </Grid>
                                                            )}

                                                            {row.id && (
                                                                <FormHelperText error shrink>
                                                                    {row.isImageErrorMessage}
                                                                </FormHelperText>
                                                            )}
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell></StyledTableCell>
                                                </TableRow>
                                            );
                                        }
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                {!isAuditor && (
                    <Grid item xs={12} sm={6} style={{ marginTop: 8 }}>
                        <CustomeSignPad
                            style={{ width: '100%' }}
                            onChange={signChange}
                            clearSig={clearSig}
                            content={'Requestor Signature'}
                            sign={state.technicianSignature}
                            name="partRequstorSignature"
                        />
                        <span className="mandatoryfields">{state.errors['partRequstorSignature']}</span>
                    </Grid>
                )}
            </DialogContents>
            {state.showConfirmModal ? (
                <DialogComp title="Part Requisitions" onClose={() => setState((st) => ({ ...st, showConfirmModal: false }))} maxWidth="sm">
                    <ConfirmationModal
                        disabled={state.isHide}
                        message={`By submitting you will not be able to make any further changes to the form. If you need to make further changes please click cancel and select the save option.`}
                        handleSubmit={() => handelAddPartToServer('submit')}
                        handleCancel={() => setState((st) => ({ ...st, showConfirmModal: false }))}
                    />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default PartRequisitionsModal;
