import React, { useContext, useEffect, useRef, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { SecondaryButton, SecondaryCheckbox, SelectBox, TextBox } from '../../../../Core/FormInput';
import { Badge, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, Link, Popover } from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AppContext from '../../../../App/AppContext';
import AddIcon from '@material-ui/icons/Add';
import { CustomIconButton } from '../../../../Core/FormInput/AppButton';
import SearchIcon from '@material-ui/icons/Search';
import { getPartListByPartNumber } from '../../../../Core/Service/partRequisitions-service';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Container } from '@material-ui/core/Container';
import DialogComp from '../../../../Core/Modal/dialogModal';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import PartReqSupplierPhotoModal from '../../../internal/part_req_supplier/ImageUploader';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { getPartReqRenaultStatus } from '../../../../Core/Service/PartReq_services/partReq-service';
import LinkIcon from '@material-ui/icons/Link';

const ordinalNumbers = [
    '1st',
    '2nd',
    '3rd',
    '4th',
    '5th',
    '6th',
    '7th',
    '8th',
    '9th',
    '10th',
    '11th',
    '12th',
    '13th',
    '14th',
    '15th',
    '16th',
    '17th',
    '18th',
    '19th',
    '20th'
];

const StyledTableCell = withStyles((theme) => ({
    head: {
        padding: 5
        // backgroundColor: \,
        // color: theme.palette.common.white
    },
    body: {
        fontSize: 14,
        padding: '3px 5px'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover
        // }
    }
}))(TableRow);
const StyledTableLinkRows = withStyles((theme) => ({
    root: {
        // '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
        // }
    }
}))(TableRow);
const StyledSubTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: 'skyblue'
        }
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: '1000px'
    },
    input: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    }
});

// const StyledBadge = withStyles((theme) => ({
//     badge: {
//         left: -3,
//         top: 10,
//         border: `2px solid ${theme.palette.background.paper}`,
//         padding: '0 0px',
//         fontSize: '7px'
//     }
// }))(Badge);

const SimpleCell = withStyles((theme) => ({
    head: {
        fontSize: 15,
        fontWeight: 600,
        backgroundColor: '#bdbdbd82',
        border: '1px solid black'
    },
    body: {
        fontSize: 12,
        border: '1px solid black'
    }
}))(TableCell);

const SubCell = withStyles((theme) => ({
    head: {
        fontSize: 10,
        fontWeight: 600,
        padding: '0px 10px',
        backgroundColor: '#bdbdbd82'
    },
    body: {
        padding: '5px 10px',
        fontSize: 12,
        fontWeight: 600
    }
}))(TableCell);

const organizeByParent = (input) => {
    const parentMap = {};

    input.forEach((item) => {
        if (item.parentLineID === null) {
            // Initialize parent with an empty children array
            parentMap[item.lineID] = { ...item, children: [] };
        }
    });

    input.forEach((item) => {
        if (item.parentLineID !== null) {
            if (parentMap[item.parentLineID]) {
                // Add child to the corresponding parent's children array
                parentMap[item.parentLineID].children.push(item);
            } else {
                // Handle orphaned children, if needed
                console.error(`Parent with lineID ${item.parentLineID} not found for child with lineID ${item.lineID}`);
            }
        }
    });
    // console.log(parentMap, 'parentMap');
    // Convert the parent map to an array
    return Object.values(parentMap);
};

const MatchingPartList = ({ list, loading, handleRowSelect }) => {
    return (
        <Paper style={{ borderRadius: '5px', maxHeight: '350px', overflow: 'auto', width: '350px' }}>
            <TableContainer>
                <Table size="small" width="550px" stickyHeader>
                    <TableHead>
                        <TableRow className="header">
                            <SimpleCell>Part No</SimpleCell>
                            <SimpleCell>Description</SimpleCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.map((j, i) => (
                            <StyledTableRow className={`row${j.isSelected ? ' is-selected-j' : ''}`} onClick={() => handleRowSelect(j)}>
                                <SimpleCell>{j.partNumber}</SimpleCell>
                                <SimpleCell>{j.description}</SimpleCell>
                            </StyledTableRow>
                        ))}
                        {list?.length === 0 && (
                            <TableRow>
                                <TableCell colSpan="4">{loading ? 'Loading...' : 'No record found.'}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

const ChildAssociatedRowValue = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorE2, setAnchorE2] = React.useState(null);

    const handleClosePOP = () => {
        setAnchorEl(null);
        setAnchorE2(null);
    };

    const QtyNewRef = useRef(null);

    const [state, setState] = useState({
        partNumber: '',
        partDescription: '',
        partQty: '',
        IsNewFlag: null,
        isFitted: false,
        isReturnToPartsDepo: false,
        partReqStatus: ''
    });

    useEffect(() => {
        if (QtyNewRef.current && state.IsNewFlag) {
            setTimeout(() => {
                QtyNewRef.current?.focus();
            }, 1000);
        }
    }, [state.IsNewFlag]);

    const [isUpdate, setIsUpdate] = useState(false);

    useEffect(() => {
        if (props.selectedAssociatedSubData.lineID) {
            setIsUpdate(true);
            setState((st) => {
                const nst = {
                    ...st,
                    partNumber: props.selectedAssociatedSubData.partNumber,
                    partDescription: props.selectedAssociatedSubData.partDescription,
                    partQty: props.selectedAssociatedSubData.partQty,
                    isFitted: props.selectedAssociatedSubData.isFitted,
                    isReturnToPartsDepo: props.selectedAssociatedSubData.isReturnToPartsDepo,
                    partReqStatus: props.selectedAssociatedSubData.partReqStatus
                };
                return nst;
            });
        }
    }, [props.selectedAssociatedSubData.lineID]);

    const handleRowSelect = (params) => {
        setState((st) => {
            const nst = {
                ...st,
                partNumber: params.partNumber,
                partDescription: params.description
            };
            return nst;
        });
        handleClosePOP();
    };

    const handleInputOnChange = (e) => {
        const { name, value, checked } = e.target;
        setState((st) => {
            let newSt = { ...st };
            if (name == 'isFitted' || name == 'isReturnToPartsDepo') {
                newSt[name] = checked;
            } else if (name === 'partReqStatus') {
                let isPhotoREQ = props.screenDropdown.partReqStatus.find((k) => value === k.id)?.photoRequired;
                console.log(value, 'value');
                console.log(isPhotoREQ, 'isPhotoREQ');
                if (isPhotoREQ) {
                    newSt['isImageErrorMessage'] = 'Image is Required';
                }

                newSt[name] = value;
            } else {
                newSt[name] = value;
            }
            return newSt;
        });
    };

    const onCloseWrapper = () => {
        props.handleChildAssocate(isUpdate, {
            ...props.selectedAssociatedSubData,
            partNumber: state.partNumber,
            partDescription: state.partDescription,
            partQty: state.partQty,
            isFitted: state.isFitted,
            isReturnToPartsDepo: state.isReturnToPartsDepo,
            partReqStatus: state.partReqStatus,
            isImageErrorMessage: state.isImageErrorMessage
        });
    };

    const pullDataByPartNumber = (val, type, selectedPartId) => async (event) => {
        let res;
        if (type == 'part') {
            setAnchorEl(event.currentTarget);
            res = await getPartListByPartNumber(val, null);
            if (res.success) {
                setState((st) => ({
                    ...st,
                    matchingPartNumber: res.data.partDetails,
                    loading: false,
                    selectedPartId: selectedPartId,
                    IsNewFlag: new Date()
                }));
            }
        } else {
            setAnchorE2(event.currentTarget);
            res = await getPartListByPartNumber(null, val);
            if (res.success) {
                setState((st) => ({
                    ...st,
                    matchingPartNumber: res.data.partDetails,
                    loading: false,
                    selectedPartId: selectedPartId,
                    IsNewFlag: new Date()
                }));
            }
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const open2 = Boolean(anchorE2);
    const id2 = open ? 'simple-popover2' : undefined;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={7} md={6}>
                <InputLabel shrink>Description</InputLabel>
                <TextBox
                    name="partDescription"
                    onChange={handleInputOnChange}
                    value={state.partDescription}
                    autoFocus={true}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <CustomIconButton
                                    tabIndex={-1}
                                    // toolTipTitle="Get Vehicle Details"
                                    onClick={pullDataByPartNumber(state.partDescription, 'desc')}
                                    // disabled={isReadOnly}
                                >
                                    <SearchIcon />
                                </CustomIconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <Popover
                    id={id2}
                    open={open2}
                    anchorEl={anchorE2}
                    onClose={handleClosePOP}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <MatchingPartList list={state.matchingPartNumber || []} loading={state.loading} handleRowSelect={handleRowSelect} />
                </Popover>
            </Grid>
            <Grid item xs={12} sm={3} md={4}>
                <InputLabel shrink>Part No.</InputLabel>
                {/* <TextBox name="partNumber" onChange={handleInputOnChange} value={state.partNumber} /> */}
                <TextBox
                    name="partNumber"
                    value={state.partNumber}
                    onChange={handleInputOnChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <CustomIconButton tabIndex={-1} onClick={pullDataByPartNumber(state.partNumber, 'part')}>
                                    <SearchIcon />
                                </CustomIconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClosePOP}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <MatchingPartList list={state.matchingPartNumber || []} loading={state.loading} handleRowSelect={handleRowSelect} />
                </Popover>
            </Grid>

            <Grid item xs={12} sm={2} md={2}>
                <InputLabel shrink>Qty</InputLabel>
                <TextBox name="partQty" type="number" onChange={handleInputOnChange} value={state.partQty} inputRef={QtyNewRef} />
            </Grid>
            <Grid item xs={12} sm={3} md={6} lg={4}>
                <InputLabel shrink>Status</InputLabel>
                <SelectBox List={props.screenDropdown.partReqStatus || []} name="partReqStatus" onChange={handleInputOnChange} value={state.partReqStatus} />
            </Grid>
            {/* <Grid item xs={12} sm={3} md={4}>
                <FormControlLabel control={<SecondaryCheckbox name="isFitted" checked={state.isFitted} onChange={handleInputOnChange} />} label="isFitted?" />
            </Grid>
            {props.selectedAssociatedSubData?.partReqID && props.selectedAssociatedSubData?.partReturnStatusID == 1 && (
                <Grid item xs={12} sm={7} md={6}>
                    <FormControlLabel
                        control={<SecondaryCheckbox name="isReturnToPartsDepo" checked={state.isReturnToPartsDepo} onChange={handleInputOnChange} />}
                        label="Part Returned?"
                    />
                </Grid>
            )} */}
            <Grid item xs={12}>
                <SecondaryButton fullWidth size={'small'} style={{ height: 25 }} onClick={onCloseWrapper} disabled={!state.partNumber}>
                    {isUpdate ? 'Update' : 'Add'}
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default function AssociatedMaterial({
    list,
    handleAssociatedRow,
    removeAssociatedRow,
    handleAssociateInput,
    onClose,
    isReadOnly,
    setInputList,
    modalIndex,
    submitChildAssocate,
    screenDropdown,
    userlinkList,
    handleLinkRows,
    selectedWriteUp
}) {
    const { showToast, showModal, userRoles } = useContext(AppContext);
    const classes = useStyles();
    const isInternal = userRoles.includes('internal');
    const isTechnician = userRoles.includes('technician');

    // const [screenDropdown, setScreenDropdown] = useState({
    //     partReqStatus: []
    // });

    const Qtyref = useRef(null);

    const [state, setState] = useState({
        matchingPartNumber: [],
        loading: true,
        showAddParts: false,
        sortedList: [],
        showImage: false,
        partId: null,
        desc: '',
        showLinkTable: false,
        sortedPartToLinkList: []
    });

    useEffect(() => {
        if (list.length > 0) {
            const organizedArray = organizeByParent([...list]);

            setState((st) => {
                const nst = {
                    ...st,
                    sortedList: organizedArray
                };
                return nst;
            });
        }
    }, [list]);

    useEffect(() => {
        if (userlinkList?.length > 0) {
            let tempList = [];
            tempList = userlinkList.filter((q) => {
                if (
                    q.isLinked &&
                    (selectedWriteUp.dateCreated == q.dateCreated ||
                        (selectedWriteUp.technicianNotesItemID == q.technicianNotesItemID && q.technicianNotesItemID != null))
                ) {
                    return q;
                }
                if (!q.dateCreated && !q.technicianNotesItemID) {
                    return q;
                }
            });
            // tempList = userlinkList.filter(
            //     (q) =>
            //         selectedWriteUp.dateCreated == q.dateCreated || selectedWriteUp.technicianNotesItemID == q.technicianNotesItemID || !q.technicianNotesItemID
            // );

            // if (selectedWriteUp.technicianNotesItemID) {
            //     tempList = userlinkList.filter(
            //         (q) => (!q.dateCreated && !q.technicianNotesItemID) || selectedWriteUp.technicianNotesItemID == q.technicianNotesItemID
            //     );
            // } else {
            //     tempList = userlinkList.filter((q) => !q.technicianNotesItemID || selectedWriteUp.dateCreated == q.dateCreated);
            // }

            // let tempList = userlinkList.filter(
            //     (q) => {
            //         if (
            //             (selectedWriteUp.technicianNotesItemID && !q.dateCreated && !q.technicianNotesItemID) ||
            //             selectedWriteUp.technicianNotesItemID == q.technicianNotesItemID
            //         ) {
            //             return q;
            //         } else if (!q.technicianNotesItemID || selectedWriteUp.dateCreated == q.dateCreated) {
            //             return q;
            //         }
            //     }
            //     // selectedWriteUp.dateCreated == q.dateCreated || !q.technicianNotesItemID || selectedWriteUp.technicianNotesItemID == q.technicianNotesItemID
            // );
            let selectedCount = 0;
            tempList.forEach((a) => {
                if (a.isLinked) {
                    selectedCount += 1;
                }
            });
            setState((st) => {
                const nst = {
                    ...st,
                    sortedPartToLinkList: tempList,
                    totalSelectedPartToLinkCount: selectedCount || 0
                };
                return nst;
            });
        }
    }, [userlinkList, modalIndex]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorE2, setAnchorE2] = React.useState(null);

    const handleClosePOP = () => {
        setAnchorEl(null);
        setAnchorE2(null);
    };

    const handleRowSelect = (params) => {
        if (state.selectedPartId) {
            setInputList((st) => {
                let newSt = [...st];
                let updatedNestedArray = [...st[modalIndex].associatedMaterials];
                console.log(modalIndex, 'modalIndex');
                console.log(state.selectedPartId, 'state.selectedPartId');
                let objIndex = updatedNestedArray.findIndex((q) => q.lineID == state.selectedPartId);
                console.log(objIndex, 'objIndex');
                updatedNestedArray[objIndex].partDescription = params.description;
                updatedNestedArray[objIndex].partNumber = params.partNumber;
                updatedNestedArray[objIndex].technicianNotesItemID = newSt[modalIndex].technicianNotesItemID || null;
                updatedNestedArray[objIndex].dateCreated = newSt[modalIndex].technicianNotesItemID
                    ? moment().format('YYYY-MM-DDTHH:mm:ss')
                    : newSt[modalIndex].dateCreated;
                return newSt;
            });
        } else {
            setState((st) => {
                const nst = {
                    ...st,
                    partNumber: params.partNumber,
                    selectedPartId: null
                };
                return nst;
            });
        }

        setState((st) => {
            const nst = {
                ...st,
                loading: false,
                matchingPartNumber: []
                // selectedPartId: null
            };
            return nst;
        });

        setAnchorEl(null);
        setAnchorE2(null);
    };

    const pullDataByPartNumber = (val, type, selectedPartId, index) => async (event) => {
        console.log(selectedPartId, 'selectedPartId');
        let res;
        if (type == 'part') {
            setAnchorEl(event.currentTarget);
            res = await getPartListByPartNumber(val, null);
            if (res.success) {
                setState((st) => ({
                    ...st,
                    matchingPartNumber: res.data.partDetails,
                    loading: false,
                    selectedPartId: selectedPartId,
                    partIndex: index,
                    isFocusFlag: new Date()
                }));
            }
        } else {
            setAnchorE2(event.currentTarget);
            res = await getPartListByPartNumber(null, val);
            if (res.success) {
                setState((st) => ({
                    ...st,
                    matchingPartNumber: res.data.partDetails,
                    loading: false,
                    selectedPartId: selectedPartId,
                    partIndex: index,
                    isFocusFlag: new Date()
                }));
            }
        }
    };

    const handleSave = () => {
        let AddCount = state.sortedList.length || 0 + state.totalSelectedPartToLinkCount || 0;

        if (list.length > 0) {
            let index = list.findIndex((k) => {
                return k.partNumber === '' && k.partReqStatus !== 1;
            });
            if (index > -1) {
                showToast(` ${ordinalNumbers[index]} row cannot be empty. Please fill in the required information.`);
            } else {
                onClose();
            }
        } else {
            onClose();
        }
    };

    const handleChildAssocate = (isUpdate, data) => {
        if (isUpdate) {
            setInputList((st) => {
                let newSt = [...st];
                let updatedNestedArray = [...st[modalIndex].associatedMaterials];
                let objIndex = updatedNestedArray.findIndex((q) => q.lineID == data.lineID);
                updatedNestedArray[objIndex] = { ...updatedNestedArray[objIndex], ...data };
                updatedNestedArray[objIndex].dateCreated = newSt[modalIndex].technicianNotesItemID
                    ? moment().format('YYYY-MM-DDTHH:mm:ss')
                    : newSt[modalIndex].dateCreated;
                newSt[modalIndex].associatedMaterials = [...updatedNestedArray];
                return newSt;
            });
        } else {
            setInputList((st) => {
                let newSt = [...st];
                let updatedNestedArray = [...st[modalIndex].associatedMaterials];
                let isParentAssoData = updatedNestedArray.find((q) => q.lineID == state.selectedAssociatedId);
                updatedNestedArray.unshift({
                    partReqID: null,
                    technicianNotesItemID: newSt[modalIndex].technicianNotesItemID || null,
                    partNumber: data.partNumber || '',
                    partDescription: data.partDescription || '',
                    partReqStatus: data.partReqStatus || '',
                    partQty: data.partQty || '',
                    isDeleted: false,
                    isFitted: isParentAssoData.isFitted || false,
                    isReturnToPartsDepo: isParentAssoData.isReturnToPartsDepo || false,
                    dateCreated: newSt[modalIndex].technicianNotesItemID ? moment().format('YYYY-MM-DDTHH:mm:ss') : newSt[modalIndex].dateCreated,
                    lineID: 50 + updatedNestedArray.length + 2,
                    parentLineID: state.selectedAssociatedId,
                    isImageErrorMessage: data.isImageErrorMessage
                });
                newSt[modalIndex].associatedMaterials = [...updatedNestedArray];
                return newSt;
            });
        }
        handleCloseSubPartModal();
    };

    const onImagemodalClose = (res) => {
        if (res) {
            setInputList((st) => {
                let newSt = [...st];
                let updatedNestedArray = [...st[modalIndex].associatedMaterials];
                let objIndex = updatedNestedArray.findIndex((q) => q.lineID == state.selectedAssociatedId);
                delete updatedNestedArray[objIndex]['isImageErrorMessage'];
                newSt[modalIndex].associatedMaterials = [...updatedNestedArray];
                return newSt;
            });
        }
        setState((st) => ({ ...st, showImage: false, partId: null, desc: '' }));
    };

    const handleImage = (row) => () => {
        setState((st) => ({
            ...st,
            partId: row.partReqID,
            desc: row.partDescription,
            showImage: true,
            partDateCreated: row.dateCreated,
            selectedAssociatedId: row.lineID
        }));
    };

    useEffect(() => {
        if (Qtyref.current) {
            setTimeout(() => {
                Qtyref.current?.focus();
            }, 1000);
        }
    }, [state.isFocusFlag, state.partIndex]);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const open2 = Boolean(anchorE2);
    const id2 = open ? 'simple-popover2' : undefined;

    const handleEditSubPart = (subPartData) => {
        setState((st) => ({
            ...st,
            showAddParts: true,
            selectedAssociatedSubData: subPartData
        }));
    };

    const handleSubPartModal = (selectedAssociatedId) => {
        setState((st) => ({
            ...st,
            showAddParts: true,
            selectedAssociatedId: selectedAssociatedId
        }));
    };

    const handleCloseSubPartModal = (isClose) => {
        setState((st) => ({
            ...st,
            showAddParts: false,
            selectedAssociatedId: null,
            selectedAssociatedSubData: {}
        }));
    };

    const getImageDateList = (data) => {
        setInputList((st) => {
            let newSt = [...st];
            let updatedNestedArray = [...st[modalIndex].associatedMaterials];
            let objIndex = updatedNestedArray.findIndex((q) => q.lineID == state.selectedAssociatedId);
            updatedNestedArray[objIndex].photosToUpload = data;
            newSt[modalIndex].associatedMaterials = [...updatedNestedArray];
            return newSt;
        });
        onImagemodalClose(true);
    };

    const handleLinkTable = () => {
        setState((previous) => ({ ...previous, showLinkTable: !previous.showLinkTable }));
    };

    return (
        <div style={{ padding: 10 }}>
            {state.sortedPartToLinkList.length > 0 && !state.showLinkTable && (
                <small style={{ display: 'flex', alignItems: 'center' }}>
                    To Link New Parts, click the icon
                    <LinkIcon style={{ color: 'blue', cursor: 'pointer', transform: 'rotate(90deg)', marginLeft: '5px' }} onClick={handleLinkTable} />
                    {state.totalSelectedPartToLinkCount}/{state.sortedPartToLinkList.length}
                </small>
            )}

            {state.sortedPartToLinkList.length > 0 && state.showLinkTable && (
                <TableContainer style={{ height: 150 }}>
                    <Table className={classes.table} aria-label="customized table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <SubCell></SubCell>
                                <SubCell width={'30%'}>Description</SubCell>
                                <SubCell width={'25%'}>Part No.</SubCell>
                                <SubCell width={'8%'}>Quantity</SubCell>
                                <SubCell width={'18%'}>Status</SubCell>
                                {/* <SubCell width={'5%'}>Fitted?</SubCell> */}
                                <SubCell>Returned?</SubCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.sortedPartToLinkList.map((value, index) => {
                                let storedStatusVal = screenDropdown.partReqStatus.find((k) => k.id === value.partReqStatus)?.name || 'N/A';
                                return (
                                    <StyledSubTableRow>
                                        <SubCell>
                                            <input type="checkbox" onChange={(e) => handleLinkRows(e, value)} checked={value.isLinked} />
                                        </SubCell>
                                        <SubCell>{value.partDescription}</SubCell>
                                        <SubCell>{value.partNumber}</SubCell>
                                        <SubCell>{value.partQty}</SubCell>
                                        <SubCell>{storedStatusVal}</SubCell>
                                        {/* <SubCell>{value.isFitted ? 'Yes' : 'No'}</SubCell> */}
                                        <SubCell>{value.isReturnToPartsDepo ? 'Yes' : 'No'}</SubCell>
                                    </StyledSubTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            <br />
            <TableContainer style={{ height: 300 }}>
                <Table className={classes.table} aria-label="customized table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell width={'5%'} align="left">
                                <IconButton
                                    onClick={handleAssociatedRow}
                                    style={{ marginLeft: 4, padding: 0, background: isReadOnly ? 'grey' : 'green', borderRadius: 5 }}
                                    disabled={isReadOnly}
                                >
                                    <AddIcon style={{ fontSize: 21, color: 'white' }} />
                                </IconButton>
                            </StyledTableCell>
                            <StyledTableCell width={'36%'}>Description </StyledTableCell>
                            <StyledTableCell width={'25%'}>Part No.</StyledTableCell>
                            <StyledTableCell width={'9%'}>Quantity</StyledTableCell>
                            <StyledTableCell width={'9%'}>Status</StyledTableCell>
                            {/* <StyledTableCell width={'5%'}>Fitted?</StyledTableCell> */}
                            <StyledTableCell>Returned?</StyledTableCell>
                            <StyledTableCell></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.sortedList.length > 0 ? (
                            state.sortedList.map(
                                (row, index) =>
                                    !row.isDeleted && (
                                        <>
                                            <StyledTableRow key={row.name}>
                                                <StyledTableCell>
                                                    <Grid container spacing={1} alignItems="center">
                                                        <Grid item xs={6}>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => removeAssociatedRow(row.lineID)}
                                                                disabled={isReadOnly || row.partReqStatus}
                                                            >
                                                                <RemoveCircleIcon style={{ color: isReadOnly || row.partReqStatus ? 'grey' : 'red' }} />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </StyledTableCell>

                                                <StyledTableCell>
                                                    <TextBox
                                                        name="partDescription"
                                                        value={row.partDescription}
                                                        autoFocus={true}
                                                        onChange={(e) => handleAssociateInput(e, row.lineID)}
                                                        disabled={isReadOnly || (isTechnician && row.partReqStatus == 15)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <CustomIconButton
                                                                        tabIndex={-1}
                                                                        // toolTipTitle="Get Vehicle Details"
                                                                        onClick={pullDataByPartNumber(row.partDescription, 'desc', row.lineID, index)}
                                                                        disabled={isReadOnly || (isTechnician && row.partReqStatus == 15)}
                                                                    >
                                                                        <SearchIcon />
                                                                    </CustomIconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                    <Popover
                                                        id={id2}
                                                        open={open2}
                                                        anchorEl={anchorE2}
                                                        onClose={handleClosePOP}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center'
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center'
                                                        }}
                                                    >
                                                        <MatchingPartList
                                                            list={state.matchingPartNumber || []}
                                                            loading={state.loading}
                                                            handleRowSelect={handleRowSelect}
                                                        />
                                                    </Popover>
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <TextBox
                                                        name="partNumber"
                                                        disabled={isReadOnly || (isTechnician && row.partReqStatus == 15)}
                                                        value={row.partNumber}
                                                        onChange={(e) => handleAssociateInput(e, row.lineID)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <CustomIconButton
                                                                        tabIndex={-1}
                                                                        disabled={isReadOnly || (isTechnician && row.partReqStatus == 15)}
                                                                        onClick={pullDataByPartNumber(row.partNumber, 'part', row.lineID, index)}
                                                                    >
                                                                        <SearchIcon />
                                                                    </CustomIconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                    <Popover
                                                        id={id}
                                                        open={open}
                                                        anchorEl={anchorEl}
                                                        onClose={handleClosePOP}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'center'
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'center'
                                                        }}
                                                    >
                                                        <MatchingPartList
                                                            list={state.matchingPartNumber || []}
                                                            loading={state.loading}
                                                            handleRowSelect={handleRowSelect}
                                                        />
                                                    </Popover>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <TextBox
                                                        disabled={isReadOnly || (isTechnician && row.partReqStatus == 15)}
                                                        className={classes.input}
                                                        name="partQty"
                                                        type="number"
                                                        value={row.partQty}
                                                        onChange={(e) => handleAssociateInput(e, row.lineID)}
                                                        inputRef={state.partIndex === index ? Qtyref : null}
                                                    />
                                                </StyledTableCell>

                                                <StyledTableCell>
                                                    <SelectBox
                                                        disabled={isReadOnly || (isTechnician && row.partReqStatus == 15)}
                                                        List={screenDropdown.partReqStatus || []}
                                                        name="partReqStatus"
                                                        onChange={(e) => handleAssociateInput(e, row.lineID)}
                                                        value={row.partReqStatus}
                                                    />
                                                </StyledTableCell>

                                                {/* <StyledTableCell>
                                                    <SecondaryCheckbox
                                                        name="isFitted"
                                                        checked={row.isFitted}
                                                        onChange={(e) => handleAssociateInput(e, row.lineID)}
                                                        disabled={isReadOnly}
                                                    />
                                                </StyledTableCell> */}

                                                <StyledTableCell>
                                                    {row.partReqID && (row.partReturnStatusID == 1 || row.partReturnStatusID == 2) && (
                                                        <SecondaryCheckbox
                                                            name="isReturnToPartsDepo"
                                                            checked={row.isReturnToPartsDepo || row.partReturnStatusID == 2}
                                                            onChange={(e) => handleAssociateInput(e, row.lineID)}
                                                            disabled={isReadOnly || row.partReturnStatusID == 2 || (isTechnician && row.partReqStatus == 15)}
                                                        />
                                                    )}
                                                </StyledTableCell>

                                                <StyledTableCell>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                        <DeviceHubIcon
                                                            disabled={isReadOnly}
                                                            style={{ marginLeft: '7px', cursor: isReadOnly ? 'default' : 'pointer' }}
                                                            onClick={isReadOnly ? () => false : () => handleSubPartModal(row.lineID)}
                                                        />
                                                        &nbsp;
                                                        <Badge
                                                            badgeContent={row.photosUploaded}
                                                            color="primary"
                                                            anchorOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right'
                                                            }}
                                                            style={{ marginRight: 10 }}
                                                        >
                                                            <PhotoCameraIcon onClick={handleImage(row)} style={{ cursor: 'pointer' }} />
                                                        </Badge>
                                                    </div>
                                                    <FormHelperText error shrink>
                                                        {row.isImageErrorMessage}
                                                    </FormHelperText>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                            {row.children.length > 0 && (
                                                <StyledTableRow key={row.name}>
                                                    <SubCell colSpan={8}>
                                                        <Table className={classes.table} aria-label="customized table" stickyHeader>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <SubCell width={'4%'}></SubCell>
                                                                    <SubCell width={'30%'}>Description</SubCell>
                                                                    <SubCell width={'25%'}>Part No.</SubCell>
                                                                    <SubCell width={'8%'}>Quantity</SubCell>
                                                                    <SubCell width={'18%'}>Status</SubCell>
                                                                    {/* <SubCell width={'5%'}>Fitted?</SubCell> */}
                                                                    <SubCell>Returned?</SubCell>
                                                                    <SubCell></SubCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {row.children.map((a) => {
                                                                    let storedStatusVal =
                                                                        screenDropdown.partReqStatus.find((k) => k.id == a.partReqStatus)?.name || '';
                                                                    return (
                                                                        <StyledSubTableRow>
                                                                            <SubCell></SubCell>
                                                                            <SubCell>{a.partDescription}</SubCell>
                                                                            <SubCell>{a.partNumber}</SubCell>
                                                                            <SubCell>{a.partQty}</SubCell>
                                                                            <SubCell>{storedStatusVal}</SubCell>
                                                                            {/* <SubCell>{a.isFitted ? 'Yes' : 'No'}</SubCell> */}
                                                                            <SubCell>{a.isReturnToPartsDepo ? 'Yes' : 'No'}</SubCell>
                                                                            <SubCell>
                                                                                <div
                                                                                    style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}
                                                                                >
                                                                                    <EditIcon
                                                                                        disabled={isReadOnly}
                                                                                        onClick={() => handleEditSubPart(a)}
                                                                                        style={{
                                                                                            marginLeft: '10px',
                                                                                            // marginTop: 10,
                                                                                            cursor: 'pointer',
                                                                                            fontSize: 20
                                                                                        }}
                                                                                    />{' '}
                                                                                    <Badge
                                                                                        variant="dot"
                                                                                        color="primary"
                                                                                        // style={{ marginTop: 5 }}
                                                                                    >
                                                                                        <PhotoCameraIcon
                                                                                            onClick={handleImage(a)}
                                                                                            style={{ cursor: 'pointer', fontSize: 20 }}
                                                                                        />
                                                                                    </Badge>
                                                                                </div>
                                                                                <FormHelperText error shrink>
                                                                                    {a.isImageErrorMessage}
                                                                                </FormHelperText>
                                                                            </SubCell>
                                                                        </StyledSubTableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </SubCell>
                                                </StyledTableRow>
                                            )}
                                        </>
                                    )
                            )
                        ) : (
                            <StyledTableRow>
                                <StyledTableCell colSpan={7} align="center">
                                    To add a new Associated Material, click the icon.
                                    <IconButton
                                        onClick={handleAssociatedRow}
                                        style={{ marginLeft: 4, padding: 0, background: isReadOnly ? 'grey' : 'green', borderRadius: 5 }}
                                        disabled={isReadOnly}
                                    >
                                        <AddIcon style={{ fontSize: 21, color: 'white' }} />
                                    </IconButton>
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <SecondaryButton fullWidth onClick={handleSave} disabled={isReadOnly}>
                        Save
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton fullWidth onClick={onClose}>
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
            {state.showAddParts ? (
                <DialogComp title="Add sub part" onClose={() => handleCloseSubPartModal(false)} maxWidth="md" fullWidth>
                    <ChildAssociatedRowValue
                        handleChildAssocate={handleChildAssocate}
                        selectedAssociatedSubData={state.selectedAssociatedSubData || {}}
                        screenDropdown={screenDropdown}
                    />
                </DialogComp>
            ) : null}
            {state.showImage && (
                <DialogComp title="Image Upload" onClose={() => onImagemodalClose(false)} fullWidth maxWidth="lg">
                    <PartReqSupplierPhotoModal
                        partId={state.partId}
                        getImageDateList={getImageDateList}
                        // onFormSubmit={() => PullData()}
                        desc={state.desc}
                        onClose={onImagemodalClose}
                        partDateCreated={state.partDateCreated}
                        disabled={isReadOnly}
                    />
                </DialogComp>
            )}
        </div>
    );
}
