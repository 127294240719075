import React, { useEffect, useState, useMemo } from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import './renault.scss';
import TabComponent from '../../../Core/Controls/Tabs';
import Dossierheader from './dossierHeader';
import ChargeTo from './ChargeTo';
import PartsList from './partsList';
import Labour from './labour';
// import Misc from './misc';
import { getRenaultJobDetails } from '../../../Core/Service/Renault-Job-Service';
import formatters from './../../../Core/Grid/inputFormatter';

const Renault = ({ headerRecordID }) => {
    const [state, setState] = useState({
        jobDetails: {},
        labours: [],
        linkedJobs: [],
        parts: [],
        isLoader: true
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getRenaultJobDetails(headerRecordID);
                setState({
                    jobDetails: res.data.jobDetails[0],
                    labours: res.data.labours,
                    linkedJobs: res.data.linkedJobs,
                    parts: res.data.parts,
                    isLoader: false
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [headerRecordID]);

    const tabs = useMemo(
        () => [
            {
                label: 'DOSSIER HEADER',

                body: (
                    <div style={{ padding: 10 }}>
                        <Dossierheader Jobs={state.jobDetails} />
                    </div>
                )
            },
            {
                label: 'CHARGE TO',
                body: (
                    <div style={{ padding: 10 }}>
                        <ChargeTo Jobs={state.jobDetails} />
                    </div>
                )
            },
            {
                label: 'PARTS LIST',
                body: <PartsList List={state.parts} />
            },
            {
                label: 'LABOUR',
                body: <Labour List={state.labours} />
            }
        ],
        [state.jobDetails, state.parts, state.labours]
    );

    return (
        <div className="renaultMain">
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ fontSize: 15, textAlign: 'left' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} container spacing={2}>
                            <Grid item xs={12} lg={1} sm={6}>
                                {/* Registration Number:&nbsp;
                                <b
                                    style={{
                                        // background: state.jobDetails.customerName ? 'red' : 'white',
                                        // color: state.jobDetails.customerName ? 'white' : 'black',
                                        padding: 4
                                    }}
                                >
                                    {' '}
                                    {state.jobDetails.registration || 'N/A'}
                                </b> */}
                                <div className="labelText">Registration Number </div>
                                <Typography className="valueText">{state.jobDetails.registration || 'N/A'}</Typography>
                            </Grid>
                            <Grid item xs={12} lg={2} sm={6} style={{ marginLeft: 3 }}>
                                {/* Customer:&nbsp;
                                <b
                                    style={{
                                        background: state.jobDetails.customerBlocked ? 'red' : 'white',
                                        color: state.jobDetails.customerBlocked ? 'white' : 'black ',
                                        padding: 4
                                    }}
                                >
                                    {state.jobDetails.customerName}
                                </b> */}

                                <div className="labelText">Customer </div>
                                <Typography
                                    className="valueText "
                                    style={{
                                        background: state.jobDetails.customerBlocked ? 'red' : 'white',
                                        color: state.jobDetails.customerBlocked ? 'white' : 'black '
                                    }}
                                >
                                    {state.jobDetails.customerName || 'N/A'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={1} sm={6}>
                            <div className="labelText"> Date Created </div>
                            <Typography className="valueText">
                                {state.jobDetails.dateCreated ? formatters.YearFormatter(state.jobDetails.dateCreated) : 'N/A'}
                            </Typography>
                            {/* Date Created :&nbsp; <b> {state.jobDetails.dateCreated ? formatters.YearFormatter(state.jobDetails.dateCreated) : 'N/A'}</b> */}
                        </Grid>
                        <Grid item xs={12} lg={1} sm={6}>
                            {/* Start of Intervention In Workshop :&nbsp;{' '}
                            <b> {state.jobDetails.datestartofintervention ? formatters.YearFormatter(state.jobDetails.datestartofintervention) : 'N/A'}</b> */}
                            <div className="labelText"> Start of Intervention </div>
                            <Typography className="valueText">
                                {state.jobDetails.datestartofintervention ? formatters.YearFormatter(state.jobDetails.datestartofintervention) : 'N/A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={1} sm={6}>
                            {/* Start of Intervention In Workshop :&nbsp;{' '}
                            <b> {state.jobDetails.datestartofintervention ? formatters.YearFormatter(state.jobDetails.datestartofintervention) : 'N/A'}</b> */}
                            <div className="labelText"> In Workshop </div>
                            <Typography className="valueText">
                                {state.jobDetails.dateWorkshopIn ? formatters.YearFormatter(state.jobDetails.dateWorkshopIn) : 'N/A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={'auto'} sm={6}>
                            {/* Workshop In Progress :&nbsp;{' '}
                            <b> {state.jobDetails.dateInProgressWorkshop ? formatters.YearFormatter(state.jobDetails.dateInProgressWorkshop) : 'N/A'}</b> */}
                            <div className="labelText"> Workshop In Progress </div>
                            <Typography className="valueText">
                                {state.jobDetails.dateInProgressWorkshop ? formatters.YearFormatter(state.jobDetails.dateInProgressWorkshop) : 'N/A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={1} sm={6}>
                            {/* Good To Delivery :&nbsp;{' '}
                            <b> {state.jobDetails.dateGoodToDeliver ? formatters.YearFormatter(state.jobDetails.dateGoodToDeliver) : 'N/A'}</b> */}
                            <div className="labelText"> Good To Delivery </div>
                            <Typography className="valueText">
                                {state.jobDetails.dateGoodToDeliver ? formatters.YearFormatter(state.jobDetails.dateGoodToDeliver) : 'N/A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={1} sm={6}>
                            {/* Date Of Delivery Good To Invoice :&nbsp;{' '}
                            <b> {state.jobDetails.dateGoodToinvoice ? formatters.YearFormatter(state.jobDetails.dateGoodToinvoice) : 'N/A'}</b> */}
                            <div className="labelText"> Date Of Delivery </div>
                            <Typography className="valueText">
                                {state.jobDetails.dateOfDelivery ? formatters.YearFormatter(state.jobDetails.dateOfDelivery) : 'N/A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={1} sm={6}>
                            {/* Date Of Delivery Good To Invoice :&nbsp;{' '}
                            <b> {state.jobDetails.dateGoodToinvoice ? formatters.YearFormatter(state.jobDetails.dateGoodToinvoice) : 'N/A'}</b> */}
                            <div className="labelText"> Good To Invoice </div>
                            <Typography className="valueText">
                                {state.jobDetails.dateGoodToinvoice ? formatters.YearFormatter(state.jobDetails.dateGoodToinvoice) : 'N/A'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} lg={1} sm={6}>
                            {/* Date Of Invoice :&nbsp; <b> {state.jobDetails.dateInvoicing ? formatters.YearFormatter(state.jobDetails.dateInvoicing) : 'N/A'}</b> */}
                            <div className="labelText"> Date Of Invoice </div>
                            <Typography className="valueText">
                                {state.jobDetails.dateInvoicing ? formatters.YearFormatter(state.jobDetails.dateInvoicing) : 'N/A'}
                            </Typography>
                        </Grid>
                        {state.jobDetails.dateDeletion ? (
                            <Grid item xs={12} lg={1} sm={6}>
                                {/* Date Deleted :&nbsp; <b> {state.jobDetails.dateDeletion ? formatters.YearFormatter(state.jobDetails.dateDeletion) : 'N/A'}</b> */}
                                <div className="labelText"> Date Deleted </div>
                                <Typography className="valueText">
                                    {state.jobDetails.dateDeletion ? formatters.YearFormatter(state.jobDetails.dateDeletion) : 'N/A'}
                                </Typography>
                            </Grid>
                        ) : null}
                        <Grid item></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {state.isLoader ? (
                        <div style={{ height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <TabComponent tabList={tabs} />
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default Renault;
