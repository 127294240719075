import React, { useContext, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import AppContext from '../../../App/AppContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    TextBox,
    PasswordBox,
    SecondaryButton,
    PrimaryCheckbox,
    DialogContents,
    DialogTitles,
    DialogsActions,
    Dialogs,
    SecondaryCheckbox,
    Multiselect,
    MultiselectWithoutCheckbox,
    YesNoButton,
    SelectBox
} from '../../../Core/FormInput';
import { getWorkflows } from '../../../Core/Service/workflow-service';
import { getBranches } from '../../../Core/Service/branches-service';
import { getCustomer } from '../../../Core/Service/customer-services';
import { Adduser, UpdateUser, getUser, getScreenDropdown, getCustomerScreenDropdown } from '../../../Core/Service/admin-service';
import CloseIcon from '@material-ui/icons/Close';
import { Checkbox, IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import UserModules from './userModules';
import { AppStorage } from '../../../Core/Service/storage-service';

const AddUserModal = (props) => {
    const { hideModal, showToast, portalSettings, userRoles } = useContext(AppContext);
    const [masterData, setMasterData] = useState({
        workflows: []
    });

    const [brancheData, setbrancheData] = useState({
        branches: []
    });

    const [processData, setprocessData] = useState({
        processes: []
    });

    const [moduleData, setmoduleData] = useState({
        modules: [
            { name: 'eWIP', id: 'eWIP' },
            { name: 'Reporting', id: 'Reporting' },
            { name: 'Communicator', id: 'Communicator' }
        ]
    });

    const [franchiseData, setFranchisesData] = useState({
        franchises: []
    });

    const [customerData, setcustomerData] = useState({
        customers: []
    });

    const [custmerGroup, setCustomerGroup] = useState([]);

    const [appraisalVehicleOption, setAppraisalVehicleOption] = useState([]);

    const [selectedData, setSelectedData] = useState({
        customers: [],
        workflows: [],
        branches: [],
        processes: [],
        modules: [],
        franchises: [],
        appraisalVehicles: []
    });

    const [state, setState] = useState({
        userType: '',
        email: '',
        name: '',
        phone: '',
        password: '',
        confirm: '',
        enableChange: false,
        allbranch: false,
        allprocess: false,
        allcust: false,
        allworkflow: false,
        isActive: true,
        isLocked: false,
        customer: [],
        workflow: [],
        branches: [],
        processes: [],
        appraiserRoleList: [],
        appraisalVehicle: [],
        doc_id: '',
        canOverrideMandatorySteps: false,
        canAssignJobsToAuditor: false,
        canReturnJobsFromLastWorkflow: false,
        canDeleteDocuments: false,
        canResetPassword: false,
        canAccessLockScreen: false,
        canAssignTechnician: false,
        canAssignJobPriority: false,
        franchises: [],
        isAgedMeasure: false,
        isDOC: false,
        isKPIs: false,
        isParts: false,
        isSales: false,
        isService: false,
        isWorkShop: false,
        eWIPAllowed: false,
        specialEquipmentAllowed: false,
        canAmendToDoList: false,
        canAmendTechWriteUp: false,
        canDeleteTechWriteup: false,
        canAmendJobNotes: false,
        autoLoadDocuments: false,
        canUploadVehicleDocs: false,
        canDeleteVehicleDocs: false,
        canAuthorisedHandoverSheet: false,
        canActionedHandoverSheet: false,
        canSetCustomerRating: false,
        eReportingAllowed: false,
        eCommunicatorAllowed: false,
        eInvoiceAllowed: false,
        canAccessAppraisalPortal: false,
        isBudgetDashboard: false,
        userCustomerGroupID: null,
        sendEmailFromOwnEmail: false,
        canAccessBudget: false,
        defaultInternalDashboard: null,
        twoFactorEnabled: false,
        tempTwoFactorEnabled: false,
        canSignOffTechWriteup: false,
        canAccessSalesPerformance: false,
        canHideDocumentCreation: false,
        canDeletePartRequests: false,
        canCopyUpperCaseWriteUps: false,
        appraisalRoleID: ''
    });

    const user = props.users;

    useEffect(() => {
        if (user) {
            const selectedfranchises = franchiseData.franchises.filter((w) => state.franchises.indexOf(w.id) > -1);
            const selectedCust = customerData.customers.filter((w) => state.customer.indexOf(w.id) > -1);
            const selectedWorkflows = masterData.workflows.filter((w) => state.workflow.indexOf(w.id) > -1);
            const selectedBranches = brancheData.branches.filter((w) => state.branches.indexOf(w.id) > -1);
            const selectedProcesses = processData.processes.filter((w) => state.processes.indexOf(w.id) > -1);
            const selectedAppraisalVehicle = appraisalVehicleOption.filter((w) => state.appraisalVehicle.indexOf(w.id) > -1);
            setSelectedData((st) => ({
                ...st,
                customers: selectedCust,
                workflows: selectedWorkflows,
                branches: selectedBranches,
                processes: selectedProcesses,
                franchises: selectedfranchises,
                appraisalVehicles: selectedAppraisalVehicle
            }));

            if (customerData.customers.length > 0 && customerData.customers === selectedCust.length) {
                setState((st) => ({ ...st, allcust: true }));
            }

            if (masterData.workflows.length > 0 && masterData.workflows.length === selectedWorkflows.length) {
                setState((st) => ({ ...st, allworkflow: true }));
            }

            if (brancheData.branches.length > 0 && brancheData.branches.length === selectedBranches.length) {
                setState((st) => ({ ...st, allbranch: true }));
            }

            if (processData.processes.length > 0 && processData.processes.length === selectedProcesses.length) {
                setState((st) => ({ ...st, allprocess: true }));
            }
        }
    }, [
        state.franchises,
        state.branches,
        state.processes,
        state.workflow,
        state.customer,
        masterData.workflows,
        brancheData.branches,
        processData.processes,
        customerData.customers,
        franchiseData.franchises,
        state.appraisalVehicle,
        appraisalVehicleOption
    ]);

    function updateDashboardList(portalSettings) {
        const updatedList = [];
        if (portalSettings.portalSettingeWIPEnabled) {
            updatedList.push({ id: 1, name: 'eWIP' });
        }
        if (portalSettings.portalSettingReportingEnabled) {
            updatedList.push({ id: 2, name: 'Reporting' });
        }
        if (portalSettings.portalSettingInvoiceEnabled) {
            updatedList.push({ id: 3, name: 'Invoices' });
        }
        if (portalSettings.portalSettingCommunicatorEnabled) {
            updatedList.push({ id: 4, name: 'Communicator' });
        }
        setState((st) => ({ ...st, DashboardList: updatedList }));
    }

    useEffect(async () => {
        let resCust = await getCustomerScreenDropdown();

        if (resCust.success) {
            const customers = resCust?.data?.customers;

            setcustomerData((st) => ({ ...st, customers }));
        }
        let respo = await getScreenDropdown();
        if (respo.success) {
            const workflows = respo?.data?.workflows;
            // .filter((w) => !w.isTechnicianWorkflow)
            // .map((w) => ({
            //     ...w
            // }));
            const customerGroup = respo?.data?.customerGroups;
            const branches = respo?.data?.branches;
            const processes = respo?.data?.processes;
            const updatedAppraiserRoles = respo?.data?.appraiserRoles.filter((role) => role.name !== 'Customer');
            const appraisalVehicleOption = respo?.data?.appraiserVehicles || [];
            setMasterData((st) => ({ ...st, workflows }));
            setbrancheData((st) => ({ ...st, branches }));
            setprocessData((st) => ({ ...st, processes }));
            setFranchisesData((st) => ({ ...st, franchises: respo?.data?.franchiseCode }));
            setCustomerGroup(customerGroup);
            setState((st) => ({ ...st, appraiserRoleList: updatedAppraiserRoles }));
            setAppraisalVehicleOption(appraisalVehicleOption);
        }
        updateDashboardList(portalSettings);
        if (user) {
            let result = await getUser(user.id);
            const updatedUser = result.data;
            if (result.success) {
                setState((st) => ({
                    ...st,
                    franchises: updatedUser.franchisesCode,
                    doc_id: updatedUser.id,
                    name: updatedUser.displayName,
                    phone: updatedUser.phoneNo,
                    email: updatedUser.email,
                    userType: updatedUser.userRole,
                    isActive: updatedUser.isActive,
                    isLocked: updatedUser.isLocked,
                    enableChange: true,
                    workflow: updatedUser.workflowIds,
                    branches: updatedUser.branchIds,
                    processes: updatedUser.processIds,
                    customer: updatedUser.customerIds,
                    appraisalVehicle: updatedUser.appraisalVehicleIDs || [],
                    canOverrideMandatorySteps: updatedUser.canOverrideMandatorySteps,
                    canAssignJobsToAuditor: updatedUser.canAssignJobsToAuditor,
                    canReturnJobsFromLastWorkflow: updatedUser.canReturnJobsFromLastWorkflow,
                    canDeleteDocuments: updatedUser.canDeleteDocuments,
                    canResetPassword: updatedUser.canResetPassword,
                    canAccessLockScreen: updatedUser.canAccessLockScreen,
                    canAssignJobPriority: updatedUser.canAssignJobPriority,
                    canAssignTechnician: updatedUser.canAssignTechnician,
                    canSetCustomerRating: updatedUser.canSetCustomerRating,
                    isAgedMeasure: updatedUser.isAgedMeasure,
                    isDOC: updatedUser.isDOC,
                    isKPIs: updatedUser.isKPIs,
                    isParts: updatedUser.isParts,
                    isSales: updatedUser.isSales,
                    isService: updatedUser.isService,
                    isWorkShop: updatedUser.isWorkShop,
                    eWIPAllowed: updatedUser.eWIPAllowed,
                    specialEquipmentAllowed: updatedUser.specialEquipmentAllowed,
                    canAmendToDoList: updatedUser.canAmendToDoList,
                    canAmendTechWriteUp: updatedUser.canAmendTechWriteUp,
                    canDeleteTechWriteup: updatedUser.canDeleteTechWriteup,
                    canAmendJobNotes: updatedUser.canAmendJobNotes,
                    autoLoadDocuments: updatedUser.autoLoadDocuments,
                    canUploadVehicleDocs: updatedUser.canUploadVehicleDocs,
                    canDeleteVehicleDocs: updatedUser.canDeleteVehicleDocs,
                    canAuthorisedHandoverSheet: updatedUser.canAuthorisedHandoverSheet,
                    canActionedHandoverSheet: updatedUser.canActionedHandoverSheet,
                    eReportingAllowed: updatedUser.eReportingAllowed,
                    eCommunicatorAllowed: updatedUser.eCommunicatorAllowed,
                    eInvoiceAllowed: updatedUser.eInvoiceAllowed,
                    canAccessAppraisalPortal: updatedUser.canAccessAppraisalPortal,
                    isBudgetDashboard: updatedUser.isBudgetDashboard,
                    userCustomerGroupID: updatedUser.userCustomerGroupID,
                    sendEmailFromOwnEmail: updatedUser.sendEmailFromOwnEmail,
                    canAccessBudget: updatedUser.canAccessBudget,
                    defaultInternalDashboard: updatedUser.defaultInternalDashboard,
                    twoFactorEnabled: updatedUser.twoFactorEnabled,
                    canSignOffTechWriteup: updatedUser.canSignOffTechWriteup,
                    canAccessSalesPerformance: updatedUser.canAccessSalesPerformance,
                    appraisalRoleID: updatedUser.appraisalRoleID,
                    canHideDocumentCreation: updatedUser.canHideDocumentCreation,
                    canDeletePartRequests: updatedUser.canDeletePartRequests,
                    canCopyUpperCaseWriteUps: updatedUser.canCopyUpperCaseWriteUps
                }));
            }
        }
    }, []);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };

    const handleSubmit = async (e) => {
        let commonData = {
            id: state.doc_id || null,
            displayName: state.name,
            email: state.email,
            phone: state.phone,
            isLocked: state.isLocked,
            isActive: state.isActive,
            twoFactorEnabled: state.tempTwoFactorEnabled ? false : state.twoFactorEnabled,
            userRole: state.userType,
            sendEmailFromOwnEmail: state.sendEmailFromOwnEmail,
            processIds: state.processes
        };

        let nicolReportDefaultFlag = {
            canAccessBranchOverview: false,
            canAccessSalesProfitAndLoss: false,
            canAccessAfterSalesProfitAndLoss: false,
            canAccessProfitAndLossDetail: false,
            canAccessUsedStockValuation: false,
            canAccessUsedVehicleStock: false,
            canAccessAgedVehicleStock: false,
            canAccessOveragePriorityList: false,
            canAccessSalesReporting: false
        };

        let customData = {
            admin: {
                ...commonData,
                branchIds: [],
                customerIds: [],
                workflowIds: [],
                canOverrideMandatorySteps: false,
                canAssignJobsToAuditor: false,
                canReturnJobsFromLastWorkflow: false,
                canResetPassword: false,
                canAccessLockScreen: state.canAccessLockScreen,
                canAssignJobPriority: false,
                canAssignTechnician: false,
                canDeleteDocuments: false,
                canSetCustomerRating: false,
                specialEquipmentAllowed: false,
                canAmendToDoList: false,
                canAmendTechWriteUp: false,
                canDeleteTechWriteup: false,
                canAuthorisedHandoverSheet: false,
                canActionedHandoverSheet: false,
                canAmendJobNotes: false,
                autoLoadDocuments: false,
                canUploadVehicleDocs: false,
                canDeleteVehicleDocs: false,
                canSignOffTechWriteup: false,
                isAgedMeasure: false,
                isDOC: false,
                isKPIs: false,
                isParts: false,
                isSales: false,
                isService: false,
                isWorkShop: false,
                franchisesCode: [],
                eWIPAllowed: false,
                eReportingAllowed: false,
                eCommunicatorAllowed: false,
                eInvoiceAllowed: false,
                isBudgetDashboard: false, //state.isBudgetDashboard,
                userCustomerGroupID: null,
                canAccessBudget: false,
                canAccessSalesPerformance: false,
                canAccessAppraisalPortal: state.canAccessAppraisalPortal,
                appraisalVehicleIDs: [],
                canHideDocumentCreation: state.canHideDocumentCreation,
                canDeletePartRequests: state.canDeletePartRequests,
                canCopyUpperCaseWriteUps: state.canCopyUpperCaseWriteUps
            },
            auditor: {
                ...commonData,
                branchIds: [],
                customerIds: [],
                workflowIds: [],
                canOverrideMandatorySteps: false,
                canAssignJobsToAuditor: false,
                canReturnJobsFromLastWorkflow: false,
                canResetPassword: false,
                canAccessLockScreen: state.canAccessLockScreen,
                canAssignJobPriority: false,
                canAssignTechnician: false,
                canDeleteDocuments: false,
                canSetCustomerRating: false,
                specialEquipmentAllowed: false,
                canAmendToDoList: false,
                canAmendTechWriteUp: false,
                canDeleteTechWriteup: false,
                canAuthorisedHandoverSheet: false,
                canActionedHandoverSheet: false,
                canAmendJobNotes: false,
                autoLoadDocuments: false,
                canUploadVehicleDocs: false,
                canDeleteVehicleDocs: false,
                isAgedMeasure: false,
                isDOC: false,
                isKPIs: false,
                isParts: false,
                isSales: false,
                isService: false,
                isWorkShop: false,
                franchisesCode: state.franchises,
                eWIPAllowed: false,
                eReportingAllowed: false,
                eCommunicatorAllowed: false,
                eInvoiceAllowed: false,
                isBudgetDashboard: false, //state.isBudgetDashboard,
                userCustomerGroupID: null,
                canAccessBudget: false,
                canSignOffTechWriteup: false,
                canAccessSalesPerformance: false,
                canAccessAppraisalPortal: false,
                appraisalVehicleIDs: [],
                canHideDocumentCreation: state.canHideDocumentCreation,
                canDeletePartRequests: state.canDeletePartRequests,
                canCopyUpperCaseWriteUps: state.canCopyUpperCaseWriteUps
            },
            customer: {
                ...commonData,
                branchIds: [],
                customerIds: state.customer,
                workflowIds: state.workflow,
                canOverrideMandatorySteps: state.canOverrideMandatorySteps,
                canAssignJobsToAuditor: state.canAssignJobsToAuditor,
                canReturnJobsFromLastWorkflow: state.canReturnJobsFromLastWorkflow,
                canResetPassword: state.canResetPassword,
                canAccessLockScreen: state.canAccessLockScreen,
                canAssignJobPriority: false,
                canAssignTechnician: false,
                canSetCustomerRating: false,
                specialEquipmentAllowed: false,
                canAmendToDoList: false,
                canAmendTechWriteUp: false,
                canDeleteTechWriteup: false,
                canAuthorisedHandoverSheet: state.canAuthorisedHandoverSheet,
                canActionedHandoverSheet: state.canActionedHandoverSheet,
                canAmendJobNotes: false,
                autoLoadDocuments: false,
                canUploadVehicleDocs: false,
                canDeleteVehicleDocs: false,
                canDeleteDocuments: state.canDeleteDocuments,
                isAgedMeasure: false,
                isDOC: false,
                isKPIs: false,
                isParts: false,
                isSales: false,
                isService: false,
                isWorkShop: false,
                franchisesCode: state.franchises,
                eWIPAllowed: state.eWIPAllowed,
                eReportingAllowed: false,
                eCommunicatorAllowed: state.eCommunicatorAllowed,
                eInvoiceAllowed: state.eInvoiceAllowed,
                isBudgetDashboard: false, //state.isBudgetDashboard,
                userCustomerGroupID: state.userCustomerGroupID,
                canAccessBudget: false,
                defaultInternalDashboard: state.defaultInternalDashboard,
                canSignOffTechWriteup: false,
                canAccessSalesPerformance: false,
                canAccessAppraisalPortal: state.canAccessAppraisalPortal,
                appraisalRoleID: portalSettings.portalSettingsAppraisalEnabled ? 'fa3db6d0-9335-4d47-8849-524f81b2e4df' : '',
                appraisalVehicleIDs: state.appraisalVehicle,
                canHideDocumentCreation: state.canHideDocumentCreation,
                canDeletePartRequests: state.canDeletePartRequests,
                canCopyUpperCaseWriteUps: state.canCopyUpperCaseWriteUps
            },
            internal: {
                ...commonData,
                branchIds: state.branches,
                customerIds: [],
                workflowIds: state.workflow,
                canOverrideMandatorySteps: state.canOverrideMandatorySteps,
                canAssignJobsToAuditor: state.canAssignJobsToAuditor,
                canReturnJobsFromLastWorkflow: state.canReturnJobsFromLastWorkflow,
                canResetPassword: state.canResetPassword,
                canAccessLockScreen: state.canAccessLockScreen,
                canAssignJobPriority: state.canAssignJobPriority,
                canAssignTechnician: state.canAssignTechnician,
                canDeleteDocuments: state.canDeleteDocuments,
                canSetCustomerRating: state.canSetCustomerRating,
                isAgedMeasure: state.isAgedMeasure,
                isDOC: state.isDOC,
                isKPIs: state.isKPIs,
                isParts: state.isParts,
                isSales: state.isSales,
                isService: state.isService,
                isWorkShop: state.isWorkShop,
                franchisesCode: state.franchises,
                eWIPAllowed: state.eWIPAllowed,
                specialEquipmentAllowed: state.specialEquipmentAllowed,
                canAmendToDoList: state.canAmendToDoList,
                canAmendTechWriteUp: state.canAmendTechWriteUp,
                canDeleteTechWriteup: state.canDeleteTechWriteup,
                canAuthorisedHandoverSheet: state.canAuthorisedHandoverSheet,
                canActionedHandoverSheet: state.canActionedHandoverSheet,
                canAmendJobNotes: state.canAmendJobNotes,
                autoLoadDocuments: state.autoLoadDocuments,
                canUploadVehicleDocs: state.canUploadVehicleDocs,
                canDeleteVehicleDocs: state.canDeleteVehicleDocs,
                eReportingAllowed: state.eReportingAllowed,
                eCommunicatorAllowed: state.eCommunicatorAllowed,
                eInvoiceAllowed: state.eInvoiceAllowed,
                isBudgetDashboard: false, //state.isBudgetDashboard,
                userCustomerGroupID: state.userCustomerGroupID,
                canAccessBudget: state.canAccessBudget,
                defaultInternalDashboard: state.defaultInternalDashboard,
                canSignOffTechWriteup: state.canSignOffTechWriteup,
                canAccessSalesPerformance: state.canAccessSalesPerformance,
                canAccessAppraisalPortal: state.canAccessAppraisalPortal,
                appraisalRoleID: state.appraisalRoleID,
                appraisalVehicleIDs: state.appraisalVehicle,
                canHideDocumentCreation: state.canHideDocumentCreation,
                canDeletePartRequests: state.canDeletePartRequests,
                canCopyUpperCaseWriteUps: state.canCopyUpperCaseWriteUps
            },
            technician: {
                ...commonData,
                branchIds: state.branches,
                customerIds: [],
                workflowIds: state.workflow,
                canOverrideMandatorySteps: state.canOverrideMandatorySteps,
                canAssignJobsToAuditor: false,
                canReturnJobsFromLastWorkflow: state.canReturnJobsFromLastWorkflow,
                canResetPassword: false,
                canAccessLockScreen: state.canAccessLockScreen,
                canAssignJobPriority: state.canAssignJobPriority,
                canSetCustomerRating: state.canSetCustomerRating,
                canAmendToDoList: state.canAmendToDoList,
                canAmendTechWriteUp: state.canAmendTechWriteUp,
                canDeleteTechWriteup: state.canDeleteTechWriteup,
                canAuthorisedHandoverSheet: state.canAuthorisedHandoverSheet,
                canActionedHandoverSheet: state.canActionedHandoverSheet,
                canAmendJobNotes: state.canAmendJobNotes,
                autoLoadDocuments: false,
                canUploadVehicleDocs: false,
                canDeleteVehicleDocs: false,
                specialEquipmentAllowed: false,
                canAssignTechnician: state.canAssignTechnician,
                canDeleteDocuments: state.canDeleteDocuments,
                isAgedMeasure: state.isAgedMeasure,
                isDOC: state.isDOC,
                isKPIs: state.isKPIs,
                isParts: state.isParts,
                isSales: state.isSales,
                isService: state.isService,
                isWorkShop: state.isWorkShop,
                franchisesCode: state.franchises,
                eWIPAllowed: true,
                eReportingAllowed: state.eReportingAllowed,
                eCommunicatorAllowed: false,
                eInvoiceAllowed: false,
                isBudgetDashboard: false, //state.isBudgetDashboard,
                userCustomerGroupID: null,
                canAccessBudget: state.canAccessBudget,
                canAccessSalesPerformance: state.canAccessSalesPerformance,
                canSignOffTechWriteup: state.canSignOffTechWriteup,
                canAccessAppraisalPortal: state.canAccessAppraisalPortal,
                appraisalVehicleIDs: [],
                canHideDocumentCreation: state.canHideDocumentCreation,
                canDeletePartRequests: state.canDeletePartRequests,
                canCopyUpperCaseWriteUps: state.canCopyUpperCaseWriteUps
            }
        };

        let data = {
            ...commonData,
            ...customData[`${state.userType}`],
            ...nicolReportDefaultFlag
        };
        let res = {};
        if (state.doc_id) {
            res = await UpdateUser(data);
        } else {
            let minLength = AppStorage.getPortalSettingPassLength();
            let pattern = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&#])[A-Za-z\\d@$!%*?&#]{${minLength},}$`, 'gm');
            // if (!pattern.test(state.password)) {
            //     showToast(`Password should be complex with ${minLength} char minimum.`);
            // }
            //  else {
            data = { ...data, password: state.password, confirmPassword: state.confirm };
            res = await Adduser(data);
            // }
        }
        if (!res.success) {
            console.error(res);
            showToast(
                <>
                    {Array.isArray(res.messages) ? (
                        res.messages.map((msg, i) => {
                            return <div key={i}>{msg}</div>;
                        })
                    ) : (
                        <div>{res.message}</div>
                    )}
                </>
            );
        } else {
            hideModal();
            props.onFormSubmit(true);
            showToast(res.message);
        }
    };

    const AutoBrChange = (event, value) => {
        setSelectedData((st) => ({ ...st, branches: value }));
        setState((st) => {
            const nst = { ...st, branches: value.map((c) => c.id) };
            return nst;
        });
    };

    const AutoPrChange = (event, value) => {
        setSelectedData((st) => ({ ...st, processes: value }));
        setState((st) => {
            const nst = { ...st, processes: value.map((c) => c.id) };
            return nst;
        });
    };

    // const AutoModuleChange = (event, value) => {
    //     setSelectedData((st) => ({ ...st, modules: value }));
    //     setState((st) => {
    //         const nst = { ...st, modules: value.map((c) => c.id) };
    //         return nst;
    //     });
    // };

    const AutoFranchiseChange = (event, value) => {
        setSelectedData((st) => ({ ...st, franchises: value }));
        setState((st) => {
            const nst = { ...st, franchises: value.map((c) => c.id) };
            return nst;
        });
    };

    const AutoCustChange = (event, value) => {
        setSelectedData((st) => ({ ...st, customers: value }));
        setState((st) => {
            const nst = { ...st, customer: value.map((c) => c.id) };
            return nst;
        });
    };

    const AutoWorkChange = (event, value) => {
        setSelectedData((st) => ({ ...st, workflows: value }));
        setState((st) => {
            const nst = { ...st, workflow: value.map((c) => c.id) };
            return nst;
        });
    };

    const AutoAppraisalVehiclechange = (event, value) => {
        setSelectedData((st) => ({ ...st, appraisalVehicles: value }));
        setState((st) => {
            const nst = { ...st, appraisalVehicle: value.map((c) => c.id) };
            return nst;
        });
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            if (name == 'userType') {
                nst.branches = [];
                nst.workflow = [];
                nst.allworkflow = false;
            }
            if (!user) {
                nst.enableChange = nst.password && nst.email && nst.name && nst.password === nst.confirm;
            }
            return nst;
        });
    };

    const handleCheckbox = (event) => {
        const { name, checked } = event.target;
        if (checked) {
            setState((st) => {
                const nst = { ...st };
                nst[name] = checked;
                if (name == 'allbranch') {
                    nst.branches = [...brancheData.branches.map((c) => c.id)];
                } else if (name == 'allprocess') {
                    nst.processes = [...processData.processes.map((c) => c.id)];
                } else if (name == 'allcust') {
                    nst.customer = [...customerData.customers.map((c) => c.id)];
                } else if (name == 'allworkflow') {
                    nst.workflow = [...masterData.workflows.map((c) => c.id)];
                } else {
                    nst[name] = checked;
                }
                return nst;
            });
        } else {
            setState((st) => {
                const nst = { ...st, [name]: checked };
                return nst;
            });

            // setSelectedData((st) => {
            //     const nst = { ...st };
            //     if (name == 'allbranch') {
            //         nst.branches = [];
            //     } else if (name == 'allprocess') {
            //         nst.processes = [];
            //     } else if (name == 'allcust') {
            //         nst.customers = [];
            //     } else if (name == 'allworkflow') {
            //         nst.workflows = [];
            //     } else {
            //         return false;
            //     }
            //     return nst;
            // });
        }
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="md">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{user ? <DialogTitles>Update User</DialogTitles> : <DialogTitles>Add User</DialogTitles>}</Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents style={{ overflow: 'auto', height: '80vh' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <TextBox autoFocus={true} required name="name" label="Name" onChange={fieldChange} value={state.name} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label="Phone Number" name="phone" variant="outlined" fullWidth onChange={fieldChange} value={state.phone} margin="dense" />
                    </Grid>
                    <Grid item xs={12}>
                        <TextBox required label="Email" name="email" type="email" onChange={fieldChange} value={state.email} />
                    </Grid>
                    {!user ? (
                        <>
                            <Grid item xs={12} sm={6}>
                                <PasswordBox autoComplete="new-password" label="Password" name="password" onChange={fieldChange} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PasswordBox autoComplete="new-password" label="Confirm Password" name="confirm" onChange={fieldChange} />
                            </Grid>
                        </>
                    ) : null}
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox checked={state.sendEmailFromOwnEmail} onChange={handleCheckbox} name="sendEmailFromOwnEmail" />}
                            label="Send Email From Own Email?"
                        />
                    </Grid>
                    <Grid item row xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">User Type</FormLabel>
                            <Grid item xs={12}>
                                <RadioGroup row aria-label="userType" name="userType" value={state.userType} onChange={fieldChange}>
                                    <FormControlLabel value="admin" control={<Radio />} label="Admin" />
                                    <FormControlLabel value="customer" control={<Radio />} label="Customer" />
                                    <FormControlLabel value="internal" control={<Radio />} label="Internal User" />
                                    {portalSettings.portalSettingeWIPEnabled && (
                                        <>
                                            <FormControlLabel value="auditor" control={<Radio />} label="Auditor" />
                                            <FormControlLabel value="technician" control={<Radio />} label="Technician" />
                                        </>
                                    )}
                                </RadioGroup>
                            </Grid>
                        </FormControl>
                    </Grid>
                    {state.userType == 'technician' || state.userType == 'internal' ? (
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<PrimaryCheckbox onChange={handleCheckbox} checked={state.allbranch} name="allbranch" value={state.allbranch} />}
                                    label="All Branches"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {!state.allbranch ? (
                                    <Multiselect label="Branches" options={brancheData.branches} value={selectedData.branches} onChange={AutoBrChange} />
                                ) : null}
                            </Grid>
                        </Grid>
                    ) : null}
                    {state.userType != 'admin' ? (
                        <Grid item xs={12} sm={12}>
                            <Multiselect
                                label="Franchise Record"
                                options={franchiseData.franchises}
                                value={selectedData.franchises}
                                onChange={AutoFranchiseChange}
                            />
                        </Grid>
                    ) : null}

                    <Grid item xs={12}>
                        {state.userType == 'customer' ? (
                            <div>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<PrimaryCheckbox onChange={handleCheckbox} checked={state.allcust} name="allcust" value={state.allcust} />}
                                        label="All Customer"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {!state.allcust ? (
                                        <MultiselectWithoutCheckbox
                                            label="Customer"
                                            options={customerData.customers}
                                            value={selectedData.customers}
                                            onChange={AutoCustChange}
                                        />
                                    ) : null}
                                </Grid>
                            </div>
                        ) : null}
                        {(state.userType == 'customer' || state.userType == 'internal' || state.userType == 'technician') && (
                            <Grid item xs={12}>
                                <Grid item xs={12} sm={6} md={6} lg={3} container>
                                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                                        <FormLabel shrink>Select Dashboard</FormLabel>
                                    </Grid>
                                    <SelectBox
                                        onChange={fieldChange}
                                        value={state.defaultInternalDashboard}
                                        name="defaultInternalDashboard"
                                        List={state.DashboardList || []}
                                    />
                                </Grid>
                                {/* <Multiselect label="Modules" options={moduleData.modules} value={selectedData.modules} onChange={AutoModuleChange} /> */}
                                <Grid container spacing={1} style={{ border: '1px solid #8080806e', marginTop: 8, padding: 3, marginBottom: 4 }}>
                                    <div>
                                        <UserModules
                                            franchiseData={franchiseData}
                                            AutoFranchiseChange={AutoFranchiseChange}
                                            selectedData={selectedData}
                                            handleClick={handleClick}
                                            state={state}
                                            handleCheckbox={handleCheckbox}
                                            portalSettings={portalSettings}
                                            masterData={masterData}
                                            AutoWorkChange={AutoWorkChange}
                                            fieldChange={fieldChange}
                                            custmerGroup={custmerGroup}
                                            process={selectedData.processes}
                                            AutoPrChange={AutoPrChange}
                                            processData={processData}
                                            appraisalVehicle={selectedData.appraisalVehicles}
                                            AutoAppraisalVehiclechange={AutoAppraisalVehiclechange}
                                            appraisalVehicleOption={appraisalVehicleOption}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            control={<SecondaryCheckbox checked={state.isActive} onChange={handleCheckbox} name="isActive" />}
                            label="Is Active?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            control={<SecondaryCheckbox checked={state.isLocked} onChange={handleCheckbox} name="isLocked" />}
                            label="Is Locked?"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            control={<SecondaryCheckbox checked={state.tempTwoFactorEnabled} onChange={handleCheckbox} name="tempTwoFactorEnabled" />}
                            label="Reset 2FA"
                        />
                    </Grid>
                </Grid>
            </DialogContents>
            <DialogsActions className="dialogActions">
                {!user ? (
                    <SecondaryButton className="Submitbtn" onClick={handleSubmit} disabled={!state.enableChange}>
                        Submit
                    </SecondaryButton>
                ) : (
                    <SecondaryButton className="Submitbtn" onClick={handleSubmit} disabled={!state.enableChange}>
                        Update
                    </SecondaryButton>
                )}
            </DialogsActions>
        </Dialogs>
    );
};

export default AddUserModal;
