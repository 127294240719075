import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SecondaryButton, SecondaryCheckbox, TextBox } from '../../../Core/FormInput';
import { Button, ButtonGroup, Checkbox, CircularProgress, FormControlLabel, FormHelperText, Grid, Hidden, IconButton, InputLabel } from '@material-ui/core';
import { FieldWrappper } from '../../../Core/FieldWrapper';

import { CustomeSignPad } from '../../../Core/SignaturePad';
import {
    downldWheelRemovalGetDetails,
    postRenaultWheelRemovalADDUpdate,
    renaultWheelRemovalGetDetails
} from '../../../Core/Service/wheelServices/wheel-services';
import AppContext from '../../../App/AppContext';
import BorderColor from '@material-ui/icons/BorderColor';
import { AppStorage } from '../../../Core/Service/storage-service';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles({
    table: {
        minWidth: 450
        // border: '1px solid rgba(0,0,0,0.3) '
    },
    btnCustomCSS: {
        border: '1px solid lightgrey',
        background: 'white',
        borderRadius: 0,
        padding: 5
        // '& :hover': {
        //     padding: 1
        // }
    },
    IconCss: {
        fontSize: 30
    }
});
const StyledTableCell = withStyles((theme) => ({
    head: {
        // backgroundColor: 'rgba(0,0,0,0.01)',
        // color: theme.palette.common.white,
        // border: 'none',
        // borderLeft: '1px solid rgba(0,0,0,0.3 )',
        // borderRight: '1px solid rgba(0,0,0,0.3)',
        border: '1px solid #f5f5f5',
        padding: 5,
        backgroundColor: 'grey',
        color: 'white'
    },
    body: {
        fontSize: 14,
        border: 'none',
        // borderLeft: '1px dashed rgba(0,0,0,0.3)',
        borderBottom: '1px dashed rgba(0,0,0,0.3)',
        // borderRight: '1px dashed rgba(0,0,0,0.3)',
        padding: '8px'
    }
}))(TableCell);
const StyledTableCellFullWid = withStyles((theme) => ({
    head: {
        borderBottom: '1px solid #f5f5f5',
        // border: '1px solid rgba(0,0,0,0.03)',
        padding: 1,
        color: 'red'
        // backgroundColor: 'grey'
    }
}))(TableCell);
const StyledTableCellEmpty = withStyles((theme) => ({
    head: {
        border: '1px solid #f5f5f5',
        padding: 5,
        color: 'red',
        backgroundColor: 'grey'
    },
    body: {
        fontSize: 14,
        fontWeight: 600,
        border: 'none',
        borderBottom: '1px dashed rgba(0,0,0,0.3)',
        borderRight: '1px dashed rgba(0,0,0,0.3)',
        // borderRight: '1px dashed rgba(0,0,0,0.3)',
        padding: '8px'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        // backgroundColor: 'white'
        // border: 'none'
        '&:nth-of-type(odd)': {
            backgroundColor: '#F6FDFE'
        },
        '&:nth-of-type(even)': {
            backgroundColor: '#FEF7F6'
        }
    }
}))(TableRow);

export default function WheelRemoval({ DefaultName, urlParams, fromStep, navigateToDashboard, headerId }) {
    // const DefaultName = AppStorage.getName();
    const { hideModal, hideTopNavAndMenu, showToast, portalSettings } = useContext(AppContext);

    const classes = useStyles();

    const [state, setState] = useState({
        headerRecordID: '',
        id: '',
        vehicleTrailerIDNumber: '',
        steerNSAxle1: '',
        steerOSAxle1: '',
        steerOSAxle2: '',
        steerNSAxle2: '',
        driverNSOuterAxle1: '',
        driverNSInnerAxle1: '',
        driverNSOuterAxle2: '',
        driverNSInnerAxle2: '',
        driverOSOuterAxle1: '',
        driverOSInnerAxle1: '',
        driverOSOuterAxle2: '',
        driverOSInnerAxle2: '',
        trailerNSInnerAxle1: '',
        trailerNSOuterAxle1: '',
        trailerOSInnerAxle1: '',
        trailerOSOuterAxle1: '',
        trailerNSInnerAxle2: '',
        trailerNSOuterAxle2: '',
        trailerOSInnerAxle2: '',
        trailerOSOuterAxle2: '',
        trailerNSInnerAxle3: '',
        trailerNSOuterAxle3: '',
        trailerOSInnerAxle3: '',
        trailerOSOuterAxle3: '',
        serialNumber: '',
        createdBy: '',
        signature: '',
        storedSignature: '',
        regNo: '',
        errors: {},
        showDwnldLoader: false
    });

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    useEffect(() => {
        async function pullData() {
            let res = await renaultWheelRemovalGetDetails(headerId);
            if (res.success) {
                let data = res.data.renaultWheelRemoval[0] || {};
                setState((st) => ({
                    ...st,
                    headerRecordID: headerId,
                    regNo: data.regNo || '',
                    id: data?.id || null,
                    vehicleTrailerIDNumber: data?.vehicleTrailerIDNumber || '',
                    steerNSAxle1: data?.steerNSAxle1 || 0,
                    steerOSAxle1: data?.steerOSAxle1 || 0,
                    steerOSAxle2: data?.steerOSAxle2 || 0,
                    steerNSAxle2: data?.steerNSAxle2 || 0,
                    driverNSOuterAxle1: data?.driverNSOuterAxle1 || 0,
                    driverNSInnerAxle1: data?.driverNSInnerAxle1 || 0,
                    driverNSOuterAxle2: data?.driverNSOuterAxle2 || 0,
                    driverNSInnerAxle2: data?.driverNSInnerAxle2 || 0,
                    driverOSOuterAxle1: data?.driverOSOuterAxle1 || 0,
                    driverOSInnerAxle1: data?.driverOSInnerAxle1 || 0,
                    driverOSOuterAxle2: data?.driverOSOuterAxle2 || 0,
                    driverOSInnerAxle2: data?.driverOSInnerAxle2 || 0,
                    trailerNSInnerAxle1: data?.trailerNSInnerAxle1 || 0,
                    trailerNSOuterAxle1: data?.trailerNSOuterAxle1 || 0,
                    trailerOSInnerAxle1: data?.trailerOSInnerAxle1 || 0,
                    trailerOSOuterAxle1: data?.trailerOSOuterAxle1 || 0,
                    trailerNSInnerAxle2: data?.trailerNSInnerAxle2 || 0,
                    trailerNSOuterAxle2: data?.trailerNSOuterAxle2 || 0,
                    trailerOSInnerAxle2: data?.trailerOSInnerAxle2 || 0,
                    trailerOSOuterAxle2: data?.trailerOSOuterAxle2 || 0,
                    trailerNSInnerAxle3: data?.trailerNSInnerAxle3 || 0,
                    trailerNSOuterAxle3: data?.trailerNSOuterAxle3 || 0,
                    trailerOSInnerAxle3: data?.trailerOSInnerAxle3 || 0,
                    trailerOSOuterAxle3: data?.trailerOSOuterAxle3 || 0,
                    serialNumber: data?.serialNumber || '',
                    createdBy: data?.createdBy || '',
                    signature: data?.signature || '',
                    storedSignature: data?.signature || ''
                }));
            }
        }
        pullData();
    }, [headerId]);

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return { ...st, [name]: value };
        });
    };
    const handleFieldS = (name, value) => {
        console.log(name, value, 'name,value');
        setState((st) => {
            return { ...st, [name]: value };
        });
    };

    const validations = () => {
        const { createdBy, signature } = state;
        let formIsValid = true;
        let errors = {};

        if (!createdBy) {
            errors.createdBy = 'This field is required';
            formIsValid = false;
        }
        if (!signature) {
            errors.signature = 'This field is required';
            formIsValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async (e) => {
        if (validations()) {
            let data = {
                headerRecordID: headerId,
                id: state.id,
                vehicleTrailerIDNumber: state.vehicleTrailerIDNumber,
                steerNSAxle1: state.steerNSAxle1,
                steerOSAxle1: state.steerOSAxle1,
                steerOSAxle2: state.steerOSAxle2,
                steerNSAxle2: state.steerNSAxle2,
                driverNSOuterAxle1: state.driverNSOuterAxle1,
                driverNSInnerAxle1: state.driverNSInnerAxle1,
                driverNSOuterAxle2: state.driverNSOuterAxle2,
                driverNSInnerAxle2: state.driverNSInnerAxle2,
                driverOSOuterAxle1: state.driverOSOuterAxle1,
                driverOSInnerAxle1: state.driverOSInnerAxle1,
                driverOSOuterAxle2: state.driverOSOuterAxle2,
                driverOSInnerAxle2: state.driverOSInnerAxle2,
                trailerNSInnerAxle1: state.trailerNSInnerAxle1,
                trailerNSOuterAxle1: state.trailerNSOuterAxle1,
                trailerOSInnerAxle1: state.trailerOSInnerAxle1,
                trailerOSOuterAxle1: state.trailerOSOuterAxle1,
                trailerNSInnerAxle2: state.trailerNSInnerAxle2,
                trailerNSOuterAxle2: state.trailerNSOuterAxle2,
                trailerOSInnerAxle2: state.trailerOSInnerAxle2,
                trailerOSOuterAxle2: state.trailerOSOuterAxle2,
                trailerNSInnerAxle3: state.trailerNSInnerAxle3,
                trailerNSOuterAxle3: state.trailerNSOuterAxle3,
                trailerOSInnerAxle3: state.trailerOSInnerAxle3,
                trailerOSOuterAxle3: state.trailerOSOuterAxle3,
                serialNumber: state.serialNumber,
                createdBy: state.createdBy,
                signature: state.signature
            };

            let res = await postRenaultWheelRemovalADDUpdate(data);
            if (!res.success) {
                console.error(res);
            } else {
                hideModal();
            }
            showToast(res?.data?.message);
            if (res.success && fromStep) {
                window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
            }
        }
    };

    const GetDriverSign = (val) => {
        setState((st) => ({
            ...st,
            ...val
        }));
    };
    const clearSig = (val) => {
        setState((st) => ({
            ...st,
            storedSignature: null,
            signature: null
        }));
    };

    const setDefaultName = () => {
        setState((st) => {
            let newSt = { ...st };
            newSt.createdBy = DefaultName;
            return newSt;
        });
    };

    const CustomWheelRBtn = ({ value, name }) => {
        return (
            <div style={{ margin: 10 }}>
                {value !== 1 ? (
                    <IconButton onClick={() => handleFieldS(name, 1)} className={classes.btnCustomCSS}>
                        <CloseIcon style={{ color: 'red' }} className={classes.IconCss} />
                    </IconButton>
                ) : (
                    <IconButton onClick={() => handleFieldS(name, 0)} className={classes.btnCustomCSS}>
                        <DoneIcon style={{ color: 'green' }} className={classes.IconCss} />
                    </IconButton>
                )}
            </div>
        );
    };

    const dwnldPdf = async () => {
        setState((st) => ({ ...st, showDwnldLoader: true }));
        let res = await downldWheelRemovalGetDetails(headerId, state.regNo);
        setState((st) => ({ ...st, showDwnldLoader: false }));
    };

    return (
        <div style={{ padding: 10, position: 'relative' }}>
            {/* {state.signature && (
                <div style={{ position: 'absolute', top: -60, right: 20 }}>
                    {state.showDwnldLoader ? (
                        <CircularProgress size={20} />
                    ) : (
                        <CloudDownloadIcon color="primary" style={{ fontSize: 30, cursor: 'pointer' }} onClick={dwnldPdf} />
                    )}
                </div>
            )} */}
            <Grid container justifyContent="center" alignItems="center">
                <Grid xs={12} sm={10}>
                    <Hidden only={['xs', 'sm', 'md']}>
                        <Grid container spacing={2} justifyContent="center" style={{ marginTop: 30 }}>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <FieldWrappper heading="" isNewCard={true}>
                                            <TableContainer style={{ minHeight: 200 }}>
                                                <Table className={classes.table} aria-label="simple table" stickyHeader>
                                                    <TableHead>
                                                        <StyledTableRow>
                                                            <StyledTableCellFullWid colSpan={4} align="center">
                                                                <small>The Wheel Positions indicate below have been work upon </small>
                                                            </StyledTableCellFullWid>
                                                        </StyledTableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        <StyledTableRow key={'row.name'}>
                                                            <StyledTableCellEmpty width="20%" component="th" scope="row" align="center">
                                                                STEER AXLES
                                                            </StyledTableCellEmpty>
                                                            <StyledTableCell width="35%" align="inherit">
                                                                <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-end">
                                                                    <Grid item xs={5}>
                                                                        <FormHelperText>Nearside</FormHelperText>

                                                                        <CustomWheelRBtn type="number" value={state.steerNSAxle1} name="steerNSAxle1" />
                                                                        <CustomWheelRBtn type="number" value={state.steerNSAxle2} name="steerNSAxle2" />
                                                                    </Grid>
                                                                </Grid>
                                                            </StyledTableCell>
                                                            <StyledTableCell width="10%" align="inherit"></StyledTableCell>
                                                            <StyledTableCell width="35%" align="inherit">
                                                                <Grid container spacing={2} justifyContent="flex-end" alignItems="flex-end">
                                                                    <Grid item xs={5}>
                                                                        <FormHelperText>Offside</FormHelperText>
                                                                        <CustomWheelRBtn type="number" value={state.steerOSAxle1} name="steerOSAxle1" />
                                                                        <CustomWheelRBtn type="number" value={state.steerOSAxle2} name="steerOSAxle2" />
                                                                    </Grid>
                                                                </Grid>
                                                            </StyledTableCell>
                                                        </StyledTableRow>

                                                        <StyledTableRow>
                                                            <StyledTableCellEmpty width="20%" component="th" scope="row" align="center">
                                                                DRIVER TAG AXLES
                                                            </StyledTableCellEmpty>
                                                            <StyledTableCell width="35%">
                                                                <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
                                                                    <Grid item xs={5}>
                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.driverNSOuterAxle1}
                                                                            name="driverNSOuterAxle1"
                                                                        />

                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.driverNSOuterAxle2}
                                                                            name="driverNSOuterAxle2"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.driverNSInnerAxle1}
                                                                            name="driverNSInnerAxle1"
                                                                        />

                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.driverNSInnerAxle2}
                                                                            name="driverNSInnerAxle2"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </StyledTableCell>
                                                            <StyledTableCell width="10%"></StyledTableCell>
                                                            <StyledTableCell width="35%">
                                                                <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
                                                                    <Grid item xs={5}>
                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.driverOSInnerAxle1}
                                                                            name="driverOSInnerAxle1"
                                                                        />

                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.driverOSInnerAxle2}
                                                                            name="driverOSInnerAxle2"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.driverOSOuterAxle1}
                                                                            name="driverOSOuterAxle1"
                                                                        />

                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.driverOSOuterAxle2}
                                                                            name="driverOSOuterAxle2"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                        <StyledTableRow>
                                                            <StyledTableCellEmpty width="20%" component="th" scope="row" align="center">
                                                                TRAILER AXLES
                                                            </StyledTableCellEmpty>
                                                            <StyledTableCell width="35%">
                                                                <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
                                                                    <Grid item xs={5}>
                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.trailerNSOuterAxle1}
                                                                            name="trailerNSOuterAxle1"
                                                                        />

                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.trailerNSOuterAxle2}
                                                                            name="trailerNSOuterAxle2"
                                                                        />

                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.trailerNSOuterAxle3}
                                                                            name="trailerNSOuterAxle3"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.trailerNSInnerAxle1}
                                                                            name="trailerNSInnerAxle1"
                                                                        />

                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.trailerNSInnerAxle2}
                                                                            name="trailerNSInnerAxle2"
                                                                        />

                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.trailerNSInnerAxle3}
                                                                            name="trailerNSInnerAxle3"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </StyledTableCell>
                                                            <StyledTableCell width="10%"></StyledTableCell>
                                                            <StyledTableCell width="35%">
                                                                <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
                                                                    <Grid item xs={5}>
                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.trailerOSInnerAxle1}
                                                                            name="trailerOSInnerAxle1"
                                                                        />

                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.trailerOSInnerAxle2}
                                                                            name="trailerOSInnerAxle2"
                                                                        />

                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.trailerOSInnerAxle3}
                                                                            name="trailerOSInnerAxle3"
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.trailerOSOuterAxle1}
                                                                            name="trailerOSOuterAxle1"
                                                                        />

                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.trailerOSOuterAxle2}
                                                                            name="trailerOSOuterAxle2"
                                                                        />

                                                                        <CustomWheelRBtn
                                                                            type="number"
                                                                            value={state.trailerOSOuterAxle3}
                                                                            name="trailerOSOuterAxle3"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </FieldWrappper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} container spacing={1} alignContent="space-between">
                                {/* <Grid container spacing={1} alignContent="space-between"> */}
                                <Grid item xs={12}>
                                    <FieldWrappper heading="" isNewCard={true}>
                                        <Grid container spacing={5}>
                                            <Grid item xs={12}>
                                                <TableContainer>
                                                    <Table className={classes.table} aria-label="simple table" stickyHeader>
                                                        <TableHead>
                                                            <StyledTableRow>
                                                                <StyledTableCellFullWid colSpan={4} align="center">
                                                                    <small>Vehicle Details </small>
                                                                </StyledTableCellFullWid>
                                                            </StyledTableRow>
                                                        </TableHead>
                                                    </Table>
                                                </TableContainer>
                                                {/* <div className="labelTextReq" style={{ background: '#f5f5f5', height: 10 }}>
                                                    {' '}
                                                </div> */}
                                                {/* <TextBox value={state.regNo} disabled name="regNo" onChange={handleFieldChange} /> */}
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4}>
                                                <div className="labelTextReq">Vehicle Reg No. </div>
                                                <TextBox value={state.regNo} disabled name="regNo" onChange={handleFieldChange} />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4}>
                                                <div className="labelTextReq">Trailer I.D. N.o </div>
                                                <TextBox value={state.vehicleTrailerIDNumber} name="vehicleTrailerIDNumber" onChange={handleFieldChange} />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4}>
                                                <div className="labelTextReq">RE-Torque Serial No </div>
                                                <TextBox value={state.serialNumber} name="serialNumber" onChange={handleFieldChange} />
                                            </Grid>
                                        </Grid>
                                    </FieldWrappper>
                                </Grid>
                                <Grid item xs={12}>
                                    <FieldWrappper heading="" isNewCard={true}>
                                        <Grid container spacing={4}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <InputLabel error>
                                                    Print Name*{' '}
                                                    {!state.createdBy && (
                                                        <BorderColor
                                                            onClick={setDefaultName}
                                                            toolTipTitle="Use User Name"
                                                            fontSize="small"
                                                            style={{ marginBottom: -4, cursor: 'pointer' }}
                                                        />
                                                    )}
                                                </InputLabel>
                                                <TextBox value={state.createdBy} name="createdBy" onChange={handleFieldChange} />
                                                <span className="mandatoryfields">{state.errors['createdBy']}</span>
                                            </Grid>
                                            <Grid item xs={12} md={12} lg={12} style={{ width: 'calc(100vw)', height: 'auto', marginBottom: '5px' }}>
                                                <CustomeSignPad
                                                    onChange={GetDriverSign}
                                                    content={<InputLabel error>Driver Signature*</InputLabel>}
                                                    sign={state.storedSignature}
                                                    name="signature"
                                                    clearSig={clearSig}
                                                />
                                                <span className="mandatoryfields">{state.errors['signature']}</span>
                                            </Grid>
                                        </Grid>
                                    </FieldWrappper>
                                </Grid>
                                {/* </Grid> */}
                            </Grid>

                            <Grid item xs={12}>
                                <SecondaryButton className="Submitbtn" onClick={handleSubmit}>
                                    Submit
                                </SecondaryButton>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden only={['lg', 'xl']}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12}>
                                <FieldWrappper heading="" isNewCard={true}>
                                    {/* <TableContainer style={{ minHeight: 200 }}>
                                        <Table className={classes.table} aria-label="simple table" stickyHeader>
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCellFullWid colSpan={4} align="center">
                                                        <small>The Wheel Positions indicate below have been work upon </small>
                                                    </StyledTableCellFullWid>
                                                </StyledTableRow>
                                            </TableHead>

                                            <TableBody>
                                                <StyledTableRow key={'row.name'}>
                                                    <StyledTableCellEmpty width="20%" component="th" scope="row" align="center">
                                                        STEER AXLES
                                                    </StyledTableCellEmpty>
                                                    <StyledTableCell width="35%" align="inherit">
                                                        <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-end">
                                                            <Grid item xs={5}>
                                                                <FormHelperText>Nearside</FormHelperText>
                                                                <TextBox
                                                                    type="number"
                                                                    value={state.steerNSAxle1}
                                                                    name="steerNSAxle1"
                                                                    onChange={handleFieldChange}
                                                                />

                                                                <TextBox
                                                                    type="number"
                                                                    value={state.steerNSAxle2}
                                                                    name="steerNSAxle2"
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell width="10%" align="inherit"></StyledTableCell>
                                                    <StyledTableCell width="35%" align="inherit">
                                                        <Grid container spacing={2} justifyContent="flex-end" alignItems="flex-end">
                                                            <Grid item xs={5}>
                                                                <FormHelperText style={{ float: 'right' }}>Offside</FormHelperText>
                                                                <TextBox
                                                                    type="number"
                                                                    value={state.steerOSAxle1}
                                                                    name="steerOSAxle1"
                                                                    onChange={handleFieldChange}
                                                                />
                                                                <TextBox
                                                                    type="number"
                                                                    value={state.steerOSAxle2}
                                                                    name="steerOSAxle2"
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                </StyledTableRow>

                                                <StyledTableRow>
                                                    <StyledTableCellEmpty width="20%" component="th" scope="row" align="center">
                                                        DRIVER TAG AXLES
                                                    </StyledTableCellEmpty>
                                                    <StyledTableCell width="35%">
                                                        <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
                                                            <Grid item xs={5}>
                                                                <TextBox
                                                                    type="number"
                                                                    value={state.driverNSOuterAxle1}
                                                                    name="driverNSOuterAxle1"
                                                                    onChange={handleFieldChange}
                                                                />

                                                                <TextBox
                                                                    type="number"
                                                                    value={state.driverNSOuterAxle2}
                                                                    name="driverNSOuterAxle2"
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <TextBox
                                                                    type="number"
                                                                    value={state.driverNSInnerAxle1}
                                                                    name="driverNSInnerAxle1"
                                                                    onChange={handleFieldChange}
                                                                />

                                                                <TextBox
                                                                    type="number"
                                                                    value={state.driverNSInnerAxle2}
                                                                    name="driverNSInnerAxle2"
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell width="10%"></StyledTableCell>
                                                    <StyledTableCell width="35%">
                                                        <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
                                                            <Grid item xs={5}>
                                                                <TextBox
                                                                    type="number"
                                                                    value={state.driverOSInnerAxle1}
                                                                    name="driverOSInnerAxle1"
                                                                    onChange={handleFieldChange}
                                                                />

                                                                <TextBox
                                                                    type="number"
                                                                    value={state.driverOSInnerAxle2}
                                                                    name="driverOSInnerAxle2"
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <TextBox
                                                                    type="number"
                                                                    value={state.driverOSOuterAxle1}
                                                                    name="driverOSOuterAxle1"
                                                                    onChange={handleFieldChange}
                                                                />

                                                                <TextBox
                                                                    type="number"
                                                                    value={state.driverOSOuterAxle2}
                                                                    name="driverOSOuterAxle2"
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                                <StyledTableRow>
                                                    <StyledTableCellEmpty width="20%" component="th" scope="row" align="center">
                                                        TRAILER AXLES
                                                    </StyledTableCellEmpty>
                                                    <StyledTableCell width="35%">
                                                        <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
                                                            <Grid item xs={5}>
                                                                <TextBox
                                                                    type="number"
                                                                    value={state.trailerNSOuterAxle1}
                                                                    name="trailerNSOuterAxle1"
                                                                    onChange={handleFieldChange}
                                                                />

                                                                <TextBox
                                                                    type="number"
                                                                    value={state.trailerNSOuterAxle2}
                                                                    name="trailerNSOuterAxle2"
                                                                    onChange={handleFieldChange}
                                                                />

                                                                <TextBox
                                                                    type="number"
                                                                    value={state.trailerNSOuterAxle3}
                                                                    name="trailerNSOuterAxle3"
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <TextBox
                                                                    type="number"
                                                                    value={state.trailerNSInnerAxle1}
                                                                    name="trailerNSInnerAxle1"
                                                                    onChange={handleFieldChange}
                                                                />

                                                                <TextBox
                                                                    type="number"
                                                                    value={state.trailerNSInnerAxle2}
                                                                    name="trailerNSInnerAxle2"
                                                                    onChange={handleFieldChange}
                                                                />

                                                                <TextBox
                                                                    type="number"
                                                                    value={state.trailerNSInnerAxle3}
                                                                    name="trailerNSInnerAxle3"
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell width="10%"></StyledTableCell>
                                                    <StyledTableCell width="35%">
                                                        <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
                                                            <Grid item xs={5}>
                                                                <TextBox
                                                                    type="number"
                                                                    value={state.trailerOSInnerAxle1}
                                                                    name="trailerOSInnerAxle1"
                                                                    onChange={handleFieldChange}
                                                                />

                                                                <TextBox
                                                                    type="number"
                                                                    value={state.trailerOSInnerAxle2}
                                                                    name="trailerOSInnerAxle2"
                                                                    onChange={handleFieldChange}
                                                                />

                                                                <TextBox
                                                                    type="number"
                                                                    value={state.trailerOSInnerAxle3}
                                                                    name="trailerOSInnerAxle3"
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <TextBox
                                                                    type="number"
                                                                    value={state.trailerOSOuterAxle1}
                                                                    name="trailerOSOuterAxle1"
                                                                    onChange={handleFieldChange}
                                                                />

                                                                <TextBox
                                                                    type="number"
                                                                    value={state.trailerOSOuterAxle2}
                                                                    name="trailerOSOuterAxle2"
                                                                    onChange={handleFieldChange}
                                                                />

                                                                <TextBox
                                                                    type="number"
                                                                    value={state.trailerOSOuterAxle3}
                                                                    name="trailerOSOuterAxle3"
                                                                    onChange={handleFieldChange}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer> */}
                                    <TableContainer style={{ minHeight: 200 }}>
                                        <Table className={classes.table} aria-label="simple table" stickyHeader>
                                            <TableHead>
                                                <StyledTableRow>
                                                    <StyledTableCellFullWid colSpan={4} align="center">
                                                        <small>The Wheel Positions indicate below have been work upon </small>
                                                    </StyledTableCellFullWid>
                                                </StyledTableRow>
                                            </TableHead>

                                            <TableBody>
                                                <StyledTableRow key={'row.name'}>
                                                    <StyledTableCellEmpty width="20%" component="th" scope="row" align="center">
                                                        STEER AXLES
                                                    </StyledTableCellEmpty>
                                                    <StyledTableCell width="35%" align="inherit">
                                                        <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-end">
                                                            <Grid item xs={5}>
                                                                <FormHelperText>Nearside</FormHelperText>

                                                                <CustomWheelRBtn type="number" value={state.steerNSAxle1} name="steerNSAxle1" />
                                                                <CustomWheelRBtn type="number" value={state.steerNSAxle2} name="steerNSAxle2" />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell width="10%" align="inherit"></StyledTableCell>
                                                    <StyledTableCell width="35%" align="inherit">
                                                        <Grid container spacing={2} justifyContent="flex-end" alignItems="flex-end">
                                                            <Grid item xs={5}>
                                                                <FormHelperText>Offside</FormHelperText>
                                                                <CustomWheelRBtn type="number" value={state.steerOSAxle1} name="steerOSAxle1" />
                                                                <CustomWheelRBtn type="number" value={state.steerOSAxle2} name="steerOSAxle2" />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                </StyledTableRow>

                                                <StyledTableRow>
                                                    <StyledTableCellEmpty width="20%" component="th" scope="row" align="center">
                                                        DRIVER TAG AXLES
                                                    </StyledTableCellEmpty>
                                                    <StyledTableCell width="35%">
                                                        <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
                                                            <Grid item xs={5}>
                                                                <CustomWheelRBtn type="number" value={state.driverNSOuterAxle1} name="driverNSOuterAxle1" />

                                                                <CustomWheelRBtn type="number" value={state.driverNSOuterAxle2} name="driverNSOuterAxle2" />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <CustomWheelRBtn type="number" value={state.driverNSInnerAxle1} name="driverNSInnerAxle1" />

                                                                <CustomWheelRBtn type="number" value={state.driverNSInnerAxle2} name="driverNSInnerAxle2" />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell width="10%"></StyledTableCell>
                                                    <StyledTableCell width="35%">
                                                        <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
                                                            <Grid item xs={5}>
                                                                <CustomWheelRBtn type="number" value={state.driverOSInnerAxle1} name="driverOSInnerAxle1" />

                                                                <CustomWheelRBtn type="number" value={state.driverOSInnerAxle2} name="driverOSInnerAxle2" />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <CustomWheelRBtn type="number" value={state.driverOSOuterAxle1} name="driverOSOuterAxle1" />

                                                                <CustomWheelRBtn type="number" value={state.driverOSOuterAxle2} name="driverOSOuterAxle2" />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                                <StyledTableRow>
                                                    <StyledTableCellEmpty width="20%" component="th" scope="row" align="center">
                                                        TRAILER AXLES
                                                    </StyledTableCellEmpty>
                                                    <StyledTableCell width="35%">
                                                        <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
                                                            <Grid item xs={5}>
                                                                <CustomWheelRBtn type="number" value={state.trailerNSOuterAxle1} name="trailerNSOuterAxle1" />

                                                                <CustomWheelRBtn type="number" value={state.trailerNSOuterAxle2} name="trailerNSOuterAxle2" />

                                                                <CustomWheelRBtn type="number" value={state.trailerNSOuterAxle3} name="trailerNSOuterAxle3" />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <CustomWheelRBtn type="number" value={state.trailerNSInnerAxle1} name="trailerNSInnerAxle1" />

                                                                <CustomWheelRBtn type="number" value={state.trailerNSInnerAxle2} name="trailerNSInnerAxle2" />

                                                                <CustomWheelRBtn type="number" value={state.trailerNSInnerAxle3} name="trailerNSInnerAxle3" />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                    <StyledTableCell width="10%"></StyledTableCell>
                                                    <StyledTableCell width="35%">
                                                        <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
                                                            <Grid item xs={5}>
                                                                <CustomWheelRBtn type="number" value={state.trailerOSInnerAxle1} name="trailerOSInnerAxle1" />

                                                                <CustomWheelRBtn type="number" value={state.trailerOSInnerAxle2} name="trailerOSInnerAxle2" />

                                                                <CustomWheelRBtn type="number" value={state.trailerOSInnerAxle3} name="trailerOSInnerAxle3" />
                                                            </Grid>
                                                            <Grid item xs={5}>
                                                                <CustomWheelRBtn type="number" value={state.trailerOSOuterAxle1} name="trailerOSOuterAxle1" />

                                                                <CustomWheelRBtn type="number" value={state.trailerOSOuterAxle2} name="trailerOSOuterAxle2" />

                                                                <CustomWheelRBtn type="number" value={state.trailerOSOuterAxle3} name="trailerOSOuterAxle3" />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </FieldWrappper>
                            </Grid>

                            <Grid item xs={12}>
                                <FieldWrappper heading="" isNewCard={true}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <div className="labelTextReq">Vehicle Reg No. </div>
                                            <TextBox value={state.regNo} disabled name="regNo" onChange={handleFieldChange} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <div className="labelTextReq">Trailer I.D. N.o </div>
                                            <TextBox value={state.vehicleTrailerIDNumber} name="vehicleTrailerIDNumber" onChange={handleFieldChange} />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <div className="labelTextReq">RE-Torque Serial No </div>
                                            <TextBox value={state.serialNumber} name="serialNumber" onChange={handleFieldChange} />
                                        </Grid>
                                    </Grid>
                                </FieldWrappper>
                            </Grid>
                            <Grid item xs={12}>
                                <FieldWrappper heading="" isNewCard={true}>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <InputLabel error>
                                                Print Name*{' '}
                                                {!state.createdBy && (
                                                    <BorderColor
                                                        onClick={setDefaultName}
                                                        toolTipTitle="Use User Name"
                                                        fontSize="small"
                                                        style={{ marginBottom: -4, cursor: 'pointer' }}
                                                    />
                                                )}
                                            </InputLabel>
                                            <TextBox value={state.createdBy} name="createdBy" onChange={handleFieldChange} />
                                            <span className="mandatoryfields">{state.errors['createdBy']}</span>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} style={{ width: 'calc(100vw)', height: '163px', marginBottom: '5px' }}>
                                            <CustomeSignPad
                                                onChange={GetDriverSign}
                                                content={<InputLabel error>Driver Signature*</InputLabel>}
                                                sign={state.storedSignature}
                                                name="signature"
                                                clearSig={clearSig}
                                            />
                                            <span className="mandatoryfields">{state.errors['signature']}</span>
                                        </Grid>
                                    </Grid>
                                </FieldWrappper>
                            </Grid>

                            <Grid item xs={12}>
                                <SecondaryButton className="Submitbtn" onClick={handleSubmit}>
                                    Submit
                                </SecondaryButton>
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
            </Grid>
        </div>
    );
}
