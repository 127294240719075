import { Grid } from '@material-ui/core';
import { CustomeSignPad } from '../../../../Core/SignaturePad';
import { AppStorage } from '../../../../Core/Service/storage-service';
import { SecondaryButton } from '../../../../Core/FormInput';
import { Alert } from '@material-ui/lab';
import { Info } from '@material-ui/icons';
import { useState } from 'react';

export default function PartRequesterSignature({ partSignObj, setPartList, partSignIndex, partList, message, onClose }) {
    const updateSignature = (propertyName) => (val) => {
        setPartList((st) => {
            const newSt = [...st];
            if (partSignIndex >= 0 && partSignIndex < newSt.length) {
                newSt[partSignIndex][propertyName] = val[propertyName];
            } else {
                console.error('Invalid partSignIndex:', partSignIndex);
            }
            return newSt;
        });
    };

    const [sign, setSign] = useState({
        requestorSignature: partSignObj.requestorSignature || '',
        approverSignature: partSignObj.approverSignature || '',
        issuerSignature: partSignObj.issuerSignature || ''
    });

    const clearSig = (name) => {
        setPartList((st) => {
            let newSt = [...st];
            newSt[partSignIndex][name] = null;
            return newSt;
        });
    };

    let signatureFlag =
        partSignObj.partReqStatus === 1 ||
        partSignObj.requestorSignature ||
        partSignObj.partReqStatus === 2 ||
        partSignObj.approverSignature ||
        partSignObj.issuerSignature;

    console.log(signatureFlag, 'signatureFlag');

    return (
        <div style={{ padding: 10 }}>
            {signatureFlag ? (
                <Grid container spacing={2}>
                    {message && (
                        <Grid item xs={12}>
                            <Alert severity="warning">{message}</Alert>
                        </Grid>
                    )}
                    {partSignObj.partReqStatus === 1 || partSignObj.requestorSignature ? (
                        <Grid item xs={12}>
                            <CustomeSignPad
                                onChange={updateSignature('requestorSignature')}
                                content={`Requestor Signature (${partSignObj.requestor || ''})`}
                                sign={sign.requestorSignature || ''}
                                name="requestorSignature"
                                clearSig={() => clearSig('requestorSignature')}
                            />
                        </Grid>
                    ) : null}

                    {partSignObj.partReqStatus === 2 || partSignObj.approverSignature ? (
                        <Grid item xs={12}>
                            <CustomeSignPad
                                onChange={updateSignature('approverSignature')}
                                content={`Authorised By (${partSignObj.approver || ''})`}
                                sign={sign.approverSignature || ''}
                                name="approverSignature"
                                clearSig={() => clearSig('approverSignature')}
                            />
                        </Grid>
                    ) : null}

                    {partSignObj.issuerSignature ? (
                        <Grid item xs={12}>
                            <CustomeSignPad
                                onChange={updateSignature('issuerSignature')}
                                content={`Issuer Signature (${partSignObj.issuer || ''})`}
                                sign={sign.issuerSignature || ''}
                                name="issuerSignature"
                                clearSig={() => clearSig('issuerSignature')}
                            />
                        </Grid>
                    ) : null}
                    <Grid item xs={12}>
                        <SecondaryButton fullWidth onClick={onClose}>
                            Save
                        </SecondaryButton>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity="info">No Signature Found</Alert>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}
