import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, Grid, IconButton, LinearProgress, Tooltip } from '@material-ui/core';
import { ArrowLeft, ArrowRight, Close, Image, Mail, Visibility } from '@material-ui/icons';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import { getWorkflowData } from '../../../../Core/Service/workflowData-service';
import './documentModal.scss';
import { getDocumentByName, getDocumentVideoByName, getOcrDocumentHistoryForJob } from '../../../../Core/Service/workflowJobDocument-service';
import doc from '../../../internal/workflow-job/doc.png';
import { SecondaryButton } from '../../../../Core/FormInput';
import moment from 'moment';
import DownloadDocModal from '../../../internal/workflow-job/more/DownloadDoc';
import { useWindowSize } from '../../../../Core/Controls/ScreenResolution';

const DocTableRow = ({ item, showDocument, mailDocument, downloadDocument, currentVisibleDocumentName, isDocumentLoading }) => {
    return (
        <tr className="row">
            <td>{item.ocrDocName}</td>
            <td className="time-type">{new Date(item.createdAt).toLocaleString()}</td>
            <td className="actions">
                <div className="icon-button-group">
                    {/* <Tooltip title="View"> */}
                    <IconButton
                        title={'View'}
                        color={currentVisibleDocumentName === item.docName ? 'primary' : 'default'}
                        onClick={() => showDocument(item)}
                        disabled={isDocumentLoading}
                    >
                        {isDocumentLoading && currentVisibleDocumentName === item.docName ? <CircularProgress size={20} /> : <Visibility />}
                    </IconButton>
                    {/* </Tooltip> */}
                    {/* <Tooltip title="Mail Document">
                        <IconButton color="secondary" onClick={() => mailDocument(item)}>
                            <Mail />
                        </IconButton>
                    </Tooltip> */}
                    {/* <Tooltip title="Download Document"> */}

                    <IconButton title={'Download Document'} color="secondary" onClick={() => downloadDocument(item)}>
                        <AssignmentReturnedIcon />
                    </IconButton>
                    {/* </Tooltip> */}
                </div>
            </td>
        </tr>
    );
};

const DocTable = ({ documents, showDocument, mailDocument, downloadDocument, currentVisibleDocumentName, isDocumentLoading }) => (
    <div className="body-table-container custom-scroll">
        <table className="all-documents-body">
            <thead>
                <tr className="header">
                    <th>Document</th>
                    <th className="time-type">Created At</th>
                    <th className="actions">Action</th>
                </tr>
            </thead>
            <tbody>
                {documents.length > 0 ? (
                    <>
                        {documents.map((document, index) => (
                            <DocTableRow
                                key={index}
                                item={document}
                                showDocument={showDocument}
                                mailDocument={mailDocument}
                                downloadDocument={downloadDocument}
                                currentVisibleDocumentName={currentVisibleDocumentName}
                                isDocumentLoading={isDocumentLoading}
                            />
                        ))}
                    </>
                ) : (
                    <tr>
                        <td colspan={'3'}>No Documents Available</td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
);

const DocumentListComponent = (props) => {
    const [widths] = useWindowSize();
    const tabView = widths < 1260 ? true : false;

    const [jobDetails, setJobDetails] = useState({ documents: [] });
    const [currentPageNo, setPageNo] = useState(0);

    const [screenState, setScreenState] = useState({
        allFilled: false,
        isReadonly: true,
        showNotesModal: false,
        showHistoryModal: false,
        showReturnReasonModal: false,
        showOcrDocHistory: false,
        documentRotateBy: 0,
        zoomFactor: 1,
        showMailModal: false,
        docInfoToMail: undefined,
        showAllDocModal: false,
        showWarning: false,
        showReturnReasonHistoryModal: false,
        showBarCode: false,
        showCustomerNotes: false,
        showHeaderDetails: false
    });
    const [state, setState] = useState({
        isSelectedFilePdf: false,
        imagesFromUrl: [],
        selectedDocHistory: [],
        currentVisibleDocName: null,
        loadingDoc: false,
        tabView: tabView
    });
    const docContainer = useRef(null);

    // const rotateDoc = (explicit = undefined) => {
    //     if (explicit === undefined) {
    //         setScreenState((st) => ({
    //             ...st,
    //             documentRotateBy: (st.documentRotateBy + 90) % 360
    //         }));
    //     } else {
    //         setScreenState((st) => ({
    //             ...st,
    //             documentRotateBy: explicit
    //         }));
    //     }
    // };

    const getDocumentHistory = (jobId, ocrDocId) => {
        return getOcrDocumentHistoryForJob(jobId, ocrDocId);
    };

    const pullDocumentVideoByName = (docName) => {
        return getDocumentVideoByName(docName);
    };

    const addDocumentImagesData = (ocrDocId, docName, imgBase64Src) => {
        setState((st) => {
            const imagesFromUrl = [...st.imagesFromUrl];
            imagesFromUrl.push(imgBase64Src);
            return {
                ...st,
                selectedOcrDocId: ocrDocId,
                selectedOcrDocName: docName,
                imagesFromUrl
            };
        });
    };

    const pullDocument_V1 = async (docId, name, pageNo = 1) => {
        return getDocumentByName(name, pageNo).then((res) => {
            addDocumentImagesData(docId, name, res.imgBase64Src);
            return res.hasMorePage && pullDocument_V1(docId, name, pageNo + 1);
        });
    };

    const loadDocument = async (docId, docName, fromSteps, OcrDoc, isVideo) => {
        if (isVideo) {
            setState((st) => ({ ...st, docHistoryLoading: true, loadingDoc: true }));
            getDocumentHistory(props.headerRecordID, docId).then((res) => {
                if (res.success) {
                    let rec = res.data;
                    setState((st) => ({ ...st, selectedOcrDocHistoryCount: rec.length, selectedDocHistory: rec, imagesFromUrl: [], videoFromUrl: null }));
                }
            });
            pullDocumentVideoByName(docName).then((res) => {
                let rec = res;
                const url = window.URL.createObjectURL(new Blob([rec.data]));
                setState((st) => ({
                    ...st,
                    loadingDoc: false,
                    isSelectedFilePdf: false,
                    selectedOcrDocName: docName,
                    docHistoryLoading: false,
                    videoFromUrl: url,
                    currentVisibleDocName: docName,
                    selectedOcrDocId: docId
                }));
            });
        } else {
            // !props.canUseNewStamp && setLoadedStamps([]);
            setState((st) => ({ ...st, docHistoryLoading: true }));
            // setPageNo(0);
            getDocumentHistory(props.headerRecordID, docId).then((res) => {
                if (res.success) {
                    let rec = res.data;
                    setState((st) => ({ ...st, selectedOcrDocHistoryCount: rec.length, selectedDocHistory: rec }));
                }
            });

            const isPdf = docName.toLowerCase().trim().endsWith('.pdf');
            setState((st) => ({
                ...st,
                loadingDoc: true,
                isSelectedFilePdf: isPdf,
                imagesFromUrl: [],
                videoFromUrl: null,
                currentVisibleDocName: docName,
                selectedOcrDocId: docId
            }));

            pullDocument_V1(docId, docName).finally(() => {
                setState((st) => ({ ...st, loadingDoc: false, docHistoryLoading: false }));
            });
        }
    };

    const downloadDocument = async (docId, docName, fromSteps = false, OcrDoc = false, isVideo = false) => {
        if (docName) {
            if (state.selectedOcrDocId !== docId || state.selectedOcrDocName !== docName) {
                loadDocument(docId, docName, fromSteps, OcrDoc, isVideo);
            }
        } else {
            setState((st) => ({ ...st, isSelectedFilePdf: false, imagesFromUrl: [] }));
        }
    };

    const showAlldocState = (item) => {
        setState((st) => ({ ...st, tabView: false }));
        console.log(item.ocrDocId, item.docName, item.IsVideo, 'showdoc, id, IsVideo');
        downloadDocument(item.ocrDocId, item.docName, item.IsVideo);
    };

    const displayDoc = () => {
        let content = null;
        const width = docContainer?.current?.clientWidth;
        const scaledWidth = parseInt(width) * screenState.zoomFactor;
        const styleObj = {};

        if (state.videoFromUrl) {
            if (screenState.documentRotateBy === 0 || screenState.documentRotateBy === 180) {
                styleObj.width = `calc(${scaledWidth}px - 6px)`;
            } else {
                styleObj.height = `calc(${scaledWidth}px - 6px)`;
            }
            content = (
                <video controls controlsList="nodownload" style={styleObj}>
                    <source src={state.videoFromUrl} type="video/mp4" />
                </video>
            );
        } else if (state.imagesFromUrl.length === 0) {
            content = <img src={doc} alt="No document selected" className="sample-doc" />;
        } else {
            const imgClassName = `shown-doc deg-${screenState.documentRotateBy}`;
            // if (props.canUseNewStamp) {
            content = <img alt="Document's" src={state.imagesFromUrl[currentPageNo]} className={imgClassName} />;
            // } else {
            //     if (screenState.documentRotateBy === 0 || screenState.documentRotateBy === 180) {
            //         styleObj.width = `calc(${scaledWidth}px - 6px)`;
            //     } else {
            //         styleObj.height = `calc(${scaledWidth}px - 6px)`;
            //     }
            //     content = state.imagesFromUrl.map((imgBase64, i) => <img key={i} alt="Document's" src={imgBase64} className={imgClassName} style={styleObj} />);
            // }
        }

        return content;
    };

    useEffect(() => {
        const fetchWorkflowData = async () => {
            const response = await getWorkflowData(props.headerRecordID);
            setJobDetails((st) => ({ ...st, documents: response.data.documents, jobBasicDetail: response.data.jobBasicDetail }));
            // setDocuments(response.data.documents);
        };

        fetchWorkflowData();
    }, [props.headerRecordID]);

    const showDownload = (item) => {
        setScreenState((st) => ({
            ...st,
            showDownloadModal: true,
            docInfoToMail: {
                ocrDocName: item.docName,
                ocrDocId: item.ocrDocId,
                // SendAllVersions: isMultiVersion,
                headerId: props.headerRecordID,
                docName: `${item.ocrDocName}`,
                createdAt: moment(item.createdAt).format('DD-MMM-YYYY HH:mm')
            }
        }));
    };

    const hideDownload = () => {
        setScreenState((st) => ({
            ...st,
            showDownloadModal: false,
            docInfoToMail: undefined
        }));
    };

    const hasPrev = (pageNo) => {
        return pageNo > 0;
    };

    const hasNext = (pageNo) => {
        let total = state.imagesFromUrl.length;
        return pageNo < total - 1;
    };

    const showStep = state.tabView || widths > 1260;
    const showDoc = !state.tabView || widths > 1260;

    // console.log(state.tabView, 'state.tabView');
    // console.log(showDoc, 'showDoc');

    return (
        <Grid container className="all-documents" alignItems="flex-start" justifyContent="center">
            {showStep && (
                <Grid item xs={12} lg={3} xl={2}>
                    <DocTable
                        documents={jobDetails.documents}
                        showDocument={showAlldocState}
                        // mailDocument={mailDocument}
                        downloadDocument={showDownload}
                        currentVisibleDocumentName={state.currentVisibleDocName}
                        isDocumentLoading={state.loadingDoc}
                    />
                </Grid>
            )}
            {showDoc && (
                <Grid item xs={12} lg={9} xl={10} className="right-doc-sec" style={{ borderLeft: '1px solid black', textAlign: 'center' }}>
                    {!showStep && (
                        <div className="document-close-action">
                            <Tooltip title="Close">
                                <IconButton
                                    component="span"
                                    onClick={() => {
                                        setState((st) => ({ ...st, tabView: true }));
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )}
                    {hasPrev(currentPageNo) && (
                        <div className="doc-page-move go-prev">
                            <IconButton component="span" color="default" onClick={() => setPageNo(currentPageNo - 1)}>
                                <ArrowLeft />
                            </IconButton>
                        </div>
                    )}
                    {hasNext(currentPageNo) && (
                        <div className="doc-page-move go-next">
                            <IconButton component="span" color="default" onClick={() => setPageNo(currentPageNo + 1)}>
                                <ArrowRight />
                            </IconButton>
                        </div>
                    )}

                    <div
                        className={`document-viewer custom-scroll${state.isSelectedFilePdf && state.imagesFromUrl.length ? ' multi-image-from-pdf' : ''}`}
                        style={{ maxHeight: `calc(100vh - 210px)`, minHeight: '300px', marginLeft: '5px' }}
                        ref={docContainer}
                    >
                        {state.loadingDoc ? <LinearProgress /> : null}
                        {displayDoc()}
                    </div>
                    <div className="actionButton-Container" style={{ marginBottom: 5, marginLeft: '5px' }}>
                        <Grid container spacing={1}>
                            {state.docHistoryLoading ? (
                                <>Loading...</>
                            ) : (
                                state.selectedDocHistory.map((m) => {
                                    return (
                                        <Grid item>
                                            <SecondaryButton
                                                className="historydoclist"
                                                title={moment(m.createdAt).format('LLL')}
                                                variant={m.docName == state.selectedOcrDocName ? 'contained' : 'outlined'}
                                                onClick={() => downloadDocument(state.selectedOcrDocId, m.docName, false, false, m.isVideo)}
                                            >
                                                <Image />
                                            </SecondaryButton>
                                        </Grid>
                                    );
                                })
                            )}
                        </Grid>
                    </div>
                </Grid>
            )}
            {screenState.showDownloadModal && (
                <DownloadDocModal
                    jobId={props.headerRecordID}
                    onClose={hideDownload}
                    docInfoToMail={screenState.docInfoToMail}
                    jobBasicDetail={jobDetails.jobBasicDetail}
                />
            )}
        </Grid>
    );
};

export default DocumentListComponent;
