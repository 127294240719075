import React, { useContext, useState, useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AppContext from '../../../App/AppContext';
import Typography from '@material-ui/core/Typography';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import { TextBox, SecondaryButton, SecondaryCheckbox, DialogContents, CustomButton, Multiselect, PrimaryButton, SingelSelect } from '../../../Core/FormInput';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import { getFailureCodes } from '../../../Core/Service/failureCode-service';
import { getWorkAccomplishCodes } from '../../../Core/Service/workaccomplished-service';
import {
    getTechWriteUp,
    AddTechWriteUp,
    getTechWriteUpPdfFile,
    getRenault_TechnicianWriteUp_ScreenDropdown,
    getTechWriteUpPdfWithFmFile,
    TechnicianWriteUp_Delete,
    getSTDWriteUpPdfFile
} from '../../../Core/Service/techWriteUp-service';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import {
    Badge,
    ButtonGroup,
    FormHelperText,
    IconButton,
    InputAdornment,
    Paper,
    Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import DisplayGrid from './../../technician/technician-app/DisplayGrid';
import Tooltip from '@material-ui/core/Tooltip';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import '../../technician/technician-app/TechnicianStyle.scss';
import CircularProgress from '@material-ui/core/CircularProgress';
import formatters from '../../../Core/Grid/inputFormatter';
import ConfirmationModal from '../../../Core/Controls/Dialog/ConfirmationModal';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import _ from 'lodash';
import { AppStorage } from '../../../Core/Service/storage-service';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import AssociatedMaterial from '../../technician/technician-app/FaultandAssociate/assciateMaterial';
import FaultCode from '../../technician/technician-app/FaultandAssociate/faultCode';
import { getPartListByPartNumber } from '../../../Core/Service/partRequisitions-service';
import { CustomIconButton } from '../../../Core/FormInput/AppButton';
import SearchIcon from '@material-ui/icons/Search';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { getPartReqRenaultStatus } from '../../../Core/Service/PartReq_services/partReq-service';
import { Alert } from '@material-ui/lab';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AssignTech from '../assign-technician/assignTechnModal';

const SimpleCell = withStyles((theme) => ({
    head: {
        fontSize: 15,
        fontWeight: 600,
        backgroundColor: '#bdbdbd82',
        border: '1px solid black'
    },
    body: {
        fontSize: 12,
        border: '1px solid black'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: theme.palette.action.hover
        // }
    }
}))(TableRow);

const TechWrite_Up = (props) => {
    const { hideModal, showToast, hideTopNavAndMenu, portalSettings, userRoles } = useContext(AppContext);
    const [ScreenWidth, screenHeight] = useWindowSize();
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    let IsUpperCase = portalSettings?.portalSettingWriteupsInUpperCase;

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    const [masterData, setMasterData] = useState({
        failureCodes: [],
        workaccomplishcodes: []
    });

    const [screenDropdown, setScreenDropdown] = useState({
        claimTypes: [],
        defectCodeList: [],
        partReqStatus: []
    });

    const [selectedData, setSelectedData] = useState({
        selectedFailureCodes: [],
        selectedWorkAccomplishCodes: []
    });
    const signCanvas = useRef({});
    const signteamleaderCanvas = useRef({});

    const clearLeaderSign = () => {
        if (state.imgteamleadersign) {
            setState((st) => ({
                ...st,
                imgteamleadersign: null,
                teamleadersign: null
            }));
        } else {
            signteamleaderCanvas.current.clear();
        }
    };

    const [isDeleteTechWriteUp, setIsDeleteTechWriteUp] = useState({
        show: false,
        selectedData: {}
    });

    const [state, setState] = useState({
        status: null,
        cause: '',
        cure: '',
        complaint: '',
        readingtaken: '',
        failurereason: [],
        timeout: '',
        date: new Date(),
        timein: '',
        mileageout: '',
        mileagein: '',
        workaccomplished: [],
        hide: null,
        hide1: null,
        techniciansign: null,
        imgtechniciansign: null,
        imgteamleadersign: null,
        teamleadersign: null,
        smoketest: false,
        serviceindicator: false,
        radiocode: false,
        wipNumber: '',
        timeclock: false,
        showWarning: false,
        registration: '',
        id: null,
        showCircularLoader: true,
        fields: {},
        errors: {},
        technicians: [],
        showGrid: false,
        GridID: null,
        odometerUnitsVM: [],
        technicianNotesReadingUnit: null,
        technicianNotesJobID: null,
        newJOBId: null,
        technicianName: '',
        technicianDateSigned: '',
        teamLeaderName: '',
        teamLeaderDateSigned: '',
        signedOff: true,
        makeDisbaled: false,
        showFault: false,
        showAssociated: false,
        modalIndex: 0,
        showTechAssign: false
    });

    const GlobalCss = withStyles({
        '@global': {
            body: {
                fontSize: `${state.fontResize ? state.fontResize + 'px' : '0.875rem'}`
            },
            '.MuiFormLabel-root, .MuiTypography-body1, .MuiButton-root': {
                fontSize: `${state.fontResize ? state.fontResize + 'px' : '0.875rem'}`
            },
            '.MuiOutlinedInput-inputMarginDense': {
                fontSize: `${state.fontResize ? state.fontResize + 'px' : '0.875rem'}`,
                paddingTop: '4px',
                paddingBottom: '4px',
                color: 'black',
                background: 'white'
            },
            '.MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
                paddingTop: '4px',
                paddingBottom: '4px',
                color: 'black',
                background: 'white'
            },
            '.MuiFormLabel-root': {
                color: 'black'
            },
            '.MuiFormControlLabel-label.Mui-disabled': {
                color: 'black'
            },
            '.MuiOutlinedInput-adornedEnd': {
                background: 'white'
                // padding: 0
            },
            '.multiSelect': {
                '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
                    padding: '0px 50px 0px 0px'
                }
            },
            '.MuiDialogTitle-root': {
                margin: 0,
                padding: '3px 14px'
            }
        }
    })(() => null);
    const canAmendTechWriteUpFlag = AppStorage.getCanAmendTechWriteUp();
    const canSignOffTechWriteup = AppStorage.getCanSignOffTechWriteup();
    const handleValidation = () => {
        let fields = state.fields;
        const list = [...inputList];
        let errors = {};
        let formIsValid = true;
        if (!state.technicianNotesReadingUnit) {
            formIsValid = false;
            errors['technicianNotesReadingUnit'] = 'Please select Unit';
        }
        if (state.date === 'Invalid date') {
            formIsValid = false;
            errors['date'] = 'Date cannot be empty';
        }
        if (!state.readingtaken) {
            formIsValid = false;
            errors['readingtaken'] = 'Reading taken cannot be empty';
        }
        if (list[0]['technicianNotesItemCause'] === '') {
            formIsValid = false;
            errors['cause'] = 'Cause cannot be empty';
        }
        if (list[0]['technicianNotesItemFault'] === '') {
            formIsValid = false;
            errors['complaint'] = 'Complaint cannot be empty';
        }
        if (list[0]['technicianNotesItemDiagnosis'] === '') {
            formIsValid = false;
            errors['cure'] = 'Cure cannot be empty';
        }
        if (canSignOffTechWriteup && !state.teamleadersign) {
            formIsValid = false;
            errors['teamleadersign'] = 'Workshop controller signature cannot be empty';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };
    useEffect(() => {
        const selectedFailure = masterData.failureCodes.filter((w) => state.failurereason.indexOf(w.id) > -1);
        const selectedWorkAccomplish = masterData.workaccomplishcodes.filter((w) => state.workaccomplished.indexOf(w.id) > -1);
        setSelectedData((st) => ({
            ...st,
            selectedFailureCodes: selectedFailure,
            selectedWorkAccomplishCodes: selectedWorkAccomplish
        }));
    }, [state.failurereason, state.workaccomplished, masterData.failureCodes, masterData.workaccomplishcodes]);

    const Id = props.match.params.jobId;
    const DisplayGridState = (i) => {
        setState((st) => ({
            ...st,
            showGrid: true,
            GridID: i
        }));
    };
    useEffect(async () => {
        let pros = [];
        pros.push(getWorkAccomplishCodes(), getFailureCodes());
        let responses = await Promise.all(pros);
        if (responses[0].success && responses[1].success) {
            setMasterData((st) => ({
                ...st,
                workaccomplishcodes: responses[0].data.map((w) => ({
                    id: w.workAccomplishedCodeID,
                    name: w.workAccomplishedDescription
                })),
                failureCodes: responses[1].data.map((w) => ({
                    id: w.failureCode,
                    name: w.failureDescription
                }))
            }));
        }
    }, []);

    const handleClosePOP = () => {
        setAnchorEl({});
        setState((st) => ({ ...st, matchingPartNumber: [] }));
    };

    const hideBracket = state.technicianName || state.technicianDateSigned;
    const hideLeaderBracket = state.teamLeaderName || state.teamLeaderDateSigned;

    const pullTechWriteUp = async (technicianNotesJobID) => {
        let resDown = await getRenault_TechnicianWriteUp_ScreenDropdown();
        setScreenDropdown((st) => ({ ...st, claimTypes: resDown.data.claimTypes || [], defectCodeList: resDown.data.defectCodeList || [] }));
        if (state.technicianNotesJobID != technicianNotesJobID || technicianNotesJobID == null) {
            let result = await getTechWriteUp(Id, technicianNotesJobID);
            const updatedUser = result.data;
            // if (updatedUser) {
            //     setState((st) => ({
            //         ...st,
            //         signedOff: canSignOffTechWriteup, //default set to true from workshop techriteup
            //         technicianNotesJobID: updatedUser.technicianJobID,
            //         status: updatedUser.status,
            //         newJOBId: updatedUser.technicianJobID,
            //         id: updatedUser.technicianNotesHeaderRecordID,
            //         registration: updatedUser.registration,
            //         cause: updatedUser.technicianNotesCause,
            //         workaccomplished: updatedUser.techWorkAccomplished.map((m) => m.techWorkAccomplishedCodeID),
            //         failurereason: updatedUser.techFailureCodes.map((m) => m.techFailureCodeID),
            //         cure: updatedUser.technicianNotesActionTaken,
            //         date: updatedUser.technicianNotesDate ? formatters.DateFormatter(updatedUser.technicianNotesDate) : moment().format('YYYY-MM-DD'),
            //         readingtaken: updatedUser.technicianNotesReadingTaken || '',
            //         complaint: updatedUser.technicianNotesFault,
            //         mileagein: updatedUser.technicianNotesMileageIn || '',
            //         mileageout: updatedUser.technicianNotesMileageOut || '',
            //         radiocode: updatedUser.technicianNotesRadioCodeReset,
            //         serviceindicator: updatedUser.technicianNotesServiceIndicatorReset,
            //         smoketest: updatedUser.technicianNotesSmokeTestPerformed,
            //         imgtechniciansign: updatedUser.technicianNotesTechnicianSignature,
            //         techniciansign: updatedUser.technicianNotesTechnicianSignature,
            //         imgteamleadersign: updatedUser.technicianNotesTeamLeaderSignature,
            //         teamleadersign: updatedUser.technicianNotesTeamLeaderSignature,
            //         hide: updatedUser.technicianNotesTechnicianSignature,
            //         hide1: updatedUser.technicianNotesTeamLeaderSignature,
            //         timeclock: updatedUser.technicianNotesTimeClockReset,
            //         timein: updatedUser.technicianNotesTimeIn ? pad(updatedUser.technicianNotesTimeIn).toString().replace('.', ':') : '',
            //         timeout: updatedUser.technicianNotesTimeOut ? pad(updatedUser.technicianNotesTimeOut).toString().replace('.', ':') : '',
            //         wipNumber: updatedUser.wipNumber,
            //         technicians: updatedUser.technicians,
            //         odometerUnitsVM: updatedUser?.odometerUnitsVM?.map((w) => ({
            //             id: w.odometerUnitID,
            //             name: w.odometerUnitDescription
            //         })),
            //         technicianNotesReadingUnit: updatedUser.technicianNotesReadingUnit,
            //         showCircularLoader: false,
            //         technicianName: updatedUser.technicianNotesTechnicianName,
            //         technicianDateSigned: formatters.DateTimeFormatter(updatedUser.technicianNotesTechnicianDateSigned),
            //         teamLeaderName: updatedUser.technicianNotesTeamLeaderName,
            //         teamLeaderDateSigned: formatters.DateTimeFormatter(updatedUser.technicianNotesTeamLeaderDateSigned),
            //         customerName: updatedUser.customerName,
            //         isReadonly: new Date() // !canAmendTechWriteUpFlag
            //     }));
            //     setInputList(
            //         !updatedUser.technicianNotesItem.length
            //             ? [
            //                   {
            //                       technicianNotesItemFault: '',
            //                       technicianNotesItemDiagnosis: '',
            //                       technicianNotesItemCause: '',
            //                       timeTaken: null,
            //                       technicianNotesItemID: null,
            //                       defectCode: '',
            //                       isDeleted: false,
            //                       dateCreated: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
            //                       faultCodes: [],
            //                       associatedMaterials: []
            //                   }
            //               ]
            //             : updatedUser.technicianNotesItem.map((k) => ({
            //                   ...k,
            //                   isDeleted: false,
            //                   faultCodes: k.faultCodes.map((d) => ({ ...d, isDeleted: false })),
            //                   associatedMaterials: k.associatedMaterials.map((d) => ({ ...d, isDeleted: false }))
            //               }))
            //     );
            // }

            if (updatedUser) {
                setState((st) => ({
                    ...st,
                    isFMOrWarranty: updatedUser.isFMOrWarranty,
                    technicianNotesJobID: updatedUser.technicianJobID,
                    newJOBId: updatedUser.technicianJobID,
                    status: updatedUser.status,
                    id: updatedUser.technicianNotesHeaderRecordID,
                    registration: updatedUser.registration,
                    technicianNotesItemCause: updatedUser.technicianNotestechnicianNotesItemCause,
                    workaccomplished: updatedUser.techWorkAccomplished.map((m) => m.techWorkAccomplishedCodeID),
                    failurereason: updatedUser.techFailureCodes.map((m) => m.techFailureCodeID),
                    technicianNotesItemDiagnosis: updatedUser.technicianNotesActionTaken,
                    date: updatedUser.technicianNotesDate ? formatters.DateFormatter(updatedUser.technicianNotesDate) : moment().format('YYYY-MM-DD'),
                    readingtaken: updatedUser.technicianNotesReadingTaken || '',
                    technicianNotesItemFault: updatedUser.technicianNotesFault,
                    mileagein: updatedUser.technicianNotesMileageIn || '',
                    mileageout: updatedUser.technicianNotesMileageOut || '',
                    radiocode: updatedUser.technicianNotesRadioCodeReset,
                    serviceindicator: updatedUser.technicianNotesServiceIndicatorReset,
                    smoketest: updatedUser.technicianNotesSmokeTestPerformed,
                    imgtechniciansign: updatedUser.technicianNotesTechnicianSignature,
                    techniciansign: updatedUser.technicianNotesTechnicianSignature,
                    imgteamleadersign: updatedUser.technicianNotesTeamLeaderSignature,
                    timeclock: updatedUser.technicianNotesTimeClockReset,
                    timein: updatedUser.technicianNotesTimeIn ? pad(updatedUser.technicianNotesTimeIn).toString().replace('.', ':') : '',
                    timeout: updatedUser.technicianNotesTimeOut ? pad(updatedUser.technicianNotesTimeOut).toString().replace('.', ':') : '',
                    wipNumber: updatedUser.wipNumber,
                    odometerUnitsVM: updatedUser?.odometerUnitsVM?.map((w) => ({
                        id: w.odometerUnitID,
                        name: w.odometerUnitDescription
                    })),
                    technicianNotesReadingUnit: updatedUser.technicianNotesReadingUnit,
                    technicians: updatedUser.technicians,

                    // isTechReadonly:
                    //     !canAmendTechWriteUpFlag && (props.match.params?.technicianJobId == st.technicianNotesJobID && isTechReadonly == 'Y' ? false : true),
                    showCircularLoader: false,
                    technicianName: updatedUser.technicianNotesTechnicianName,
                    technicianDateSigned: formatters.DateTimeFormatter(updatedUser.technicianNotesTechnicianDateSigned),
                    teamLeaderName: updatedUser.technicianNotesTeamLeaderName,
                    teamLeaderDateSigned: formatters.DateTimeFormatter(updatedUser.technicianNotesTeamLeaderDateSigned),
                    customerName: updatedUser.customerName,
                    signedOff: true, // default false from techwrite up
                    partsToLink: updatedUser.partsToLink?.map((q) => ({ ...q, dateCreated: '' })) || [],
                    isReadonly: new Date() // !canAmendTechWriteUpFlag
                }));
                setInputList(
                    !updatedUser.technicianNotesItem.length
                        ? [
                              {
                                  technicianNotesItemFault: '',
                                  technicianNotesItemDiagnosis: '',
                                  technicianNotesItemCause: '',
                                  timeTaken: '',
                                  technicianNotesItemID: null,
                                  defectCode: '',
                                  isDeleted: false,
                                  dateCreated: moment().format('YYYY-MM-DDTHH:mm:ss'),
                                  faultCodes: [],
                                  associatedMaterials: []
                              }
                          ]
                        : updatedUser.technicianNotesItem.map((k) => ({
                              ...k,
                              timeTaken: k.timeTaken || '',
                              isDeleted: false,
                              faultCodes: k.faultCodes.map((d) => ({ ...d, isDeleted: false })),
                              associatedMaterials: k.associatedMaterials.map((d) => ({ ...d, isDeleted: false })),
                              defectCodeTemp: resDown.data.defectCodeList.find((w) => w.id == k.defectCode) || '',
                              claimTypeIDTemp: resDown.data.claimTypes.find((w) => w.id == k.claimTypeID) || ''
                          }))
                );
            }
        }
    };

    const IsYesNOButton = ({ typeID, value, onClick, name, index, disabled }) => {
        return (
            <>
                <br />
                <div>
                    <ButtonGroup color="primary" aria-label="outlined primary button group" size="small" style={{ float: 'left' }} disabled>
                        <Button size="small" variant={value ? 'contained' : 'outlined'} style={{ fontSize: 10 }} onClick={() => onClick(name, true, index)}>
                            Yes
                        </Button>
                        <Button
                            size="small"
                            // onClick={() => buttonClick(typeName, false)}
                            variant={value ? 'outlined' : 'contained'}
                            style={{ fontSize: 10 }}
                            onClick={() => onClick(name, false, index)}
                        >
                            No
                        </Button>
                    </ButtonGroup>
                </div>
            </>
        );
    };

    const handleLinkRows = (e, value) => {
        let TempList = [...inputList[state.modalIndex].associatedMaterials];
        let TempPartsToLink = [...state.partsToLink];

        if (e.target.checked) {
            let TempPartsToLinkIndex = TempPartsToLink.findIndex((q) => q.partReqID == value.partReqID);
            TempPartsToLink[TempPartsToLinkIndex].isLinked = true;
            TempPartsToLink[TempPartsToLinkIndex].technicianNotesItemID = inputList[state.modalIndex].technicianNotesItemID || null;
            TempPartsToLink[TempPartsToLinkIndex].dateCreated = inputList[state.modalIndex].technicianNotesItemID
                ? ''
                : inputList[state.modalIndex].dateCreated;
            // TempList.push({
            //     ...value,
            //     technicianNotesItemID: inputList[state.modalIndex].technicianNotesItemID,
            //     dateCreated: value.dateCreated || inputList[state.modalIndex].dateCreated
            // });
        } else {
            // let objIndex = TempList.findIndex((q) => q.lineID == value.lineID);
            // TempList.splice(objIndex, 1);
            let TempPartsToLinkIndex = TempPartsToLink.findIndex((q) => q.partReqID == value.partReqID);
            TempPartsToLink[TempPartsToLinkIndex].isLinked = false;
            TempPartsToLink[TempPartsToLinkIndex].technicianNotesItemID = null;
            TempPartsToLink[TempPartsToLinkIndex].dateCreated = '';
        }

        // setInputList((st) => {
        //     let newSt = [...st];
        //     newSt[state.modalIndex].associatedMaterials = [...TempList];
        //     return newSt;
        // });
        setState((st) => ({ ...st, partsToLink: TempPartsToLink }));
    };

    useEffect(async () => {
        setState((st) => ({ ...st, showCircularLoader: true }));
        pullTechWriteUp();
    }, []);

    useEffect(async () => {
        if (state.modalIndex != null) {
            const dropDownRes = await getPartReqRenaultStatus();
            let partIdArray = new Set([...inputList[state.modalIndex].associatedMaterials].map((obj) => obj.partReqStatus));
            let Res = [...dropDownRes.data.partReqStatus];
            Res = Res.reduce((acc, status) => {
                if (partIdArray.has(status.id)) {
                    if (isInternal && !status.visibleToInternal) {
                        acc.push({ ...status, visibleToInternal: true });
                    }
                    if (isTechnician && !status.visibleToTech) {
                        acc.push({ ...status, visibleToTech: true });
                    }
                }
                acc.push(status);
                return acc;
            }, []);

            const FilterList = Res.filter((status) => {
                return (isInternal && status.visibleToInternal) || (isTechnician && status.visibleToTech);
            });

            const uniqueData = Array.from(new Set(FilterList.map((status) => status.id))).map((id) => FilterList.find((status) => status.id === id));

            setScreenDropdown((st) => ({ ...st, partReqStatus: uniqueData || [] }));
        }
    }, [state.modalIndex]);

    function pad(number) {
        // Check if the number is an integer
        if (Number.isInteger(number)) {
            return number < 10 ? '0' + number : number.toString();
        } else {
            // Convert the number to a string with exactly two decimal places
            var str = number.toFixed(2);
            // Check if the whole part is less than 10 and add a leading zero if necessary
            if (number < 10 && number >= 0) {
                str = '0' + str;
            }
            return str;
        }
    }

    const technician = state.technicians?.map((m) => m.technician);

    const handleSubmit = async () => {
        if (handleValidation()) {
            setState((st) => ({ ...st, showWarning: true }));
        }
    };
    const hideConfirmationModal = () => {
        setState((st) => ({ ...st, showWarning: false }));
    };
    const isAuditor = userRoles.includes('auditor');
    const isCustomer = userRoles.includes('customer');

    const isInternal = userRoles.includes('internal');
    const isTechnician = userRoles.includes('technician');

    const submitFunction = async () => {
        if (handleValidation()) {
            let mode = state.teamleadersign && state.signedOff;
            let data = {
                Mode: mode ? 'S' : 'T',
                partsToLink: state.partsToLink || [],
                TechnicianNotesDate: state.date,
                TechnicianNotesHeaderRecordID: state.id,
                technicianNotesItem: inputList.map((prev) => ({ ...prev, timeTaken: prev.timeTaken ? prev.timeTaken : null })),
                TechnicianNotesMileageIn: state.mileagein,
                TechnicianNotesTimeIn: parseFloat(state.timein.replace(':', '.')),
                TechnicianNotesMileageOut: state.mileageout,
                TechnicianNotesTimeOut: parseFloat(state.timeout.replace(':', '.')),
                TechnicianNotesSmokeTestPerformed: state.smoketest,
                TechnicianNotesServiceIndicatorReset: state.serviceindicator,
                TechnicianNotesRadioCodeReset: state.radiocode,
                TechnicianNotesTimeClockReset: state.timeclock,
                TechnicianNotesTechnicianSignature: null, //state.techniciansign, no need to pass tech sifn from workshop
                techWorkAccomplished: state.workaccomplished,
                TechnicianNotesReadingTaken: state.readingtaken,
                techFailureCodes: state.failurereason,
                TechnicianNotesTeamLeaderSignature: state.teamleadersign,
                TechnicianNotesReadingUnit: state.technicianNotesReadingUnit,
                TechnicianNotesJobID: state.newJOBId,
                SignedOff: state.signedOff || false
            };
            let res = await AddTechWriteUp(data);
            if (!res.success) {
                console.error(res);
            } else {
                setState((st) => ({ ...st, showWarning: false }));
                hideModal();
            }
            showToast(res.message);
            if (res.success && fromStep) {
                // setTimeout(() => {
                //     window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
                // }, 1000);
                setState((st) => ({ ...st, showCircularLoader: true }));
                pullTechWriteUp();
            }
        }
    };

    const GetTechnicianSign = (o) => {
        setState((st) => {
            const nst = {
                ...st,
                ...o
            };
            return nst;
        });
    };
    const clearSig = (val) => {
        setState((st) => {
            let newSt = { ...st };
            if (val === 'teamleadersign') {
                newSt.teamleadersign = null;
            }
            if (val === 'techniciansign') {
                newSt.techniciansign = null;
            }
            return newSt;
        });
    };

    // const GetTeamleaderSign = () => {
    //     let fields = state.fields;
    //     fields['teamleadersign'] = signteamleaderCanvas.current.toDataURL();
    //     setState((st) => {
    //         const nst = {
    //             ...st,
    //             teamleadersign: signteamleaderCanvas.current.toDataURL(),
    //             fields: fields
    //         };
    //         return nst;
    //     });
    // };
    const handleCheck = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            const nst = { ...st, [name]: checked };
            return nst;
        });
    };
    const fieldChange = (e) => {
        const { name, value } = e.target;
        let fields = state.fields;
        fields[name] = value;
        setState((st) => {
            return { ...st, [name]: value, fields: fields };
        });
    };
    const AutoWorkAccomplish = (event, value) => {
        setSelectedData((st) => ({
            ...st,
            selectedWorkAccomplishCodes: value
        }));
        setState((st) => {
            const nst = { ...st, workaccomplished: value.map((c) => c.id) };
            return nst;
        });
    };
    const TechBtnClick = (id) => {
        if (state.technicianNotesJobID != id) {
            // setState((st) => ({
            //     ...st,
            //     technicianNotesJobID: id
            // }));
            pullTechWriteUp(id);
        }
    };
    const AutoFrChange = (event, value) => {
        setSelectedData((st) => ({ ...st, selectedFailureCodes: value }));
        setState((st) => {
            const nst = { ...st, failurereason: value.map((c) => c.id) };
            return nst;
        });
    };

    const pullAndUpdateState = () => {
        setState((st) => ({
            ...st,
            showGrid: false
        }));
    };

    const MatchingPartList = ({ list, loading, handleRowSelect, selectedRowIndex, name }) => {
        return (
            <Paper style={{ borderRadius: '5px', maxHeight: '350px', overflow: 'auto', width: '350px' }}>
                <TableContainer>
                    <Table size="small" width="550px" stickyHeader>
                        <TableHead>
                            <TableRow className="header">
                                <SimpleCell>Number</SimpleCell>
                                <SimpleCell>Description</SimpleCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list?.map((j, i) => (
                                <StyledTableRow
                                    className={`row${j.isSelected ? ' is-selected-j' : ''}`}
                                    onClick={() => handleRowSelect(j, selectedRowIndex, name)}
                                >
                                    <SimpleCell>{j.partNumber}</SimpleCell>
                                    <SimpleCell>{j.description}</SimpleCell>
                                </StyledTableRow>
                            ))}
                            {list?.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan="4">{loading ? 'Loading...' : 'No record found.'}</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    };

    const PopoverWrapper = (props) => {
        const open = Boolean(props.anchorEl);
        const id = open ? 'simple-popover' : undefined;

        return (
            <Popover
                id={id}
                open={open}
                anchorEl={props.anchorEl}
                onClose={props.handleClosePOP}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <MatchingPartList
                    list={props.matchingPartNumber || []}
                    loading={false}
                    handleRowSelect={props.handleRowSelect}
                    selectedRowIndex={props.selectedRowIndex}
                    name={props.name}
                />
            </Popover>
        );
    };

    const getStandartdFromChild = (props) => {
        const list = [...inputList];
        list[state.GridID]['technicianNotesItemFault'] = IsUpperCase ? props.complaint?.toUpperCase() : props.complaint;
        list[state.GridID]['technicianNotesItemDiagnosis'] = IsUpperCase ? props.cure?.toUpperCase() : props.cure;
        list[state.GridID]['technicianNotesItemCause'] = props.cause == null ? '' : IsUpperCase ? props.cause?.toUpperCase() : props.cause;
        setInputList(list);
    };
    const [inputList, setInputList] = useState([
        {
            technicianNotesItemFault: '',
            technicianNotesItemDiagnosis: '',
            technicianNotesItemCause: '',
            timeTaken: null,
            technicianNotesItemID: null,
            defectCode: '',
            isDeleted: false,
            dateCreated: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
            faultCodes: [],
            associatedMaterials: []
        }
    ]);

    console.log(inputList);

    const [anchorEl, setAnchorEl] = useState({
        causualPartNumber: null,
        causualPartDescription: null
    });

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = IsUpperCase ? value.toUpperCase() : value;
        setInputList(list);
    };
    const handleRemoveClick = (index, id) => {
        const list = [...inputList];
        if (id) {
            list[index].isDeleted = true;
            setInputList(list);
        } else {
            list.splice(index, 1);
            setInputList(list);
        }
    };
    const handleAddClick = () => {
        setInputList([
            ...inputList,
            {
                technicianNotesItemFault: '',
                technicianNotesItemDiagnosis: '',
                technicianNotesItemCause: '',
                timeTaken: null,
                technicianNotesItemID: null,
                defectCode: '',
                isDeleted: false,
                dateCreated: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
                faultCodes: [],
                associatedMaterials: []
            }
        ]);
    };

    const handleFault = (index) => {
        let TempList = [...inputList[index].faultCodes];
        // if (TempList.length === 0) {
        //     const defaultFault = {
        //         technicianNotesItemsFaultCodeID: null,
        //         technicianNotesItemsID: null,
        //         technicianNotesItemsFaultCode: '',
        //         technicianNotesItemsFaultDescription: '',
        //         dateCreated: inputList[index].dateCreated
        //     };
        //     TempList.unshift(defaultFault);
        // }
        setInputList((st) => {
            let newSt = [...st];
            newSt[index].faultCodes = [...TempList];
            return newSt;
        });
        setState((st) => ({ ...st, showFault: true, modalIndex: index }));
    };
    const handleAssociated = (index) => {
        let TempList = [...inputList[index].associatedMaterials];
        // if (TempList.length === 0) {
        //     const defaultMaterial = {
        //         partReqID: null,
        //         technicianNotesItemID: null,
        //         partNumber: '',
        //         partDescription: '',
        //         partQty: '',
        //         dateCreated: inputList[index].dateCreated
        //     };
        //     TempList.unshift(defaultMaterial);
        // }
        setInputList((st) => {
            let newSt = [...st];
            newSt[index].associatedMaterials = [...TempList];
            return newSt;
        });
        setState((st) => ({ ...st, showAssociated: true, modalIndex: index }));
    };

    const closeModal = () => {
        setState((st) => ({ ...st, showAssociated: false, showFault: false, modalIndex: null }));
    };

    const handleFaultCodeRow = () => {
        let TempList = [...inputList[state.modalIndex].faultCodes];
        TempList.unshift({
            technicianNotesItemsFaultCodeID: null,
            technicianNotesItemsID: null,
            technicianNotesItemsFaultCode: '',
            technicianNotesItemsFaultDescription: '',
            dateCreated: inputList[state.modalIndex].dateCreated
        });

        setInputList((st) => {
            let newSt = [...st];
            newSt[state.modalIndex].faultCodes = [...TempList];
            return newSt;
        });
    };
    const removeFaultCodeRow = (index) => {
        setInputList((prevState) => {
            const newState = [...prevState];
            const updatedFaultCodes = [...newState[state.modalIndex].faultCodes];
            if (updatedFaultCodes[index]?.technicianNotesItemsFaultCodeID) {
                updatedFaultCodes[index].isDeleted = true;
            } else {
                updatedFaultCodes.splice(index, 1);
            }

            newState[state.modalIndex].faultCodes = updatedFaultCodes;

            return newState;
        });
    };

    const handleFaultCodeInput = (e, index) => {
        const { name, value } = e.target;
        setInputList((st) => {
            let newSt = [...st];
            let updatedNestedArray = [...st[state.modalIndex].faultCodes];
            updatedNestedArray[index][name] = value;
            return newSt;
        });
    };

    const handleAssociatedRow = () => {
        let TempList = [...inputList[state.modalIndex].associatedMaterials];
        TempList.unshift({
            partReqID: null,
            technicianNotesItemID: null,
            partNumber: '',
            partDescription: '',
            isDeleted: false,
            isFitted: false,
            isReturnToPartsDepo: false,
            partQty: '',
            dateCreated: inputList[state.modalIndex].dateCreated,
            lineID: 10 + TempList.length + 2,
            parentLineID: null
        });

        setInputList((st) => {
            let newSt = [...st];
            newSt[state.modalIndex].associatedMaterials = [...TempList];
            return newSt;
        });
    };

    const removeAssociatedRow = (index) => {
        setInputList((prevState) => {
            const newState = [...prevState];
            const updatedAssociatedMaterials = [...newState[state.modalIndex].associatedMaterials];
            if (updatedAssociatedMaterials[index]?.partReqID) {
                updatedAssociatedMaterials[index].isDeleted = true;
            } else {
                updatedAssociatedMaterials.splice(index, 1);
            }
            newState[state.modalIndex].associatedMaterials = updatedAssociatedMaterials;
            return newState;
        });
    };

    const handleAssociateInput = (e, index) => {
        const { name, value, checked } = e.target;

        setInputList((st) => {
            let newSt = [...st];
            let updatedNestedArray = [...st[state.modalIndex].associatedMaterials];
            let objIndex = updatedNestedArray.findIndex((q) => q.lineID == index);

            if (name == 'isFitted' || name == 'isReturnToPartsDepo') {
                updatedNestedArray[objIndex][name] = checked;
                updatedNestedArray.forEach((q) => {
                    if (q.parentLineID == index) {
                        q[name] = checked;
                    }
                });
            } else if (name === 'partReqStatus') {
                let isPhotoREQ = screenDropdown.partReqStatus.find((k) => value === k.id)?.photoRequired;
                console.log(value, 'value');
                console.log(isPhotoREQ, 'isPhotoREQ');
                if (isPhotoREQ) {
                    updatedNestedArray[objIndex]['isImageErrorMessage'] = 'Image is Required';
                } else {
                    delete updatedNestedArray[objIndex]['isImageErrorMessage'];
                }
                updatedNestedArray[objIndex][name] = value;
            } else {
                updatedNestedArray[objIndex][name] = value;
            }
            return newSt;
        });
    };
    const DownloadSTDListDocument = () => {
        getSTDWriteUpPdfFile(Id, 'T');
    };
    const printDocument = () => {
        if (state.isFMOrWarranty) {
            getTechWriteUpPdfWithFmFile(Id, 'T');
        } else {
            getTechWriteUpPdfFile(Id, 'T');
        }
    };
    useEffect(() => {
        let checkReadonly = showSubmit && !state.imgteamleadersign && !isAuditor && !isCustomer && canAmendTechWriteUpFlag;
        setState((st) => ({
            ...st,
            makeDisbaled: !checkReadonly
        }));
    }, [state.isReadonly]);

    const copyToClipboard = (value) => {
        navigator.clipboard.writeText(AppStorage.getCanCopyUpperCaseWriteUps() ? value.toUpperCase() : value);
        showToast('Copied!');
    };

    const pullDataByPartNumber = (val, name, index, isPart) => async (event) => {
        let res = {};
        setAnchorEl((st) => ({
            ...st,
            [name]: event.currentTarget
        }));
        if (isPart) {
            res = await getPartListByPartNumber(val, null);
        } else {
            res = await getPartListByPartNumber(null, val);
        }
        if (res.success) {
            setState((st) => ({ ...st, matchingPartNumber: res.data.partDetails, loading: false }));
        }
    };

    const handleRowSelect = (params, selectedRowIndex, name) => {
        const list = [...inputList];
        if (name == 'causual') {
            list[selectedRowIndex].causualPartNumber = params.partNumber;
            list[selectedRowIndex].causualPartDescription = params.description;
        }
        if (name == 'new') {
            list[selectedRowIndex].newPartNumber = params.partNumber;
            list[selectedRowIndex].newPartDescription = params.description;
        }
        setInputList(list);

        setAnchorEl({});
    };

    const singleSelectChange = (nm, index) => (ev, val) => {
        const list = [...inputList];
        list[index][nm] = val.id;
        list[index][`${nm}Temp`] = val;
        setInputList(list);
    };

    const yesNoHandle = (name, val, index) => {
        const list = [...inputList];
        list[index][name] = val;
        setInputList(list);
    };

    const submitChildAssocate = (DataObj, selectedAssociatedId) => {
        let TempList = [...inputList[state.modalIndex].associatedMaterials];
        TempList.unshift({
            partReqID: null,
            technicianNotesItemID: null,
            partNumber: DataObj.partNumber || '',
            partDescription: DataObj.partDescription || '',
            partQty: DataObj.partQty || '',
            isDeleted: false,
            dateCreated: inputList[state.modalIndex].dateCreated,
            lineID: 50 + TempList.length + 2,
            parentLineID: selectedAssociatedId
        });

        setInputList((st) => {
            let newSt = [...st];
            newSt[state.modalIndex].associatedMaterials = [...TempList];
            return newSt;
        });
    };

    const openIsDeleteTechWriteUpModal = (event, data) => {
        event.stopPropagation();
        setIsDeleteTechWriteUp((st) => ({ ...st, show: true, selectedData: data }));
    };

    const deleteTechWriteUpHandle = async () => {
        let res = await TechnicianWriteUp_Delete(isDeleteTechWriteUp.selectedData?.technicianJobID);
        if (res.success) {
            // if (state.technicianNotesJobID != isDeleteTechWriteUp.selectedData?.technicianJobID) {
            pullTechWriteUp();
            // }
            showToast(res.data.message);
        } else {
            showToast(res.message);
        }
        closeIsDeleteTechWriteUpModal();
    };

    const closeIsDeleteTechWriteUpModal = () => {
        setIsDeleteTechWriteUp((st) => ({ ...st, show: false, selectedData: {} }));
    };

    const handleAssignmentBtnClick = (val) => {
        setState((st) => ({
            ...st,
            showTechAssign: true
        }));
    };

    const closeTechAssignDialog = (res) => {
        setState((st) => ({ ...st, showTechAssign: false }));
        res && pullTechWriteUp();
    };

    const showSubmit = ['WS', 'TS', 'TR'].includes(state.status);
    let msg = `Please be aware that technicians ${technician?.toString().replace(/,(?=[^,]*$)/, ' and ')} ${
        technician.length == 1 ? 'is' : 'are'
    } assigned to this job. Please ensure to review and sign write ups of all technicians assigned to this job by clicking technician name, signing the write up and click the Submit button`;
    return (
        <>
            {state.showWarning ? (
                <DialogComp title="Warning" onClose={hideConfirmationModal} maxWidth="sm">
                    <ConfirmationModal message={msg} handleSubmit={submitFunction} handleCancel={hideConfirmationModal} />
                </DialogComp>
            ) : null}
            {state.showGrid ? <DisplayGrid onFormSubmit={pullAndUpdateState} onStandardWriteup={getStandartdFromChild} /> : null}
            <GlobalCss />
            {!fromStep && (
                <Breadcrumbs separator=">>" aria-label="breadcrumb">
                    <Link color="inherit" to={'/'}>
                        Technician App
                    </Link>
                    <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                        Technician Write-up
                    </Typography>
                </Breadcrumbs>
            )}
            <div style={{ background: 'white' }}>
                <DialogContents>
                    {state.showCircularLoader ? (
                        <Grid item sm={12}>
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <>
                            <Grid item sm={3} style={{ position: 'absolute', top: '1px', right: '20px' }} id="zommContainer">
                                <CustomButton
                                    IconColor="green"
                                    icon={CloudDownloadIcon}
                                    onClick={DownloadSTDListDocument}
                                    toolTipTitle="Download writeup by date created"
                                    iconFontSize={28}
                                />
                                <CustomButton
                                    color="secondary"
                                    icon={CloudDownloadIcon}
                                    onClick={printDocument}
                                    toolTipTitle="Download this document"
                                    iconFontSize={28}
                                />
                            </Grid>
                            <Grid container spacing={1}>
                                Registration Number:&nbsp; <b> {state.registration}</b>
                                <Grid item sm={3}>
                                    WIP No:&nbsp; <b> {state.wipNumber}</b>
                                </Grid>
                                <Grid item sm={3}>
                                    Customer:&nbsp; <b> {state.customerName}</b>
                                </Grid>
                                <Grid item xs={12}></Grid>
                                {state.technicians.map((m) => (
                                    <div className="tech-btn-group">
                                        <Button
                                            className={state.technicianNotesJobID == m.technicianJobID ? 'active-btn' : 'all-btn'}
                                            onClick={() => TechBtnClick(m.technicianJobID)}
                                            endIcon={m.canDelete == 'Y' ? <DeleteIcon onClick={(e) => openIsDeleteTechWriteUpModal(e, m)} /> : ''}
                                        >
                                            {m.technician}
                                        </Button>
                                    </div>
                                ))}
                                <div title="Assign Technician" className="tech-btn-group" style={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton aria-label="add" style={{ padding: '0px' }}>
                                        <PersonAddIcon onClick={handleAssignmentBtnClick} />
                                    </IconButton>
                                </div>
                                <>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <FormLabel component="legend" className="mandatoryfields">
                                            Date
                                        </FormLabel>
                                        <TextBox
                                            id="date"
                                            type="date"
                                            name="date"
                                            value={state.date}
                                            autoComplete="new-password"
                                            onChange={fieldChange}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            disabled={state.makeDisbaled}
                                        />
                                        <span className="mandatoryfields">{state.errors['date']}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <FormLabel component="legend" className="mandatoryfields">
                                            Odometer Reading
                                        </FormLabel>
                                        <TextBox
                                            type="number"
                                            required
                                            name="readingtaken"
                                            value={state.readingtaken}
                                            onChange={fieldChange}
                                            autoFocus={true}
                                            disabled={state.makeDisbaled}
                                        />
                                        <span className="mandatoryfields">{state.errors['readingtaken']}</span>
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <FormLabel component="legend" className="mandatoryfields">
                                            Odometer Reading Unit
                                        </FormLabel>
                                        <TextBox
                                            disabled={state.makeDisbaled}
                                            select
                                            value={state.technicianNotesReadingUnit}
                                            name="technicianNotesReadingUnit"
                                            onChange={fieldChange}
                                        >
                                            {state.odometerUnitsVM?.length > 0 &&
                                                state.odometerUnitsVM.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.name}
                                                    </MenuItem>
                                                ))}
                                        </TextBox>
                                        <FormHelperText error shrink>
                                            {state.errors?.technicianNotesReadingUnit}
                                        </FormHelperText>
                                    </Grid>

                                    <Grid item xs={12} sm={12}>
                                        {inputList.map((x, i) => {
                                            let assoPartsCount =
                                                x.associatedMaterials?.filter((k) => k.isDeleted === false)?.length +
                                                state.partsToLink?.filter(
                                                    (j) =>
                                                        j.isLinked &&
                                                        (x.dateCreated == j.dateCreated ||
                                                            (x.technicianNotesItemID == j.technicianNotesItemID && j.technicianNotesItemID != null))
                                                )?.length;
                                            return (
                                                !x.isDeleted && (
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        style={{
                                                            border: i % 2 ? '1px dashed black' : '1px dashed  #2f38dd',
                                                            background: i % 2 ? '#f5f2c5' : '#ECEFF1',
                                                            marginBottom: 10,
                                                            padding: 5
                                                        }}
                                                    >
                                                        <Grid item xs={12} sm={4} md={4}>
                                                            <FormLabel component="legend" className="mandatoryfields">
                                                                Complaint{' '}
                                                                {x.technicianNotesItemFault && (
                                                                    <FileCopyIcon
                                                                        style={{ fontSize: '15px', cursor: 'pointer' }}
                                                                        onClick={() => copyToClipboard(x.technicianNotesItemFault)}
                                                                    />
                                                                )}
                                                                <Tooltip title="Select Standard Write-Up" aria-label="add">
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            DisplayGridState(i);
                                                                        }}
                                                                        style={{
                                                                            padding: '1px',
                                                                            marginTop: '-9px',
                                                                            marginLeft: '10px'
                                                                        }}
                                                                        disabled={state.makeDisbaled}
                                                                    >
                                                                        <NoteAddIcon color="secondary" fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </FormLabel>
                                                            <TextBox
                                                                name="technicianNotesItemFault"
                                                                // autoFocus={true}
                                                                value={x.technicianNotesItemFault}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                                multiline
                                                                disabled={state.makeDisbaled}
                                                                rows={portalSettings.portalSettingTechnWriteupHeight || 4}
                                                            />
                                                            <FormHelperText error shrink>
                                                                {state.errors[i]?.technicianNotesItemFault}
                                                            </FormHelperText>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4} md={4}>
                                                            <FormLabel component="legend" className="mandatoryfields">
                                                                Cause{' '}
                                                                {x.technicianNotesItemCause && (
                                                                    <FileCopyIcon
                                                                        style={{ fontSize: '15px', cursor: 'pointer' }}
                                                                        onClick={() => copyToClipboard(x.technicianNotesItemCause)}
                                                                    />
                                                                )}
                                                            </FormLabel>
                                                            <TextBox
                                                                name="technicianNotesItemCause"
                                                                disabled={state.makeDisbaled}
                                                                value={x.technicianNotesItemCause}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                                multiline
                                                                rows={portalSettings.portalSettingTechnWriteupHeight || 4}
                                                            />
                                                            <FormHelperText error shrink>
                                                                {state.errors[i]?.technicianNotesItemCause}
                                                            </FormHelperText>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4} md={4}>
                                                            <FormLabel component="legend" className="mandatoryfields">
                                                                Cure{' '}
                                                                {x.technicianNotesItemDiagnosis && (
                                                                    <FileCopyIcon
                                                                        style={{ fontSize: '15px', cursor: 'pointer' }}
                                                                        onClick={() => copyToClipboard(x.technicianNotesItemDiagnosis)}
                                                                    />
                                                                )}
                                                            </FormLabel>

                                                            {!state.makeDisbaled && inputList.filter((a) => !a.isDeleted)?.length > 1 && (
                                                                <Tooltip title="Remove Standard Write-Up" aria-label="remove">
                                                                    <IconButton
                                                                        disabled={state.makeDisbaled}
                                                                        onClick={() => handleRemoveClick(i, x.technicianNotesItemID)}
                                                                        className="rightButton"
                                                                    >
                                                                        <RemoveCircleIcon
                                                                            style={{ color: state.makeDisbaled ? 'grey' : 'red' }}
                                                                            fontSize="small"
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                            {!state.makeDisbaled && (
                                                                <Tooltip title="Add Standard Write-Up" aria-label="add">
                                                                    <IconButton
                                                                        disabled={state.makeDisbaled}
                                                                        onClick={handleAddClick}
                                                                        className="rightButton"
                                                                        style={{
                                                                            marginRight: '5px'
                                                                        }}
                                                                    >
                                                                        <AddBoxIcon style={{ color: state.makeDisbaled ? 'grey' : 'green' }} fontSize="small" />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}

                                                            <TextBox
                                                                name="technicianNotesItemDiagnosis"
                                                                disabled={state.makeDisbaled}
                                                                value={x.technicianNotesItemDiagnosis}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                                multiline
                                                                rows={portalSettings.portalSettingTechnWriteupHeight || 4}
                                                            />
                                                            <FormHelperText error shrink>
                                                                {state.errors[i]?.technicianNotesItemDiagnosis}
                                                            </FormHelperText>
                                                        </Grid>
                                                        {/* {i !== state.expandRowIndex && (
                                            <Grid item xs={12} className={classes.inFoButton}>
                                                <div
                                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}
                                                    onClick={() => handleRowExpand(i)}
                                                >
                                                    <div>
                                                        {' '}
                                                        <ExpandMoreIcon style={{ marginTop: 5 }} />
                                                    </div>{' '}
                                                    <div>More Details</div>
                                                </div>
                                            </Grid>
                                        )} */}
                                                        {/* {i === state.expandRowIndex && ( */}
                                                        {/* <> */}

                                                        {state.isFMOrWarranty && (
                                                            <Grid item xs={12} sm={4} md={4} lg={2} xl={2} className="multiSelect">
                                                                <FormLabel component="legend" error>
                                                                    Claim Type*
                                                                </FormLabel>
                                                                <SingelSelect
                                                                    disabled={state.makeDisbaled}
                                                                    onChange={singleSelectChange('claimTypeID', i)}
                                                                    options={screenDropdown.claimTypes || []}
                                                                    value={x.claimTypeIDTemp || {}}
                                                                />
                                                                <FormHelperText error shrink>
                                                                    {state.errors[i]?.claimTypeID}
                                                                </FormHelperText>
                                                            </Grid>
                                                        )}

                                                        <Grid item xs={12} sm={4} md={4} lg={2}>
                                                            <FormLabel component="legend" error>
                                                                Time Taken (Hours)*
                                                            </FormLabel>
                                                            <TextBox
                                                                name="timeTaken"
                                                                type="number"
                                                                disabled={state.makeDisbaled}
                                                                value={x.timeTaken}
                                                                onChange={(e) => handleInputChange(e, i)}
                                                            />
                                                            <FormHelperText error shrink>
                                                                {state.errors[i]?.timeTaken}
                                                            </FormHelperText>
                                                        </Grid>
                                                        <Grid item xs={12} sm={4} md={4} lg={2}>
                                                            <FormLabel component="legend">&nbsp;</FormLabel>
                                                            <PrimaryButton
                                                                fullWidth
                                                                size="small"
                                                                style={{ marginTop: 5 }}
                                                                onClick={() => handleAssociated(i, x.dateCreated)}
                                                            >
                                                                <Badge color="secondary" badgeContent={assoPartsCount} showZero>
                                                                    <span style={{ fontSize: 12 }}>Parts &nbsp;</span>
                                                                </Badge>
                                                            </PrimaryButton>
                                                            <FormHelperText error shrink>
                                                                {state.errors[i]?.isimageExist}
                                                            </FormHelperText>
                                                        </Grid>

                                                        {x.claimTypeIDTemp?.displayCausalParts && (
                                                            <React.Fragment>
                                                                <Grid item xs={12} sm={8} md={8} lg={6} xl={3} className="multiSelect">
                                                                    <FormLabel component="legend" error>
                                                                        Defect Code*
                                                                    </FormLabel>
                                                                    <SingelSelect
                                                                        onChange={singleSelectChange('defectCode', i)}
                                                                        options={screenDropdown.defectCodeList || []}
                                                                        value={x.defectCodeTemp || {}}
                                                                        disabled={state.makeDisbaled}
                                                                    />
                                                                    <FormHelperText error shrink>
                                                                        {state.errors[i]?.defectCode}
                                                                    </FormHelperText>
                                                                </Grid>

                                                                <Grid item xs={12} sm={4} md={4} lg={2} xl={1}>
                                                                    <FormLabel component="legend">&nbsp;</FormLabel>
                                                                    <PrimaryButton
                                                                        fullWidth
                                                                        size="small"
                                                                        style={{ marginTop: 5 }}
                                                                        onClick={() => handleFault(i, x.dateCreated)}
                                                                    >
                                                                        <Badge
                                                                            color="secondary"
                                                                            badgeContent={x.faultCodes?.filter((k) => k.isDeleted === false)?.length}
                                                                            showZero
                                                                        >
                                                                            <span style={{ fontSize: 12 }}>Fault Codes &nbsp;</span>
                                                                        </Badge>
                                                                    </PrimaryButton>
                                                                </Grid>

                                                                <Grid item xs={12} sm={4} md={4} lg={2}>
                                                                    <FormLabel component="legend" error>
                                                                        Causal Part No.*
                                                                    </FormLabel>
                                                                    <TextBox
                                                                        name="causualPartNumber"
                                                                        disabled={state.makeDisbaled}
                                                                        value={x.causualPartNumber}
                                                                        onChange={(e) => handleInputChange(e, i)}
                                                                        autoFocus={true}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <CustomIconButton
                                                                                        tabIndex={-1}
                                                                                        disabled={state.makeDisbaled}
                                                                                        onClick={pullDataByPartNumber(
                                                                                            x.causualPartNumber,
                                                                                            'causualPartNumber',
                                                                                            i,
                                                                                            true
                                                                                        )}
                                                                                    >
                                                                                        <SearchIcon />
                                                                                    </CustomIconButton>
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                    <FormHelperText error shrink>
                                                                        {state.errors[i]?.causualPartNumber}
                                                                    </FormHelperText>
                                                                    <PopoverWrapper
                                                                        handleClosePOP={handleClosePOP}
                                                                        anchorEl={anchorEl.causualPartNumber}
                                                                        matchingPartNumber={state.matchingPartNumber}
                                                                        handleRowSelect={handleRowSelect}
                                                                        selectedRowIndex={i}
                                                                        name={'causual'}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4} md={4} lg={2}>
                                                                    <FormLabel component="legend" error>
                                                                        Causal Description*
                                                                    </FormLabel>
                                                                    <TextBox
                                                                        name="causualPartDescription"
                                                                        disabled={state.makeDisbaled}
                                                                        value={x.causualPartDescription}
                                                                        onChange={(e) => handleInputChange(e, i)}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <CustomIconButton
                                                                                        tabIndex={-1}
                                                                                        disabled={state.makeDisbaled}
                                                                                        onClick={pullDataByPartNumber(
                                                                                            x.causualPartDescription,
                                                                                            'causualPartDescription',
                                                                                            i,
                                                                                            false
                                                                                        )}
                                                                                    >
                                                                                        <SearchIcon />
                                                                                    </CustomIconButton>
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                    <FormHelperText error shrink>
                                                                        {state.errors[i]?.causualPartDescription}
                                                                    </FormHelperText>
                                                                    <PopoverWrapper
                                                                        handleClosePOP={handleClosePOP}
                                                                        anchorEl={anchorEl.causualPartDescription}
                                                                        matchingPartNumber={state.matchingPartNumber}
                                                                        handleRowSelect={handleRowSelect}
                                                                        selectedRowIndex={i}
                                                                        name={'causual'}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12} sm={4} md={4} lg={2}>
                                                                    <FormLabel component="legend" error>
                                                                        New Part No.*
                                                                    </FormLabel>
                                                                    <TextBox
                                                                        name="newPartNumber"
                                                                        disabled={state.makeDisbaled}
                                                                        value={x.newPartNumber}
                                                                        onChange={(e) => handleInputChange(e, i)}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <CustomIconButton
                                                                                        tabIndex={-1}
                                                                                        disabled={state.makeDisbaled}
                                                                                        onClick={pullDataByPartNumber(
                                                                                            x.newPartNumber,
                                                                                            'newPartNumber',
                                                                                            i,
                                                                                            true
                                                                                        )}
                                                                                    >
                                                                                        <SearchIcon />
                                                                                    </CustomIconButton>
                                                                                </InputAdornment>
                                                                            )
                                                                        }}
                                                                    />
                                                                    <FormHelperText error shrink>
                                                                        {state.errors[i]?.newPartNumber}
                                                                    </FormHelperText>
                                                                    <PopoverWrapper
                                                                        handleClosePOP={handleClosePOP}
                                                                        anchorEl={anchorEl.newPartNumber}
                                                                        matchingPartNumber={state.matchingPartNumber}
                                                                        handleRowSelect={handleRowSelect}
                                                                        selectedRowIndex={i}
                                                                        name={'new'}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={4} md={4} lg={2}>
                                                                    <FormLabel component="legend">Old Serial No.</FormLabel>
                                                                    <TextBox
                                                                        name="causualPartSerialNumber"
                                                                        disabled={state.makeDisbaled}
                                                                        value={x.causualPartSerialNumber}
                                                                        onChange={(e) => handleInputChange(e, i)}
                                                                    />
                                                                    {/* <FormHelperText error shrink>
                                                        {state.errors[i]?.causualPartSerialNumber}
                                                    </FormHelperText> */}
                                                                </Grid>
                                                                <Grid item xs={12} sm={4} md={4} lg={2}>
                                                                    <FormLabel component="legend">New Serial No.</FormLabel>
                                                                    <TextBox
                                                                        name="newPartSerialNumber"
                                                                        disabled={state.makeDisbaled}
                                                                        value={x.newPartSerialNumber}
                                                                        onChange={(e) => handleInputChange(e, i)}
                                                                    />
                                                                    {/* <FormHelperText error shrink>
                                                        {state.errors[i]?.newPartSerialNumber}
                                                    </FormHelperText> */}
                                                                </Grid>

                                                                {/* <Grid item xs={12} sm={4} md={4} lg={2}>
                                                    <FormLabel component="legend" error>
                                                        New Description*
                                                    </FormLabel>
                                                    <TextBox
                                                        name="newPartDescription"
                                                        disabled={state.isTechReadonly}
                                                        value={x.newPartDescription}
                                                        onChange={(e) => handleInputChange(e, i)}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <CustomIconButton
                                                                        tabIndex={-1}
                                                                        disabled={state.isTechReadonly}
                                                                        onClick={pullDataByPartNumber(x.newPartDescription, 'newPartDescription', i, false)}
                                                                    >
                                                                        <SearchIcon />
                                                                    </CustomIconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                    <FormHelperText error shrink>
                                                        {state.errors[i]?.newPartDescription}
                                                    </FormHelperText>
                                                    <PopoverWrapper
                                                        handleClosePOP={handleClosePOP}
                                                        anchorEl={anchorEl.newPartDescription}
                                                        matchingPartNumber={state.matchingPartNumber}
                                                        handleRowSelect={handleRowSelect}
                                                        selectedRowIndex={i}
                                                        name={'new'}
                                                    />
                                                </Grid> */}

                                                                {/* <Grid item xs={12}>
                                                    <Grid container spacing={2}> */}

                                                                <Grid item xs={12} sm={6} md={'auto'} lg={3} xl={3}>
                                                                    <FormLabel component="legend" shrink>
                                                                        Material Located in Designated Area
                                                                    </FormLabel>
                                                                    <IsYesNOButton
                                                                        disabled={state.makeDisbaled}
                                                                        name="materialLocatedInDesignatedArea"
                                                                        value={x.materialLocatedInDesignatedArea}
                                                                        index={i}
                                                                        onClick={yesNoHandle}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={'auto'} lg={3} xl={3}>
                                                                    <FormLabel component="legend" shrink>
                                                                        Parts in Quarantine
                                                                    </FormLabel>
                                                                    <IsYesNOButton
                                                                        disabled={state.makeDisbaled}
                                                                        name="partInQuarantine"
                                                                        value={x.partInQuarantine}
                                                                        index={i}
                                                                        onClick={yesNoHandle}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={'auto'} lg={3} xl={3}>
                                                                    <FormLabel component="legend" shrink>
                                                                        Causual Parts Labelled
                                                                    </FormLabel>
                                                                    <IsYesNOButton
                                                                        disabled={state.makeDisbaled}
                                                                        name="causalPartsLabelled"
                                                                        value={x.causalPartsLabelled}
                                                                        index={i}
                                                                        onClick={yesNoHandle}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} sm={6} md={'auto'} lg={3} xl={3}>
                                                                    <FormLabel component="legend" shrink>
                                                                        Material in original Boxes
                                                                    </FormLabel>
                                                                    <IsYesNOButton
                                                                        disabled={state.makeDisbaled}
                                                                        name="materialInOrignalBoxes"
                                                                        value={x.materialInOrignalBoxes}
                                                                        index={i}
                                                                        onClick={yesNoHandle}
                                                                    />
                                                                </Grid>
                                                                {/* </Grid>
                                                </Grid> */}
                                                            </React.Fragment>
                                                        )}

                                                        {/* <Grid item xs={12} sm={1}>
                                            <FormLabel component="legend">&nbsp;</FormLabel>
                                            <PrimaryButton fullWidth size="small" style={{ marginTop: 5 }} onClick={() => handleAssociated(i, x.dateCreated)}>
                                                <Badge
                                                    color="secondary"
                                                    badgeContent={x.associatedMaterials?.filter((k) => k.isDeleted === false)?.length}
                                                    showZero
                                                >
                                                    <PhotoCameraIcon />
                                                </Badge>
                                            </PrimaryButton>
                                        </Grid> */}
                                                    </Grid>
                                                )
                                            );
                                        })}
                                        <Grid container item xs={12}></Grid>
                                    </Grid>
                                    {!portalSettings.hideWorkAccomplishedAndFailureReasons && (
                                        <>
                                            <Grid item xs={12} sm={6}>
                                                <FormLabel component="legend">Work Accomplished</FormLabel>
                                                <Multiselect
                                                    label="Work Accomplished"
                                                    options={masterData.workaccomplishcodes}
                                                    value={selectedData.selectedWorkAccomplishCodes}
                                                    onChange={AutoWorkAccomplish}
                                                    disabled={state.makeDisbaled}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <FormLabel component="legend">Failure Reasons</FormLabel>
                                                <Multiselect
                                                    label="Failu Rereason"
                                                    options={masterData.failureCodes}
                                                    value={selectedData.selectedFailureCodes}
                                                    onChange={AutoFrChange}
                                                    disabled={state.makeDisbaled}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12} className="gridcontainer">
                                        <div>
                                            <Typography style={{ float: 'left' }}>Diagnostic Road Test</Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={3}>
                                                    <FormLabel component="legend">Mileage In</FormLabel>
                                                    <TextBox
                                                        disabled={state.makeDisbaled}
                                                        value={state.mileagein}
                                                        type="number"
                                                        name="mileagein"
                                                        onChange={fieldChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={3} className="marginBottom">
                                                    <FormLabel component="legend">Mileage Out</FormLabel>
                                                    <TextBox
                                                        disabled={state.makeDisbaled}
                                                        value={state.mileageout}
                                                        type="number"
                                                        name="mileageout"
                                                        onChange={fieldChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={3}>
                                                    <FormLabel component="legend">Time In</FormLabel>
                                                    <TextBox
                                                        disabled={state.makeDisbaled}
                                                        name="timein"
                                                        value={state.timein}
                                                        type="time"
                                                        onChange={fieldChange}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        inputProps={{
                                                            step: 300 // 5 min
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={3} className="marginBottom">
                                                    <FormLabel component="legend">Time Out</FormLabel>
                                                    <TextBox
                                                        disabled={state.makeDisbaled}
                                                        name="timeout"
                                                        type="time"
                                                        value={state.timeout}
                                                        onChange={fieldChange}
                                                        InputLabelProps={{
                                                            shrink: true
                                                        }}
                                                        inputProps={{
                                                            step: 300 // 5 min
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <FormControlLabel
                                            control={
                                                <SecondaryCheckbox
                                                    disabled={state.makeDisbaled}
                                                    checked={state.smoketest}
                                                    onChange={handleCheck}
                                                    value={state.smoketest}
                                                    name="smoketest"
                                                />
                                            }
                                            label="Smoke Test Performed"
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <FormControlLabel
                                            control={
                                                <SecondaryCheckbox
                                                    disabled={state.makeDisbaled}
                                                    checked={state.serviceindicator}
                                                    onChange={handleCheck}
                                                    value={state.serviceindicator}
                                                    name="serviceindicator"
                                                />
                                            }
                                            label="Service Indicator Reset "
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <FormControlLabel
                                            control={
                                                <SecondaryCheckbox
                                                    disabled={state.makeDisbaled}
                                                    checked={state.radiocode}
                                                    onChange={handleCheck}
                                                    name="radiocode"
                                                    value={state.radiocode}
                                                />
                                            }
                                            label="Radio Code Reset"
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <FormControlLabel
                                            control={
                                                <SecondaryCheckbox
                                                    disabled={state.makeDisbaled}
                                                    checked={state.timeclock}
                                                    onChange={handleCheck}
                                                    name="timeclock"
                                                    value={state.timeclock}
                                                />
                                            }
                                            label="Time Clock Reset"
                                        />
                                    </Grid>
                                    <>
                                        {state.imgtechniciansign && (
                                            <Grid item xs={12} sm={6}>
                                                <CustomeSignPad
                                                    onChange={GetTechnicianSign}
                                                    content={
                                                        <FormLabel
                                                            component="legend"
                                                            className="mandatoryfields"
                                                            style={{
                                                                marginBottom: '9px',
                                                                marginTop: '5px'
                                                            }}
                                                        >
                                                            Technician
                                                            {hideBracket ? (
                                                                <span> ({`${state.technicianName || ''} ${state.technicianDateSigned}`})</span>
                                                            ) : (
                                                                ' Signature'
                                                            )}
                                                        </FormLabel>
                                                    }
                                                    sign={state.imgtechniciansign}
                                                    name="techniciansign"
                                                    hideBtn={true}
                                                    clearSig={() => clearSig('techniciansign')}
                                                />
                                            </Grid>
                                        )}
                                        {(showSubmit || state.imgteamleadersign) && canSignOffTechWriteup && (
                                            <Grid item xs={12} sm={6} style={{ marginTop: state.imgteamleadersign ? 0 : state.imgtechniciansign ? 4 : 11 }}>
                                                <CustomeSignPad
                                                    onChange={GetTechnicianSign}
                                                    content={
                                                        <FormLabel
                                                            component="legend"
                                                            className="mandatoryfields"
                                                            style={{
                                                                marginBottom: '9px',
                                                                marginTop: '5px'
                                                            }}
                                                        >
                                                            Workshop Controller
                                                            {hideLeaderBracket ? (
                                                                <span>({`${state.teamLeaderName || ''} ${state.teamLeaderDateSigned}`})</span>
                                                            ) : (
                                                                ' Signature'
                                                            )}
                                                        </FormLabel>
                                                    }
                                                    sign={state.imgteamleadersign}
                                                    name="teamleadersign"
                                                    hideBtn={state.imgteamleadersign ? true : false}
                                                    clearSig={() => clearSig('teamleadersign')}
                                                />
                                                <span className="mandatoryfields">{state.errors['teamleadersign']}</span>
                                            </Grid>
                                        )}
                                    </>
                                    <Grid item xs={12} sm={4} style={{ marginTop: '25px' }}></Grid>
                                </>
                                {/* {!showSubmit && (
                                    <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '90vh' }}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography color="secondary" variant="h2" component="h2">
                                                Technician write up not completed
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )} */}
                            </Grid>

                            {state.showAssociated ? (
                                <DialogComp title={'Associated Materials'} hideClose={true} maxWidth="lg" onClose={closeModal} fullWidth>
                                    <AssociatedMaterial
                                        onClose={closeModal}
                                        list={[...inputList[state.modalIndex].associatedMaterials]}
                                        selectedWriteUp={{ ...inputList[state.modalIndex] }}
                                        handleAssociatedRow={handleAssociatedRow}
                                        removeAssociatedRow={removeAssociatedRow}
                                        handleAssociateInput={handleAssociateInput}
                                        isReadOnly={state.makeDisbaled}
                                        setInputList={setInputList}
                                        modalIndex={state.modalIndex}
                                        submitChildAssocate={submitChildAssocate}
                                        screenDropdown={screenDropdown}
                                        userlinkList={[...state.partsToLink] || []}
                                        handleLinkRows={handleLinkRows}
                                    />
                                </DialogComp>
                            ) : null}
                            {state.showFault ? (
                                <DialogComp title={'Fault Codes'} hideClose={true} maxWidth="md" onClose={closeModal}>
                                    <FaultCode
                                        onClose={closeModal}
                                        list={inputList[state.modalIndex].faultCodes}
                                        handleFaultCodeRow={handleFaultCodeRow}
                                        removeFaultCodeRow={removeFaultCodeRow}
                                        handleFaultCodeInput={handleFaultCodeInput}
                                        isReadOnly={state.makeDisbaled}
                                    />
                                </DialogComp>
                            ) : null}
                            {showSubmit && !state.imgteamleadersign && !isAuditor && !isCustomer && (canAmendTechWriteUpFlag || canSignOffTechWriteup) ? (
                                <SecondaryButton className="btn_add setFullWidth" onClick={technician.length > 1 ? handleSubmit : submitFunction}>
                                    Submit
                                </SecondaryButton>
                            ) : null}
                            {isDeleteTechWriteUp.show ? (
                                <DialogComp title={'Warning'} maxWidth="md" onClose={closeIsDeleteTechWriteUpModal} fullWidth>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Alert severity="warning">
                                                Are you sure you want to delete write up for {isDeleteTechWriteUp.selectedData?.technician}
                                            </Alert>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SecondaryButton
                                                fullWidth
                                                onClick={deleteTechWriteUpHandle}
                                                // disabled={!state.partReturnCollectedSign}
                                                //  isBtnLoader={btnLoader}
                                            >
                                                Yes
                                            </SecondaryButton>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <SecondaryButton fullWidth onClick={closeIsDeleteTechWriteUpModal}>
                                                No
                                            </SecondaryButton>
                                        </Grid>
                                    </Grid>
                                </DialogComp>
                            ) : null}
                            {state.showTechAssign ? (
                                <DialogComp title="Assigned Technician" maxWidth="sm" onClose={() => closeTechAssignDialog(false)} fullWidth>
                                    <AssignTech jobId={Id} handleCancel={closeTechAssignDialog} />
                                </DialogComp>
                            ) : null}
                        </>
                    )}
                </DialogContents>
            </div>
        </>
    );
};
export default TechWrite_Up;
