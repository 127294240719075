import React, { useEffect, useState } from 'react';
import { getPartReturnStatus } from '../../../../Core/Service/PartReq_services/partReq-service';
import { Grid } from '@material-ui/core';
import TabComponent from '../../../../Core/Controls/Tabs';
import RenaultPartReqsList from '../RenaultPartReqsList';
import { changeLanguage } from 'i18next';
import PartReqsSurchargedList from '../../part_req_supplier/partSurchargedList';

const RenaultReturnReasonTabs = (props) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    let indexID = params.get('id') || 0;

    const [state, setState] = useState({ dropDownRes: [], selectedIndex: 0 });

    useEffect(() => {
        async function fetch() {
            try {
                let dropDownRes = await getPartReturnStatus('');
                if (dropDownRes.success) {
                    let index = dropDownRes.data.list.findIndex((k) => indexID == k.id);
                    setState((st) => ({ ...st, dropDownRes: dropDownRes.data.list, selectedIndex: index > -1 ? index : 0 }));
                }
            } catch (error) {
                console.error('Return Reason ', error);
            }
        }
        fetch();
    }, []);

    const tabs = state.dropDownRes.map((q) => {
        return {
            label: `${q.name}`,
            body: <PartReqsSurchargedList id={q.id} />
        };
    });

    return (
        <Grid container>
            <Grid item xs={12}>
                <TabComponent tabList={tabs} showWarning={state.isValueChange} selectedIndex={+state.selectedIndex} />
            </Grid>
        </Grid>
    );
};

export default RenaultReturnReasonTabs;
