import React, { useContext, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppContext from '../../App/AppContext';
import { PasswordBox, SecondaryButton, PrimaryButton } from '../FormInput';
import { makeStyles } from '@material-ui/core/styles';
import { ChangePasswordAfterExpiry } from '../Service/changePassword-service';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import './model.css';
import { AppStorage } from '../Service/storage-service';

const ExpiryResetPasswordModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);

    const [state, setState] = useState({
        newPassword: '',
        confirmPassword: '',
        enableChange: false,
        isResetLoading: false,
        errors: {}
    });

    const handleClose = () => {
        props.onClose();
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            nst.enableChange = nst.newPassword && nst.confirmPassword && nst.newPassword === nst.confirmPassword;
            return nst;
        });
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        let minLength = AppStorage.getPortalSettingPassLength();
        let pattern = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&#])[A-Za-z\\d@$!%*?&#]{${minLength},}$`, 'gm');
        if (!pattern.test(state.newPassword)) {
            console.log('newPassword', state.newPassword);
            formIsValid = false;
            errors['newPassword'] = `Password should be complex with ${minLength} char minimum.`;
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };
    const handleChangePassword = async (e) => {
        e.preventDefault();
        if (state.newPassword && state.confirmPassword) {
            if (handleValidation()) {
                setState((st) => ({ ...st, isResetLoading: true }));
                let data = {
                    newPassword: state.newPassword,
                    confirmPassword: state.confirmPassword
                };
                const res = await ChangePasswordAfterExpiry(data, { headers: { Authorization: `Bearer ${props.temporaryToken}` } });
                if (!res.success) {
                    console.error(res);
                } else {
                    showToast(res.message);
                    props.onClose();
                }
                setState((st) => ({ ...st, isResetLoading: false }));
            }
        } else {
            showToast('New password and confirm password is required.');
        }
        hideModal();
    };
    return (
        <Dialog open={true} onClose={handleClose} maxWidth="sm">
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                    <DialogTitle>Reset Password</DialogTitle>
                </Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContent>
                <PasswordBox autoFocus label="New Password" name="newPassword" onChange={fieldChange} />
                <span className="mandatoryfields">{state.errors?.newPassword}</span>

                <PasswordBox label="Confirm Password" name="confirmPassword" onChange={fieldChange} />
                <PrimaryButton className="Submitbtn" onClick={handleChangePassword} disabled={!state.enableChange} isBtnLoader={state.isResetLoading}>
                    Submit
                </PrimaryButton>
                <span className="mandatoryfields">Your password has expired. Please reset it to continue.</span>
            </DialogContent>
        </Dialog>
    );
};

export default ExpiryResetPasswordModal;
