import { createContext, useCallback, useEffect, useState, useRef, useContext } from 'react';
import { getFailureCodes } from '../../../Core/Service/failureCode-service';
import { getWorkAccomplishCodes } from '../../../Core/Service/workaccomplished-service';
import {
    getTechWriteUp,
    AddTechWriteUp,
    getTechWriteUpPdfFile,
    getRenault_TechnicianWriteUp_ScreenDropdown,
    getTechWriteUpPdfWithoutFmFile,
    getTechWriteUpPdfWithFmFile,
    getSTDWriteUpPdfFile
} from '../../../Core/Service/techWriteUp-service';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import AppContext from '../../../App/AppContext';
import formatters from '../../../Core/Grid/inputFormatter';
import { AppStorage } from '../../../Core/Service/storage-service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import _, { forEach } from 'lodash';
import { getPartListByPartNumber } from '../../../Core/Service/partRequisitions-service';
import { getPartReqRenaultStatus } from '../../../Core/Service/PartReq_services/partReq-service';
// import { backgroundClip } from 'html2canvas/dist/types/css/property-descriptors/background-clip';
export const TechWriteScreenContext = createContext();

export const TechWriteProvider = (props) => {
    const { hideModal, showToast, hideTopNavAndMenu, portalSettings, userRoles } = useContext(AppContext);
    const urlParams = new URLSearchParams(props.location.search.toLowerCase());
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const fromStep = urlParams.get('fromstep') === 'true';
    const [anchorEl, setAnchorEl] = useState({
        causualPartNumber: null,
        causualPartDescription: null
    });
    const [inputList, setInputList] = useState([
        {
            technicianNotesItemFault: '',
            technicianNotesItemDiagnosis: '',
            technicianNotesItemCause: '',
            timeTaken: null,
            technicianNotesItemID: null,
            defectCode: '',
            isDeleted: false,
            materialLocatedInDesignatedArea: false,
            partInQuarantine: false,
            causalPartsLabelled: false,
            materialInOrignalBoxes: false,
            dateCreated: moment().format('YYYY-MM-DDTHH:mm:ss'),
            faultCodes: [],
            associatedMaterials: [],
            claimTypeIDTemp: {},
            defectCodeTemp: {}
        }
    ]);

    const isTechnician = userRoles.includes('technician');
    const isInternal = userRoles.includes('internal');

    let IsUpperCase = portalSettings?.portalSettingWriteupsInUpperCase;

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
    }, []);

    const [isFittedWarnig, setIsFittedWarnig] = useState({
        show: false,
        list: []
    });
    const [masterData, setMasterData] = useState({
        failureCodes: [],
        workaccomplishcodes: []
    });
    const [selectedData, setSelectedData] = useState({
        selectedFailureCodes: [],
        selectedWorkAccomplishCodes: []
    });
    const [screenDropdown, setScreenDropdown] = useState({
        claimTypes: [],
        defectCodeList: [],
        partReqStatus: []
    });
    const signCanvas = useRef({});
    const clear = () => {
        if (state.imgtechniciansign) {
            setState((st) => ({
                ...st,
                imgtechniciansign: null,
                techniciansign: null
            }));
        } else {
            signCanvas.current.clear();
        }
    };
    const [state, setState] = useState({
        showModal: false,
        fontResize: 16,
        technicians: [],
        readingtaken: '',
        failurereason: [],
        timeout: '',
        date: moment().format('YYYY-MM-DD'),
        timein: '',
        mileageout: '',
        mileagein: '',
        workaccomplished: [],
        techniciansign: null,
        imgtechniciansign: null,
        smoketest: false,
        serviceindicator: false,
        radiocode: false,
        wipNumber: '',
        timeclock: false,
        registration: '',
        id: null,
        fields: {},
        errors: {},
        showGrid: false,
        GridID: null,
        odometerUnitsVM: [],
        technicianNotesReadingUnit: null,
        showCircularLoader: true,
        technicianNotesJobID: null,
        newJOBId: null,
        technicianName: '',
        technicianDateSigned: '',
        teamLeaderName: '',
        teamLeaderDateSigned: '',
        signedOff: false,
        showSaveConfirmModal: false,
        showFault: false,
        showAssociated: false,
        modalIndex: null,
        dateCreated: '',
        expandRowIndex: 0,
        isFMOrWarranty: false,
        partsToLink: [],
        showDisplayCausalParts: false
    });

    const handleFault = (index, date) => {
        let TempList = [...inputList[index].faultCodes];
        // if (TempList.length === 0) {
        //     const defaultFault = {
        //         technicianNotesItemsFaultCodeID: null,
        //         technicianNotesItemsID: null,
        //         technicianNotesItemsFaultCode: '',
        //         technicianNotesItemsFaultDescription: '',
        //         dateCreated: inputList[index].dateCreated
        //     };
        //     TempList.unshift(defaultFault);
        // }
        setInputList((st) => {
            let newSt = [...st];
            newSt[index].faultCodes = [...TempList];
            return newSt;
        });
        setState((st) => ({ ...st, showFault: true, modalIndex: index }));
    };
    const handleAssociated = (index, date) => {
        let TempList = [...inputList[index].associatedMaterials];
        // if (TempList.length === 0) {
        //     const defaultMaterial = {
        //         partReqID: null,
        //         technicianNotesItemID: null,
        //         partNumber: '',
        //         partDescription: '',
        //         partQty: '',
        //         dateCreated: inputList[index].dateCreated
        //     };
        //     TempList.unshift(defaultMaterial);
        // }
        setInputList((st) => {
            let newSt = [...st];
            newSt[index].associatedMaterials = [...TempList];
            return newSt;
        });
        setState((st) => ({ ...st, showAssociated: true, modalIndex: index, dateCreated: date }));
    };

    const closeModal = () => {
        setState((st) => ({ ...st, showAssociated: false, showFault: false, modalIndex: null }));
    };

    const handleFaultCodeRow = () => {
        let TempList = [...inputList[state.modalIndex].faultCodes];
        TempList.unshift({
            technicianNotesItemsFaultCodeID: null,
            technicianNotesItemsID: null,
            isDeleted: false,
            isActive: false,
            technicianNotesItemsFaultCode: '',
            technicianNotesItemsFaultDescription: '',
            dateCreated: inputList[state.modalIndex].dateCreated
        });

        setInputList((st) => {
            let newSt = [...st];
            newSt[state.modalIndex].faultCodes = [...TempList];
            return newSt;
        });
    };
    const removeFaultCodeRow = (index) => {
        setInputList((prevState) => {
            const newState = [...prevState];
            const updatedFaultCodes = [...newState[state.modalIndex].faultCodes];
            if (updatedFaultCodes[index]?.technicianNotesItemsFaultCodeID) {
                updatedFaultCodes[index].isDeleted = true;
            } else {
                updatedFaultCodes.splice(index, 1);
            }

            newState[state.modalIndex].faultCodes = updatedFaultCodes;

            return newState;
        });
    };

    const handleFaultCodeInput = (e, index) => {
        const { name, value, checked } = e.target;
        setInputList((st) => {
            let newSt = [...st];
            let updatedNestedArray = [...st[state.modalIndex].faultCodes];
            if (name == 'isActive') {
                updatedNestedArray[index][name] = checked;
            } else {
                updatedNestedArray[index][name] = value;
            }
            return newSt;
        });
    };

    const handleAssociatedRow = () => {
        let TempList = [...inputList[state.modalIndex].associatedMaterials];
        TempList.push({
            partReqID: null,
            technicianNotesItemID: null,
            partNumber: '',
            partDescription: '',
            isDeleted: false,
            isFitted: false,
            isReturnToPartsDepo: false,
            partQty: '',
            dateCreated: inputList[state.modalIndex].dateCreated,
            lineID: 10 + TempList.length + 2,
            parentLineID: null
        });

        setInputList((st) => {
            let newSt = [...st];
            newSt[state.modalIndex].associatedMaterials = [...TempList];
            return newSt;
        });
    };

    const handleLinkRows = (e, value) => {
        let TempList = [...inputList[state.modalIndex].associatedMaterials];
        let TempPartsToLink = [...state.partsToLink];

        if (e.target.checked) {
            let TempPartsToLinkIndex = TempPartsToLink.findIndex((q) => q.partReqID == value.partReqID);
            TempPartsToLink[TempPartsToLinkIndex].isLinked = true;
            TempPartsToLink[TempPartsToLinkIndex].technicianNotesItemID = inputList[state.modalIndex].technicianNotesItemID || null;
            TempPartsToLink[TempPartsToLinkIndex].dateCreated = inputList[state.modalIndex].technicianNotesItemID
                ? ''
                : inputList[state.modalIndex].dateCreated;
            // TempList.push({
            //     ...value,
            //     technicianNotesItemID: inputList[state.modalIndex].technicianNotesItemID,
            //     dateCreated: value.dateCreated || inputList[state.modalIndex].dateCreated
            // });
        } else {
            // let objIndex = TempList.findIndex((q) => q.lineID == value.lineID);
            // TempList.splice(objIndex, 1);
            let TempPartsToLinkIndex = TempPartsToLink.findIndex((q) => q.partReqID == value.partReqID);
            TempPartsToLink[TempPartsToLinkIndex].isLinked = false;
            TempPartsToLink[TempPartsToLinkIndex].technicianNotesItemID = null;
            TempPartsToLink[TempPartsToLinkIndex].dateCreated = '';
        }

        // setInputList((st) => {
        //     let newSt = [...st];
        //     newSt[state.modalIndex].associatedMaterials = [...TempList];
        //     return newSt;
        // });
        setState((st) => ({ ...st, partsToLink: TempPartsToLink }));
    };

    const removeAssociatedRow = (index) => {
        setInputList((prevState) => {
            const newState = [...prevState];
            const updatedAssociatedMaterials = [...newState[state.modalIndex].associatedMaterials];
            let objIndex = updatedAssociatedMaterials.findIndex((q) => q.lineID == index);
            if (updatedAssociatedMaterials[objIndex]?.partReqID) {
                updatedAssociatedMaterials[objIndex].isDeleted = true;
            } else {
                updatedAssociatedMaterials.splice(objIndex, 1);
            }
            newState[state.modalIndex].associatedMaterials = updatedAssociatedMaterials;
            return newState;
        });
    };

    const handleAssociateInput = (e, index) => {
        const { name, value, checked } = e.target;

        setInputList((st) => {
            let newSt = [...st];
            let updatedNestedArray = [...st[state.modalIndex].associatedMaterials];
            let objIndex = updatedNestedArray.findIndex((q) => q.lineID == index);

            if (name == 'isFitted' || name == 'isReturnToPartsDepo') {
                updatedNestedArray[objIndex][name] = checked;
                updatedNestedArray.forEach((q) => {
                    if (q.parentLineID == index) {
                        q[name] = checked;
                    }
                });
            } else if (name === 'partReqStatus') {
                let isPhotoREQ = screenDropdown.partReqStatus.find((k) => value === k.id)?.photoRequired;
                console.log(value, 'value');
                console.log(isPhotoREQ, 'isPhotoREQ');
                if (isPhotoREQ) {
                    updatedNestedArray[objIndex]['isImageErrorMessage'] = 'Image is Required';
                } else {
                    delete updatedNestedArray[objIndex]['isImageErrorMessage'];
                }
                updatedNestedArray[objIndex][name] = value;
            } else {
                updatedNestedArray[objIndex][name] = value;
            }
            return newSt;
        });
    };

    const submitChildAssocate = (DataObj, selectedAssociatedId) => {
        let TempList = [...inputList[state.modalIndex].associatedMaterials];
        TempList.unshift({
            partReqID: null,
            technicianNotesItemID: null,
            partNumber: DataObj.partNumber || '',
            partDescription: DataObj.partDescription || '',
            partQty: DataObj.partQty || '',
            isDeleted: false,
            dateCreated: inputList[state.modalIndex].dateCreated,
            lineID: 50 + TempList.length + 2,
            parentLineID: selectedAssociatedId
        });

        setInputList((st) => {
            let newSt = [...st];
            newSt[state.modalIndex].associatedMaterials = [...TempList];
            return newSt;
        });
    };

    useEffect(() => {
        console.log(inputList, 'inputList');
    }, [inputList]);

    const handleValidation = () => {
        let fields = state.fields;
        let errors = {};
        const list = [...inputList];
        let formIsValid = true;
        if (!state.technicianNotesReadingUnit) {
            formIsValid = false;
            errors['technicianNotesReadingUnit'] = 'Please select Unit';
        }
        if (state.date === 'Invalid date') {
            formIsValid = false;
            errors['date'] = 'Date cannot be empty';
        }
        if (!state.readingtaken) {
            formIsValid = false;
            errors['readingtaken'] = 'Reading taken cannot be empty';
        }

        if (!state.techniciansign) {
            formIsValid = false;
            errors['techniciansign'] = 'Technician signature cannot be empty';
        }

        let isNittedAndReturedNullList = [];
        list.forEach((g, i) => {
            let displayCausalParts = g.claimTypeIDTemp?.displayCausalParts || false;
            errors[i] = {};
            if (!g.claimTypeIDTemp && state.isFMOrWarranty) {
                formIsValid = false;
                errors[`${i}`]['claimTypeID'] = 'Claim Type is required';
            }
            if (!g.technicianNotesItemFault) {
                formIsValid = false;
                errors[`${i}`]['technicianNotesItemFault'] = 'Complaint is required';
            }
            if (!g.technicianNotesItemCause) {
                formIsValid = false;
                errors[`${i}`]['technicianNotesItemCause'] = 'Cause is required';
            }
            if (!g.technicianNotesItemDiagnosis) {
                formIsValid = false;
                errors[`${i}`]['technicianNotesItemDiagnosis'] = 'Cure is required';
            }
            if (!g.causualPartNumber && displayCausalParts) {
                formIsValid = false;
                errors[`${i}`]['causualPartNumber'] = 'Part number is required';
            }
            if (!g.causualPartDescription && displayCausalParts) {
                formIsValid = false;
                errors[`${i}`]['causualPartDescription'] = 'Description is required';
            }
            // if (!g.causualPartSerialNumber && state.isFMOrWarranty) {
            //     formIsValid = false;
            //     errors[`${i}`]['causualPartSerialNumber'] = 'Serial number is required';
            // }
            if (!g.newPartNumber && displayCausalParts) {
                formIsValid = false;
                errors[`${i}`]['newPartNumber'] = 'Part number is required';
            }
            // if (!g.newPartDescription && state.isFMOrWarranty) {
            //     formIsValid = false;
            //     errors[`${i}`]['newPartDescription'] = 'Description is required';
            // }
            // if (!g.newPartSerialNumber && state.isFMOrWarranty) {
            //     formIsValid = false;
            //     errors[`${i}`]['newPartSerialNumber'] = 'Serial number is required';
            // }
            if (!g.defectCode && displayCausalParts) {
                formIsValid = false;
                errors[`${i}`]['defectCode'] = 'Defect code is required';
            }
            if (!g.claimTypeID && displayCausalParts) {
                formIsValid = false;
                errors[`${i}`]['claimTypeID'] = 'Claim type is required';
            }
            if (!g.timeTaken) {
                formIsValid = false;
                errors[`${i}`]['timeTaken'] = 'Time taken is required';
            }
            let isimageExist = g.associatedMaterials.findIndex((k) => k.isImageErrorMessage);
            if (isimageExist > -1) {
                errors[`${i}`]['isimageExist'] = 'Please upload required images';
                formIsValid = false;
            }
        });

        // if (list[0]['technicianNotesItemCause'] === '') {
        //     formIsValid = false;
        //     errors['cause'] = 'Cause cannot be empty';
        // }
        // if (list[0]['technicianNotesItemFault'] === '') {
        //     formIsValid = false;
        //     errors['complaint'] = 'Complaint cannot be empty';
        // }
        // if (list[0]['technicianNotesItemDiagnosis'] === '') {
        //     formIsValid = false;
        //     errors['cure'] = 'Cure cannot be empty';
        // }

        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };
    useEffect(() => {
        const selectedFailure = masterData.failureCodes.filter((w) => state.failurereason.indexOf(w.id) > -1);
        const selectedWorkAccomplish = masterData.workaccomplishcodes.filter((w) => state.workaccomplished.indexOf(w.id) > -1);
        setSelectedData((st) => ({
            ...st,
            selectedFailureCodes: selectedFailure,
            selectedWorkAccomplishCodes: selectedWorkAccomplish
        }));
    }, [state.failurereason, state.workaccomplished, masterData.failureCodes, masterData.workaccomplishcodes]);
    const Id = props.match.params.jobId;

    const isTechReadonly = props.match.params?.isTechReadonly;

    const DisplayGridState = (i) => {
        setState((st) => ({
            ...st,
            showGrid: true,
            GridID: i
        }));
    };

    const TechBtnClick = (id) => {
        if (state.technicianNotesJobID != id) {
            pullTechWriteUp(id);
        }
    };

    const pullDataByPartNumber = (val, name, index, isPart) => async (event) => {
        let res = {};
        setAnchorEl((st) => ({
            ...st,
            [name]: event.currentTarget
        }));
        if (isPart) {
            res = await getPartListByPartNumber(val, null);
        } else {
            res = await getPartListByPartNumber(null, val);
        }
        if (res.success) {
            setState((st) => ({ ...st, matchingPartNumber: res.data.partDetails, selectedRowIndex: index, loading: false }));
        }
    };

    const handleRowSelect = (params, selectedRowIndex, name) => {
        const list = [...inputList];
        if (name == 'causual') {
            list[state.selectedRowIndex].causualPartNumber = params.partNumber;
            list[state.selectedRowIndex].causualPartDescription = params.description;
        }
        if (name == 'new') {
            list[state.selectedRowIndex].newPartNumber = params.partNumber;
            list[state.selectedRowIndex].newPartDescription = params.description;
        }
        setInputList(list);

        setAnchorEl({});
    };

    const handleClosePOP = () => {
        setAnchorEl({});
        setState((st) => ({ ...st, matchingPartNumber: [] }));
    };

    useEffect(async () => {
        let res1 = await getWorkAccomplishCodes();
        if (res1.success) {
            const WorkAccomplishlist = res1.data.map((w) => ({
                id: w.workAccomplishedCodeID,
                name: w.workAccomplishedDescription
            }));
            setMasterData((st) => ({
                ...st,
                workaccomplishcodes: WorkAccomplishlist
            }));
        }
        let res = await getFailureCodes();
        if (res.success) {
            const failureCode = res.data.map((w) => ({
                id: w.failureCode,
                name: w.failureDescription
            }));
            setMasterData((st) => ({ ...st, failureCodes: failureCode }));
        }
    }, []);

    const canAmendTechWriteUpFlag = AppStorage.getCanAmendTechWriteUp();

    const pullTechWriteUp = async (technicianNotesJobID) => {
        let resDown = await getRenault_TechnicianWriteUp_ScreenDropdown();
        setScreenDropdown((st) => ({ ...st, claimTypes: resDown.data.claimTypes || [], defectCodeList: resDown.data.defectCodeList || [] }));
        if (state.technicianNotesJobID != technicianNotesJobID || technicianNotesJobID == null) {
            let result = await getTechWriteUp(Id, technicianNotesJobID);
            const updatedUser = result?.data;

            if (updatedUser) {
                setState((st) => ({
                    ...st,
                    isFMOrWarranty: updatedUser.isFMOrWarranty,
                    technicianNotesJobID: updatedUser.technicianJobID,
                    newJOBId: updatedUser.technicianJobID,
                    status: updatedUser.status,
                    id: updatedUser.technicianNotesHeaderRecordID,
                    registration: updatedUser.registration,
                    technicianNotesItemCause: updatedUser.technicianNotestechnicianNotesItemCause,
                    workaccomplished: updatedUser.techWorkAccomplished.map((m) => m.techWorkAccomplishedCodeID),
                    failurereason: updatedUser.techFailureCodes.map((m) => m.techFailureCodeID),
                    technicianNotesItemDiagnosis: updatedUser.technicianNotesActionTaken,
                    date: updatedUser.technicianNotesDate ? formatters.DateFormatter(updatedUser.technicianNotesDate) : moment().format('YYYY-MM-DD'),
                    readingtaken: updatedUser.technicianNotesReadingTaken || '',
                    technicianNotesItemFault: updatedUser.technicianNotesFault,
                    mileagein: updatedUser.technicianNotesMileageIn || '',
                    mileageout: updatedUser.technicianNotesMileageOut || '',
                    radiocode: updatedUser.technicianNotesRadioCodeReset,
                    serviceindicator: updatedUser.technicianNotesServiceIndicatorReset,
                    smoketest: updatedUser.technicianNotesSmokeTestPerformed,
                    imgtechniciansign: updatedUser.technicianNotesTechnicianSignature,
                    techniciansign: updatedUser.technicianNotesTechnicianSignature,
                    imgteamleadersign: updatedUser.technicianNotesTeamLeaderSignature,
                    timeclock: updatedUser.technicianNotesTimeClockReset,
                    timein: updatedUser.technicianNotesTimeIn ? pad(updatedUser.technicianNotesTimeIn).toString().replace('.', ':') : '',
                    timeout: updatedUser.technicianNotesTimeOut ? pad(updatedUser.technicianNotesTimeOut).toString().replace('.', ':') : '',
                    wipNumber: updatedUser.wipNumber,
                    odometerUnitsVM: updatedUser?.odometerUnitsVM?.map((w) => ({
                        id: w.odometerUnitID,
                        name: w.odometerUnitDescription
                    })),
                    technicianNotesReadingUnit: updatedUser.technicianNotesReadingUnit,
                    technicians: updatedUser.technicians,
                    isTechReadonly:
                        isInternal && canAmendTechWriteUpFlag
                            ? false
                            : props.match.params?.technicianJobId == technicianNotesJobID && isTechReadonly == 'Y'
                            ? false
                            : true,

                    // isTechReadonly:
                    //     !canAmendTechWriteUpFlag && (props.match.params?.technicianJobId == st.technicianNotesJobID && isTechReadonly == 'Y' ? false : true),
                    showCircularLoader: false,
                    technicianName: updatedUser.technicianNotesTechnicianName,
                    technicianDateSigned: formatters.DateTimeFormatter(updatedUser.technicianNotesTechnicianDateSigned),
                    teamLeaderName: updatedUser.technicianNotesTeamLeaderName,
                    teamLeaderDateSigned: formatters.DateTimeFormatter(updatedUser.technicianNotesTeamLeaderDateSigned),
                    customerName: updatedUser.customerName,
                    signedOff: false, // default false from techwrite up
                    partsToLink: updatedUser.partsToLink?.map((q) => ({ ...q, dateCreated: '' })) || []
                }));
                setInputList(
                    !updatedUser.technicianNotesItem.length
                        ? [
                              {
                                  technicianNotesItemFault: '',
                                  technicianNotesItemDiagnosis: '',
                                  technicianNotesItemCause: '',
                                  timeTaken: '',
                                  technicianNotesItemID: null,
                                  defectCode: '',
                                  isDeleted: false,
                                  dateCreated: moment().format('YYYY-MM-DDTHH:mm:ss'),
                                  faultCodes: [],
                                  associatedMaterials: []
                              }
                          ]
                        : updatedUser.technicianNotesItem.map((k) => ({
                              ...k,
                              timeTaken: k.timeTaken || '',
                              isDeleted: false,
                              faultCodes: k.faultCodes.map((d) => ({ ...d, isDeleted: false })),
                              associatedMaterials: k.associatedMaterials.map((d) => ({ ...d, isDeleted: false })),
                              defectCodeTemp: resDown.data.defectCodeList.find((w) => w.id == k.defectCode) || '',
                              claimTypeIDTemp: resDown.data.claimTypes.find((w) => w.id == k.claimTypeID) || ''
                          }))
                );
            }
        }
    };

    useEffect(() => {
        setState((st) => ({ ...st, showCircularLoader: true }));
        pullTechWriteUp(props.match.params?.technicianJobId);
    }, []);

    function pad(number) {
        // Check if the number is an integer
        if (Number.isInteger(number)) {
            return number < 10 ? '0' + number : number.toString();
        } else {
            // Convert the number to a string with exactly two decimal places
            var str = number.toFixed(2);
            // Check if the whole part is less than 10 and add a leading zero if necessary
            if (number < 10 && number >= 0) {
                str = '0' + str;
            }
            return str;
        }
    }

    const saveButtonConfirmationValidation = () => {
        if (state.techniciansign && portalSettings.portalSettingDisallowTechnicianSignatureOnSaveButton) {
            setState((st) => {
                const nst = { ...st, showSaveConfirmModal: true };
                return nst;
            });
        } else {
            handleSubmit('T');
        }
    };

    const handleSubmit = async (val) => {
        setState((st) => ({ ...st, submitLoader: true }));
        let res = '';

        let data = {
            partsToLink: state.partsToLink || [],
            Mode: val,
            TechnicianNotesDate: state.date,
            TechnicianNotesHeaderRecordID: state.id,
            technicianNotesItem: inputList.map((newMap) => ({
                ...newMap,
                newPartDescription: newMap.causualPartDescription,
                timeTaken: newMap.timeTaken ? newMap.timeTaken : null
            })),
            TechnicianNotesMileageIn: state.mileagein,
            TechnicianNotesTimeIn: parseFloat(state.timein.replace(':', '.')),
            TechnicianNotesMileageOut: state.mileageout,
            TechnicianNotesTimeOut: parseFloat(state.timeout.replace(':', '.')),
            TechnicianNotesSmokeTestPerformed: state.smoketest,
            TechnicianNotesServiceIndicatorReset: state.serviceindicator,
            TechnicianNotesRadioCodeReset: state.radiocode,
            TechnicianNotesTimeClockReset: state.timeclock,
            TechnicianNotesTechnicianSignature: state.techniciansign,
            techWorkAccomplished: state.workaccomplished,
            TechnicianNotesReadingTaken: state.readingtaken,
            techFailureCodes: state.failurereason,
            TechnicianNotesTeamLeaderSignature: null, //state.teamleadersign,
            TechnicianNotesReadingUnit: state.technicianNotesReadingUnit,
            TechnicianNotesJobID: state.newJOBId,
            SignedOff: state.signedOff || false
        };
        console.log(data, 'data');
        if (!portalSettings.portalSettingDisplaySubmitbutton) {
            if (handleValidation()) {
                res = await AddTechWriteUp(data);
            }
        } else {
            res = await AddTechWriteUp(data);
        }
        if (!res.success) {
            console.error(res);
        } else {
            hideModal();
        }
        showToast(res.message);
        if (res.success && fromStep) {
            setTimeout(() => {
                window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
            }, 1000);
        }
        setState((st) => ({ ...st, submitLoader: false }));
    };

    const GetTechnicianSign = (o) => {
        setState((st) => {
            const nst = {
                ...st,
                ...o
            };
            return nst;
        });
    };

    const clearSig = (val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.teamleadersign = null;
            newSt.techniciansign = null;
            return newSt;
        });
    };

    const handleCheck = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            const nst = { ...st, [name]: checked };
            return nst;
        });
    };
    const fieldChange = (e) => {
        const { name, value } = e.target;
        let fields = state.fields;
        fields[name] = value;

        setState((st) => {
            return { ...st, [name]: value, fields: fields };
        });
    };
    const AutoWorkAccomplish = (event, value) => {
        setSelectedData((st) => ({
            ...st,
            selectedWorkAccomplishCodes: value
        }));
        setState((st) => {
            const nst = { ...st, workaccomplished: value.map((c) => c.id) };
            return nst;
        });
    };

    const AutoFrChange = (event, value) => {
        setSelectedData((st) => ({ ...st, selectedFailureCodes: value }));
        setState((st) => {
            const nst = { ...st, failurereason: value.map((c) => c.id) };
            return nst;
        });
    };

    const showConfirmationModal = () => {
        if (handleValidation('S')) {
            const list = [...inputList];
            let isNittedAndReturedNullList = [];
            list.forEach((g) => {
                isNittedAndReturedNullList = g.associatedMaterials.filter((q) => !q.isFitted || !q.isReturnToPartsDepo);

                setIsFittedWarnig((st) => ({ ...st, show: true, list: isNittedAndReturedNullList }));
            });

            // setState((st) => ({ ...st, showConfirmModal: true }));
        }
    };

    const hideConfirmationModal = () => {
        setState((st) => ({ ...st, showConfirmModal: false }));
    };

    const closeIsFittedWarnigModal = () => {
        setIsFittedWarnig((st) => ({ ...st, show: false, list: [] }));
    };

    const hideSaveConfirmModal = () => {
        setState((st) => ({ ...st, showSaveConfirmModal: false }));
    };

    const GlobalCss = withStyles({
        '@global': {
            body: {
                fontSize: `${state.fontResize ? state.fontResize + 'px' : '0.875rem'}`
            },
            '.MuiFormLabel-root, .MuiTypography-body1, .MuiButton-root': {
                fontSize: `${state.fontResize ? state.fontResize + 'px' : '0.875rem'}`
            },
            '.MuiOutlinedInput-inputMarginDense': {
                fontSize: `${state.fontResize ? state.fontResize + 'px' : '0.875rem'}`,
                paddingTop: '4px',
                paddingBottom: '4px',
                color: 'black',
                background: 'white'
            },
            '.MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense': {
                paddingTop: '4px',
                paddingBottom: '4px',
                color: 'black',
                background: 'white'
            },
            '.MuiFormLabel-root': {
                color: 'black'
            },
            '.MuiFormControlLabel-label.Mui-disabled': {
                color: 'black'
            },
            '.MuiOutlinedInput-adornedEnd': {
                background: 'white'
                // padding: 0
            },
            '.multiSelect': {
                '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
                    padding: '0px 50px 0px 0px'
                }
            },
            '.MuiDialogTitle-root': {
                margin: 0,
                padding: '3px 14px'
            }
        }
    })(() => null);

    const pullAndUpdateState = () => {
        setState((st) => ({
            ...st,
            showGrid: false
        }));
    };

    const getStandartdFromChild = (props) => {
        const list = [...inputList];
        list[state.GridID]['technicianNotesItemFault'] = IsUpperCase ? props.complaint?.toUpperCase() : props.complaint;
        list[state.GridID]['technicianNotesItemDiagnosis'] = IsUpperCase ? props.cure?.toUpperCase() : props.cure;
        list[state.GridID]['technicianNotesItemCause'] = props.cause == null ? '' : IsUpperCase ? props.cause?.toUpperCase() : props.cause;
        setInputList(list);
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = IsUpperCase ? value.toUpperCase() : value;
        setInputList(list);
    };

    const handleRemoveClick = (index, id) => {
        const list = [...inputList];
        if (id) {
            list[index].isDeleted = true;
            setInputList(list);
        } else {
            list.splice(index, 1);
            setInputList(list);
        }
    };
    const handleAddClick = () => {
        setInputList([
            ...inputList,
            {
                technicianNotesItemFault: '',
                technicianNotesItemDiagnosis: '',
                technicianNotesItemCause: '',
                timeTaken: null,
                isDeleted: false,
                defectCode: '',
                dateCreated: moment().format('YYYY-MM-DDTHH:mm:ss'),
                faultCodes: [],
                associatedMaterials: []
            }
        ]);
    };

    const useDefaultSig = () => {
        let userSign = AppStorage.getSignature();
        if (userSign) {
            signCanvas.current.fromDataURL(AppStorage.getSignature());
            setState((st) => ({ ...st, techniciansign: AppStorage.getSignature() }));
        } else {
            showToast('User signature not found');
        }
    };
    const zoomOutFun = () => {
        setState((st) => ({ ...st, fontResize: st.fontResize - 1 }));
    };
    const zoomInFun = (val) => {
        setState((st) => ({ ...st, fontResize: st.fontResize + 1 }));
    };
    const DownloadSTDListDocument = () => {
        getSTDWriteUpPdfFile(Id, 'T');
    };
    const printDocument = () => {
        if (state.isFMOrWarranty) {
            getTechWriteUpPdfWithFmFile(Id, 'T');
        } else {
            getTechWriteUpPdfFile(Id, 'T');
        }

        // let input = document.getElementById('divToPrint');
        // let calMaxHeight;
        // html2canvas(input, {
        //     onclone: (cloned) => {
        //         let textArea = cloned.getElementsByTagName('textarea');
        //         cloned.getElementById('zommContainer').style.display = 'none';
        //         let getHeight = [];
        //         for (var i = 0; i < textArea.length; i++) {
        //             getHeight.push(textArea[i].scrollHeight);
        //         }
        //         calMaxHeight = _.max(getHeight);
        //         for (var i = 0; i < textArea.length; i++) {
        //             const div = document.createElement('div');
        //             const textProp = textArea[i].getBoundingClientRect();
        //             div.innerText = textArea[i].value;
        //             div.style.padding = '2px -3px';
        //             div.style.height = `${calMaxHeight}px`; //`${textProp.height}px`;
        //             div.style.width = `${textProp.width}px`;
        //             div.style.textAlign = 'left';
        //             div.style.color = 'black';
        //             div.style.fontSize = 8;
        //             textArea[i].style.display = 'none';
        //             textArea[i].parentElement.append(div);
        //         }
        //     }
        // }).then((canvas) => {
        //     const imgData = canvas.toDataURL('image/png');
        //     // const pdf = new jsPDF({
        //     //     orientation: 'landscape'
        //     // });
        //     const pdf = new jsPDF('p', 'px', 'a4');
        //     const imgProps = pdf.getImageProperties(imgData);
        //     const pdfWidth = pdf.internal.pageSize.getWidth();

        //     const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        //     pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight + calMaxHeight);
        //     // pdf.save('download.pdf');
        //     pdf.save(`TechnitianWriteUp-WIP${state.wipNumber}-${moment().format('DD-MMM-YYYY-HH:mm')}.pdf`);
        // });
    };

    const handleRowExpand = (index) => {
        setState((st) => ({
            ...st,
            expandRowIndex: index
        }));
    };

    const singleSelectChange = (nm, index) => (ev, val) => {
        const list = [...inputList];
        list[index][nm] = val?.id;
        list[index][`${nm}Temp`] = val;
        setInputList(list);
        // if (nm == 'claimTypeID') {
        //     setState((prev) => ({
        //         ...prev,
        //         showDisplayCausalParts: val?.displayCausalParts
        //     }));
        // }
    };

    const yesNoHandle = (name, val, index) => {
        const list = [...inputList];
        list[index][name] = val;
        setInputList(list);
    };

    useEffect(async () => {
        if (state.modalIndex != null) {
            const dropDownRes = await getPartReqRenaultStatus();
            let partIdArray = new Set([...inputList[state.modalIndex].associatedMaterials].map((obj) => obj.partReqStatus));
            let Res = [...dropDownRes.data.partReqStatus];
            Res = Res.reduce((acc, status) => {
                if (partIdArray.has(status.id)) {
                    if (isInternal && !status.visibleToInternal) {
                        acc.push({ ...status, visibleToInternal: true });
                    }
                    if (isTechnician && !status.visibleToTech) {
                        acc.push({ ...status, visibleToTech: true });
                    }
                }
                acc.push(status);
                return acc;
            }, []);

            const FilterList = Res.filter((status) => {
                return (isInternal && status.visibleToInternal) || (isTechnician && status.visibleToTech);
            });

            const uniqueData = Array.from(new Set(FilterList.map((status) => status.id))).map((id) => FilterList.find((status) => status.id === id));

            setScreenDropdown((st) => ({ ...st, partReqStatus: uniqueData || [] }));
        }
    }, [state.modalIndex]);

    return (
        <TechWriteScreenContext.Provider
            value={{
                signCanvas,
                state,
                fromStep,
                masterData,
                inputList,
                selectedData,
                clear,
                DisplayGridState,
                GetTechnicianSign,
                handleSubmit,
                handleCheck,
                fieldChange,
                AutoWorkAccomplish,
                GlobalCss,
                AutoFrChange,
                handleAddClick,
                handleRemoveClick,
                handleInputChange,
                getStandartdFromChild,
                pullAndUpdateState,
                TechBtnClick,
                useDefaultSig,
                portalSettings,
                showConfirmationModal,
                hideConfirmationModal,
                zoomOutFun,
                zoomInFun,
                clearSig,
                printDocument,
                DownloadSTDListDocument,
                isTechnician,
                saveButtonConfirmationValidation,
                hideSaveConfirmModal,
                handleFault,
                handleAssociated,
                closeModal,
                handleAssociatedRow,
                removeAssociatedRow,
                handleAssociateInput,
                handleFaultCodeRow,
                removeFaultCodeRow,
                handleFaultCodeInput,
                isInternal,
                canAmendTechWriteUpFlag,
                setInputList,
                handleRowExpand,
                singleSelectChange,
                screenDropdown,
                yesNoHandle,
                submitChildAssocate,
                pullDataByPartNumber,
                handleClosePOP,
                anchorEl,
                handleRowSelect,
                isFittedWarnig,
                closeIsFittedWarnigModal,
                handleLinkRows
            }}
        >
            {props.children}
        </TechWriteScreenContext.Provider>
    );
};
