import { memoize } from 'lodash';
import { get, post, put } from './../http-calls';

export const PostCollectedByMainSite = async (data) => {
    let res = await post(`PartReturn/PartReturn_MarkCollectedByMainSite`, data, { useAuthToken: true });
    return res;
};

export const PostGridCollectedByMainSite = async (data) => {
    let res = await post(`PartReturn/PartReturn_SetPartsListAsCollectedByMainSite`, data, { useAuthToken: true });
    return res;
};

export const PostReceivedByMainSite = async (data) => {
    let res = await post(`PartReturn/PartReturn_MarkReceivedByMainSite`, data, { useAuthToken: true });
    return res;
};

export const PostGridReceivedByMainSite = async (data) => {
    let res = await post(`PartReturn/PartReturn_SetPartsListAsReceivedByMainSite`, data, { useAuthToken: true });
    return res;
};

export const PostReturnToPartDepartment = async (data) => {
    let res = await post(`PartReturn/PartReturn_MarkReturnToPartDepartment`, data, { useAuthToken: true });
    return res;
};

export const PostGridReturnToPartDepartment = async (data) => {
    let res = await post(`PartReturn/PartRetrun_SetPartsListAsReturnToPartDepartment`, data, { useAuthToken: true });
    return res;
};

export const PostAwaitingCollection = async (data) => {
    let res = await post(`PartReturn/PartReturn_MarkAwaitingCollection`, data, { useAuthToken: true });
    return res;
};

export const PostGridAwaitingCollection = async (data) => {
    let res = await post(`PartReturn/PartRetrun_SetPartsAsAwaitingCollection`, data, { useAuthToken: true });
    return res;
};

export const PostMarkCollected = async (data) => {
    let res = await post(`PartReturn/PartReturn_MarkCollected`, data, { useAuthToken: true });
    return res;
};

export const PostGridMarkCollected = async (data) => {
    let res = await post(`PartReturn/PartReturn_SetPartsListAsCollected`, data, { useAuthToken: true });
    return res;
};

export const PostMarkCreditReceived = async (data) => {
    let res = await post(`PartReturn/PartReturn_MarkCreditReceived`, data, { useAuthToken: true });
    return res;
};

export const PostGridMarkCreditReceived = async (data) => {
    let res = await post(`PartReturn/PartReturn_SetPartsListAsCreditReceived`, data, { useAuthToken: true });
    return res;
};

export const PostGridPartReqs_MarkedAsPartArrived = async (data) => {
    let res = await post(`RenaultPartReqs/PartReqs_MarkedAsPartArrived`, data, { useAuthToken: true });
    return res;
};
export const PostGridPartReqs_MarkedAsFitted = async (data) => {
    let res = await post(`RenaultPartReqs/PartReqs_SetNextStatus`, data, { useAuthToken: true });
    return res;
};
