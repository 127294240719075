import { Button, ButtonGroup, Checkbox, CircularProgress, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import formatters from '../../../Core/Grid/inputFormatter';
import './breakdownFormSheetStyle.scss';
import { DatePicker, DateTimePicker, PrimaryButton, PrimaryCheckbox, SecondaryButton, TextBox, YesNoButton } from '../../../Core/FormInput';
import Participants from './participants';
import { FieldWrappper } from '../../../Core/FieldWrapper';
import { SignPad } from '../../../Core/SignaturePad/SignPad';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import { downldBreakdownFrom, downldTorqueGetDetails, getWheelBreakDownFromSheet, postBreakDownFrom } from '../../../Core/Service/wheelServices/wheel-services';
import { Alert } from '@material-ui/lab';
import AppContext from '../../../App/AppContext';
import CircularIndeterminate from '../ReportsScreens/ReportsScreens/BudgetCore/loader';
import moment from 'moment';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const CustomCheckbox = ({ label, onChange, name, value }) => {
    return (
        <Grid container spacing={1} alignItems="center" justifyContent="space-between">
            <Grid item>
                <div className="labelText">{label}</div>
            </Grid>
            <Grid item>
                <PrimaryCheckbox checked={value} onChange={onChange} name={name} />
            </Grid>
        </Grid>
    );
};

function pad(number) {
    // Check if the number is an integer
    if (Number.isInteger(number)) {
        return number < 10 ? '0' + number : number.toString();
    } else {
        // Convert the number to a string with exactly two decimal places
        var str = number.toFixed(2);
        // Check if the whole part is less than 10 and add a leading zero if necessary
        if (number < 10 && number >= 0) {
            str = '0' + str;
        }
        return str;
    }
}

const BreakdownFormSheet = (props) => {
    const urlParams = new URLSearchParams(window.location.search.toLowerCase());
    const fromStep = urlParams.get('fromstep') === 'true';
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const [state, setState] = useState({
        headerRecordID: props?.match?.params?.jobId || props?.jobId,
        isNonWorkingDay: null,
        isMotorWay: false,
        isUrbanZone: false,
        isOutsideUrbanZone: false,
        guaranteeOfPayment: false,
        paymentIsCreditCard: false,
        paymentIsCash: false,
        paymentRenaultTrucks: false,
        paymentIsDealership: false,
        paymentIsOther: false,
        actualMileage: 0,
        typeOfBreakdown: '',
        meanTypeID: '',
        paymentVehicleType: '',
        customerIsDealership: false,
        customerIsTransient: false,
        showLoader: true,
        showDwnldLoader: false
    });

    const [errors, setErrors] = useState({});

    const { showToast, showModal } = useContext(AppContext);

    useEffect(() => {
        let errors = {};
        let actualMileage = state.mileageReturn - state.mileageOutWard;
        setState((st) => ({
            ...st,
            actualMileage: actualMileage
        }));

        if (state.mileageReturn) {
            if (state.mileageReturn < state.mileageOutWard) {
                errors.Message = 'Return mileage must exceed Outward mileage.';
            } else {
                errors.Message = '';
            }
        }
        setErrors(errors);
    }, [state.mileageOutWard, state.mileageReturn]);

    const [width, height] = useWindowSize();

    const GetTechnicianSign = (val) => {
        setState((st) => ({
            ...st,
            ...val
        }));
    };
    const clearSig = (val) => {
        setState((st) => ({
            ...st,
            driverSignatureAfterInstruction: null
        }));
    };
    const clearMechSig = (val) => {
        setState((st) => ({
            ...st,

            mechanicSignature: null
        }));
    };
    const clearDriverSig = (val) => {
        setState((st) => ({
            ...st,

            driverSignature: null
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const buttonClick = (name, value) => {
        setState((st) => {
            let nst = { ...st };
            nst[name] = value;
            if (value == st[name]) {
                nst[name] = null;
            } else {
                nst[name] = value;
            }
            return nst;
        });
    };

    const handleCheck = (e) => {
        const { name, checked } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: checked };
            return nst;
        });
    };
    const handleRadioCheck = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const onCancelFunction = () => {
        if (props.onClose) {
            props.onClose(true);
        } else {
            setTimeout(() => {
                window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
            }, 1000);
        }
    };

    useEffect(async () => {
        if (state?.headerRecordID) {
            let res = await getWheelBreakDownFromSheet(state?.headerRecordID);
            let data = res?.data?.breakdownSheet[0];
            if (res.success) {
                setState((st) => ({
                    ...st,
                    breakdownSheetID: data?.breakdownSheetID || null,
                    headerRecordID: data?.headerRecordID || state?.headerRecordID,
                    date: formatters.DateFormatter(data?.date) || moment().format('YYYY-MM-DD'),
                    reneuoOrder: data?.reneuoOrder ? pad(data?.reneuoOrder).toString().replace('.', ':') : null,
                    estimatedArrrivalTime: data?.estimatedArrrivalTime || '',
                    arrrivalAtScene: data?.arrrivalAtScene || '',
                    estimatedTimeCompleteRepair: data?.estimatedTimeCompleteRepair || '',
                    vehicleRepairedAt: data?.vehicleRepairedAt || '',
                    isNonWorkingDay: data?.isNonWorkingDay ? 1 : 0 || null,
                    dossierRefNumber: data?.dossierRefNumber || '',
                    breakdownSheet24by7: data?.breakdownSheet24by7 || '',
                    repairer: data?.repairer || '',
                    typeOfBreakdown: data?.typeOfBreakdown?.toString() || '',
                    meanTypeID: data?.meanTypeID?.toString() || '',
                    breakdownLocation: data?.breakdownLocation || '',
                    isMotorWay: data?.isMotorWay || false,
                    isUrbanZone: data?.isUrbanZone || false,
                    isOutsideUrbanZone: data?.isOutsideUrbanZone || false,
                    accountOfBreakdown: data?.accountOfBreakdown || '',
                    load: data?.load || '',
                    isDangerousZone: data?.isDangerousZone ? 1 : 0 || null,
                    replacementVehicle: data?.replacementVehicle ? 1 : 0 || null,
                    actionTaken: data?.actionTaken || '',
                    customerDriverName: data?.customerDriverName || '',
                    customerDriverPhone: data?.customerDriverPhone || '',
                    customerPersonToInformed: data?.customerPersonToInformed || '',
                    customerPersonInformedPhone: data?.customerPersonInformedPhone || '',
                    customerCompanyName: data?.customerCompanyName || '',
                    customerCompanyAddress: data?.customerCompanyAddress || '',
                    customerPostCode: data?.customerPostCode || '',
                    customerTown: data?.customerTown || '',
                    customerCountry: data?.customerCountry || '',
                    customerFaxNumber: data?.customerFaxNumber || '',
                    customerIsDealership: data?.CustomerIsDealership ? 1 : 0,
                    customerIsTransient: data?.customerIsTransient || false,
                    customerIntraaCommunityVATNo: data?.customerIntraaCommunityVATNo || null,
                    paymentIsCreditCard: data?.paymentIsCreditCard || false,
                    paymentCardName: data?.paymentCardName || '',
                    paymentCardExpiryDate: formatters.DateFormatter(data?.paymentCardExpiryDate) || '',
                    paymentCardNumber: data?.paymentCardNumber || '',
                    paymentCardAggrementFor: data?.paymentCardAggrementFor || '',
                    guaranteeOfPayment: data?.guaranteeOfPayment || false,
                    paymentIsCash: data?.paymentIsCash || false,
                    paymentVehicleType: data?.paymentVehicleType?.toString() || '',
                    warrantyExtentionCode: data?.warrantyExtentionCode || '',
                    dealershipToInvoice: data?.dealershipToInvoice || '',
                    hireToInvoice: data?.hireToInvoice || '',
                    paymentRenaultTrucks: data?.paymentRenaultTrucks || false,
                    paymentPaymentNumber: data?.paymentPaymentNumber || '',
                    paymentIsDealership: data?.paymentIsDealership || false,
                    paymentDealerName: data?.paymentDealerName || '',
                    paymentDealerPhone: data?.paymentDealerPhone || '',
                    paymentDealerFax: data?.paymentDealerFax || '',
                    paymentOtherName: data?.paymentOtherName || '',
                    paymentOtherPhone: data?.paymentOtherPhone || '',
                    paymentOtherFax: data?.paymentOtherFax || '',
                    vehicleMake: data?.vehicleMake || '',
                    vehicleType: data?.vehicleType || '',
                    vehicleRegistration: data?.vehicleRegistration || '',
                    vehicleRegistrationDate: formatters.DateFormatter(data?.vehicleRegistrationDate) || '',
                    vehicleSerialNumber: data?.vehicleSerialNumber || '',
                    nameOfOwnerRegistrationcard: data?.nameOfOwnerRegistrationcard || '',
                    vehicleMileage: data?.vehicleMileage || 0,
                    vehicleIdentification: data?.vehicleIdentification || '',
                    carriedOut: data?.carriedOut || '',
                    instructions: data?.instructions || '',
                    driverNameName: data?.driverNameName || '',
                    driverSignature: data?.driverSignature || '',
                    driverSignatureAfterInstruction: data?.driverSignatureAfterInstruction || '',
                    mechanicSignature: data?.mechanicSignature || '',
                    driverSignatureUpdate: data?.driverSignature || '',
                    driverSignatureAfterInstructionUpdate: data?.driverSignatureAfterInstruction || '',
                    mechanicSignatureUpdate: data?.mechanicSignature || '',
                    mechanicName: data?.mechanicName || '',
                    trailerMake: data.trailerMake || '',
                    trailerType: data.trailerType || '',
                    trailerRegistration: data.trailerRegistration,
                    trailerRegistrationDate: formatters.DateFormatter(data.trailerRegistrationDate) || '',
                    trailerSerialNumber: data.trailerSerialNumber,
                    trailerNameOfOwnerRegistrationcard: data.trailerNameOfOwnerRegistrationcard,
                    trailerMileage: data.trailerMileage || 0,
                    trailerIdentification: data.trailerIdentification || '',
                    mileageOutWard: data.mileageOutWard || '',
                    mileageOutWardIsKMUnit: data.mileageOutWardIsKMUnit || false,
                    mileageReturn: data.mileageReturn || null,
                    mileageReturnIsKMUnit: data.mileageReturnIsKMUnit || false,
                    zoneTypeID: data.zoneTypeID || null,
                    paymentIsOther: data.paymentIsOther || false,
                    showLoader: false
                }));
            } else {
                setState((st) => ({
                    ...st,
                    showLoader: false
                }));
            }
        }
    }, []);

    let validation = () => {
        let {
            dossierRefNumber,
            date,
            estimatedArrrivalTime,
            arrrivalAtScene,
            estimatedTimeCompleteRepair,
            vehicleRepairedAt,
            repairer,
            breakdownLocation,
            accountOfBreakdown,
            customerDriverName,
            customerDriverPhone,
            paymentCardName,
            vehicleMake,
            vehicleType,
            vehicleRegistration,
            vehicleMileage,
            carriedOut,
            instructions,
            mechanicSignature,
            driverSignatureAfterInstruction
        } = state;
        let errors = {};
        let isValid = true;
        if (!dossierRefNumber) {
            isValid = false;
            errors.dossierRefNumber = 'Renault Truck 24/7 Dossier Reference  is required.';
        }
        if (!date) {
            isValid = false;
            errors.date = 'Date is required';
        }
        if (!estimatedArrrivalTime) {
            isValid = false;
            errors.estimatedArrrivalTime = 'ETA is required';
        }
        if (!arrrivalAtScene) {
            isValid = false;
            errors.arrrivalAtScene = 'ATA is required';
        }
        if (!estimatedTimeCompleteRepair) {
            isValid = false;
            errors.estimatedTimeCompleteRepair = 'ETC is required';
        }
        if (!vehicleRepairedAt) {
            isValid = false;
            errors.vehicleRepairedAt = 'ATC is required';
        }
        if (!repairer) {
            isValid = false;
            errors.repairer = 'Repairer is required';
        }
        if (!breakdownLocation) {
            isValid = false;
            errors.breakdownLocation = 'Exact location of vehicle is required';
        }
        if (!accountOfBreakdown) {
            isValid = false;
            errors.accountOfBreakdown = 'Account of the breakdown is required';
        }
        if (!customerDriverName) {
            isValid = false;
            errors.customerDriverName = 'Name of Driver is required';
        }
        if (!customerDriverPhone) {
            isValid = false;
            errors.customerDriverPhone = 'Phone is required';
        }

        if (!vehicleMake) {
            isValid = false;
            errors.vehicleMake = 'Make is required';
        }
        if (!vehicleType) {
            isValid = false;
            errors.vehicleType = 'Type is required';
        }
        if (!vehicleRegistration) {
            isValid = false;
            errors.vehicleRegistration = 'Registration Number is required';
        }
        if (!vehicleMileage) {
            isValid = false;
            errors.vehicleMileage = 'Mileage is required';
        }
        if (!carriedOut) {
            isValid = false;
            errors.carriedOut = 'Description of work carried out is required';
        }
        if (!instructions) {
            isValid = false;
            errors.instructions = 'Instructions (for temporary repairs) out is required';
        }
        if (!mechanicSignature) {
            isValid = false;
            errors.mechanicSignature = 'Signature of mechanic  is required';
        }
        if (!driverSignatureAfterInstruction) {
            isValid = false;
            errors.driverSignatureAfterInstruction = 'Signature of driver is required';
        }
        setErrors(errors);
        return isValid;
    };

    const handleSubmit = async () => {
        if (validation()) {
            let res = await postBreakDownFrom(state);
            if (res.success) {
                showToast(res?.data?.message);
                if (fromStep) {
                    setTimeout(() => {
                        window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
                    }, 1000);
                }
                if (props.onClose) {
                    props.onClose(true);
                }
            }
            if (res.errors) {
                for (const [key, value] of Object.entries(res?.errors)) {
                    showToast(`${key}: ${value}`);
                }
            } else {
                showToast(res?.data.message);
            }
        }
    };
    const handleSave = async () => {
        // if (validation()) {
        let res = await postBreakDownFrom(state);
        if (res.success) {
            showToast(res?.data?.message);
            if (fromStep) {
                setTimeout(() => {
                    window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
                }, 1000);
            }
            if (props.onClose) {
                props.onClose(true);
            }
        }
        if (res.errors) {
            for (const [key, value] of Object.entries(res?.errors)) {
                showToast(`${key}: ${value}`);
            }
        } else {
            showToast(res?.data?.message);
        }
        // }
    };

    const dwnldPdf = async () => {
        setState((st) => ({ ...st, showDwnldLoader: true }));
        let res = await downldBreakdownFrom(state.headerRecordID);
        // console.log(res);
        setState((st) => ({ ...st, showDwnldLoader: false }));
    };

    return (
        <div style={{ padding: fromStep ? '10px' : '0px' }}>
            {state.showLoader ? (
                <Grid container alignItems="center" justifyContent="center" style={{ height: 500 }}>
                    <Grid item>
                        <CircularIndeterminate />
                    </Grid>
                </Grid>
            ) : (
                <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: 20, right: 20 }}>
                        {state.showDwnldLoader ? (
                            <CircularProgress size={20} />
                        ) : (
                            <CloudDownloadIcon color="primary" style={{ fontSize: 30, cursor: 'pointer' }} onClick={dwnldPdf} />
                        )}
                    </div>

                    <Grid container spacing={2} className="breakdownFrom" style={{ padding: fromStep ? '10px' : '0px' }}>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FieldWrappper isNewCard={true}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                                <div className="labelTextReq">Renault Truck 24/7 Dossier Ref*</div>
                                                <TextBox name="dossierRefNumber" value={state.dossierRefNumber} onChange={handleChange} />
                                                <FormHelperText error shrink style={{ marginTop: 0 }}>
                                                    {errors.dossierRefNumber}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4} lg={2}>
                                                <div className="labelTextReq">Date* </div>
                                                <DatePicker style={{ marginTop: 6 }} name="date" value={state.date} onChange={handleChange} />
                                                <FormHelperText error shrink style={{ marginTop: 0 }}>
                                                    {errors.date}
                                                </FormHelperText>
                                            </Grid>
                                            {width >= 1280 && <Grid item xs={0} md={0} lg={2}></Grid>}
                                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                                <div className="labelText">RO </div>
                                                <TextBox type="time" name="reneuoOrder" value={state.reneuoOrder} onChange={handleChange} />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} md={6} lg={2}>
                                                <div className="labelTextReq">ETA (Estimated time of Arrival)* </div>
                                                <DateTimePicker
                                                    style={{ marginTop: 8 }}
                                                    name="estimatedArrrivalTime"
                                                    value={state.estimatedArrrivalTime}
                                                    onChange={handleChange}
                                                />
                                                <FormHelperText error shrink style={{ marginTop: 0 }}>
                                                    {errors.estimatedArrrivalTime}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={2}>
                                                <div className="labelTextReq">ATA (Arrival at Scene)* </div>
                                                <DateTimePicker
                                                    style={{ marginTop: 8 }}
                                                    name="arrrivalAtScene"
                                                    value={state.arrrivalAtScene}
                                                    onChange={handleChange}
                                                />
                                                <FormHelperText error shrink style={{ marginTop: 0 }}>
                                                    {errors.arrrivalAtScene}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={2}>
                                                <div className="labelTextReq">ETC (Estimated time to complete repair)* </div>
                                                <DateTimePicker
                                                    style={{ marginTop: 8 }}
                                                    name="estimatedTimeCompleteRepair"
                                                    value={state.estimatedTimeCompleteRepair}
                                                    onChange={handleChange}
                                                />
                                                <FormHelperText error shrink style={{ marginTop: 0 }}>
                                                    {errors.estimatedTimeCompleteRepair}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={6} lg={2}>
                                                <div className="labelTextReq">ATC (Vehicle repaired at)* </div>
                                                <DateTimePicker
                                                    style={{ marginTop: 8 }}
                                                    name="vehicleRepairedAt"
                                                    value={state.vehicleRepairedAt}
                                                    onChange={handleChange}
                                                />
                                                <FormHelperText error shrink style={{ marginTop: 0 }}>
                                                    {errors.vehicleRepairedAt}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6} lg={4}>
                                                <div className="labelText" style={{ marginBottom: 8 }}>
                                                    Non working day or time{' '}
                                                </div>
                                                <ButtonGroup color="primary" aria-label="outlined primary button group">
                                                    <Button
                                                        onClick={() => buttonClick('isNonWorkingDay', 1)}
                                                        variant={state.isNonWorkingDay == 1 && 'contained'}
                                                    >
                                                        Yes
                                                    </Button>
                                                    <Button
                                                        onClick={() => buttonClick('isNonWorkingDay', 0)}
                                                        variant={state.isNonWorkingDay == 0 && 'contained'}
                                                    >
                                                        No
                                                    </Button>
                                                </ButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </FieldWrappper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Participants
                                state={state}
                                handleChange={handleChange}
                                buttonClick={buttonClick}
                                handleCheck={handleCheck}
                                handleRadioCheck={handleRadioCheck}
                                actualMileage={state.actualMileage}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FieldWrappper heading="Analysis of the situation" isNewCard={true}>
                                <Grid container spacing={2}>
                                    {/* <Grid item xs={6} md={5}></Grid> */}
                                    <Grid item xs={6} md={10}>
                                        <Grid container direction="column">
                                            <Grid item xs={12}>
                                                <div className="labelTextReq">Exact location of vehicle*</div>
                                                <TextBox multiline rows={1} name="breakdownLocation" value={state.breakdownLocation} onChange={handleChange} />
                                                <FormHelperText error shrink style={{ marginTop: 0 }}>
                                                    {errors.breakdownLocation}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className="labelTextReq">Account of the breakdown (Symptoms)*</div>
                                                <TextBox
                                                    type="text"
                                                    multiline
                                                    rows={1}
                                                    name="accountOfBreakdown"
                                                    value={state.accountOfBreakdown}
                                                    onChange={handleChange}
                                                />
                                                <FormHelperText error shrink style={{ marginTop: 0 }}>
                                                    {errors.accountOfBreakdown}
                                                </FormHelperText>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6} md={2}>
                                        <Grid container direction="column">
                                            <Grid item xs={12}>
                                                <CustomCheckbox label={'Motorway'} onChange={handleCheck} name={'isMotorWay'} value={state.isMotorWay} id={1} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomCheckbox
                                                    label={'Urban Zone'}
                                                    onChange={handleCheck}
                                                    name={'isUrbanZone'}
                                                    value={state.isUrbanZone}
                                                    id={2}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <CustomCheckbox
                                                    label={'Outside Urban Zone'}
                                                    onChange={handleCheck}
                                                    name={'isOutsideUrbanZone'}
                                                    value={state.isOutsideUrbanZone}
                                                    id={3}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <div className="labelText">Load</div>
                                        <TextBox name="load" value={state.load} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={4} lg={2}>
                                        <div className="labelText" style={{ marginBottom: 8 }}>
                                            Dangerous Materials
                                        </div>
                                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                                            <Button onClick={() => buttonClick('isDangerousZone', 1)} variant={state.isDangerousZone == 1 && 'contained'}>
                                                Yes
                                            </Button>
                                            <Button onClick={() => buttonClick('isDangerousZone', 0)} variant={state.isDangerousZone == 0 && 'contained'}>
                                                No
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={4} lg={2}>
                                        <div className="labelText" style={{ marginBottom: 8 }}>
                                            Replacement Vehicle
                                        </div>
                                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                                            <Button onClick={() => buttonClick('replacementVehicle', 1)} variant={state.replacementVehicle == 1 && 'contained'}>
                                                Yes
                                            </Button>
                                            <Button onClick={() => buttonClick('replacementVehicle', 0)} variant={state.replacementVehicle == 0 && 'contained'}>
                                                No
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>

                                    <Grid item xs={12} md={5}>
                                        <div className="labelText">Action Taken</div>
                                        <TextBox name="actionTaken" value={state.actionTaken} onChange={handleChange} />
                                    </Grid>
                                </Grid>
                            </FieldWrappper>
                        </Grid>
                        <Grid item xs={12}>
                            <FieldWrappper heading="Customer" isNewCard={true}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelTextReq">Name of Driver* </div>
                                        <TextBox name="customerDriverName" value={state.customerDriverName} onChange={handleChange} />
                                        <FormHelperText error shrink style={{ marginTop: 0 }}>
                                            {errors.customerDriverName}
                                        </FormHelperText>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelTextReq">Phone* </div>
                                        <TextBox name="customerDriverPhone" value={state.customerDriverPhone} onChange={handleChange} />
                                        <FormHelperText error shrink style={{ marginTop: 0 }}>
                                            {errors.customerDriverPhone}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelText">Person to be informed </div>
                                        <TextBox name="customerPersonToInformed" value={state.customerPersonToInformed} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelText">Phone </div>
                                        <TextBox name="customerPersonInformedPhone" value={state.customerPersonInformedPhone} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <div className="labelText">Name of Company </div>
                                        <TextBox name="customerCompanyName" value={state.customerCompanyName} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <div className="labelText">Address of Company </div>
                                        <TextBox name="customerCompanyAddress" value={state.customerCompanyAddress} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelText">Post Code </div>
                                        <TextBox name="customerPostCode" value={state.customerPostCode} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelText">Town </div>
                                        <TextBox name="customerTown" value={state.customerTown} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelText">Country </div>
                                        <TextBox name="customerCountry" value={state.customerCountry} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelText">Fax Number </div>
                                        <TextBox name="customerFaxNumber" value={state.customerFaxNumber} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelText" style={{ marginBottom: 8 }}>
                                            Dealership Customer{' '}
                                        </div>
                                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                                            <Button
                                                onClick={() => buttonClick('customerIsDealership', 1)}
                                                variant={state.customerIsDealership == 1 && 'contained'}
                                            >
                                                Yes
                                            </Button>
                                            <Button
                                                onClick={() => buttonClick('customerIsDealership', 0)}
                                                variant={state.customerIsDealership == 0 && 'contained'}
                                            >
                                                No
                                            </Button>
                                        </ButtonGroup>
                                    </Grid>
                                </Grid>
                            </FieldWrappper>
                        </Grid>
                        {/* <Grid item xs={12}>
                        <FieldWrappper heading="Payment" isNewCard={true}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} lg={2}>
                                    <div className="labelTextReq">&nbsp; </div>
                                    <FormControlLabel
                                        control={<Checkbox onChange={handleCheck} name={'paymentIsCreditCard'} checked={state.paymentIsCreditCard} />}
                                        label="Credit card"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <div className="labelText">Name</div>
                                    <TextBox name="paymentCardName" value={state.paymentCardName} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6} lg={2}>
                                    <div className="labelText" style={{ marginBottom: 8 }}>
                                        Date of expiry{' '}
                                    </div>
                                    <DatePicker name="paymentCardExpiryDate" value={state.paymentCardExpiryDate} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6} lg={2}>
                                    <div className="labelText">Card Number</div>
                                    <TextBox name="paymentCardNumber" value={state.paymentCardNumber} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <div className="labelText">Agreement for </div>
                                    <TextBox name="paymentCardAggrementFor" value={state.paymentCardAggrementFor} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6} lg={2}>
                                    <div className="labelText">&nbsp; </div>
                                    <FormControlLabel
                                        control={<Checkbox onChange={handleCheck} name={'guaranteeOfPayment'} checked={state.guaranteeOfPayment} />}
                                        label="Guarantee of payment"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={2}>
                                    <div className="labelText">&nbsp; </div>
                                    <FormControlLabel
                                        control={<Checkbox onChange={handleCheck} name={'paymentIsCash'} checked={state.paymentIsCash} />}
                                        label="Cash / cheque"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} lg={5}>
                                    <div className="labelText">This Vehicle is </div>
                                    <Grid container justifyContent="space-between">
                                        <Grid item xs={12}>
                                            <RadioGroup row name="paymentVehicleType" value={state.paymentVehicleType} onChange={handleRadioCheck}>
                                                <FormControlLabel value="1" control={<Radio />} label="Under warranty" />
                                                <FormControlLabel value="2" control={<Radio />} label="Under contract" />
                                                <FormControlLabel value="3" control={<Radio />} label="Under rental" />
                                            </RadioGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <div className="labelText">Warranty extension code </div>
                                    <TextBox name="warrantyExtentionCode" value={state.warrantyExtentionCode} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6} lg={2}>
                                    <div className="labelText">Dealership to invoice </div>
                                    <TextBox name="dealershipToInvoice" value={state.dealershipToInvoice} onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} md={6} lg={2}>
                                    <div className="labelText">Hirer to invoice </div>
                                    <TextBox name="hireToInvoice" value={state.hireToInvoice} onChange={handleChange} />
                                </Grid>
                            </Grid>
                        </FieldWrappper>
            </Grid>*/}
                        <Grid item xs={12}>
                            <FieldWrappper heading="Guarantee of Payment" isNewCard={true}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <div className="labelText">&nbsp; </div>
                                        <FormControlLabel
                                            control={<Checkbox onChange={handleCheck} name={'paymentRenaultTrucks'} checked={state.paymentRenaultTrucks} />}
                                            label="Renault Truck 24/7"
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={6} lg={9}>
                                        <div className="labelText">Guarantee of Payment Number </div>
                                        <TextBox name="paymentPaymentNumber" value={state.paymentPaymentNumber} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={3} lg={3}>
                                        <div className="labelText">&nbsp; </div>
                                        <FormControlLabel
                                            control={<Checkbox name="paymentIsDealership" onChange={handleCheck} checked={state.paymentIsDealership} />}
                                            label="Dealership"
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={3} lg={3}>
                                        <div className="labelText">Name </div>
                                        <TextBox name="paymentDealerName" value={state.paymentDealerName} onChange={handleChange} />
                                    </Grid>

                                    <Grid item xs={12} sm={3} lg={3}>
                                        <div className="labelText">Phone </div>
                                        <TextBox name="paymentDealerPhone" value={state.paymentDealerPhone} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={3} lg={3}>
                                        <div className="labelText">Fax </div>
                                        <TextBox name="paymentDealerFax" value={state.paymentDealerFax} onChange={handleChange} />
                                    </Grid>

                                    <Grid item xs={12} sm={3} lg={3}>
                                        <div className="labelText">&nbsp;</div>
                                        <FormControlLabel
                                            control={<Checkbox name="paymentIsOther" onChange={handleCheck} checked={state.paymentIsOther} />}
                                            label="Other"
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={3} lg={3}>
                                        <div className="labelText">Name </div>
                                        <TextBox name="paymentOtherName" value={state.paymentOtherName} onChange={handleChange} />
                                    </Grid>

                                    <Grid item xs={12} sm={3} lg={3}>
                                        <div className="labelText">Phone </div>
                                        <TextBox name="paymentOtherPhone" value={state.paymentOtherPhone} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={3} lg={3}>
                                        <div className="labelText">Fax </div>
                                        <TextBox name="paymentOtherFax" value={state.paymentOtherFax} onChange={handleChange} />
                                    </Grid>
                                </Grid>
                            </FieldWrappper>
                        </Grid>
                        <Grid item xs={12}>
                            <FieldWrappper heading="Vehicle" isNewCard={true}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelTextReq">Make*</div>
                                        <TextBox name="vehicleMake" value={state.vehicleMake} onChange={handleChange} />
                                        <FormHelperText error shrink style={{ marginTop: 0 }}>
                                            {errors.vehicleMake}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelTextReq">Type*</div>
                                        <TextBox name="vehicleType" value={state.vehicleType} onChange={handleChange} />
                                        <FormHelperText error shrink style={{ marginTop: 0 }}>
                                            {errors.vehicleType}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelTextReq">Registration Number*</div>
                                        <TextBox name="vehicleRegistration" value={state.vehicleRegistration} onChange={handleChange} />
                                        <FormHelperText error shrink style={{ marginTop: 0 }}>
                                            {errors.vehicleRegistration}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelText" style={{ marginBottom: 8 }}>
                                            Date of 1st Registration
                                        </div>
                                        <DatePicker name="vehicleRegistrationDate" value={state.vehicleRegistrationDate} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelText">Serial Number</div>
                                        <TextBox name="vehicleSerialNumber" value={state.vehicleSerialNumber} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={6}>
                                        <div className="labelText">Name of Owner on Registration Card</div>
                                        <TextBox name="nameOfOwnerRegistrationcard" value={state.nameOfOwnerRegistrationcard} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelTextReq">Mileage*</div>
                                        <TextBox type="number" name="vehicleMileage" value={state.vehicleMileage} onChange={handleChange} />
                                        <FormHelperText error shrink style={{ marginTop: 0 }}>
                                            {errors.vehicleMileage}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelText">Identification</div>
                                        <TextBox name="vehicleIdentification" value={state.vehicleIdentification} onChange={handleChange} />
                                    </Grid>
                                </Grid>
                            </FieldWrappper>
                        </Grid>
                        <Grid item xs={12}>
                            <FieldWrappper heading="Trailer or Semitrailer" isNewCard={true}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelText">Make</div>
                                        <TextBox name="trailerMake" value={state.trailerMake} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelText">Type</div>
                                        <TextBox name="trailerType" value={state.trailerType} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelText">Registration Number</div>
                                        <TextBox name="trailerRegistration" value={state.trailerRegistration} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelText" style={{ marginBottom: 8 }}>
                                            Date of 1st Registration
                                        </div>
                                        <DatePicker name="trailerRegistrationDate" value={state.trailerRegistrationDate} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelText">Serial Number</div>
                                        <TextBox name="trailerSerialNumber" value={state.trailerSerialNumber} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} lg={6}>
                                        <div className="labelText">Name of Owner on Registration Card</div>
                                        <TextBox
                                            name="trailerNameOfOwnerRegistrationcard"
                                            value={state.trailerNameOfOwnerRegistrationcard}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelText">Mileage</div>
                                        <TextBox type="number" name="trailerMileage" value={state.trailerMileage} onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={3}>
                                        <div className="labelText">Identification</div>
                                        <TextBox name="trailerIdentification" value={state.trailerIdentification} onChange={handleChange} />
                                    </Grid>
                                </Grid>
                            </FieldWrappper>
                        </Grid>
                        <Grid item xs={12}>
                            <FieldWrappper isNewCard={true}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={6}>
                                        <div className="labelTextReq">Description of work carried out*</div>
                                        <TextBox multiline rows={2} name="carriedOut" value={state.carriedOut} onChange={handleChange} />
                                        <FormHelperText error shrink style={{ marginTop: 0 }}>
                                            {errors.carriedOut}
                                        </FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <div className="labelTextReq">
                                            Instructions <>(for temporary repairs)*</>{' '}
                                        </div>
                                        <TextBox multiline rows={2} name="instructions" value={state.instructions} onChange={handleChange} />
                                        <FormHelperText error shrink style={{ marginTop: 0 }}>
                                            {errors.instructions}
                                        </FormHelperText>
                                    </Grid>
                                </Grid>
                            </FieldWrappper>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FieldWrappper isNewCard={true}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} md={12} lg={12} style={{ width: 'auto', height: '175px' }}>
                                            <CustomeSignPad
                                                onChange={GetTechnicianSign}
                                                clearSig={clearSig}
                                                style={{ marginTop: 2 }}
                                                content={<span className="labelTextReq">Signature of driver after Repair work and instructions*</span>}
                                                name="driverSignatureAfterInstruction"
                                                sign={state.driverSignatureAfterInstructionUpdate}
                                                showBtnOnBottom={true}
                                                hideBtn={true}
                                            />
                                            <FormHelperText error shrink style={{ marginTop: 0 }}>
                                                {errors.driverSignatureAfterInstruction}
                                            </FormHelperText>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </FieldWrappper>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FieldWrappper isNewCard={true}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid item xs={12} md={12} lg={12} style={{ width: 'auto', height: '175px' }}>
                                            <CustomeSignPad
                                                content={<span className="labelTextReq">Signature of repair mechanic*</span>}
                                                style={{ marginTop: 2 }}
                                                name="mechanicSignature"
                                                onChange={GetTechnicianSign}
                                                clearSig={clearMechSig}
                                                sign={state.mechanicSignatureUpdate}
                                                showBtnOnBottom={true}
                                                hideBtn={true}
                                            />
                                            <FormHelperText error shrink style={{ marginTop: 0 }}>
                                                {errors.mechanicSignature}
                                            </FormHelperText>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </FieldWrappper>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FieldWrappper isNewCard={true}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <div className="labelTextReq">
                                            ADVISED NOT TO DRIVE. I am fully aware that i have been advised not to drive and do so entirely at my own risk
                                        </div>
                                        <Grid item xs={12} md={12} lg={12} style={{ width: 'auto', height: '163px' }}>
                                            <CustomeSignPad
                                                style={{ marginTop: -24 }}
                                                onChange={GetTechnicianSign}
                                                clearSig={clearDriverSig}
                                                name="driverSignature"
                                                sign={state.driverSignatureUpdate}
                                                showBtnOnBottom={true}
                                                hideBtn={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </FieldWrappper>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <PrimaryButton className="Submitbtn" onClick={handleSave}>
                                        Save
                                    </PrimaryButton>
                                </Grid>
                                <Grid item xs={4}>
                                    <PrimaryButton className="Submitbtn" onClick={handleSubmit}>
                                        Submit
                                    </PrimaryButton>
                                </Grid>
                                <Grid item xs={4}>
                                    <SecondaryButton style={{ marginTop: 5 }} fullWidth className="cancel" onClick={() => onCancelFunction()}>
                                        Cancel
                                    </SecondaryButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    );
};

export default BreakdownFormSheet;
