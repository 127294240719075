import React, { useEffect, useState, useContext, useMemo } from 'react';
import AppContext from '../../../App/AppContext';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import { BasicButtonGroup, EvenSpaceButtonGroup } from '../../../Core/ButtonGroup';
import './TechnicianApp_screen.css';
import moment from 'moment';
import { getTechnicianJobs } from '../../../Core/Service/technician-service';
import { IconButton, Tooltip } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { find } from 'lodash';
import { getWorkflows } from '../../../Core/Service/workflow-service';
import Grid from '@material-ui/core/Grid';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InfoIcon from '@material-ui/icons/Info';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import TechnicianGijgoGrid from './Grid';
import ReactDOM from 'react-dom';
import GridFilterModal from '../../internal/dashboard/FilterModal';
import { getGridFilterList, postGridFilterList } from '../../../Core/Service/workflow-service';
import FilterListIcon from '@material-ui/icons/FilterList';
import DialogComp from '../../../Core/Modal/dialogModal';
import GridInfo from './GridInfo';
import { getAllowedWorkflow } from '../../../Core/Service/workflow-service';
import MotHistoryScreen from '../../internal/ReportsScreens/MotHistoryScreen';
import ReportIcon from '@material-ui/icons/Report';
import StarIcon from '@material-ui/icons/Star';
import CustomerRating from '../../internal/dashboard/customerRating';
import { AppStorage } from '../../../Core/Service/storage-service';
import FilterModalMainTabComponent from '../../internal/dashboard/FilterModalMainTabComp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

let currentTechnicianId;

const useStyles = (props) =>
    makeStyles((thems) => {
        return {
            ...props
        };
    });

const isFalseValue = (val) => {
    if (val == false) {
        return false;
    } else {
        return true;
    }
};

const technicianJobBucket = [
    { id: 1, label: 'Jobs Assigned To You' },
    { id: 3, label: 'Jobs Assigned To Others' }
    // { id: 2, label: "All other Jobs" },
];

let config = {
    // act: {
    //     from: '',
    //     to: '',
    //     getSequence: 'actionSequence',
    //     postSequence: 'actionSequence'
    // },
    wipNumber: {
        from: 'displayWIPNo',
        to: 'userGridColumnDisplayWIPNo',
        getSequence: 'wipNoSequence',
        postSequence: 'WIPNoSequence'
    },
    registration: {
        from: 'displayVehicleReg',
        to: 'userGridColumnDisplayVehicleReg',
        getSequence: 'vehicleRegSequence',
        postSequence: 'VehicleRegSequence'
    },
    makeModel: {
        from: 'displayModel',
        to: 'userGridColumnDisplayModel',
        getSequence: 'modelSequence',
        postSequence: 'modelSequence'
    },
    customerName: {
        from: 'displayCustomer',
        to: 'userGridColumnDisplayCustomer',
        getSequence: 'customerSequence',
        postSequence: 'customerSequence'
    },
    dateDueIn: {
        from: 'displayDateDueIn',
        to: 'userGridColumnDisplayDateDueIn',
        getSequence: 'dateDueInSequence',
        postSequence: 'dateDueInSequence'
    },
    dateDueOut: {
        from: 'displayDateDueOut',
        to: 'userGridColumnDisplayDateDueOut',
        getSequence: 'dateDueOutSequence',
        postSequence: 'dateDueOutSequence'
    },
    returnReason: {
        from: 'displayReturnReason',
        to: 'userGridColumnDisplayReturnReason',
        getSequence: 'returnReasonSequence',
        postSequence: 'returnReasonSequence'
    },
    branchShortName: {
        from: 'displayBranch',
        to: 'userGridColumnDisplayBranch',
        getSequence: 'branchSequence',
        postSequence: 'branchSequence'
    },
    franchiseName: {
        from: 'displayFranchise',
        to: 'userGridColumnDisplayFranchise',
        getSequence: 'franchiseSequence',
        postSequence: 'franchiseSequence'
    },
    st: {
        from: 'displayJobDescription',
        to: 'userGridColumnDisplayJobDescription',
        getSequence: 'jobDescriptionSequence',
        postSequence: 'jobDescriptionSequence'
    },
    accountNo: {
        from: 'displayAccount',
        to: 'userGridColumnDisplayAccount',
        getSequence: 'accountSequence',
        postSequence: 'accountSequence'
    },
    createdBy: {
        from: 'displayCreatedBy',
        to: 'userGridColumnDisplayCreatedBy',
        getSequence: 'createdBySequence',
        postSequence: 'createdBySequence'
    },
    assigned: {
        from: 'displayAssignedDate',
        to: 'userGridColumnDisplayAssignedDate',
        getSequence: 'assignedDateSequence',
        postSequence: 'assignedDateSequence'
    },
    taken: {
        from: 'displayTimeTaken',
        to: 'userGridColumnDisplayTimeTaken',
        getSequence: 'timeTakenSequence',
        postSequence: 'timeTakenSequence'
    },
    status: {
        from: 'displayStatus',
        to: 'UserGridColumnDisplayStatus',
        getSequence: 'statusSequence',
        postSequence: 'statusSequence'
    },
    assignedDays: {
        from: 'displayAssignedDays',
        to: 'UserGridColumnDisplayAssignedDays',
        getSequence: 'assignedDaysSequence',
        postSequence: 'assignedDaysSequence'
    },
    owningOperator: {
        from: 'displayOwningOperator',
        to: 'UserGridDisplayColumnOwningOperator',
        getSequence: 'owningOperatorSequence',
        postSequence: 'owningOperatorSequence'
    },
    jobAssignedUser: {
        from: 'displayJobAssignedUser',
        to: 'UserGridColumnDisplayJobAssignedUser',
        getSequence: 'jobAssignedUserSequence',
        postSequence: 'jobAssignedUserSequence'
    },
    chassisNumber: {
        from: 'displayChassisNumber',
        to: 'UserGridColumnDisplayChassisNumber',
        getSequence: 'chassisNumberSequence',
        postSequence: 'chassisNumberSequence'
    },

    courtesyCarReq: {
        from: 'displayCourtesyCarReq',
        to: 'UserGridColumnDisplayCourtesyCarReq',
        getSequence: 'courtesyCarReqSequence',
        postSequence: 'courtesyCarReqSequence'
    },
    customerWaiting: {
        from: 'displayCustomerWaiting',
        to: 'UserGridColumnDisplayCustomerWaiting',
        getSequence: 'customerWaitingSequence',
        postSequence: 'customerWaitingSequence'
    }
};

const TechnicianApp = (props) => {
    const [state, setState] = useState({
        technicianWorkflowId: undefined,
        rows: [],
        totalRec: 0,
        id: null,
        btnid: null,
        currentTechnicianId: null,
        tempObj: {},
        filterList: [],
        displayPagination: true,
        filterReturnReasone: [],
        technicianJobBucket: [],
        isReload: ''
    });
    const { portalSettings, getUserPreference, isAppReaload } = useContext(AppContext);
    const canSetCustomerRating = AppStorage.getCanSetCustomerRating();

    let checkCurrentId = state.currentTechnicianId == 1 || state.currentTechnicianId == 3;
    let getURL = checkCurrentId ? `TechnicianJobs/${state.currentTechnicianId}` : `WorkflowJobs/GetWorkflow/${state.currentTechnicianId}`;

    const pullAndUpdateState = async () => {
        getWorkflows().then((res) => {
            if (res.success) {
                const wf = find(res.data, (w) => w.isTechnicianWorkflow);
                if (wf) {
                    setState((st) => ({
                        ...st,
                        technicianWorkflowId: wf.id
                    }));
                }
            }
        });
    };

    const pullGridFilterList = async () => {
        let data = getUserPreference();
        if (data) {
            setState((st) => ({
                ...st,
                appbarLocation: data.details[0].appbarLocation || 'T',
                languageCode: data.details[0].languageCode,
                languageData: data.languages,
                tempObj: data.details[0] || {},
                displayPagination: isFalseValue(data.details[0]?.displayPagination)
            }));
        }
    };

    useEffect(async () => {
        pullAndUpdateState();
        let res = await getAllowedWorkflow();
        let data = res?.data?.list?.table;
        const buttons = res?.data?.list?.table.map((w) => ({
            id: w.id,
            label: w.label,
            displayJobPriorityButton: w.displayJobPriorityButton == 'Y' ? true : false,
            displayCustomerRatingButton: w.displayCustomerRatingButton == 'Y' ? true : false
        }));
        setState((st) => ({
            ...st,
            technicianJobBucket: [{ id: 1, label: 'Jobs Assigned To You' }, { id: 3, label: 'Jobs Assigned To Others' }, ...buttons]
        }));
    }, []);
    const ratingList = {
        1: <StarIcon style={{ color: 'gold' }} size="small" />,
        2: <StarIcon style={{ color: '#C0C0C0' }} size="small" />,
        3: <StarIcon style={{ color: '#CD7F32' }} size="small" />
    };
    const custerRating = (val) => {
        setState((st) => ({
            ...st,
            jobId: val.headerRecordID,
            workFlowData: val,
            showCustomerRating: true
        }));
    };
    const handleCustmer = (val) => {
        setState((st) => ({
            ...st,
            customerMessage: val,
            displayCustomerMessage: true
        }));
    };
    const handleBtnClcik = (m) => {
        props.history.push(
            `/Job/${m.headerRecordID}/${checkCurrentId ? state.technicianWorkflowId : state.currentTechnicianId}/${m.technicianJobID}/${m.ownJob}`
        );
    };

    useEffect(() => {
        if (isAppReaload) {
            pullGridFilterList();
        }
    }, [isAppReaload]);

    const editButton = (id, val) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justify="center">
                <Grid>
                    {state.technicianWorkflowId && (
                        <IconButton size="small" onClick={() => handleBtnClcik(val)}>
                            <ArrowForwardIosIcon
                                fontSize="small"
                                style={{
                                    color: val.returnReasonTextColourCode
                                }}
                            />
                        </IconButton>
                    )}
                </Grid>
                <Grid>
                    {canSetCustomerRating ? (
                        <>
                            {val.customerRatingIcon ? (
                                <Tooltip title={val.customerRatingNotes}>
                                    <IconButton size="small" onClick={() => custerRating(val)}>
                                        {ratingList[val.customerRatingIcon]}
                                    </IconButton>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Set Customer Rating">
                                    <IconButton size="small" onClick={() => custerRating(val)}>
                                        <ReportIcon
                                            fontSize="small"
                                            style={{
                                                color: val.returnReasonTextColourCode
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </>
                    ) : (
                        state.displayCustomerRatingButton && (
                            <Tooltip title={val.customerRatingNotes}>
                                <IconButton size="small" onClick={() => handleCustmer(val.customerRatingNotes)}>
                                    {ratingList[val.customerRatingIcon]}
                                </IconButton>
                            </Tooltip>
                        )
                    )}
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const regNumberRendrer = (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid
                onClick={() => setState((st) => ({ ...st, showMotHistory: true, regNo: record?.registration, FranchiseCode: record?.f }))}
                style={{ cursor: 'pointer' }}
            >
                {value}
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const column_new = [
        {
            field: '',
            title: 'Action',
            sortable: false,
            filterable: false,
            renderer: editButton,
            width: 80
        },
        {
            field: 'wipNumber',
            title: `${portalSettings.portalSettingGridWIPNoLabel || 'WIP No'}`,
            sortable: true,
            width: 70
        },
        {
            field: 'registration',
            title: `${portalSettings.portalSettingGridVehicleRegLabel || 'Vehicle Reg'}`,
            renderer: regNumberRendrer,
            sortable: true,
            width: 90
        },
        {
            field: 'makeModel',
            title: `${portalSettings.portalSettingGridModelLabel || 'Model'}`,
            sortable: true,
            width: 200
        },
        {
            field: 'customerName',
            title: `${portalSettings.portalSettingGridCustomerNameLabel || 'Customer Name'}`,
            sortable: true,
            width: 200
        },
        {
            field: 'dateDueIn',
            title: `${portalSettings.portalSettingGridDateDueInLabel || 'Date Due In'}`,
            sortable: true,
            width: 135,
            renderer: function (value, record) {
                if (record.dateDueIn != null) {
                    return moment(record.dateDueIn).format('DD-MMM-YYYY HH:mm');
                }
                return '';
            }
        },
        {
            field: 'dateDueOut',
            sortable: true,
            title: `${portalSettings.portalSettingGridDateDueOutLabel || 'Date Due Out'}`,
            // type: 'date',
            type: 'dateTime',
            width: 135,
            renderer: function (value, record) {
                if (record.dateDueOut != null) {
                    return moment(record.dateDueOut).format('DD-MMM-YYYY HH:mm');
                }
                return '';
            }
        },
        {
            field: 'returnReason',
            title: `${portalSettings.portalSettingGridReturnReasonLabel || 'Return Reason'}`,
            sortable: true,
            width: 190
        },
        {
            field: 'chassisNumber',
            title: `${portalSettings.portalSettingGridChassisNumberLabel || 'Chassis Number'}`,
            sortable: true,
            width: 200
        },
        {
            field: 'branchShortName',
            title: `${portalSettings.portalSettingGridBranchLabel || 'Branch'}`,
            sortable: true,
            width: 120
        },
        { field: 'franchiseName', sortable: true, title: `${portalSettings.portalSettingGridFranchiseLabel || 'Franchise'}`, width: 160 },
        { field: 'st', sortable: true, title: `${portalSettings.portalSettingGridJobDescriptionLabel || 'Job Description'}`, width: 170 },
        { field: 'accountNo', sortable: true, title: `${portalSettings.portalSettingGridAccountLabel || 'Account'}`, width: 80 },
        {
            field: 'userName',
            title: 'Technician',
            sortable: true,
            width: 180
        },
        {
            field: 'assigned',
            sortable: true,
            title: `${portalSettings.portalSettingGridAssignedDateLabel || 'Assigned Date'}`,
            width: 120,
            renderer: function (value, record) {
                if (record.assigned != null) {
                    return moment(record.assigned).format('DD-MMM-YYYY HH:mm');
                }
                return '';
            }
        },
        {
            field: 'taken',
            sortable: true,
            title: `${portalSettings.portalSettingGridTimeTakenLabel || 'Time Taken'}`,
            width: 100
        },
        {
            field: 'status',
            sortable: true,
            title: `${portalSettings.portalSettingGridStatusLabel || 'Status'}`,
            width: 100
        },
        {
            field: 'assignedDays',
            sortable: true,
            title: `${portalSettings.portalSettingGridAssignedDaysLabel || 'Assigned Days'}`,
            width: 100
        },
        {
            field: 'owningOperator',
            sortable: true,
            title: `${portalSettings.portalSettingGridOwningOperatorLabel || 'Owning Operator'}`,
            width: 100
        },
        {
            field: 'courtesyCarReq',
            sortable: true,
            title: `${portalSettings.portalSettingGridCourtesyCarReqLabel || 'Loan Vehicle'}`,
            width: 140
        },
        {
            field: 'customerWaiting',
            sortable: true,
            title: `${portalSettings.portalSettingGridCustomerWaitingLabel || 'Customer Waiting'}`,
            width: 140
        }
    ]
        .map((c) => {
            let obj = {};
            if (Object.keys(state.tempObj).length > 0 && !c.isShow) {
                obj.sequenceNo = state.tempObj[config[c.field]?.getSequence] || 9999999;
                if (c.title !== 'Action') {
                    obj.hidden = !state.tempObj[config[c.field]?.from];
                }
            } else {
                obj.hidden = false;
            }
            if (c.title !== 'Action' && c.field !== 'registration' && c.field !== 'chassisNumber') {
                obj.events = {
                    click: function (e) {
                        if (checkCurrentId) {
                            props.history.push(
                                `/Job/${e.data.record.headerRecordID}/${checkCurrentId ? state.technicianWorkflowId : state.currentTechnicianId}/${
                                    e.data.record.technicianJobID
                                }/${e.data.record.ownJob}?ProcessId=${e.data.record.pid}`
                            );
                        } else {
                            props.history.push(`/Job/${e.data.record.headerRecordID}/${e.data.record.workflowID}?ProcessId=${e.data.record.pid}`);
                        }
                    }
                };
            }
            if (c.title == 'Action') {
                obj.sequenceNo = -1;
            }
            return {
                ...c,
                ...obj,
                headerCssClass: 'gridHeader'
            };
        })
        .sort((a, b) => {
            return a.sequenceNo - b.sequenceNo;
        });

    if (state.currentTechnicianId == 1) {
        let isTechnicianIndex = column_new.findIndex((q) => q.field == 'userName');
        column_new.splice(isTechnicianIndex, 1);
    }

    const buttonClicked = async (btn) => {
        setState((st) => ({
            ...st,
            currentTechnicianId: btn.id,
            currentWorkflowLabel: btn.label,
            displayCustomerRatingButton: btn.displayCustomerRatingButton || false
        }));
    };

    const closeDialog = (res) => {
        setState((st) => ({ ...st, showFilter: false, displayCustomerMessage: false, showCustomerRating: false }));

        res && pullGridFilterList();
    };

    const handleOpen = () => {
        let tempArr = [...column_new]
            .map((q) => {
                return {
                    field: q.field,
                    title: q.title,
                    hidden: !q.hidden,
                    fieldName: config[q.field]?.to,
                    postSequenceFieldName: config[q.field]?.postSequence
                };
            })
            .filter((p) => (!p.isShow && p.title != 'Action' ? p : null));
        // tempArr.push({
        //     field: 'pagination',
        //     title: 'Pagination',
        //     hidden: state.displayPagination,
        //     fieldName: 'UserGridColumnDisplayPagination'
        // });
        setState((st) => ({ ...st, showFilter: true, filterList: tempArr }));
    };

    const resetFilters = () => {
        localStorage.setItem('FiltterParams', JSON.stringify({}));
        setState((prev) => ({
            ...prev,
            isReload: new Date()
        }));
    };

    const getfilterReturnReasone = (returnReasonelist) => {
        const uniqueReturnReasonelist = [...new Map(returnReasonelist.map((item) => [item['returnReason'], item])).values()];
        setState((st) => ({ ...st, filterReturnReasone: uniqueReturnReasonelist }));
    };
    return (
        <div className="filter-Container">
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                <BasicButtonGroup
                    buttons={state.technicianJobBucket}
                    onClick={buttonClicked}
                    className="workflow-buttons"
                    margin={6}
                    hideWorkflowScrollbar={state.hideWorkflowScrollbar}
                />
                <span
                    style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}
                    // className="filter-div"
                >
                    <abbr title={'Info'}>
                        <GridInfo filterReturnReasone={state.filterReturnReasone} />
                    </abbr>

                    {/* <IconButton aria-label="reset" onClick={resetFilters}> */}
                    <abbr title={'Reset Filters'}>
                        <RotateLeftIcon onClick={resetFilters} style={{ margin: 5, cursor: 'pointer' }} />
                    </abbr>
                    {/* </IconButton> */}

                    {/* <IconButton aria-label="filter" onClick={handleOpen}> */}
                    <abbr title={'My Preferences'}>
                        <FilterListIcon onClick={handleOpen} style={{ margin: 5, cursor: 'pointer' }} />
                    </abbr>
                    {/* </IconButton> */}
                </span>
            </div>
            {state.currentTechnicianId && state.technicianWorkflowId && (
                <div style={{ marginTop: !state.hideWorkflowScrollbar ? 0 : -20 }}>
                    <TechnicianGijgoGrid
                        columns={column_new}
                        //  getUrl={`TechnicianJobs/${state.currentTechnicianId}`}
                        isReload={state.isReload}
                        getUrl={getURL}
                        currentWorkflowLabel={state.currentWorkflowLabel}
                        filterList={state.tempObj}
                        displayPagination={state.displayPagination}
                        getfilterReturnReasone={getfilterReturnReasone}
                    />
                </div>
            )}
            {state.showFilter ? (
                <DialogComp title="My Preferences" maxWidth="md" onClose={() => closeDialog(false)} fullWidth>
                    <FilterModalMainTabComponent
                        languageCode={state.languageCode}
                        appbarLocation={state.appbarLocation}
                        filterConfig={config}
                        filterList={state.filterList}
                        handleCancel={closeDialog}
                        languageData={state.languageData}
                        isPagination={state.displayPagination}
                    />
                    {/* <GridFilterModal
                        languageCode={state.languageCode}
                        appbarLocation={state.appbarLocation}
                        filterConfig={config}
                        filterList={state.filterList}
                        languageData={state.languageData}
                        handleCancel={closeDialog}
                    /> */}
                </DialogComp>
            ) : null}
            {state.showMotHistory && (
                <DialogComp
                    title="MOT History"
                    onClose={() => setState((st) => ({ ...st, showMotHistory: false }))}
                    maxWidth="md"
                    fullWidth
                    overflow={'auto'}
                    height="480px"
                >
                    <MotHistoryScreen regNo={state.regNo} FranchiseCode={state.FranchiseCode} hideSeachText={true} />
                </DialogComp>
            )}
            {state.showCustomerRating ? (
                <DialogComp title="Customer Rating" maxWidth="sm" onClose={() => closeDialog(false)} fullWidth>
                    <CustomerRating jobId={state.jobId} workFlowData={state.workFlowData} handleCancel={closeDialog} />
                </DialogComp>
            ) : null}
            {state.displayCustomerMessage ? (
                <DialogComp title="Customer Rating Message" maxWidth="xs" onClose={() => closeDialog(false)} fullWidth>
                    <Typography variant="h6" style={{ textAlign: 'center' }} color="secondary">
                        {state.customerMessage}
                    </Typography>
                </DialogComp>
            ) : null}
        </div>
    );
};
export default TechnicianApp;
