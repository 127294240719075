import React, { useContext, useEffect, useState } from 'react';

import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { SecondaryButton, TextBox } from '../../../../Core/FormInput';

import { Alert } from '@material-ui/lab';

import { PostAwaitingCollection, PostGridAwaitingCollection } from '../../../../Core/Service/PartReq_services/partReturnSteps-services';
import AppContext from '../../../../App/AppContext';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';

const StyledTableCell = withStyles((theme) => ({
    head: {
        // padding: ' 5px',
        fontSize: 15,
        fontWeight: 600,
        backgroundColor: '#bdbdbd82'
    },
    body: {
        fontSize: 12
        // padding: ' 5px'
        // fontSize: 11,
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'grey',
            color: 'white'
        },
        cursor: 'pointer'
    }
}))(TableRow);

const AwaitingCollectionGridModal = (props) => {
    const { showToast } = useContext(AppContext);
    const [btnLoader, setBtnLoader] = useState(false);
    const [state, setState] = useState({
        partReturnSupplierInvoiceNo: '',
        partReturnIDs: []
    });

    useEffect(() => {
        if (props.selectedRecords.length > 0) {
            setState((st) => ({ ...st, partReturnIDs: props.selectedRecords.map((q) => q.partReturnID) }));
        }
    }, []);

    const Onsubmit = async () => {
        setBtnLoader(true);
        let data = {
            partReturnIDs: state.partReturnIDs,
            partReturnSupplierInvoiceNo: state.partReturnSupplierInvoiceNo || ''
        };
        let res = await PostGridAwaitingCollection(data);

        if (res.success) {
            showToast(res?.data?.message);
            props.onClose && props.onClose(true);
        }
        setBtnLoader(false);
    };

    return (
        <div style={{ padding: 10 }}>
            {state.partReturnIDs.length <= 0 ? (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {/* <Alert>{state.partReturnIDs?.toString()}</Alert> */}
                        <Alert severity="info">Please Select parts </Alert>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity="info">Do you want to set status of these parts to be awaiting collection?</Alert>
                        <TableContainer>
                            <Table size="small" width="550px" stickyHeader>
                                <TableHead>
                                    <TableRow className="header">
                                        <StyledTableCell>Part Number</StyledTableCell>
                                        <StyledTableCell>Supplier Name</StyledTableCell>
                                        <StyledTableCell>Part Return Date Created</StyledTableCell>
                                        <StyledTableCell>Is Surcharged</StyledTableCell>
                                        <StyledTableCell>Returned By</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.selectedRecords.map((q) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell>{q.partNo}</StyledTableCell>
                                                <StyledTableCell>{q.supplierName}</StyledTableCell>
                                                <StyledTableCell>{moment(q.partReturnDateCreated).format('DD-MMM-YYYY HH:mm')}</StyledTableCell>
                                                <StyledTableCell>{q.isSurcharged}</StyledTableCell>
                                                <StyledTableCell>{q.returnedBy}</StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Alert>{state.partReturnIDs?.toString()}</Alert>
                    <Alert severity="info">Do you want to set status of these parts to be awaiting collection?</Alert>
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextBox
                            placeholder="Supplier Invoice Number"
                            name="partReturnSupplierInvoiceNo"
                            value={state.partReturnSupplierInvoiceNo}
                            onChange={(e) => {
                                setState((st) => ({ ...st, partReturnSupplierInvoiceNo: e.target.value }));
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SecondaryButton fullWidth onClick={Onsubmit} disabled={!state.partReturnSupplierInvoiceNo} isBtnLoader={btnLoader}>
                            Yes{' '}
                        </SecondaryButton>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default AwaitingCollectionGridModal;
