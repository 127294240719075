import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { SecondaryButton } from '../../../../Core/FormInput';
import { Alert } from '@material-ui/lab';
import { PostReturnToPartDepartment } from '../../../../Core/Service/PartReq_services/partReturnSteps-services';

const ReturnToPartDept = ({ onClose, handleSubmit, partReturnPartReqID, partReturnID, showToast }) => {
    const [btnLoader, setBtnLoader] = useState(false);
    const Onsubmit = async () => {
        setBtnLoader(true);
        let data = {
            partReturnPartReqID: partReturnPartReqID,
            partReturnID: partReturnID
        };
        let res = await PostReturnToPartDepartment(data);

        if (res.success) {
            showToast(res?.data?.message);
            handleSubmit();
            onClose();
        }
        setBtnLoader(false);
    };
    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Alert severity="info">Has this part been returned to the parts department?</Alert>
                </Grid>
                <Grid item xs={12}>
                    <SecondaryButton fullWidth onClick={Onsubmit} isBtnLoader={btnLoader}>
                        Yes{' '}
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default ReturnToPartDept;
