import React, { useContext, useState, useEffect, useMemo } from 'react';
import AppContext from '../../../App/AppContext';
import { TextBox, SecondaryButton, DialogContents, DialogTitles, UploadWithBadge } from '../../../Core/FormInput';
import '../../../Core/Modal/model.css';
import Grid from '@material-ui/core/Grid';
// import { DataGrid } from '@mui/x-data-grid';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import {
    getPartReqs,
    approvePartReq,
    addPartReq,
    getPartReqStatus,
    getScreenDropdownPartReqID,
    getPartListByPartNumber
} from '../../../Core/Service/partRequisitions-service';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { some } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { IconButton, InputAdornment, Popover } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import '../../technician/technician-app/TechnicianStyle.scss';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import DialogComp from '../../../Core/Modal/dialogModal';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import PartReqPhotoModal from '../../technician/technician-app/PartReqPhotoUploadmodal';
import InfoIcon from '@material-ui/icons/Info';
import PartReqOtherInfo from '../../technician/technician-app/RequestorApproverIssuerInfo';
import SearchIcon from '@material-ui/icons/Search';
import { postPhotosByPartReqID } from '../../../Core/Service/partRequisitions-service';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 15
    },
    inputBase: {
        '&.MuiFormControl-fullWidth': {
            // background: 'white'
            // border: '1px solid rgba(0, 0, 0, 0.3)'
        },
        '& .MuiOutlinedInput-inputMarginDense': {
            padding: '13px',
            border: 'none',
            background: 'white',
            borderLeft: '1px solid rgba(0, 0, 0, 0.3)',
            borderTop: '1px solid rgba(0, 0, 0, 0.3)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.3)'
        },
        '.MuiInputBase-root': {
            background: 'white',
            borderLeft: '1px solid rgba(0, 0, 0, 0.3)',
            borderTop: '1px solid rgba(0, 0, 0, 0.3)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.3)'
        }
    },
    table: {
        minWidth: '100%'
    },
    title: {
        color: '#f50057',
        marginLeft: 8,
        marginTop: 8,
        marginBottom: 8
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        border: '1px solid rgba(0,0,0,0.2)'
    },
    axelTitles: {
        textAlign: 'center',
        color: '#f50057',
        width: '100%'
    },
    headings: {
        color: '#f50057',
        margin: 0,
        marginLeft: -4,
        marginBottom: 20
    }
}));
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'grey',
            color: 'white'
        },
        cursor: 'pointer'
    }
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        // padding: ' 5px',
        fontSize: 15,
        fontWeight: 600,
        backgroundColor: '#bdbdbd82'
    },
    body: {
        fontSize: 12
        // padding: ' 5px'
        // fontSize: 11,
    }
}))(TableCell);

const MatchingPartList = ({ list, loading, handleRowSelect, id }) => {
    return (
        <Paper style={{ borderRadius: '5px', maxHeight: '350px', overflow: 'auto', width: '350px' }}>
            <TableContainer>
                <Table size="small" width="550px" stickyHeader>
                    <TableHead>
                        <TableRow className="header">
                            <StyledTableCell>Number</StyledTableCell>
                            <StyledTableCell>Description</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list?.map((j, i) => (
                            <StyledTableRow className={`row${j.isSelected ? ' is-selected-j' : ''}`} onClick={() => handleRowSelect(j, id)}>
                                <StyledTableCell>{j.partNumber}</StyledTableCell>
                                <StyledTableCell>{j.description}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                        {list?.length === 0 && (
                            <TableRow>
                                <TableCell colSpan="4">{loading ? 'Loading...' : 'No record found.'}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};
const ApprovePartRequisitions = (props) => {
    // let index = 0;
    const classes = useStyles();
    const { hideTopNavAndMenu, showToast, userRoles } = useContext(AppContext);
    const fromStep = new URLSearchParams(props.location.search.toLowerCase()).get('fromstep') === 'true';
    const Id = props.match.params.jobId;
    const [windowWidths, windowHeight] = useWindowSize();
    const [btnLoader, setBtnLoader] = useState(false);
    const [index, setIndex] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorE2, setAnchorE2] = React.useState(null);

    const [state, setState] = useState({
        needApproval: false,
        rows: [],
        statusList: [],
        technicianJobID: props.match.params?.technicianJobId,
        technician: [],
        wipDetails: [],
        errors: {},
        changedRecordIds: [],
        partApproverSignature: null,
        createdUserID: null
    });

    const setReturnReason = (id, des) => (e) => {
        let changedArray = [...state.rows];
        let temp = [...state.changedRecordIds];
        let objIndex = state.rows.findIndex((x) => x.id === id);
        changedArray[objIndex].partReqStatus = e.target.value;
        let findIDExist = temp.find((a) => a == id);
        if (!findIDExist) {
            temp.push(id);
        }
        setState((st) => ({ ...st, rows: changedArray, changedRecordIds: temp }));
    };

    const changeQantity = (id, des, name) => (e) => {
        let changedArray = [...state.rows];
        let temp = [...state.changedRecordIds];
        let objIndex = state.rows.findIndex((x) => x.id === id);
        changedArray[objIndex][name] = e.target.value;
        let findIDExist = temp.find((a) => a == id);
        if (!findIDExist) {
            temp.push(id);
        }
        setState((st) => ({ ...st, rows: changedArray, changedRecordIds: temp }));
    };

    const pullDataByPartNumber = async (e, type, row) => {
        setIndex(row.id);
        let res;

        if (type == 'P') {
            // if (row?.row?.partReqPartNumber) {
            setAnchorEl(e.currentTarget);
            res = await getPartListByPartNumber(row?.row?.partReqPartNumber, null);
            if (res.success) {
                setState((st) => ({ ...st, matchingPartNumber: res.data.partDetails, loading: false }));
            }
            // } else {
            //     showToast('Please enter part number to fetch data');
            // }
        } else {
            // if (state.part) {
            setAnchorE2(e.currentTarget);
            res = await getPartListByPartNumber(null, row?.row?.desc);
            if (res.success) {
                setState((st) => ({ ...st, matchingPartNumber: res.data.partDetails, loading: false }));
            }
            // } else {
            //     showToast('Please enter description to fetch data');
            // }
        }
    };

    const handleRowSelect = (params, id) => {
        setState((st) => {
            let newSt = { ...st };
            let list = [...st.rows];
            let indexID = list.findIndex((j) => j.id === index);
            newSt.rows[indexID].partReqPartNumber = params.partNumber;
            newSt.rows[indexID].partReqPartDescription = params.description;
            return newSt;
        });

        setAnchorEl(null);
        setAnchorE2(null);
    };

    const handlePoperClose = () => {
        setAnchorEl(null);
        setAnchorE2(null);
    };

    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorE2);
    const id = open ? 'simple-popover' : undefined;
    const id2 = open2 ? 'simple-popover2' : undefined;

    const getScreenDropdown = async () => {
        let result = await getScreenDropdownPartReqID(Id, 'A');
        const ops = result.data.partReqStatus.map((rr) => ({ value: rr.partReqStatusID, text: rr.partReqStatusDescription }));

        if (result.success) {
            setState((st) => {
                const nst = { ...st };
                //   nst.stockList = ops1;
                nst.statusList = ops;
                nst.technician = result.data.technician;
                nst.wipDetails = result.data.wipDetails[0];
                if (st.createdUserID == null) {
                    nst.createdUserID = result.data.technician[0]?.createdUserID;
                    nst.technicianJobID = result.data.technician[0]?.technicianJobID;
                    nst.createdUserID = result.data.technician[0]?.createdUserID;
                    nst.allowEdit = result.data.technician[0]?.allowEdit == 'Y';
                }
                return nst;
            });
            pullWorkflowAndUpdateState();
        } else {
            console.log(result.msg);
        }
    };
    const pullWorkflowAndUpdateState = async (createdUserID) => {
        let result = await getPartReqs(Id, 'A', state.technicianJobID);
        if (result.success) {
            const rows = result.data.partReqs.map((r, i) => ({
                id: r.partReqID,
                partReqPartDescription: r.partReqPartDescription,
                partReqQuantity: r.partReqQuantity,
                partReqStatus: r.partReqStatus,
                isApproved: r.isApproved,
                approvedSig: r.approverSignature,
                technicianSig: r.requestorSignature,
                requestor: r.requestor,
                approver: r.approver,
                partReqIssuedQuantity: r.partReqIssuedQuantity,
                partReqQuantity: r.partReqQuantity,
                issueStatus: r.issueStatus,
                count: r.partReqQuantity === r.partReqIssuedQuantity,
                requestedTime: r.requestedTime,
                approvedTime: r.approvedTime,
                issuedTime: r.issuedTime,
                photosUploaded: r.photosUploaded,
                Photos: [],
                oldPhoto: [],
                isFitted: r.isFitted,
                partReqPartNumber: r.partReqPartNumber
            }));
            setState((st) => {
                const nst = { ...st };
                nst.rows = rows;
                nst.needApproval = some(rows, (r) => !r.isApproved);
                nst.allowEdit = some(rows, (r) => !r.isApproved); //for first render
                // if (st.technicianJobID !== result.data?.partReqs[0]?.partReqTechnicianJobID) {
                //     nst.technicianJobID = result.data?.partReqs[0]?.partReqTechnicianJobID;
                // }
                nst.partReqTechnicianSignature = result.data?.partReqs[0]?.partReqTechnicianSignature;
                nst.techSign = result.data?.partReqs[0]?.partReqTechnicianSignature;
                return nst;
            });
        } else {
            showToast(result.message);
        }
    };

    const TechBtnClick = (val) => {
        setState((st) => ({
            ...st,
            technicianJobID: val.technicianJobID,
            createdUserID: val.createdUserID,
            allowEdit: val.allowEdit == 'Y' && some(st.rows, (r) => !r.isApproved)
        }));
        pullWorkflowAndUpdateState(val.createdUserID);
    };

    const onImagemodalClose = (res) => {
        setState((st) => ({ ...st, showImage: false, partId: null, desc: '' }));
        makeEmptyPhotos('oldPhoto');
        res && pullWorkflowAndUpdateState(state.createdUserID);
    };
    const [data, setData] = useState({ showInfo: false });
    const setShowInfo = (val) => {
        setData((st) => ({
            ...st,
            showInfo: true,
            requestor: val.requestor,
            requestorSign: val.technicianSig,
            requestorDate: val.requestedTime,
            issuer: val.issuer,
            issuerSign: val.issuerSignature,
            issuerDate: val.issuedTime,
            approver: val.approver,
            approverSign: val.approvedSig,
            approverDate: val.approvedTime
        }));
    };

    const onChangeCheckbox = (id, des, name) => (e) => {
        let changedArray = [...state.rows];
        let objIndex = changedArray.findIndex((x) => x.id === id);

        changedArray[objIndex][name] = e.target.checked;

        setState((st) => ({
            ...st,
            rows: changedArray
        }));
    };

    const columns = [
        {
            field: 'partReqPartNumber',
            headerName: 'Part Number',
            width: 160,
            renderCell: (params) => {
                return (
                    <>
                        <TextBox
                            className={classes.inputBase}
                            name="partReqPartNumber"
                            id="partReqPartNumber"
                            data-rowid={params.row.id}
                            // onChange={onIssueQtyChange}
                            onChange={changeQantity(params.row.id, params.row.partReqPartNumber, 'partReqPartNumber')}
                            // onChange={changeQantity}
                            value={params.value}
                            style={{ paddingBottom: 2 }}
                            disabled={!state.allowEdit}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title={'Fetch Part Details By Number'} arrow>
                                            <Grid>
                                                <IconButton style={{ padding: '0px' }} onClick={(e) => pullDataByPartNumber(e, 'P', params)} size="small">
                                                    <SearchIcon />
                                                </IconButton>
                                            </Grid>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Popover
                            open={open}
                            id="simple-popover"
                            anchorEl={anchorEl}
                            autoFocus={true}
                            onClose={handlePoperClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            // style={{ left: '-267px' }}
                        >
                            <MatchingPartList
                                list={state.matchingPartNumber || []}
                                loading={state.loading}
                                handleRowSelect={handleRowSelect}
                                id={params.tabIndex}
                            />
                        </Popover>
                    </>
                );
            }
        },
        {
            field: 'partReqPartDescription',
            headerName: 'Part',
            width: 450,
            // flex: 1,
            renderCell: (params) => {
                return (
                    <div style={{ position: 'relative', width: '100%' }}>
                        {!params.row.partReqPartDescription && <span style={{ position: 'absolute', top: -11, right: -5, color: 'red' }}>*</span>}
                        <TextBox
                            className={classes.inputBase}
                            name="desc"
                            id="desc"
                            data-rowid={params.row.id}
                            onChange={changeQantity(params.row.id, params.row.partReqPartDescription, 'partReqPartDescription')}
                            value={params.value}
                            style={{ paddingTop: 3 }}
                            // className="pa-4"
                            disabled={!state.allowEdit}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title={'Fetch Part Details By Number'} arrow>
                                            <Grid>
                                                <IconButton style={{ padding: '0px' }} onClick={(e) => pullDataByPartNumber(e, 'D', params)} size="small">
                                                    <SearchIcon />
                                                </IconButton>
                                            </Grid>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Popover
                            open={open}
                            id="simple-popover"
                            anchorEl={anchorEl}
                            autoFocus={true}
                            onClose={handlePoperClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center'
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left'
                            }}
                            // style={{ left: '-267px' }}
                        >
                            <MatchingPartList
                                list={state.matchingPartNumber || []}
                                loading={state.loading}
                                handleRowSelect={handleRowSelect}
                                id={params.tabIndex}
                            />
                        </Popover>
                    </div>
                );
            }
        },
        {
            field: 'partReqQuantity',
            headerName: 'Qty',
            width: 100,
            renderCell: (params) => {
                return (
                    <div style={{ position: 'relative', width: '100%' }}>
                        {!params.row.partReqQuantity && <span style={{ position: 'absolute', top: -11, right: -5, color: 'red' }}>*</span>}
                        <input
                            name="desc"
                            id="desc"
                            data-rowid={params.row.id}
                            onChange={changeQantity(params.row.id, params.row.partReqQuantity, 'partReqQuantity')}
                            value={params.value}
                            className="pa-4"
                            disabled={!state.allowEdit}
                        />
                    </div>
                );
            }
        },
        {
            field: 'isFitted',
            headerName: 'Fitted',
            width: 100,
            renderCell: (params) => {
                return (
                    <input
                        type="checkbox"
                        name="isFitted"
                        id="isFitted"
                        data-rowid={params.row.id}
                        // onChange={onIssueQtyChange}
                        onClick={onChangeCheckbox(params.row.id, params.row.isFitted, 'isFitted')}
                        // value={params.value}
                        className="pa-4"
                        disabled={!state.allowEdit}
                        checked={params.row.isFitted}
                    />
                );
            }
        },
        {
            field: 'partReqStatusDescription',
            headerName: 'Status',
            width: 180,
            renderCell: (params) => {
                return (
                    <div style={{ position: 'relative', width: '100%' }}>
                        {!params.row.partReqStatus && <span style={{ position: 'absolute', top: -11, right: -5, color: 'red' }}>*</span>}
                        <select
                            error
                            name="partReqStockStatusId"
                            id="partReqStockStatusId"
                            className="pa-4 "
                            disabled={!state.allowEdit}
                            data-rowid={params.row.id}
                            onChange={setReturnReason(params.row.id, params.row.partReqStatus)}
                        >
                            <option value="" selected>
                                Choose here
                            </option>
                            {state.statusList.map((option) => {
                                let isSelected = option.value == params.row.partReqStatus;
                                return (
                                    <option key={option.value} value={option.value} selected={isSelected}>
                                        {option.text}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                );
            }
        },
        {
            field: 'partReqIssuedQuantity',
            headerName: 'Issued',
            width: 118
        },
        { field: 'issueStatus', headerName: 'Issue Status', width: 169 },
        {
            field: '',
            headerName: ' ',
            width: 130,
            sortable: false,
            renderCell: (params) => (
                <div style={{ margin: '20px 0px 0px 9px', cursor: `${params.row.photosUploaded >= 0 ? 'pointer' : ''}` }}>
                    {/* {console.log(params, 'params')} */}
                    <UploadWithBadge
                        disabled={!state.allowEdit}
                        state={params.row.photosUploaded > 0 || 0}
                        count={params.row.photosUploaded || 0}
                        onClick={() =>
                            params.row.photosUploaded >= 0 &&
                            setState((st) => ({ ...st, partId: params.row.id, desc: params.row.partReqPartDescription, showImage: true }))
                        }
                    />
                    <InfoIcon className="doneIcon" onClick={() => setShowInfo(params.row)} />
                </div>
            )
        }
    ];

    const isTechnician = userRoles.includes('technician');

    const handelAddPartToServer = async () => {
        if (isTechnician) {
            if (state.rows.length > 0) {
                handelSubmit();
            } else {
                showToast('Please add least one part');
            }
        } else {
            handelSubmit();
        }
    };

    const validations = () => {
        const { partApproverSignature } = state;
        let formIsValid = true;
        let errors = {};
        let isPartReq = false;
        let isPartReqErrorMsg = '';
        if (!partApproverSignature) {
            // errors.partApproverSignature = 'Approver Signature is required';
            // formIsValid = false;
            isPartReq = true;
            isPartReqErrorMsg = 'Approver Signature is required';
        }
        state.rows.forEach((m) => {
            if (!m.isApproved) {
                if (!m.partReqStatus) {
                    isPartReq = true;
                    isPartReqErrorMsg = 'Please select status';
                }
                if (!m.partReqPartDescription) {
                    isPartReq = true;
                    isPartReqErrorMsg = 'Description is required';
                }
                if (+m.partReqQuantity <= 0) {
                    isPartReq = true;
                    isPartReqErrorMsg = 'Please enter a valid quantity';
                }
            }
        });

        if (isPartReq) {
            errors.isPartReq = isPartReqErrorMsg;
            formIsValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handelSubmit = async () => {
        if (validations()) {
            setBtnLoader(true);
            const data = {
                mode: 'submit',
                partApproverSignature: state.partApproverSignature,
                partReqsAddVM: state.changedRecordIds.map((i) => {
                    let temp = state.rows.find((m) => i == m.id);
                    if (temp) {
                        return {
                            partReqId: temp.id,
                            partReqPartDescription: temp.partReqPartDescription,
                            partReqQuantity: temp.partReqQuantity,
                            partReqStatus: temp.partReqStatus,
                            isFitted: temp.isFitted,
                            partReqPartNumber: temp.partReqPartNumber
                        };
                    }
                })
            };
            let res1 = await postPhotosByPartReqID(state.rows);
            let res = await approvePartReq(Id, data, state.technicianJobID);
            if (!res.success) {
                console.error(res);
            }
            showToast(res.message);
            setBtnLoader(false);
            if (res.success && fromStep) {
                setTimeout(() => {
                    window.top.postMessage({ type: 'navigate-dashboard' }, '*');
                }, 1000);
            }
        }
    };

    const fieldChange = (val) => {
        setState((st) => ({
            ...st,
            ...val
        }));
    };

    const clearSig = (val) => {
        setState((st) => ({
            ...st,
            partApproverSignature: null
        }));
    };

    useEffect(() => {
        fromStep && hideTopNavAndMenu();
        getScreenDropdown();
    }, []);

    const closeDialog = () => {
        setState((st) => ({
            ...st,
            showSignature: false
        }));
    };

    const new_columns = useMemo(() => {
        const tempCols = [...columns];

        return tempCols;
    }, [columns]);

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>No parts available</div>
            </GridOverlay>
        );
    }

    const gridheight = `calc(100vh - ${state.needApproval ? '351px' : '142px'})`;

    const makeEmptyPhotos = (name) => {
        let changedArray = [...state.rows];
        let objIndex = state.rows.findIndex((x) => x.id === state.partId);
        changedArray[objIndex][name] = [];
        setState((st) => ({ ...st, rows: changedArray }));
    };

    const setPhotos = (name) => {
        setState((st) => ({ ...st, name }));
    };
    return (
        <div style={{ maxWidth: '100%', margin: '10px auto' }}>
            {!fromStep && (
                <Breadcrumbs separator=">>" aria-label="breadcrumb">
                    <Link color="inherit" to={'/'}>
                        Home
                    </Link>
                    <Typography color="secondary" style={{ fontSize: '0.85rem' }}>
                        Part Requisitions
                    </Typography>
                </Breadcrumbs>
            )}
            {/* <DialogTitles>
                <Grid container spacing={2}>
                    {state.technician.map((m) => (
                        <div className="tech-btn-group">
                            <Button className={state.createdUserID == m.createdUserID ? 'active-btn' : 'all-btn'} onClick={() => TechBtnClick(m)}>
                                {m.requestor}
                            </Button>
                        </div>
                    ))}
                </Grid>
            </DialogTitles> */}
            <DialogContents>
                <Grid container spacing={2}>
                    <Grid item xs={9} style={{ fontSize: 21, textAlign: 'left' }}>
                        Registration Number:&nbsp; <b> {state.wipDetails?.registration}</b> &nbsp; WIP No:&nbsp; <b> {state.wipDetails?.wipNumber}</b>
                    </Grid>
                    {data.showInfo && <PartReqOtherInfo data={data} onClose={() => setData((st) => ({ ...st, showInfo: false }))} />}
                    {state.needApproval && state.showImage && (
                        <DialogComp title="Image Upload" onClose={() => onImagemodalClose(false)} fullWidth maxWidth="lg">
                            <PartReqPhotoModal
                                partId={state.partId}
                                onFormSubmit={() => pullWorkflowAndUpdateState(state.createdUserID)}
                                desc={state.desc}
                                onClose={onImagemodalClose}
                                onImageChange={setPhotos}
                                rows={state.rows}
                            />
                        </DialogComp>
                    )}
                    {state.needApproval
                        ? state.rows.length > 0 && (
                              <Grid item xs={3} sm={3}>
                                  <SecondaryButton isBtnLoader={btnLoader} onClick={handelAddPartToServer} className="setFullWidth">
                                      Submit
                                  </SecondaryButton>
                                  <span className="mandatoryfields">{state.errors['isPartReq']}</span>
                              </Grid>
                          )
                        : state.rows.length > 0 && (
                              <Grid item xs={3} sm={3}>
                                  <Typography color="secondary">All parts are approved</Typography>
                              </Grid>
                          )}
                    {state.rows.length > 0 ? (
                        <>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                style={{
                                    height: `${windowHeight < 600 ? '400px' : gridheight}`,
                                    width: '100%',
                                    margin: '10px'
                                }}
                            >
                                <DataGrid
                                    className="data-grid-container"
                                    components={{
                                        NoRowsOverlay: CustomNoRowsOverlay
                                    }}
                                    headerHeight={50}
                                    rowHeight={50}
                                    hideFooter={true}
                                    columns={new_columns}
                                    rows={state.rows}
                                    disableColumnMenu={true}
                                    showColumnRightBorder={true}
                                    pageSize={100}
                                />
                            </Grid>
                            {state.needApproval && (
                                <Grid item xs={12} md={6} lg={6}>
                                    <CustomeSignPad
                                        onChange={fieldChange}
                                        content={'Approver Signature'}
                                        sign={state.workSign}
                                        name="partApproverSignature"
                                        clearSig={clearSig}
                                    />
                                    <span className="mandatoryfields">{state.errors['partApproverSignature']}</span>
                                </Grid>
                            )}
                        </>
                    ) : (
                        <Grid container item xs={12} style={{ height: 'calc(100vh - 342px)' }} justify="center" alignContent="center">
                            There are no part requisition requests for this job
                        </Grid>
                    )}
                    {state.showSignature ? (
                        <DialogComp title="Signature" maxWidth="sm" onClose={closeDialog}>
                            {state.signature ? (
                                <div style={{ position: 'relative' }}>
                                    <div style={{ position: 'absolute', bottom: 10, right: 0 }}>{state.sigDate}</div>
                                    <img src={state.signature} alt="my signature" style={{ border: '1px solid', height: 178, width: '100%' }} />
                                </div>
                            ) : (
                                <h1>No Signature found</h1>
                            )}
                        </DialogComp>
                    ) : null}
                </Grid>
            </DialogContents>
        </div>
    );
};

export default ApprovePartRequisitions;
