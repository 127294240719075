import { CircularProgress, FormHelperText, FormLabel, Grid, InputLabel } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import AppContext from '../../../App/AppContext';
import { GreenButton, SecondaryButton, TextBox } from '../../../Core/FormInput';
import { postCustomerRating, getCustomerRating } from '../../../Core/Service/workflow-service';
// import { SelectBox } from '';
import SelectBox from './../../../Core/FormInput/SelectBox';
import StarIcon from '@material-ui/icons/Star';

const CustomerRating = (props) => {
    const [state, setState] = useState({ customerRatingIcon: null, message: '', isLoading: true });
    const { hideTopNavAndMenu, showToast, portalSettings } = useContext(AppContext);

    useEffect(async () => {
        if (props.jobId) {
            let res = await getCustomerRating(props.jobId);
            if (res?.success) {
                let data = res?.data?.table[0];
                setState((st) => ({
                    ...st,
                    headerRecordID: data.headerRecordID,
                    customerRatingIcon: data.customerRatingIcon || null,
                    customerRatingNotes: data.customerRatingNotes || '',
                    enteredRegNo: data.enteredRegNo || '',
                    s24ChassisNumber: data.s24ChassisNumber || '',
                    registration: data.registration,
                    wipNo: data.wipNumber,
                    isLoading: false
                }));
            }
        }
    }, []);
    const fieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            const nst = { ...st, [name]: value };
            // nst.isSetDisabled = nst.jobPriority ? false : true;
            return nst;
        });
    };

    const ratingList = [
        {
            id: 1,
            name: (
                <>
                    <StarIcon style={{ color: 'gold' }} /> Gold
                </>
            )
        },
        {
            id: 2,
            name: (
                <>
                    <StarIcon style={{ color: '#C0C0C0' }} /> Silver
                </>
            )
        },
        {
            id: 3,
            name: (
                <>
                    <StarIcon style={{ color: '#CD7F32' }} /> Bronze
                </>
            )
        }
    ];

    let validation = () => {
        let isValid = true;
        let message = '';
        if (props.isRegMandetory) {
            if (!state.enteredRegNo) {
                isValid = false;
                message = 'This Field is required';
            }
        }

        setState((st) => ({ ...st, message: message }));
        return isValid;
    };
    const handelSubmit = async () => {
        if (validation()) {
            let data = {
                headerRecordID: props.jobId,
                customerRatingIcon: state.customerRatingIcon,
                customerRatingNotes: state.customerRatingNotes,
                enteredRegNo: state.enteredRegNo,
                s24ChassisNumber: state.s24ChassisNumber
            };
            const res = await postCustomerRating(data);
            if (res.success) {
                showToast(res.data.list.table[0].msg);
                props.handleCancel(true);
            }
            if (!res.success) {
                console.error(res);
            }
        }
    };

    return (
        <>
            {state.isLoading ? (
                <CircularProgress />
            ) : (
                <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={8}>
                        WIP No:&nbsp; <b> {state.wipNo}</b> <b> {state.registration}</b>
                    </Grid>
                    <Grid item xs={4}>
                        Reg No:&nbsp; <b> {state.registration}</b>
                    </Grid>
                    <br />
                    {portalSettings.showRegNumberInCustomerRatingBox && (
                        <>
                            <Grid item xs={8}>
                                <FormLabel component="legend">S24 Chassis Number</FormLabel>
                                <TextBox name="s24ChassisNumber" value={state.s24ChassisNumber} onChange={fieldChange} />
                            </Grid>

                            <Grid item xs={4}>
                                <FormLabel component="legend" error={props.isRegMandetory}>
                                    Reg No
                                </FormLabel>
                                <TextBox name="enteredRegNo" value={state.enteredRegNo} onChange={fieldChange} />
                                <FormHelperText shrink error style={{ marginTop: 0 }}>
                                    {state.message}
                                </FormHelperText>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <FormLabel component="legend" style={{ marginBottom: 10 }}>
                            Customer Rating Icon
                        </FormLabel>
                        <SelectBox List={ratingList} name="customerRatingIcon" value={state.customerRatingIcon} onChange={fieldChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel component="legend">Notes</FormLabel>
                        <TextBox rows={4} multiline name="customerRatingNotes" value={state.customerRatingNotes} onChange={fieldChange} />
                    </Grid>
                    <Grid item xs={12}>
                        <SecondaryButton className="Submitbtn" onClick={() => handelSubmit('reset')}>
                            Submit
                        </SecondaryButton>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default CustomerRating;
