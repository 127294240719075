import React, { useContext, useEffect, useState } from 'react';
import { SquareButton } from '../../../Core/FormInput/AppButton';
import { Grid } from '@material-ui/core';
import ReactDOM from 'react-dom';
import { useMemo } from 'react';
import ReportGijgoGrid from '../ReportsScreens/GijgoForReportsScreen';
import { useHistory } from 'react-router-dom';
import BreadCrumbs from '../../../Core/Controls/Breadcrumb';
import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import AwaitingCollectionGridModal from './GridStatusModals/AwaitingCollection';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import CollectedByMainSiteGridModal from './GridStatusModals/CollectedByMainSite';
import { Alert } from '@material-ui/lab';
import CollectedGridModal from './GridStatusModals/Collected';
import ReceivedByMainSiteGridModal from './GridStatusModals/ReceivedByMainSite';
import CreditReceivedGridModal from './GridStatusModals/CreditReceived';
import ReturnToPartDeptGridModal from './GridStatusModals/ReturnToPartDept';
import AppContext from '../../../App/AppContext';

var $ = window.$;

let styleObj = {
    backgroundColor: '#183B68',
    borderRadius: '20%',
    width: '32px',
    height: '32px',
    textAlign: 'center',
    padding: '0px 0px 0px 0px'
};

const crumbs = (desc) => {
    let list = [
        { name: 'Home', path: '/' },
        { name: desc, active: true }
    ];
    return list;
};

let configComp = {
    1: {
        id: 1,
        name: 'Incomplete',
        Comp: (props) => {
            return <ReturnToPartDeptGridModal selectedRecords={props.selectedRecords} onClose={props.onClose} />;
        }
    },
    2: {
        id: 2,
        name: 'Returned to Parts Department',
        Comp: (props) => {
            return <AwaitingCollectionGridModal selectedRecords={props.selectedRecords} onClose={props.onClose} />;
        }
    },
    3: {
        id: 3,
        name: 'Awaiting Collection',
        Comp: (props) => {
            if (props.isSameSupplierIsInternal == 'Yes') {
                return <CollectedByMainSiteGridModal selectedRecords={props.selectedRecords} onClose={props.onClose} />;
            } else if (props.isSameSupplierIsInternal == 'No') {
                return <CollectedGridModal selectedRecords={props.selectedRecords} onClose={props.onClose} />;
            } else {
                return <p>No Data....!</p>;
            }
        }
    },
    4: {
        id: 4,
        name: 'Collected by Main Site',
        Comp: (props) => {
            return <ReceivedByMainSiteGridModal selectedRecords={props.selectedRecords} onClose={props.onClose} />;
        }
    },
    5: {
        id: 5,
        name: 'Received by Main Site',
        Comp: (props) => {
            return <CollectedGridModal selectedRecords={props.selectedRecords} onClose={props.onClose} />;
        }
    },
    6: {
        id: 6,
        name: 'Collected',
        Comp: (props) => {
            return <CreditReceivedGridModal selectedRecords={props.selectedRecords} onClose={props.onClose} />;
        }
    },
    7: {
        id: 7,
        name: 'Credit Received',
        Comp: (props) => {
            return <p>No Data </p>;
        }
    }
};

function checkAllSupplierIsInternalSame(arr, property) {
    if (!Array.isArray(arr) || arr.length === 0) {
        return { isSame: false, value: undefined }; // Return false and undefined value if input is not a valid array or is empty
    }

    const firstValue = arr[0][property]; // Get the value of the specified property from the first element

    // Check if all elements in the array have the same value for the specified property as the first element
    const isSame = arr.every((item) => item[property] === firstValue);

    return { isSame, value: isSame ? firstValue : undefined }; // Return whether all are the same and the common value if true
}

// function checkAllSupplierIsInternalSame(arr) {
//     if (!Array.isArray(arr) || arr.length === 0) {
//         return false; // Return false if input is not a valid array or is empty
//     }

//     const firstSupplierIsInternal = arr[0].supplierIsInternal; // Get the value of supplierIsInternal from the first element

//     // Check if all elements in the array have the same value for supplierIsInternal as the first element
//     for (let i = 1; i < arr.length; i++) {
//         if (arr[i].supplierIsInternal !== firstSupplierIsInternal) {
//             return false; // Return false if any element's supplierIsInternal differs from the first element's value
//         }
//     }

//     return true; // Return true if all elements have the same value for supplierIsInternal
// }

const PartReqsSurchargedList = (props) => {
    const { portalSettings } = useContext(AppContext);

    const [state, setState] = useState({ selectedRecords: [], isSameSupplierIsInternal: null });
    const [modalTitle, setModalTitle] = useState('');
    const history = useHistory();
    // const search = window.location.search;
    // const params = new URLSearchParams(search);
    // let desc = decodeURIComponent(params.get('description') || '');
    let code = props.id || 1;

    const baseColumns = [
        {
            field: 'action',
            title: `Action`,
            width: '5%',
            filterable: false,
            sortable: false
        },
        {
            field: 'partNo',
            title: `Part No`,
            filterable: true,
            sortable: true,
            width: '8%'
        },
        {
            field: 'supplierName',
            title: `Supplier`,
            filterable: true,
            sortable: true,
            width: '10%'
        },
        {
            field: 'qty',
            title: `Quantity`,
            filterable: true,
            sortable: true,
            width: '5%'
        },
        {
            field: 'isSurcharged',
            title: `Surcharge`,
            filterable: true,
            sortable: true,
            width: '10%'
        },
        {
            field: 'rr',
            title: `Return Reason`,
            filterable: true,
            sortable: true
        },
        {
            field: 'invoiceNo',
            title: `Invoice No`,
            filterable: true,
            sortable: true,
            width: '6%'
        },
        {
            field: 'creditNo',
            title: `Credit No`,
            filterable: true,
            sortable: true,
            width: '8%'
        },
        {
            field: 'receivedBy',
            title: `Received By`,
            filterable: true,
            sortable: true,
            width: '8%'
        },
        {
            field: 'returnedBy',
            title: `Returned By`,
            filterable: true,
            sortable: true,
            width: '8%'
        }
    ];

    const goToJobDetails = (value) => {
        history.push(`/PartReqSupplier/${value.headerRecordID}?fromStep=false`);
    };

    const editButton = (field) => (value, record) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="space-evenly">
                <SquareButton toolTipTitle="Get Part Details" style={styleObj} onClick={() => goToJobDetails(record)}>
                    <EditIcon fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.field === 'action');
        if (actionCol) {
            actionCol.renderer = editButton({});
        }
        return tempCols;
    }, []);

    const baseUrl = `RenaultPartReqs/PartReqs_Surcharged_List?StatusID=${code}`;

    const getVal = async (gridRef, recList) => {
        var val = [];
        $(`${gridRef} tbody :checkbox:checked`).each(function (i) {
            val[i] = $(this).val();
        });

        let newList = val.map((m, i) => {
            let getValues = m - 1;
            return recList[getValues];
        });

        const isSameSupplierIsInternal = checkAllSupplierIsInternalSame(newList, 'supplierIsInternal');

        if (!isSameSupplierIsInternal.isSame && newList.length > 0) {
            setState((st) => ({
                ...st,
                ShowIsInternalWarningModal: true
            }));
        } else {
            setState((st) => ({
                ...st,
                ShowPartAsAwaitingCollModal: true,
                selectedRecords: newList,
                isSameSupplierIsInternal: isSameSupplierIsInternal.value
            }));
        }
    };

    const mailButton = (gridRef, valueList) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justifyContent="center" style={{ paddingLeft: '15px' }}>
                <SquareButton
                    style={{
                        backgroundColor: 'green',
                        borderRadius: '20%',
                        width: '32px',
                        height: '32px',
                        textAlign: 'center',
                        padding: '0px 0px 0px 0px'
                    }}
                    onClick={() => getVal(gridRef, valueList)}
                >
                    <SyncIcon fontSize="small" style={{ color: 'white' }} />
                </SquareButton>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    let NewComp = configComp[code].Comp;

    const closeCommonModal = (res) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.ShowPartAsAwaitingCollModal = false;
            newSt.isSameSupplierIsInternal = null;
            newSt.selectedRecords = [];
            if (res) newSt.isReload = new Date();
            return newSt;
        });
    };

    const closeWarningModal = (res) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.ShowIsInternalWarningModal = false;

            return newSt;
        });
    };

    const RefreshGrid = () => {
        setState((st) => {
            let newSt = { ...st };
            newSt.isReload = new Date();
            return newSt;
        });
    };

    let [isRefresh, setIsrefresh] = useState(false);

    useEffect(() => {
        let RefreshTime = portalSettings.portalSettingGridRefreshTime * 1000;
        if (isRefresh) {
            setIsrefresh(true);
            return 0;
        }
        let interval = setInterval(RefreshGrid, RefreshTime);
        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            <div className="report-screen-container">
                {/* <BreadCrumbs crumbs={crumbs(desc)} /> */}
                <Grid item container spacing={1} xs={12}>
                    <Grid item xs={12}>
                        <ReportGijgoGrid
                            dataConverter={(res) => ({
                                records: res.data.list,
                                total: res.data.total
                            })}
                            columns={columns}
                            selectionType={+code !== 7 && 'multiple'}
                            selectionMethod={+code !== 7 && 'checkbox'}
                            getUrl={baseUrl}
                            isReload={state.isReload}
                            isShowTotal={false}
                            hidePagination={false}
                            hideFilterRow={false}
                            showCheckboxColFilterBtn={+code !== 7 && mailButton}
                            // downloadName={crumbsList[+props.status] || 'All Invoices'}
                        />
                    </Grid>
                </Grid>
            </div>

            {state.ShowPartAsAwaitingCollModal ? (
                <DialogComp
                    // title={modalTitle}
                    title={`${configComp[+code + (state.isSameSupplierIsInternal === 'No' && code == 3 ? 3 : 1)]?.name}`}
                    maxWidth="md"
                    onClose={() => closeCommonModal(false)}
                    fullWidth
                >
                    <NewComp selectedRecords={state.selectedRecords} onClose={closeCommonModal} isSameSupplierIsInternal={state.isSameSupplierIsInternal} />
                </DialogComp>
            ) : null}
            {state.ShowIsInternalWarningModal ? (
                <DialogComp title={`Warning`} maxWidth="md" onClose={() => closeWarningModal(false)} fullWidth>
                    <Alert severity="warning">Please select same supplier type</Alert>
                </DialogComp>
            ) : null}
        </div>
    );
};

export default PartReqsSurchargedList;
