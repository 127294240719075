import { memoize } from 'lodash';
import { get, post, put } from './../http-calls';

export const getPartReqDetails = async (headerID) => {
    let res = await get(`RenaultPartReqs/PartReqs_GetDetails_Renault/${headerID}`, { useAuthToken: true });
    return res;
};
export const getPartReqSupplierDropDown = async (branchID) => {
    let res = await get(`RenaultPartReqs/PartReq_Supplier_ScreenDropdown?BranchID=${branchID}`, { useAuthToken: true });
    return res;
};
export const getPartReqRenaultStatus = async () => {
    let res = await get('RenaultPartReqs/PartReq_DropdownList_Renault', { useAuthToken: true });
    return res;
};
export const postSupplier = async (data) => {
    let res = await post(`RenaultPartReqs/Supplier_AddUpdate`, data, { useAuthToken: true });
    return res;
};
export const SearchReg = async (reg, hederRecId) => {
    let res = await get(`RecallPart/PartReqs_GetRecallParts?RegNo=${reg}&headerRecordID=${hederRecId}`, { useAuthToken: true });
    return res;
};
export const postPartReq = async (data, headerID) => {
    let res = await post(`RenaultPartReqs/PartReqs_AddUpdate_Requestor_Renault/${headerID}`, data, {
        useAuthToken: true
    });
    return res;
};

export const getPartReturnByPartReqID = async (PartReqID) => {
    let res = await get(`RenaultPartReqs/PartReturn_GetDetails/${PartReqID}`, { useAuthToken: true });
    return res;
};
export const getPartReqHistory = async (PartReqID) => {
    let res = await get(`RenaultPartReqs/PartReqStatusChangeHistory_GET?PartReqID=${PartReqID}`, { useAuthToken: true });
    return res;
};

export const addPartReturnByPartReqID = async (data) => {
    let res = await post(`RenaultPartReqs/PartReturn_AddUpdate`, data, { useAuthToken: true });
    return res;
};

export const PostReqData = async (data) => {
    let res = await post(`RecallPart/PartReqs_AddUpdate_Recallparts`, data, { useAuthToken: true });
    return res;
};

export const getPartReturnStatus = async (id) => {
    let res = await get(`RenaultPartReqs/PartReturn_PartStatus?PartReqId=${id}`, { useAuthToken: true });
    return res;
};
