import moment from 'moment';

const formatters = {
    DateFormatter: (params) => {
        return params ? moment(params).format('YYYY-MM-DD') : '';
    },
    YearFormatter: (params) => {
        return params ? moment(params).format('DD/MM/YYYY') : '--:--';
    },
    MonthShortFormatter: (params) => {
        return params ? moment(params).format('DD-MMM-YYYY') : '-';
    },
    DateTimeFormatter: (params) => {
        return params ? moment(params).format('DD-MMM-YYYY HH:mm') : '';
    },
    DateTimeTHMSFormatter: (params) => {
        return params ? moment(params).format('YYYY-MM-DDTHH:mm') : '';
    },

    // CurrencyThousandSeparator: (params) => {
    //     return params ? `£${params.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '£0.00';
    // },
    CurrencyThousandSeparator: (params) => {
        if (params >= 0) {
            return params ? `£${params.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '£0.00';
        } else {
            return params
                ? `-£${Math.abs(params)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
                : '£0.00';
        }
    },
    CurrencyThousandSeparatorWithoutZero: (params, parentTextStyle) => {
        // let param = Math.trunc(params);
        if (params >= 0) {
            return params ? `£${Math.round(params).toLocaleString()}` : '£0';
        } else {
            let newVal = Math.abs(params);
            return newVal ? <span style={parentTextStyle ? {} : { color: 'red' }}>{`-£${Math.round(newVal).toLocaleString()}`}</span> : '£0';
        }

        // return new Intl.NumberFormat('en-GB', {
        //     style: 'currency',

        //     currency: 'GBP'
        // }).format(param);
    },

    PercentageFormatter: (params) => {
        return params ? `${Math.round(params).toLocaleString()}%` : '0%';
    },

    ThousandSeparatorWithoutZero: (params) => {
        let param = Math.trunc(params);
        return params ? `${param.toLocaleString()}` : '0';
    },

    constantMonthList: (params) => {
        return [
            { description: 'January', id: '01' },
            { description: 'February', id: '02' },
            { description: 'March', id: '03' },
            { description: 'April', id: '04' },
            { description: 'May', id: '05' },
            { description: 'June', id: '06' },
            { description: 'July', id: '07' },
            { description: 'August', id: '08' },
            { description: 'September', id: '09' },
            { description: 'October', id: '10' },
            { description: 'November', id: '11' },
            { description: 'December', id: '12' }
        ];
    },
    ToFixedSeparator: (params) => {
        return params ? `${params.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '0.00';
    },
    addPrefixAndSuffixToBarCode: (number) => {
        // Convert number to string
        let numString = String(number);

        // Determine prefix based on length and add suffix
        switch (numString.length) {
            case 5:
                return '00' + numString + '000000001';
            case 6:
                return '0' + numString + '000000001';
            case 7:
                return numString + '000000001';
            default:
                // Handle cases where the input number length is not 5, 6, or 7
                throw new Error('Invalid number length');
        }
    }
};

export default formatters;
