import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PrimaryCheckbox, TextBox } from '../../../Core/FormInput';
import { Button, ButtonGroup, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup } from '@material-ui/core';
import { FieldWrappper } from '../../../Core/FieldWrapper';

const useStyles = makeStyles({
    table: {
        minWidth: 650
    }
});

const CustomTextbox = ({ onChange, name, value, buttonClick, typeID, typeName, disabled }) => {
    return (
        <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
            <Grid item xs={6} sm={6} md={6} lg={8}>
                <TextBox onChange={onChange} name={name} value={value} disabled={disabled} />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={4}>
                <ButtonGroup color="primary" aria-label="outlined primary button group" disabled={disabled}>
                    <Button onClick={() => buttonClick(typeName, true)} variant={typeID ? 'contained' : 'outlined'}>
                        KM
                    </Button>
                    <Button onClick={() => buttonClick(typeName, false)} variant={typeID ? 'outlined' : 'contained'}>
                        Miles
                    </Button>
                </ButtonGroup>
            </Grid>
        </Grid>
    );
};

export default function Participants({ state, handleChange, buttonClick, handleCheck, handleRadioCheck, actualMileage, errors }) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
                <FieldWrappper heading="Participants" isNewCard={true}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="labelText">24/7 </div>
                            <TextBox multiline rows={2} name="breakdownSheet24by7" value={state.breakdownSheet24by7} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <div className="labelTextReq">Repairer* </div>
                            <TextBox multiline rows={2} name="repairer" value={state.repairer} onChange={handleChange} />
                            <FormHelperText error shrink style={{ marginTop: 0 }}>
                                {errors.repairer}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                </FieldWrappper>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <FieldWrappper heading="Type Of Breakdown" isNewCard={true}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <RadioGroup name={'typeOfBreakdown'} value={state.typeOfBreakdown} onChange={handleRadioCheck}>
                                <FormControlLabel value="1" control={<Radio />} label="Roadside" />
                                <FormControlLabel value="2" control={<Radio />} label="Towing" />
                                <FormControlLabel value="3" control={<Radio />} label="Accident" />
                                <FormControlLabel value="4" control={<Radio />} label="Temporary Repair" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>
                </FieldWrappper>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
                <FieldWrappper heading="Means" isNewCard={true}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <RadioGroup name={'meanTypeID'} value={state.meanTypeID} onChange={handleRadioCheck}>
                                <FormControlLabel value="1" control={<Radio />} label="Workshop Van" />
                                <FormControlLabel value="2" control={<Radio />} label="Towing Truck" />
                                <FormControlLabel value="3" control={<Radio />} label="Lift Truck" />
                                <FormControlLabel value="4" control={<Radio />} label="Semi-Trailer" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>
                </FieldWrappper>
            </Grid>
            <Grid item xs={12}>
                <FieldWrappper heading="Mileage" isNewCard={true}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <div className="labelText">Outward</div>
                            <CustomTextbox
                                name="mileageOutWard"
                                value={state.mileageOutWard}
                                onChange={handleChange}
                                buttonClick={buttonClick}
                                typeID={state.mileageReturnIsKMUnit}
                                typeName={'mileageReturnIsKMUnit'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <div className="labelText">Return</div>
                            <CustomTextbox
                                name="mileageReturn"
                                value={state.mileageReturn}
                                onChange={handleChange}
                                buttonClick={buttonClick}
                                typeID={state.mileageReturnIsKMUnit}
                                typeName={'mileageReturnIsKMUnit'}
                            />
                            <FormHelperText error shrink style={{ marginTop: 0 }}>
                                {errors.Message}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <div className="labelText">Actual </div>
                            <CustomTextbox
                                disabled={true}
                                value={state.actualMileage}
                                typeID={state.mileageReturnIsKMUnit}
                                typeName={'mileageReturnIsKMUnit'}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <div className="labelText" style={{ marginBottom: 8 }}>
                                Zone
                            </div>
                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                <Button onClick={() => buttonClick('zoneTypeID', 1)} variant={state.zoneTypeID === 1 ? 'contained' : 'outlined'}>
                                    1
                                </Button>
                                <Button onClick={() => buttonClick('zoneTypeID', 2)} variant={state.zoneTypeID === 2 ? 'contained' : 'outlined'}>
                                    2
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </FieldWrappper>
            </Grid>
        </Grid>
    );
}
