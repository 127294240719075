import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Card, CardContent, CardHeader, makeStyles, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        background: 'cyan'
    },

    newCardroot: {
        width: '100%',
        margin: 3,
        border: '2px solid white ',
        Opacity: 0.9,
        minWidth: 120,
        // padding: '5px 0px',
        background: 'white'
        // borderRadius: 10
    },
    summary: {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    cardRoot: {
        margin: theme.spacing(2),
        '&.MuiCardContent-root:last-child': {
            paddingBottom: 0
        }
    },
    cardHeader: {
        '&.MuiCardHeader-content': {
            // background: 'red'
        },
        '&.MuiTypography-h5': {
            fontSize: '0.9rem'
        }
    }
}));

export const FieldWrappper = React.memo((props) => {
    const { children, heading, isAccordion, setState, state, component: Comps, isNewCard } = props;
    let { isCard } = props;
    const classes = useStyles(props);
    if (!isAccordion) {
        if (isCard === undefined) {
            isCard = true;
        }
    }

    const FinalRender = () => (Comps ? <Comps setState={setState} state={state} /> : children);

    return (
        <>
            {isAccordion ? (
                <Accordion style={props.style ? props.style : { margin: '15px' }}>
                    <AccordionSummary className={classes.summary} expandIcon={<ExpandMore style={{ color: 'white' }} />}>
                        <Typography className={classes.heading}>{heading}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>{FinalRender()}</AccordionDetails>
                </Accordion>
            ) : isNewCard ? (
                <Card variant="elevation" elevation={3} className={classes.newCardroot}>
                    <Typography
                        style={{
                            fontSize: 20,
                            fontWeight: 500,
                            textAlign: 'left',
                            margin: '10px 20px 10px 20px',
                            borderBottom: !heading ? 'none' : '1px dotted rgba(0,0,0,0.5)'
                        }}
                    >
                        {heading}
                    </Typography>
                    {/* <CardHeader
                        titleTypographyProps={{ variant: 'body2' }}
                        // className={classes.cardHeader}
                        // style={{ textAlign: 'left', fontSize: '0.9rem', padding: '10px 16px 0px 16px' }}
                        // title={heading}
                    ></CardHeader> */}
                    <CardContent style={{ textAlign: 'left', padding: '5px 16px 16px 16px' }}>{FinalRender()}</CardContent>
                </Card>
            ) : isCard ? (
                <Card variant="elevation" elevation={3} className={classes.cardRoot}>
                    <CardHeader
                        titleTypographyProps={{ variant: 'body2' }}
                        className={classes.cardHeader}
                        style={{ textAlign: 'left', fontSize: '0.9rem', padding: '10px 16px 0px 16px' }}
                        title={heading}
                    />
                    <CardContent style={{ textAlign: 'left', padding: '5px 16px 16px 16px' }}>{FinalRender()}</CardContent>
                </Card>
            ) : (
                <>{FinalRender()}</>
            )}
        </>
    );
});
