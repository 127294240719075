import { Grid } from '@material-ui/core';
import { CustomeSignPad } from '../../../../Core/SignaturePad';
import { AppStorage } from '../../../../Core/Service/storage-service';
import { SecondaryButton } from '../../../../Core/FormInput';
import { Alert } from '@material-ui/lab';
import { Info } from '@material-ui/icons';
import { useState } from 'react';

export default function ApproverSignature({ onClose, partList, setPartList }) {
    const [sign, setSign] = useState({
        approverSignature: ''
    });
    const updateSignature = (val) => {
        let tempList = [...partList];
        let ids = tempList.filter((index) => index.partReqStatus === 2 && !index.approverSignature);

        ids.forEach((k) => {
            let indexValue = partList.findIndex(
                (item) => (k.partReqID && item.partReqID === k.partReqID) || (!k.partReqID && k.partReqDateCreated === item.partReqDateCreated)
            );
            if (indexValue !== -1) {
                if (k.approverSignature) {
                    tempList[indexValue].approverSignature = k.approverSignature;
                } else {
                    tempList[indexValue].approverSignature = val.approverSignature;
                }
            }
        });

        setPartList(tempList);
    };

    // );
    const clearSig = (name) => {
        setSign((st) => {
            let newSt = { ...st };
            newSt[name] = '';
            return newSt;
        });
    };

    const Submit = () => {
        onClose(true);
    };

    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                    <Alert severity="warning">{''}</Alert>
                </Grid> */}
                <Grid item xs={12}>
                    <CustomeSignPad
                        onChange={updateSignature}
                        content={`Authorised By`}
                        sign={sign.approverSignature || ''}
                        name="approverSignature"
                        clearSig={() => clearSig('approverSignature')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SecondaryButton fullWidth onClick={Submit}>
                        Submit
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
}
