export const ColorsDash = [
    '#FF5733', // Vivid Tangelo
    '#6A5ACD', // Slate Blue
    '#FFD700', // Gold
    '#8A2BE2', // Blue Violet
    '#2E8B57', // Sea Green
    '#FF6347', // Tomato
    '#9932CC', // Dark Orchid
    '#FF4500', // Orange Red
    '#00CED1', // Dark Turquoise
    '#9370DB', // Medium Purple
    '#FF1493', // Deep Pink
    '#32CD32', // Lime Green
    '#4169E1', // Royal Blue
    '#FF8C00', // Dark Orange
    '#8B008B', // Dark Magenta
    '#ADFF2F', // Green Yellow
    '#7B68EE', // Medium Slate Blue
    '#20B2AA', // Light Sea Green
    '#DC143C', // Crimson
    '#00FF00', // Lime
    '#FF69B4', // Hot Pink
    '#1E90FF', // Dodger Blue
    '#7CFC00', // Lawn Green
    '#00BFFF', // Deep Sky Blue
    '#FFA07A', // Light Salmon
    '#6B8E23', // Olive Drab
    '#FFDAB9', // Peach Puff
    '#4682B4', // Steel Blue
    '#FF6347', // Tomato
    '#8B4513', // Saddle Brown
    '#9370DB', // Medium Purple
    '#CD5C5C', // Indian Red
    '#3CB371' // Medium Sea Green
];

export let setObject = {
    ['<24hr']: '#00F433',
    ['1-5 days']: '#88EBA4',
    ['5-10 days']: '#FFBF00',
    ['10-20 days']: '#FFE28A',
    ['21-30 days']: '#bc4443',
    ['>30 days']: '#F11C0E'
};

export let timenumber = {
    ['<24hr']: 1,
    ['1-5 days']: 2,
    ['5-10 days']: 3,
    ['10-20 days']: 4,
    ['21-30 days']: 5,
    ['>30 days']: 6
};

export default function randomColor() {
    let hex = Math.floor(Math.random() * 0xffffff);
    let color = '#' + hex.toString(16);

    return color;
}
