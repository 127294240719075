import React, { useContext, useEffect, useState } from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import { SecondaryButton, TextBox } from '../../../../Core/FormInput';
import { PostCollectedByMainSite } from '../../../../Core/Service/PartReq_services/partReturnSteps-services';
import { CustomeSignPad } from '../../../../Core/SignaturePad';

const CollectedByMainSite = ({ onClose, handleSubmit, partReturnPartReqID, partReturnID, showToast }) => {
    const [state, setState] = useState({
        partReturnInternalInvoiceNo: '',
        partReturnMainSiteReceivedBy: '',
        partReturnMaintSiteCollectedSign: ''
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const clearSig = () => {
        setState((st) => ({
            ...st,
            partReturnMaintSiteCollectedSign: null
        }));
    };
    const fieldChange = async (val) => {
        setState((st) => ({
            ...st,
            ...val
        }));
    };

    const Onsubmit = async () => {
        setBtnLoader(true);
        let data = {
            partReturnPartReqID: partReturnPartReqID,
            partReturnID: partReturnID,
            partReturnInternalInvoiceNo: state.partReturnInternalInvoiceNo || '',
            partReturnMainSiteReceivedBy: state.partReturnMainSiteReceivedBy || '',
            partReturnMaintSiteCollectedSign: state.partReturnMaintSiteCollectedSign || ''
        };
        let res = await PostCollectedByMainSite(data);

        if (res.success) {
            showToast(res?.data?.message);
            handleSubmit();
            onClose();
        }
        setBtnLoader(false);
    };
    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel>Received By</InputLabel>
                    <TextBox
                        placeholder="Received By"
                        name="partReturnMainSiteReceivedBy"
                        value={state.partReturnMainSiteReceivedBy}
                        onChange={(e) => {
                            setState((st) => ({ ...st, partReturnMainSiteReceivedBy: e.target.value }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    {' '}
                    <InputLabel>Internal Invoice Number</InputLabel>
                    <TextBox
                        placeholder="Internal Invoice Number"
                        name="partReturnInternalInvoiceNo"
                        value={state.partReturnInternalInvoiceNo}
                        onChange={(e) => {
                            setState((st) => ({ ...st, partReturnInternalInvoiceNo: e.target.value }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomeSignPad
                        onChange={fieldChange}
                        clearSig={clearSig}
                        // sign={state.partReturnMaintSiteCollectedSign}
                        content={<span style={{ color: 'gray' }}>Signature</span>}
                        name="partReturnMaintSiteCollectedSign"
                    />
                </Grid>
                <Grid item xs={12}>
                    <SecondaryButton fullWidth onClick={Onsubmit} disabled={!state.partReturnMaintSiteCollectedSign} isBtnLoader={btnLoader}>
                        Submit
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default CollectedByMainSite;
