import moment from 'moment';
import { get, post, put } from './../http-calls';

export const getWheelTorque = async (headerRecordID) => {
    let res = await get(`WheelRetorque/RenaultWheelRetorque_GetDetails/${headerRecordID}`, { useAuthToken: true });
    return res;
};
export const getWheelBreakDownFromSheet = async (headerRecordID) => {
    let res = await get(`BreakdownSheet/BreakdownSheet_GetDetails/${headerRecordID}`, { useAuthToken: true });
    return res;
};

export const renaultWheelRemovalGetDetails = async (HeaderRecordID) => {
    let res = await get(`WheelRemoval/RenaultWheelRemoval_GetDetails/${HeaderRecordID}`, { useAuthToken: true });
    return res;
};
export const downldBreakdownFrom = async (HeaderRecordID, reg) => {
    let res;

    try {
        res = await get(`BreakdownSheet/GetPDF?Id=${HeaderRecordID}`, {
            responseType: 'blob', // Request blob response for PDF
            returnOrgRes: true,
            useAuthToken: true
        });

        if (res) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.getElementById('tempDownloadPDFLink') || document.createElement('a');
            link.id = 'tempDownloadPDFLink';
            link.href = url;
            link.setAttribute('download', `${`Breakdown Sheet`} ${moment().format('DD-MMM-YYYY HH:mm')}.pdf`); // Set download filename with .pdf extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            return true;
        } else {
            console.error('Empty response received from the server.');
            return false;
        }
    } catch (error) {
        console.error('Error downloading PDF:', error);
        return false;
    }
    return res;
};
export const downldWheelRemovalGetDetails = async (HeaderRecordID, reg) => {
    let res;

    try {
        res = await get(`WheelRemoval/GetPDF?Id=${HeaderRecordID}`, {
            responseType: 'blob', // Request blob response for PDF
            returnOrgRes: true,
            useAuthToken: true
        });

        if (res) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.getElementById('tempDownloadPDFLink') || document.createElement('a');
            link.id = 'tempDownloadPDFLink';
            link.href = url;
            link.setAttribute('download', `${`Wheel Removal ${reg}`} ${moment().format('DD-MMM-YYYY HH:mm')}.pdf`); // Set download filename with .pdf extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            return true;
        } else {
            console.error('Empty response received from the server.');
            return false;
        }
    } catch (error) {
        console.error('Error downloading PDF:', error);
        return false;
    }
    return res;
};

export const downldTorqueGetDetails = async (HeaderRecordID) => {
    let res;

    try {
        res = await get(`WheelRetorque/GetPDF?Id=${HeaderRecordID}`, {
            responseType: 'blob', // Request blob response for PDF
            returnOrgRes: true,
            useAuthToken: true
        });

        if (res) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.getElementById('tempDownloadPDFLink') || document.createElement('a');
            link.id = 'tempDownloadPDFLink';
            link.href = url;
            link.setAttribute('download', `${`Torque`} ${moment().format('DD-MMM-YYYY HH:mm')}.pdf`); // Set download filename with .pdf extension
            document.body.appendChild(link);
            link.click();
            link.remove();
            return true;
        } else {
            console.error('Empty response received from the server.');
            return false;
        }
    } catch (error) {
        console.error('Error downloading PDF:', error);
        return false;
    }
    return res;
};

export const postRenaultWheelRemovalADDUpdate = async (data) => {
    let res = await post('WheelRemoval/RenaultWheelRemoval_ADDUpdate', data, { useAuthToken: true });
    return res;
};

export const postWheelTorque = async (data) => {
    let resData = {
        wheelRetorqueID: data.wheelRetorqueID || null,
        headerRecordID: data.headerRecordID,
        fittingTorqueNutsNN: +data.fittingTorque.nm || null,
        fittingTorqueNutslbft: +data.fittingTorque.lbft || null,
        fittingTorqueMileage: +data.fittingTorque.vm || null,
        fittingTorqueDateTime: data.fittingTorque.date || '',
        fittingTorqueCreatedBy: data.fittingTorque.pn || '',
        fittingTorqueSignature: data.fittingTorque.signature || '',
        reTorqueNutsNN: +data.reTorque.nm || null,
        reTorqueNutslbft: +data.reTorque.lbft || null,
        reTorqueMileage: +data.reTorque.vm || null,
        reTorqueDateTime: data.reTorque.date || '',
        reTorqueCreatedBy: data.reTorque.pn || '',
        reTorqueSignature: data.reTorque.signature || '',
        additionalReTorqueNutsNN: +data.Add_ReTorque.nm || null,
        additionalReTorqueNutslbft: +data.Add_ReTorque.lbft || null,
        additionalReTorqueMileage: +data.Add_ReTorque.vm || null,
        additionalReTorqueDateTime: data.Add_ReTorque.date || '',
        additionalReTorqueCreatedBy: data.Add_ReTorque.pn || '',
        additionalReTorqueSignature: data.Add_ReTorque.signature || ''
    };
    let res = await post(`WheelRetorque/RenaultWheelRetorque_ADDUpdate`, resData, { useAuthToken: true });
    return res;
};
export const postBreakDownFrom = async (data) => {
    let resData = {
        breakdownSheetID: data.breakdownSheetID,
        headerRecordID: data.headerRecordID,
        date: data.date,
        reneuoOrder: data.reneuoOrder ? parseFloat(data.reneuoOrder.replace(':', '.')) : '',
        estimatedArrrivalTime: data.estimatedArrrivalTime,
        arrrivalAtScene: data.arrrivalAtScene,
        estimatedTimeCompleteRepair: data.estimatedTimeCompleteRepair,
        vehicleRepairedAt: data.vehicleRepairedAt,
        isNonWorkingDay: data.isNonWorkingDay == 1 ? true : false,
        dossierRefNumber: data.dossierRefNumber,
        breakdownSheet24by7: data.breakdownSheet24by7,
        repairer: data.repairer,
        typeOfBreakdown: data.typeOfBreakdown,
        meanTypeID: data.meanTypeID,
        mileageTypeID: data.mileageTypeID,
        mileageIsKMUnit: data.mileageIsKMUnit,
        breakdownLocation: data.breakdownLocation,
        isMotorWay: data.isMotorWay,
        isUrbanZone: data.isUrbanZone,
        isOutsideUrbanZone: data.isOutsideUrbanZone,
        accountOfBreakdown: data.accountOfBreakdown,
        load: data.load,
        isDangerousZone: data.isDangerousZone == 1 ? true : false,
        replacementVehicle: data.replacementVehicle == 1 ? true : false,
        actionTaken: data.actionTaken,
        customerDriverName: data.customerDriverName,
        customerDriverPhone: data.customerDriverPhone,
        customerPersonToInformed: data.customerPersonToInformed,
        customerPersonInformedPhone: data.customerPersonInformedPhone,
        customerCompanyName: data.customerCompanyName,
        customerCompanyAddress: data.customerCompanyAddress,
        customerPostCode: data.customerPostCode,
        customerTown: data.customerTown,
        customerCountry: data.customerCountry,
        customerFaxNumber: data.customerFaxNumber,
        customerIsDealership: data.customerIsDealership == 1 ? true : false,
        customerIsTransient: data.customerIsTransient,
        customerIntraaCommunityVATNo: data.customerIntraaCommunityVATNo,
        paymentIsCreditCard: data.paymentIsCreditCard,
        paymentCardName: data.paymentCardName,
        paymentCardExpiryDate: data.paymentCardExpiryDate,
        paymentCardNumber: data.paymentCardNumber,
        paymentCardAggrementFor: data.paymentCardAggrementFor,
        guaranteeOfPayment: data.guaranteeOfPayment,
        paymentIsCash: data.paymentIsCash,
        paymentVehicleType: data.paymentVehicleType,
        warrantyExtentionCode: data.warrantyExtentionCode,
        dealershipToInvoice: data.dealershipToInvoice,
        hireToInvoice: data.hireToInvoice,
        paymentRenaultTrucks: data.paymentRenaultTrucks,
        paymentPaymentNumber: data.paymentPaymentNumber,
        paymentIsDealership: data.paymentIsDealership,
        paymentDealerName: data.paymentDealerName,
        paymentDealerPhone: data.paymentDealerPhone,
        paymentDealerFax: data.paymentDealerFax,
        paymentOtherName: data.paymentOtherName,
        paymentOtherPhone: data.paymentOtherPhone,
        paymentOtherFax: data.paymentOtherFax,
        vehicleMake: data.vehicleMake,
        vehicleType: data.vehicleType,
        vehicleRegistration: data.vehicleRegistration,
        vehicleRegistrationDate: data.vehicleRegistrationDate,
        vehicleSerialNumber: data.vehicleSerialNumber,
        nameOfOwnerRegistrationcard: data.nameOfOwnerRegistrationcard,
        vehicleMileage: data.vehicleMileage,
        vehicleIdentification: data.vehicleIdentification,
        carriedOut: data.carriedOut,
        instructions: data.instructions,
        driverNameName: data.driverNameName,
        driverSignature: data.driverSignature,
        driverSignatureAfterInstruction: data.driverSignatureAfterInstruction,
        mechanicSignature: data.mechanicSignature,
        mechanicName: data.mechanicName,
        trailerMake: data.trailerMake,
        trailerType: data.trailerType,
        trailerRegistration: data.trailerRegistration,
        trailerRegistrationDate: data.trailerRegistrationDate,
        trailerSerialNumber: data.trailerSerialNumber,
        trailerNameOfOwnerRegistrationcard: data.trailerNameOfOwnerRegistrationcard,
        trailerMileage: data.trailerMileage,
        trailerIdentification: data.trailerIdentification,
        mileageOutWard: data.mileageOutWard,
        mileageOutWardIsKMUnit: data.mileageOutWardIsKMUnit,
        mileageReturn: data.mileageReturn,
        mileageReturnIsKMUnit: data.mileageReturnIsKMUnit,
        zoneTypeID: data.zoneTypeID,
        paymentIsOther: data.paymentIsOther
    };
    let res = await post(`BreakdownSheet/BreakdownSheet_AddUpdate`, resData, { useAuthToken: true });
    return res;
};
