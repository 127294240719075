import { AppStorage } from '../../../Core/Service/storage-service';
import WheelTorque from './Torque';
import WheelRemoval from './wheelRemoval';
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.paper
        // width: 500
    }
}));
export default function WheelAndTorque(props) {
    let isTorque = props?.match?.path == '/wheelTorque/:jobId' ? true : false;
    const DefaultName = AppStorage.getName();
    const urlParams = new URLSearchParams(window.location.search.toLowerCase());
    const fromStep = urlParams.get('fromstep') === 'true';
    const navigateToDashboard = urlParams.get('navigate') === 'true';
    const headerId = props?.match?.params?.jobId;

    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(isTorque ? 1 : 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div className={classes.root}>
            <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" aria-label="full width tabs example">
                <Tab label="Wheel Removal" />
                <Tab label="Wheel Torque" />
            </Tabs>

            {/* <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}> */}
            <TabPanel value={value} index={0}>
                <WheelRemoval
                    DefaultName={DefaultName}
                    urlParams={urlParams}
                    fromStep={fromStep}
                    navigateToDashboard={navigateToDashboard}
                    headerId={headerId}
                />
            </TabPanel>

            <TabPanel value={value} index={1}>
                <WheelTorque
                    DefaultName={DefaultName}
                    urlParams={urlParams}
                    fromStep={fromStep}
                    navigateToDashboard={navigateToDashboard}
                    headerId={headerId}
                />
            </TabPanel>
            {/* </SwipeableViews> */}
        </div>
    );
}
