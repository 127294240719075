import React, { useContext, useState, useEffect } from 'react';
import { FormHelperText, IconButton, InputLabel, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { MenuItem } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AppContext from '../../../App/AppContext';
import {
    TextBox,
    DialogContents,
    DialogTitles,
    Dialogs,
    SecondaryButton,
    DialogsActions,
    DefaultButton,
    SingelSelect,
    SelectBox
} from '../../../Core/FormInput';
import { addWebForm, getWebFormsById } from './../../../Core/Service/webForm-service';
import { getWorkflows, getWorkflowsByProcessID } from '../../../Core/Service/workflow-service';
import { getByWorkflowIdAndHeaderId } from '../../../Core/Service/returnReason-service';
import CircularIndeterminate from '../../internal/ReportsScreens/ReportsScreens/BudgetCore/loader';
import { getProcesses } from '../../../Core/Service/process-service';

const AddWebFormModal = (props) => {
    const { hideModal, showToast } = useContext(AppContext);

    const [state, setState] = useState({
        name: '',
        buttonLabel: '',
        url: '',
        description: '',
        errors: {},
        fields: {},
        nextWorkFlow: null,
        workFlowList: [],
        navigateToDashboard: false,
        webformSettingVMList: [],
        apiWebformSettingVMList: [],
        editableWorkflowVal: {},
        nextWorkflowVal: {},
        returnReasonVal: {},
        showLoader: true,
        processID: props.processID
    });

    const { WebFormId, WebFormDetails } = props;

    useEffect(() => {
        const fetchData = async () => {
            const res = await getWorkflowsByProcessID(props.processID, 'Y');
            if (res.success) {
                setState((st) => ({
                    ...st,
                    workFlowList: res.data.map((q) => ({ id: q.id, name: q.workflowName }))
                }));
            }

            if (WebFormId) {
                const res = await getWebFormsById(WebFormId);
                if (res.success) {
                    setState((st) => ({
                        ...st,
                        name: res.data.list[0].name,
                        url: res.data.list[0].webFormRelativeUrl,
                        buttonLabel: res.data.list[0].webFormButtonLabel,
                        description: res.data.list[0].nonFunctionalDescription,
                        nextWorkFlow: res.data.list[0].webformEditableWorkflowID,
                        navigateToDashboard: res.data.list[0].navigateToDashboardOnSubmit,
                        processID: res.data.list[0].processID,
                        apiWebformSettingVMList:
                            res.data.webformSettingVM.length > 0
                                ? res.data.webformSettingVM.map((q) => ({ ...q, isDelete: 0 }))
                                : [{ id: null, editableWorkflowID: '', nextWorkflowID: '', isDelete: 0 }]
                    }));
                }
            } else {
                setState((st) => ({
                    ...st,
                    webformSettingVMList: [{ id: null, editableWorkflowID: '', nextWorkflowID: '', isDelete: 0 }],
                    showLoader: false
                }));
            }
        };

        fetchData();
    }, [WebFormId]);

    useEffect(() => {
        const fetchData = async () => {
            const tempList = [...state.apiWebformSettingVMList];
            const tempWorkFlowList = [...state.workFlowList];

            if (tempList.length > 0) {
                await Promise.all(
                    tempList.map(async (q) => {
                        if (q.editableWorkflowID) {
                            q.editableWorkflowVal = tempWorkFlowList.find((r) => r.id === q.editableWorkflowID);
                            const result = await getByWorkflowIdAndHeaderId(q.editableWorkflowID);
                            q.returnReasonList = result.data.returnReasons.map((q) => ({ id: q.returnReasonID, name: q.returnReasonDescription }));
                        }
                        if (q.nextWorkflowID) {
                            q.nextWorkflowVal = tempWorkFlowList.find((r) => r.id === q.nextWorkflowID);
                        }
                        if (q.returnReasonID) {
                            q.returnReasonVal = q.returnReasonList.find((r) => r.id === q.returnReasonID);
                        }
                    })
                );

                setState((prevState) => ({ ...prevState, webformSettingVMList: tempList, showLoader: false }));
            }
        };

        fetchData();
    }, [state.apiWebformSettingVMList]);

    const handleClose = () => {
        props.onFormSubmit(false);
        hideModal();
    };

    const fieldChange = (e) => {
        const { name, value } = e.target;
        let fields = state.fields;
        fields[name] = value;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
            fields: fields
        }));
    };

    const handleClick = (name, value) => {
        setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleValidation = () => {
        let fields = state.fields;
        let errors = {};
        let formIsValid = true;
        let tempWebformSettingVMList = [...state.webformSettingVMList];
        tempWebformSettingVMList.forEach((q) => {
            if (!q.editableWorkflowVal?.id) {
                q.editableWorkflowValError = 'Please Select Editable Workflow';
                formIsValid = false;
            } else {
                q.editableWorkflowValError = '';
                formIsValid = true;
            }
            if (!q.nextWorkflowVal?.id) {
                q.nextWorkflowValError = 'Please Select Next Workflow';
                formIsValid = false;
            } else {
                q.nextWorkflowValError = '';
                formIsValid = true;
            }
        });

        if (!state.name) {
            formIsValid = false;
            errors['name'] = 'Name cannot be empty';
        }
        if (!state.url) {
            formIsValid = false;
            errors['url'] = 'Url cannot be empty';
        }
        if (!state.buttonLabel) {
            formIsValid = false;
            errors['buttonLabel'] = 'Button label cannot be empty';
        }

        setState((prevState) => ({
            ...prevState,
            errors: errors
        }));
        return formIsValid;
    };

    const handelSubmit = async (e) => {
        e.preventDefault();

        if (handleValidation()) {
            let data = {
                Id: WebFormId,
                Name: state.name,
                WebFormRelativeUrl: state.url,
                WebFormButtonLabel: state.buttonLabel,
                NonFunctionalDescription: state.description,
                NavigateToDashboardOnSubmit: state.navigateToDashboard,
                processID: state.processID,
                // webformEditableWorkflowID: null,
                webformSettingVM: state.webformSettingVMList.map((q) => ({
                    editableWorkflowID: q?.editableWorkflowVal?.id || '',
                    id: q.id || null,
                    nextWorkflowID: q?.nextWorkflowVal?.id || '',
                    returnReasonID: q?.returnReasonVal?.id || '',
                    isDelete: q.isDelete
                }))
            };

            let res = await addWebForm(data);
            if (!res.success) {
                console.error(res);
            } else {
                hideModal();
                props.onFormSubmit(true);
            }
            showToast(res.message);
        }
    };

    const handleRemoveClick = (index) => {
        setState((st) => {
            let newSt = { ...st };

            let findIndex = [...st.webformSettingVMList].findIndex((n, i) => i === index);

            if (findIndex) {
                st.webformSettingVMList[findIndex].isDelete = 1;
            }

            return newSt;
        });
    };

    const handleAddClick = () => {
        setState((prevState) => ({
            ...prevState,
            webformSettingVMList: [
                ...state.webformSettingVMList,
                {
                    id: null,
                    editableWorkflowID: '',
                    nextWorkflowID: '',
                    isDelete: 0
                }
            ]
        }));
    };

    const singleSelectChange = (nm, index) => async (ev, val) => {
        const { webformSettingVMList } = state;
        const tempWebformSettingVMList = [...webformSettingVMList];
        tempWebformSettingVMList[index] = {
            ...tempWebformSettingVMList[index],
            [nm]: val
        };

        if (nm === 'editableWorkflowVal') {
            tempWebformSettingVMList[index].returnReasonVal = {};
            if (val?.id) {
                const result = await getByWorkflowIdAndHeaderId(val.id);
                tempWebformSettingVMList[index].returnReasonList = result.data.returnReasons.map((q) => ({
                    id: q.returnReasonID,
                    name: q.returnReasonDescription
                }));
            }
        }

        setState((prevState) => ({
            ...prevState,
            webformSettingVMList: tempWebformSettingVMList
        }));
    };

    const { webformSettingVMList, workFlowList } = state;

    return (
        <Dialogs open={true} onClose={handleClose} maxWidth="lg" fullWidth>
            <Box display="flex" alignItems="center">
                <Box flexGrow={1}>{props.WebFormId ? <DialogTitles>Update Web Form</DialogTitles> : <DialogTitles>Add Web Form</DialogTitles>}</Box>
                <Box>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </Box>
            <DialogContents style={{ overflow: 'auto', height: 'calc(100vh - 389px)' }}>
                {state.showLoader ? (
                    <Grid container alignItems="center" justifyContent="center" style={{ height: '100%' }}>
                        <Grid item>
                            <CircularIndeterminate />
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormLabel component="legend" className="mandatoryfields">
                                Name
                            </FormLabel>
                            <TextBox autoFocus name="name" onChange={fieldChange} value={state.name} />
                            <span className="mandatoryfields">{state.errors['name']}</span>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormLabel component="legend" className="mandatoryfields">
                                URL
                            </FormLabel>
                            <TextBox name="url" onChange={fieldChange} value={state.url} />
                            <span className="mandatoryfields">{state.errors['url']}</span>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <FormLabel component="legend" className="mandatoryfields">
                                Button Label
                            </FormLabel>
                            <TextBox name="buttonLabel" onChange={fieldChange} value={state.buttonLabel} />
                            <span className="mandatoryfields">{state.errors['buttonLabel']}</span>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs>
                                    <FormLabel component="legend" className="mandatoryfields">
                                        Workflow Settings
                                    </FormLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {webformSettingVMList.map((webForm, i) => {
                                return (
                                    !webForm.isDelete && (
                                        <Grid container spacing={2} key={i}>
                                            <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                                                <InputLabel required shrink>
                                                    Editable in Workflow
                                                </InputLabel>
                                                <SingelSelect
                                                    options={workFlowList}
                                                    value={webForm.editableWorkflowVal}
                                                    onChange={singleSelectChange('editableWorkflowVal', i)}
                                                />
                                                <FormHelperText error>{webForm.editableWorkflowValError}</FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                                                <InputLabel required shrink>
                                                    Next Workflow
                                                </InputLabel>
                                                <SingelSelect
                                                    options={workFlowList}
                                                    value={webForm.nextWorkflowVal}
                                                    onChange={singleSelectChange('nextWorkflowVal', i)}
                                                />
                                                <FormHelperText error>{webForm.nextWorkflowValError}</FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <InputLabel shrink>Return Reason</InputLabel>
                                                {webformSettingVMList.length !== 1 && (
                                                    <Tooltip title="Remove Standard Write-Up" aria-label="add">
                                                        <IconButton onClick={() => handleRemoveClick(i)} className="rightButton">
                                                            <RemoveCircleIcon color="primary" fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                {webformSettingVMList.length - 1 === i && (
                                                    <Tooltip title="Add Standard Write-Up" aria-label="add">
                                                        <IconButton
                                                            onClick={handleAddClick}
                                                            className="rightButton"
                                                            style={{
                                                                marginRight: '5px'
                                                            }}
                                                        >
                                                            <AddBoxIcon color="secondary" fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                )}
                                                <SingelSelect
                                                    options={webForm.returnReasonList || []}
                                                    value={webForm.returnReasonVal || {}}
                                                    onChange={singleSelectChange('returnReasonVal', i)}
                                                />
                                            </Grid>
                                        </Grid>
                                    )
                                );
                            })}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormLabel component="legend">Description</FormLabel>
                            <TextBox name="description" onChange={fieldChange} value={state.description} multiline rows={2} />
                        </Grid>
                    </Grid>
                )}
            </DialogContents>
            <Grid container xs={12} sm={12}>
                <Grid item xs={1} sm={1}></Grid>
                <Grid item xs={10} sm={10}>
                    <Grid container spacing={0}>
                        <Grid item xs={2} sm={2}>
                            <FormLabel component="legend" className="btn_add">
                                Navigate to dashboard?
                            </FormLabel>
                        </Grid>
                        <Grid item xs={5} sm={5}>
                            <DefaultButton
                                className={state.navigateToDashboard ? 'btnActive' : 'btninActive'}
                                value={true}
                                name="navigateToDashboard"
                                onClick={() => handleClick('navigateToDashboard', true)}
                            >
                                Yes
                            </DefaultButton>
                        </Grid>
                        <Grid item xs={5} sm={5}>
                            <DefaultButton
                                className={state.navigateToDashboard ? 'btninActive' : 'btnActiveNo'}
                                value={false}
                                name="navigateToDashboard"
                                onClick={() => handleClick('navigateToDashboard', false)}
                            >
                                No
                            </DefaultButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <DialogsActions className="dialogActions">
                <SecondaryButton className="Submitbtn" onClick={handelSubmit}>
                    Submit
                </SecondaryButton>
            </DialogsActions>
        </Dialogs>
    );
};

export default AddWebFormModal;
