import { AppStorage } from '../Service/storage-service';

let configFromStorage = undefined;

export const isValidConfig = (configName) => {
    return !!getConfigIdFromName(configName);
};

export const getConfigIdFromName = (configName) => {
    if (!configFromStorage) {
        configFromStorage = AppStorage.getFileUploaderConfig();
    }
    return configFromStorage[configName];
};
