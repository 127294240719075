import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { AppStorage } from '../../../Core/Service/storage-service';
import { ExpBaseURL } from '../../../Core/Service/http-calls';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { useWindowSize } from '../../../Core/Controls/ScreenResolution';
import '../../internal/dashboard/dashboard.scss'; //'./dashboard.scss';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { getBranchAndFranchise } from '../../../Core/Service/branch-screen-service';

var grid1;
var $ = window.$;
let storedFiltterValue = {};
let branchList = [];
let franchiseList = [];
const TechnicianGijgoGrid = (props) => {
    const token = AppStorage.getToken();
    const [state, setState] = useState({});
    const [WindowWidths, WindowHeights] = useWindowSize();
    useEffect(async () => {
        if (token) {
            let res = await getBranchAndFranchise();
            if (res.success) {
                branchList = res.data.branches;
                franchiseList = res.data.franchiseRecords;
                pullArchivedJobsAndUpdateState();
            }
        } else {
            window.location = '/';
            AppStorage.performLogout();
        }
    }, [token, props.getUrl, props.filterList, props.isReload]);

    var GijgoFilterExtension = (function () {
        function DropdownFilter(grid, propertyName, dropdown, changeValueConversion) {
            var id = $(grid).attr('id');
            var rowXPath = '#' + id + ' thead tr[data-role="filter"]';
            var filterRow = $(rowXPath);
            if ($(filterRow).length == 1) {
                var colXPath = 'th [data-field="' + propertyName + '"]';
                var filterInput = $(filterRow).find(colXPath);
                if ($(filterInput).length) {
                    $(filterInput).parent().addClass('grid-filter-select-th');
                    $(dropdown).attr('data-field', propertyName);
                    $(filterInput).replaceWith(dropdown);
                    $(dropdown).on('change', function () {
                        ChangeFilter(grid, propertyName, $(this), changeValueConversion);
                    });
                }
            }
        }

        function ChangeFilter(grid, propertyName, ddl, changeValueConversion) {
            if (changeValueConversion) {
                var propArr = changeValueConversion(ddl, propertyName, grid);
                $(propArr).each(function (i, obj) {
                    grid.data().params[obj.propertyName] = obj.value;
                });
                if (propArr.length) {
                    grid.reload();
                }
            } else {
                grid.data().params[propertyName] = $(ddl).val();
                grid.reload();
            }
        }

        return {
            Dropdown: DropdownFilter
        };
    })();

    const pullArchivedJobsAndUpdateState = async () => {
        window.$('#grid').grid('destroy', true, true);

        const onSuccessFunc = function (response) {
            var obj = {
                records: response.data.rows,
                total: response.data.total
            };
            props.getfilterReturnReasone(obj.records);
            grid1.render(obj);
        };

        grid1 = window.$('#grid').grid({
            primaryKey: 'wipNo',
            dataSource: {
                url: `${ExpBaseURL}/api/${props.getUrl}`,
                headers: { Authorization: `Bearer ${token}` },
                success: onSuccessFunc
            },
            width: '100%',
            headerFilter: {
                type: 'onchange'
            },
            columns: [...props.columns],
            pager: props.displayPagination ? { limit: 100 } : false
        });

        setTimeout(() => {
            storedFiltterValue = JSON.parse(localStorage.getItem('FiltterParams')) || {};
            props.columns.forEach((itm) => {
                if (storedFiltterValue) {
                    var id = $(grid1).attr('id');
                    var rowXPath = '#' + id + ' thead tr[data-role="filter"]';
                    var filterRow = $(rowXPath);
                    if ($(filterRow).length == 1) {
                        var colXPath = 'th [data-field="' + `${itm.field}` + '"]';
                        var filterInput = $(filterRow).find(colXPath);
                        if ($(filterInput).length && storedFiltterValue[`${itm.field}`]) {
                            $(filterInput).val(storedFiltterValue[`${itm.field}`]);
                            grid1.data().params[itm.field] = storedFiltterValue[`${itm.field}`];
                        }
                    }
                }
            });
            if (storedFiltterValue.sortBy && storedFiltterValue.direction) {
                grid1.data().params.sortBy = storedFiltterValue.sortBy;
                grid1.data().params.direction = storedFiltterValue.direction;
            }
            grid1.reload();
        });

        grid1.on('dataBound', function (e, records, totalRecords) {
            let { page, limit, ...rest } = grid1.data().params;
            let tempArray = storedFiltterValue;
            for (const property in rest) {
                tempArray = {
                    ...tempArray,
                    [`${property}`]: rest[property]
                };
            }
            localStorage.setItem('FiltterParams', JSON.stringify(tempArray));

            var ddl = $('<select class="gj-width-full grid-filter-select"><option value="" ></option></select>');
            var supCd = grid1.data().params.branchShortName || '';
            $(branchList).each(function (i, itm) {
                if (supCd.length > 0 && itm.branchShortName == supCd) {
                    ddl.append(`<option value="${itm.branchShortName}" selected >${itm.branchShortName}</option>`);
                } else {
                    ddl.append(`<option value="${itm.branchShortName}" >${itm.branchShortName}</option>`);
                }
            });
            GijgoFilterExtension.Dropdown(grid1, 'branchShortName', ddl);
        });

        grid1.on('rowDataBound', function (e, $row, id, record) {
            $row.css('background-color', `white`);
            if (record.returnReasonColourCode) {
                $row.css('background-color', `${record.returnReasonColourCode}`);
            } else {
                const dtDueIn = moment(record.dateDueIn).format('MM/DD/YYYY');
                const daysPast = moment().diff(dtDueIn, 'days');
                if (daysPast > 40) {
                    $row.css('background-color', 'orange');
                } else if (daysPast > 5) {
                    $row.css('background-color', 'yellow');
                }
            }
        });
        grid1.on('cellDataBound', function (e, $displayEl, id, column, record) {
            $displayEl.css('color', `${record.returnReasonTextColourCode}`);
        });
        // grid1.reload();
    };

    const downloadCSVWrapper = () => {
        grid1?.downloadCSV(`${props.currentWorkflowLabel}-${moment().format('DD-MMM-YYYY HH:mm')}.csv`);
    };

    return (
        <Grid container style={{ overflowX: 'auto', position: 'relative' }}>
            <Grid item style={{ top: '33px', left: '18px', zIndex: 99, position: 'absolute' }}>
                <Tooltip title="Download CSV" arrow>
                    <IconButton aria-label="delete">
                        <CloudDownloadIcon fontSize="large" onClick={downloadCSVWrapper} />
                    </IconButton>
                </Tooltip>
            </Grid>
            <Grid container className="common-gijgo-grid" item xs={12} sm={12} style={{ margin: '10px auto' }}>
                <table id="grid"></table>
            </Grid>
        </Grid>
    );
};
export default TechnicianGijgoGrid;
