import { Checkbox, FormControlLabel, Grid, InputLabel, Typography } from '@material-ui/core';
import React from 'react';
import { DatePicker, PrimaryButton, SecondaryButton, SelectBox, TextBox } from '../../../Core/FormInput';
import formatters from '../../../Core/Grid/inputFormatter';
import moment from 'moment';

const Dossierheader = (props) => {
    let jobDetails = props.Jobs;
    return (
        <Grid container spacing={1} justifyContent="space-between">
            <Grid item container sm={12} lg={6} xl={6}>
                {' '}
                <Grid item xs={12}>
                    <div className="component-container g-brd-blue">
                        <div className="header-container g-bg-black">
                            <span>Customer Account Information</span>
                        </div>

                        <div className="table-container table-responsive ">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <div className="labelText">Customer</div>
                                            <Typography className="valueText">{jobDetails?.customerName || 'N/A'}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <div className="labelText"> Account Code </div>
                                            <Typography className="valueText">{jobDetails?.customerAccountCode || 'N/A'}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <div className="labelText">Ord. No.</div>
                                            <Typography className="valueText">{jobDetails?.customerOrderNo || 'N/A'}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <div className="labelText">Address</div>
                                            <Typography className="valueText">{jobDetails?.customerStreet || 'N/A'}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <div className="labelText">Post Code </div>
                                            <Typography className="valueText">
                                                {jobDetails?.customerPostCode || 'N/A'}
                                                &nbsp;
                                                {jobDetails?.customerCity || 'N/A'}
                                            </Typography>
                                        </Grid>
                                        {/* <Grid item xs={12}sm={6}lg={4}xl={4}>
                                            <div className="labelText">Reg. </div>
                                            <Typography className="valueText">{jobDetails?.customerCountry || 'N/A'}</Typography>
                                        </Grid> */}
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <div className="labelText">Country </div>
                                            <Typography className="valueText">{jobDetails?.customerCountry || 'N/A'}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <div className="labelText">Phone </div>
                                            <Typography className="valueText">{jobDetails?.customerTelephone || 'N/A'}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <div className="labelText">Fax </div>
                                            <Typography className="valueText">{jobDetails?.customerFax || 'N/A'}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} xl={4}>
                                            <div className="labelText">VAT No. </div>
                                            <Typography className="valueText">{jobDetails?.customerVAT || 'N/A'}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div className="labelText">Co. Reg </div>
                                            <Typography className="valueText">{jobDetails?.customerRegistration || 'N/A'}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div className="labelText">Comm. n </div>
                                            <Typography className="valueText">{jobDetails?.customerComments || 'N/A'}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
                {/* <Grid item xs={12}>
                    <div className="component-container g-brd-blue">
                        <div className="header-container g-bg-black">
                            <span>Intervention</span>
                        </div>

                        <div className="table-container table-responsive ">
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControlLabel control={<Checkbox name="checkedC" />} label="Exclusion ot the Workshop Planning" />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <InputLabel style={{ marginTop: 10 }}>Start</InputLabel>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <DatePicker />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <TextBox style={{ marginTop: 0 }} />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <InputLabel style={{ marginTop: 10 }}>Duration</InputLabel>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextBox style={{ marginTop: 0 }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid> */}
            </Grid>
            <Grid item container sm={12} lg={6} xl={6}>
                <Grid item xs={12}>
                    <div className="component-container g-brd-blue">
                        <div className="header-container g-bg-black">
                            <span>Vehicle</span>
                        </div>

                        <div className="table-container table-responsive ">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} lg={4} xl={4}>
                                    <div className="labelText">Registration Plate </div>
                                    <Typography className="valueText">{jobDetails?.registration || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4} xl={4}>
                                    <div className="labelText">VIN </div>
                                    <Typography className="valueText">{jobDetails?.vinNumber || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4} xl={4}>
                                    <div className="labelText">Qty /Hrs </div>
                                    <Typography className="valueText">{moment(jobDetails.hourCreated).format('HH:MM') || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4} xl={4}>
                                    <div className="labelText">Type Range </div>
                                    <Typography className="valueText">{jobDetails.modelCode || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4} xl={4}>
                                    <div className="labelText">Date 1st Reg. </div>
                                    <Typography className="valueText">{formatters.MonthShortFormatter(jobDetails.quoteDate) || 'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4} xl={4}>
                                    <div className="labelText">Future MOT Date </div>
                                    <Typography className="valueText">{'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4} xl={4}>
                                    <div className="labelText">Maint Contract</div>
                                    <Typography className="valueText">{'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4} xl={4}>
                                    <div className="labelText">Entry Vehicle </div>
                                    <Typography className="valueText">{'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={4} xl={4}>
                                    <div className="labelText">Exit Vehicle </div>
                                    <Typography className="valueText">{'N/A'}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <div className="labelText">Truck comments </div>
                                    <Typography className="valueText">{jobDetails.comments || 'N/A'}</Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Dossierheader;
