import React from 'react';
import Barcode from 'react-barcode';
import formatters from '../../../Core/Grid/inputFormatter';

export default function ShowBarCode(props) {
    return (
        <div className="App">
            <Barcode value={formatters.addPrefixAndSuffixToBarCode(props.wipNo)} />
        </div>
    );
}
