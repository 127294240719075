import { useEffect, useState } from 'react';
import { DefaultButton, PrimaryButton, SecondaryButton } from '../FormInput';
import { useHistory } from 'react-router-dom';
import './BasicButtonGroup.scss';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useWindowSize } from '../Controls/ScreenResolution';
import { Grid } from '@material-ui/core';

export const SpaceType = {
    start: 'start',
    spaceAround: 'space-around',
    spaceBetween: 'space-between'
};

function scrollbarVisible(element) {
    return element?.scrollWidth > element?.clientWidth;
}

const scrollNext = (id) => {
    document.getElementById('WorkFlow-Btn').scrollBy({ left: 100, top: 0, behavior: 'smooth' });
};

const scrollPrev = (id) => {
    document.getElementById('WorkFlow-Btn').scrollBy({ left: -100, top: 0, behavior: 'smooth' });
};

const BasicButtonGroup = ({
    className = '',
    buttons = [],
    btnSelectedId,
    selectedId = 0,
    triggerButtonClickOnLoad = true,
    onClick,
    spaceType = SpaceType.start,
    margin = 0,
    color = 'secondary',
    allGrid,
    hideWorkflowScrollbar
}) => {
    let WorkFlowBtnElementscrollWidth = document.getElementById('WorkFlow-Btn');
    let history = useHistory();
    let mainWorkflowButtonId = history.location.pathname.split('/')[3];

    const [state, setState] = useState({
        selectedId,
        buttons: [],
        // basicStyle: { minHeight: 30, display: 'flex', justifyContent: spaceType, margin, overflow: 'auto' },
        basicStyle: { minHeight: 30, display: 'flex', justifyContent: spaceType, margin, overflow: 'auto' },
        showHighlightedButton: true
    });

    const [WindowWidths, WindowHeights] = useWindowSize();

    useEffect(() => {
        if (triggerButtonClickOnLoad && onClick) {
            if (btnSelectedId !== 'All' && btnSelectedId) {
                const hasSelected = buttons.filter((b) => b.id === +btnSelectedId).length === 1;
                hasSelected ? buttonClicked(buttons.filter((b) => b.id === +btnSelectedId)[0]) : buttons.length > 0 && buttonClicked(buttons[0]);
            }
            if (!allGrid) {
                const hasSelected = buttons.filter((b) => b.id === state.selectedId).length === 1;
                hasSelected ? buttonClicked(buttons.filter((b) => b.id === state.selectedId)[0]) : buttons.length > 0 && buttonClicked(buttons[0]);
            }
        } else {
            const hasSelected = buttons.filter((b) => b.id === selectedId).length === 1;
            hasSelected ? buttonClicked(buttons.filter((b) => b.id === selectedId)[0]) : buttons.length > 0 && buttonClicked(buttons[0]);
        }
        setState((st) => ({
            ...st,
            buttons
        }));
    }, [selectedId, buttons]);

    useEffect(() => {
        setState((st) => ({
            ...st,
            isShowScrollArrow: scrollbarVisible(WorkFlowBtnElementscrollWidth)
        }));
    }, [WindowWidths, WorkFlowBtnElementscrollWidth?.scrollWidth]);

    const buttonClicked = (btn) => {
        setState((st) => ({ ...st, selectedId: btn.id }));
        onClick && onClick(btn);
    };

    const wfbuttonClicked = (btn) => {
        setState((st) => ({ ...st, selectedId: btn.id, showHighlightedButton: true }));
        onClick && onClick(btn);
    };

    // useEffect(() => {
    //     setState((st) => ({ ...st, showHighlightedButton: !(mainWorkflowButtonId == 7) }));
    // }, [history]);

    const ButtonStyle = {
        // width: 100,
        // height: 40,
        fontSize: 11,
        wordWrap: 'break-word',
        border: '1px solid grey',
        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)'
    };

    const SpanStyle = {
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        lineHeight: 1.2,
        fontWeight: 600
    };

    return (
        <>
            {!hideWorkflowScrollbar ? (
                <div style={{ overflow: 'hidden', display: 'flex', alignItems: 'center', width: '100%' }} className="BasicButtonGroup-Container">
                    {state.isShowScrollArrow && (
                        <span onClick={() => scrollPrev()} className="ScrollArrow-Container">
                            <ChevronLeftIcon />
                        </span>
                    )}
                    <div style={state.basicStyle} className={`${className} btn-container`} id="WorkFlow-Btn">
                        {state.buttons.map((btn) => {
                            if (btn.id == mainWorkflowButtonId) {
                                return (
                                    <SecondaryButton style={{ backgroundColor: 'green' }} key={btn.id} size="small" onClick={() => buttonClicked(btn)}>
                                        {btn.label}
                                    </SecondaryButton>
                                );
                            }
                            if (btn.id === state.selectedId && state.showHighlightedButton) {
                                if (color === 'secondary') {
                                    return (
                                        <SecondaryButton key={btn.id} size="small" onClick={() => wfbuttonClicked(btn)}>
                                            {btn.label}
                                        </SecondaryButton>
                                    );
                                }
                                return (
                                    <PrimaryButton key={btn.id} size="small" onClick={() => wfbuttonClicked(btn)}>
                                        {btn.label}
                                    </PrimaryButton>
                                );
                            } else {
                                return (
                                    <DefaultButton key={btn.id} size="small" onClick={() => wfbuttonClicked(btn)}>
                                        {btn.label}
                                    </DefaultButton>
                                );
                            }
                        })}
                    </div>
                    {state.isShowScrollArrow && (
                        <span onClick={() => scrollNext()} className="ScrollArrow-Container">
                            <ChevronRightIcon />
                        </span>
                    )}
                </div>
            ) : (
                <div style={{ padding: 5 }}>
                    <Grid container justifyContent="flex-start">
                        <Grid item xs={12} style={{ height: '100%', overflow: 'hidden' }}>
                            <Grid container justifyContent="flex-start" spacing={1}>
                                {state.buttons.map((btn) => {
                                    if (btn.id == mainWorkflowButtonId) {
                                        return (
                                            <Grid item>
                                                <SecondaryButton
                                                    style={{ ...ButtonStyle, backgroundColor: 'green' }}
                                                    key={btn.id}
                                                    size="small"
                                                    onClick={() => buttonClicked(btn)}
                                                >
                                                    <span style={SpanStyle}>{btn.label}</span>
                                                </SecondaryButton>
                                            </Grid>
                                        );
                                    }
                                    if (btn.id === state.selectedId && state.showHighlightedButton) {
                                        if (color === 'secondary') {
                                            return (
                                                <Grid item>
                                                    <SecondaryButton key={btn.id} style={ButtonStyle} size="small" onClick={() => wfbuttonClicked(btn)}>
                                                        <span style={SpanStyle}> {btn.label}</span>
                                                    </SecondaryButton>
                                                </Grid>
                                            );
                                        }
                                        return (
                                            <Grid item>
                                                <PrimaryButton key={btn.id} size="small" style={ButtonStyle} onClick={() => wfbuttonClicked(btn)}>
                                                    <span style={SpanStyle}>{btn.label}</span>
                                                </PrimaryButton>
                                            </Grid>
                                        );
                                    } else {
                                        return (
                                            <Grid item>
                                                <DefaultButton key={btn.id} size="small" style={ButtonStyle} onClick={() => wfbuttonClicked(btn)}>
                                                    <span style={SpanStyle}>{btn.label}</span>
                                                </DefaultButton>
                                            </Grid>
                                        );
                                    }
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                    ,{' '}
                </div>
            )}
        </>
    );
};

export default BasicButtonGroup;
