import { LinearProgress } from '@material-ui/core';
import React, { useRef, useState } from 'react';

const SingleDocumentViewer = (props) => {
    return (
        <div style={{ height: '100%' }}>
            {props.slectedFileExt == '.pdf' ? (
                <div style={{ height: '100%' }}>
                    <iframe
                        src={`${props.imageList}`}
                        id="editviewer"
                        frameBorder="0"
                        scrolling="auto"
                        allowFullScreen="allowFullScreen"
                        width="100%"
                        // height={props.height ? props.height : '730'}
                        height={'100%'}
                    ></iframe>
                </div>
            ) : (
                <img src={props.imageList} alt="No document selected" width="100%" height={'100%'} />
            )}
        </div>
    );
};

export default SingleDocumentViewer;
