import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Mail, Visibility } from '@material-ui/icons';
import { CircularProgress, Grid, IconButton, Tooltip } from '@material-ui/core';
import moment from 'moment';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import { AppStorage } from '../../../../Core/Service/storage-service';

const DocTableRow = ({ item, showDoc, HandelMailDocument, DownloadOcrDocument, currentVisibleDocName, isLoadingDoc, isHide }) => {
    const docAction = (item) => {
        HandelMailDocument && HandelMailDocument(item);
    };

    const downlaodOCRDocHandler = (item, isMultiVersion) => {
        DownloadOcrDocument && DownloadOcrDocument(item, isMultiVersion);
    };

    return (
        <tr className="row">
            <td>{item.ocrDocName}</td>
            {isHide && <td className="time-type">{moment(item.createdAt).format('LLL')}</td>}
            {isHide && <td className="created-by">{item.createdBy}</td>}
            <td className="actions">
                <div className="icon-button-group">
                    <Tooltip title="View">
                        <IconButton
                            color={currentVisibleDocName === item?.docName ? 'primary' : 'secondary'}
                            component="span"
                            onClick={() => showDoc(item)}
                            disabled={isLoadingDoc}
                        >
                            {isLoadingDoc && currentVisibleDocName === item?.docName ? (
                                <Grid>
                                    <Grid>
                                        <CircularProgress size={20} />
                                        &nbsp;
                                    </Grid>
                                </Grid>
                            ) : (
                                <Visibility disabled={isLoadingDoc} />
                            )}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Mail Document">
                        <IconButton color="secondary" component="span" onClick={() => docAction(item)}>
                            <Mail />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Download Document">
                        <IconButton color="secondary" component="span" onClick={() => downlaodOCRDocHandler(item, false)}>
                            <AssignmentReturnedIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </td>
        </tr>
    );
};

const DocTable = ({ rec, showDoc, HandelMailDocument, DownloadOcrDocument, currentVisibleDocName, isLoadingDoc }) => {
    let hideDocCreatedValue = AppStorage.getCanHideDocumentCreation();

    return (
        <>
            {/* <table className="all-documents-header">
                <thead>
                    <tr className="header">
                        <th>Document</th>
                        <th className="time-type">Created At</th>
                        <th className="created-by">Created By</th>
                        <th className="actions">Action</th>
                    </tr>
                </thead>
            </table> */}
            <div className="body-table-container custom-scroll">
                <table className="all-documents-body">
                    <thead>
                        <tr className="header">
                            <th>Document</th>
                            {!hideDocCreatedValue && <th className="time-type">Created At</th>}
                            {!hideDocCreatedValue && <th className="created-by">Created By</th>}
                            <th className="actions">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rec.map((r, i) => (
                            <DocTableRow
                                key={i}
                                item={r}
                                showDoc={showDoc}
                                HandelMailDocument={HandelMailDocument}
                                DownloadOcrDocument={DownloadOcrDocument}
                                currentVisibleDocName={currentVisibleDocName}
                                isLoadingDoc={isLoadingDoc}
                                isHide={!hideDocCreatedValue}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

const JobAllDocs = (props) => {
    const [state, setState] = useState({
        allDoc: props.allDoc
    });
    useEffect(() => {
        setState((st) => ({ ...st, allDoc: props.allDoc }));
    }, [props]);

    const handleClose = () => {
        props.onClose && props.onClose();
    };

    const showDoc = (item) => {
        props.showDoc && props.showDoc(item.ocrDocId, item.docName, item.IsVideo);
    };

    return (
        <>
            <Dialog open={true} onClose={handleClose} maxWidth="md" className="dialog-custom all-documents-modal">
                <DialogTitle>
                    Job Documents
                    <div className="title-header-actions">
                        <span className="close-icon" onClick={handleClose}>
                            &times;
                        </span>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className="all-documents">
                        <DocTable
                            rec={state.allDoc}
                            showDoc={showDoc}
                            HandelMailDocument={props.HandelMailDocument}
                            DownloadOcrDocument={props.DownloadOcrDocument}
                            currentVisibleDocName={props.currentVisibleDocName}
                            isLoadingDoc={props.isLoadingDoc}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default JobAllDocs;
