import React, { useState } from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import { SecondaryButton, TextBox } from '../../../../Core/FormInput';

import { PostReceivedByMainSite } from '../../../../Core/Service/PartReq_services/partReturnSteps-services';

const ReceivedByMainSite = ({ onClose, handleSubmit, partReturnPartReqID, partReturnID, showToast }) => {
    const [state, setState] = useState({
        partReturnInternalCreditNo: ''
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const Onsubmit = async () => {
        setBtnLoader(true);
        let data = {
            partReturnPartReqID: partReturnPartReqID,
            partReturnID: partReturnID,
            partReturnInternalCreditNo: state.partReturnInternalCreditNo || ''
        };
        let res = await PostReceivedByMainSite(data);

        if (res.success) {
            showToast(res?.data?.message);
            handleSubmit();
            onClose();
        }
        setBtnLoader(false);
    };
    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel>Internal Credit Number</InputLabel>
                    <TextBox
                        placeholder="Internal Credit Number"
                        name="partReturnInternalCreditNo"
                        value={state.partReturnInternalCreditNo}
                        onChange={(e) => {
                            setState((st) => ({ ...st, partReturnInternalCreditNo: e.target.value }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SecondaryButton fullWidth onClick={Onsubmit} disabled={!state.partReturnInternalCreditNo} isBtnLoader={btnLoader}>
                        Submit
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default ReceivedByMainSite;
