import React, { useEffect, useState, useContext, useMemo } from 'react';
import AppContext from '../../../App/AppContext';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import { Link } from 'react-router-dom';
import { EvenSpaceButtonGroup } from '../../../Core/ButtonGroup';
import './TechnicianApp_screen.css';
import moment from 'moment';
import { getTechnicianJobs } from '../../../Core/Service/technician-service';
import { IconButton, Tooltip } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { find } from 'lodash';
import { getWorkflows } from '../../../Core/Service/workflow-service';
import Grid from '@material-ui/core/Grid';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InfoIcon from '@material-ui/icons/Info';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import './TechnicianApp_screen.css';

const GridInfo = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <>
            {/* <Tooltip title="View Details" aria-label="add"> */}

            <abbr title="View Details">
                <InfoIcon className="grid-info-icon" onClick={handleClick} style={{ margin: 5 }} />
            </abbr>
            {/* </Tooltip> */}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                {props?.filterReturnReasone.map((m) => {
                    return (
                        <Grid item>
                            <Grid item container style={{ margin: '10px' }}>
                                <Grid item>
                                    <div
                                        style={{
                                            height: 20,
                                            width: 20,
                                            backgroundColor: m.returnReasonColourCode ? m.returnReasonColourCode : 'orange'
                                        }}
                                    >
                                        <span
                                            style={{
                                                color: m.returnReasonTextColourCode ? m.returnReasonTextColourCode : 'black',
                                                fontWeight: 500,
                                                marginLeft: '5px'
                                            }}
                                        >
                                            T
                                        </span>
                                    </div>
                                </Grid>
                                &nbsp;&nbsp;
                                <Grid item>{m.returnReason || 'Due date'}</Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </Popover>
        </>
    );
};

export default GridInfo;
