import React, { useState } from 'react';

import { Grid, InputLabel } from '@material-ui/core';
import { SecondaryButton, TextBox } from '../../../../Core/FormInput';

import { PostMarkCollected } from '../../../../Core/Service/PartReq_services/partReturnSteps-services';

import { CustomeSignPad } from '../../../../Core/SignaturePad';

const Collected = ({ onClose, handleSubmit, partReturnPartReqID, partReturnID, showToast }) => {
    const [state, setState] = useState({
        partReturnReceivedBy: '',
        partReturnCollectedSign: ''
    });
    const [btnLoader, setBtnLoader] = useState(false);

    const clearSig = () => {
        setState((st) => ({
            ...st,
            partReturnCollectedSign: null
        }));
    };
    const fieldChange = async (val) => {
        setState((st) => ({
            ...st,
            ...val
        }));
    };
    const Onsubmit = async () => {
        setBtnLoader(true);
        let data = {
            partReturnPartReqID: partReturnPartReqID,
            partReturnID: partReturnID,
            partReturnReceivedBy: state.partReturnReceivedBy || '',
            partReturnCollectedSign: state.partReturnCollectedSign
        };
        let res = await PostMarkCollected(data);

        if (res.success) {
            showToast(res?.data?.message);
            handleSubmit();
            onClose();
        }
        setBtnLoader(false);
    };

    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel>Received By</InputLabel>
                    <TextBox
                        placeholder="Received By"
                        name="partReturnReceivedBy"
                        value={state.partReturnReceivedBy}
                        onChange={(e) => {
                            setState((st) => ({ ...st, partReturnReceivedBy: e.target.value }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomeSignPad
                        onChange={fieldChange}
                        clearSig={clearSig}
                        sign={state.signature}
                        content={<span style={{ color: 'gray' }}>Signature</span>}
                        name="partReturnCollectedSign"
                    />
                </Grid>
                <Grid item xs={12}>
                    <SecondaryButton fullWidth onClick={Onsubmit} disabled={!state.partReturnCollectedSign} isBtnLoader={btnLoader}>
                        Submit
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default Collected;
