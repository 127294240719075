import moment from 'moment';
import { PartSupplierContext, PartSupplierProvider } from './partSupplierProvider';
import { useContext } from 'react';
import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, withStyles } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
    head: {
        padding: 5,
        border: '1px solid rgba(0,0,0,0.2)',
        fontWeight: 600,
        background: 'grey',
        color: 'white'
    },
    body: {
        padding: 5,
        border: '1px solid rgba(0,0,0,0.2)'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700
    }
});

const PartReqHistory = (props) => {
    const classes = useStyles();
    const { state } = useContext(PartSupplierContext);
    return (
        <div style={{ padding: 10 }}>
            <TableContainer>
                <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Created By</StyledTableCell>
                            <StyledTableCell>Created At</StyledTableCell>
                            <StyledTableCell>Moved By</StyledTableCell>
                            <StyledTableCell>Moved At</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    {!state.isHistory ? (
                        <TableBody>
                            {state.historyList.length > 0 ? (
                                state.historyList.map((row) => (
                                    <StyledTableRow key={row.name}>
                                        <StyledTableCell component="th" scope="row">
                                            {row.name}
                                        </StyledTableCell>
                                        <StyledTableCell>{row.createdBy || '-'}</StyledTableCell>
                                        <StyledTableCell>{row.createdAt ? moment(row.createdAt).format('LLL') : '-'}</StyledTableCell>
                                        <StyledTableCell>{row.movedBy || '-'}</StyledTableCell>
                                        <StyledTableCell>{row.movedAt ? moment(row.movedAt).format('LLL') : '-'}</StyledTableCell>
                                    </StyledTableRow>
                                ))
                            ) : (
                                <StyledTableRow>
                                    <StyledTableCell align="center" colSpan={5}>
                                        No Record Found
                                    </StyledTableCell>
                                </StyledTableRow>
                            )}
                        </TableBody>
                    ) : (
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell align="center" colSpan={5}>
                                    <CircularProgress />
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
        </div>
    );
};

export default function screen(props) {
    return (
        <PartSupplierProvider {...props}>
            <PartReqHistory {...props} />
        </PartSupplierProvider>
    );
}
