import React from 'react';
import { Grid, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Alert } from '@material-ui/lab';
import { SecondaryButton } from '../../../../Core/FormInput';

const StyledTableCell = withStyles((theme) => ({
    head: {
        // padding: ' 5px',
        fontSize: 15,
        fontWeight: 600,
        backgroundColor: '#bdbdbd82'
    },
    body: {
        fontSize: 12
        // padding: ' 5px'
        // fontSize: 11,
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'grey',
            color: 'white'
        },
        cursor: 'pointer'
    }
}))(TableRow);

const IsFittedwarnigModal = (props) => {
    return (
        <Grid container spacing={2}>
            {props.list.length > 0 && (
                <Grid item xs={12}>
                    <TableContainer>
                        <Table size="small" width="550px" stickyHeader>
                            <TableHead>
                                <TableRow className="header">
                                    <StyledTableCell>Part Number</StyledTableCell>
                                    <StyledTableCell>Part Description</StyledTableCell>
                                    <StyledTableCell>Fitted?</StyledTableCell>
                                    <StyledTableCell>Returned?</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.list.map((q) => {
                                    return (
                                        <StyledTableRow>
                                            <StyledTableCell>{q.partNumber}</StyledTableCell>
                                            <StyledTableCell>{q.partDescription}</StyledTableCell>
                                            <StyledTableCell>{q.isFitted ? 'Yes' : 'No'}</StyledTableCell>
                                            <StyledTableCell>{q.isReturnToPartsDepo ? 'Yes' : 'No'}</StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Alert severity="warning">Fitted and returned are not set for these parts. Are you sure you want to continue?</Alert>
                </Grid>
            )}
            <Alert severity="warning">
                By submitting you will not be able to make any further changes to the form and it transferred to the next queue. If you need to make further
                changes please click cancel and select the save option.
            </Alert>
            <Grid item xs={6}>
                <SecondaryButton
                    fullWidth
                    onClick={props.handleSubmit}
                    // disabled={!state.partReturnCollectedSign}
                    //  isBtnLoader={btnLoader}
                >
                    Submit
                </SecondaryButton>
            </Grid>
            <Grid item xs={6}>
                <SecondaryButton fullWidth onClick={props.onClose}>
                    Cancel
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default IsFittedwarnigModal;
