import moment from 'moment';
import { get, post, put } from './http-calls';
import { nicoleDownloadFileWithExt } from './ReportScreens-service';

export const getTechWriteUp = async (data, technicianJobId) => {
    let res = '';
    if (technicianJobId) {
        res = await get(`/TechnicianWriteUpFromHeaderRecordID/${data}?TechnicianJobId=${technicianJobId}&time=${moment().format('YYMMDDHHmmSS')}`, {
            useAuthToken: true
        });
    } else {
        res = await get(`/TechnicianWriteUpFromHeaderRecordID/${data}?time=${moment().format('YYMMDDHHmmSS')}`, { useAuthToken: true });
    }
    return res;
};

export const AddTechWriteUp = async (data, NotesItem, val = 'S') => {
    let res = await post('/SaveTechnicianWriteUp', data, { useAuthToken: true });
    return res;
};
export const getStandardWriteUp = async (IsAllRecord = 'N', langCode = 'en') => {
    let res = await get(`StandardWriteup?AllRecord=${IsAllRecord || 'N'}&LanguageCode=${langCode || 'en'}`, { useAuthToken: true });
    return res;
};

export const TechnicianWriteUp_Delete = async (TechnicianJobID) => {
    let res = await post(`TechnicianWriteUp/TechnicianWriteUp_Delete?TechnicianJobID=${TechnicianJobID}`, {}, { useAuthToken: true });
    return res;
};

export const AddStandardWriteUp = async (data) => {
    let res = await post(`StandardWriteup`, data, { useAuthToken: true });
    return res;
};

export const AddStandardWriteUpUpdate = async (data) => {
    let res = await post(`StandardWriteup/add`, data, { useAuthToken: true });
    return res;
};

export const DeleteStandardWriteUp = async (id) => {
    let res = await post(
        `StandardWriteup/DeleteStandardWriteup/${id}`,
        {
            Id: id
        },
        { useAuthToken: true }
    );
    return res;
};

export const getStandardWriteUpById = async (id) => {
    let res = await get(`StandardWriteup/${id}`, { useAuthToken: true });
    return res;
};

export const getRenault_TechnicianWriteUp_ScreenDropdown = async (id) => {
    let res = await get(`TechnicianWriteUp/Renault_TechnicianWriteUp_ScreenDropdown`, { useAuthToken: true });
    return res;
};

export const getTechWriteUpPdfFile = async (HeaderRecordID, screenName) => {
    let res = await get(`TechnicianWriteUp/GetPDF?HeaderRecordID=${HeaderRecordID}&mode=${screenName}`, {
        responseType: 'blob',
        returnOrgRes: true,
        useAuthToken: true
    });
    await nicoleDownloadFileWithExt(res, 'pdf', 'TechnicianWriteUp');
};
export const getTechWriteUpPdfWithFmFile = async (HeaderRecordID, screenName) => {
    let res = await get(`TechnicianWriteUp/FMWarrantyGetPDF?HeaderRecordID=${HeaderRecordID}&mode=${screenName}`, {
        responseType: 'blob',
        returnOrgRes: true,
        useAuthToken: true
    });
    await nicoleDownloadFileWithExt(res, 'pdf', 'TechnicianWriteUp');
};

export const getSTDWriteUpPdfFile = async (HeaderRecordID, screenName) => {
    let res = await get(`TechnicianWriteUp/GetSTDByDateandTechName?HeaderRecordID=${HeaderRecordID}&mode=${screenName}`, {
        responseType: 'blob',
        returnOrgRes: true,
        useAuthToken: true
    });
    await nicoleDownloadFileWithExt(res, 'pdf', 'TechnicianWriteUp');
};
