import React, { useContext, useEffect, useState } from 'react';

import { SecondaryButton, TextBox } from '../../../../Core/FormInput';
import { PostCollectedByMainSite, PostGridCollectedByMainSite } from '../../../../Core/Service/PartReq_services/partReturnSteps-services';
import { CustomeSignPad } from '../../../../Core/SignaturePad';
import { Alert } from '@material-ui/lab';
import AppContext from '../../../../App/AppContext';

import { Grid, InputLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import moment from 'moment';
const StyledTableCell = withStyles((theme) => ({
    head: {
        // padding: ' 5px',
        fontSize: 15,
        fontWeight: 600,
        backgroundColor: '#bdbdbd82'
    },
    body: {
        fontSize: 12
        // padding: ' 5px'
        // fontSize: 11,
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'grey',
            color: 'white'
        },
        cursor: 'pointer'
    }
}))(TableRow);

const CollectedByMainSiteGridModal = (props) => {
    const { showToast } = useContext(AppContext);
    const [btnLoader, setBtnLoader] = useState(false);
    const [state, setState] = useState({
        partReturnInternalInvoiceNo: '',
        partReturnMainSiteReceivedBy: '',
        partReturnMaintSiteCollectedSign: '',
        partReturnIDs: []
    });

    useEffect(() => {
        if (props.selectedRecords.length > 0) {
            setState((st) => ({ ...st, partReturnIDs: props.selectedRecords.map((q) => q.partReturnID) }));
        }
    }, []);

    const clearSig = () => {
        setState((st) => ({
            ...st,
            partReturnMaintSiteCollectedSign: null
        }));
    };
    const fieldChange = async (val) => {
        setState((st) => ({
            ...st,
            ...val
        }));
    };

    const Onsubmit = async () => {
        setBtnLoader(true);
        let data = {
            partReturnIDs: state.partReturnIDs,
            partReturnInternalInvoiceNo: state.partReturnInternalInvoiceNo || '',
            partReturnMainSiteReceivedBy: state.partReturnMainSiteReceivedBy || '',
            partReturnMaintSiteCollectedSign: state.partReturnMaintSiteCollectedSign || ''
        };
        let res = await PostGridCollectedByMainSite(data);

        if (res.success) {
            showToast(res?.data?.message);
            props.onClose && props.onClose(true);
        }
        setBtnLoader(false);
    };
    return (
        <div style={{ padding: 10 }}>
            {state.partReturnIDs.length <= 0 ? (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Alert severity="info">Please Select parts </Alert>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table size="small" width="550px" stickyHeader>
                                <TableHead>
                                    <TableRow className="header">
                                        <StyledTableCell>Part Number</StyledTableCell>
                                        <StyledTableCell>Supplier Name</StyledTableCell>
                                        <StyledTableCell>Part Return Date Created</StyledTableCell>
                                        <StyledTableCell>Is Surcharged</StyledTableCell>
                                        <StyledTableCell>Returned By</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.selectedRecords.map((q) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell>{q.partNo}</StyledTableCell>
                                                <StyledTableCell>{q.supplierName}</StyledTableCell>
                                                <StyledTableCell>{moment(q.partReturnDateCreated).format('DD-MMM-YYYY HH:mm')}</StyledTableCell>
                                                <StyledTableCell>{q.isSurcharged}</StyledTableCell>
                                                <StyledTableCell>{q.returnedBy}</StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel>Received By</InputLabel>
                        <TextBox
                            placeholder="Received By"
                            name="partReturnMainSiteReceivedBy"
                            value={state.partReturnMainSiteReceivedBy}
                            onChange={(e) => {
                                setState((st) => ({ ...st, partReturnMainSiteReceivedBy: e.target.value }));
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel>Internal Invoice Number</InputLabel>
                        <TextBox
                            placeholder="Internal Invoice Number"
                            name="partReturnInternalInvoiceNo"
                            value={state.partReturnInternalInvoiceNo}
                            onChange={(e) => {
                                setState((st) => ({ ...st, partReturnInternalInvoiceNo: e.target.value }));
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <CustomeSignPad
                            onChange={fieldChange}
                            clearSig={clearSig}
                            // sign={state.partReturnMaintSiteCollectedSign}
                            content={<span style={{ color: 'gray' }}>Signature</span>}
                            name="partReturnMaintSiteCollectedSign"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SecondaryButton fullWidth onClick={Onsubmit} disabled={!state.partReturnMaintSiteCollectedSign} isBtnLoader={btnLoader}>
                            Submit
                        </SecondaryButton>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default CollectedByMainSiteGridModal;
