import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CustomeSignPad } from '../../../Core/SignaturePad';
import {
    Card,
    CardActions,
    CardContent,
    Grid,
    Step,
    StepButton,
    Stepper,
    Typography,
    Hidden,
    InputLabel,
    FormHelperText,
    CircularProgress
} from '@material-ui/core';
import { CustomButton, DatePicker, DateTimePicker, PrimaryButton, SecondaryButton, TextBox } from '../../../Core/FormInput';

import { downldTorqueGetDetails, getWheelTorque, postWheelTorque } from '../../../Core/Service/wheelServices/wheel-services';
import DialogComp from '../../../Core/Modal/dialogModal';
import { Alert, AlertTitle } from '@material-ui/lab';
import AppContext from '../../../App/AppContext';
import CircularIndeterminate from '../ReportsScreens/ReportsScreens/BudgetCore/loader';
import BorderColor from '@material-ui/icons/BorderColor';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { AppStorage } from '../../../Core/Service/storage-service';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        textAlign: 'left',

        transition: '0.5s ease',
        padding: '0px 10px'
    },

    header: {
        background: 'white',
        padding: 0,
        transition: '0.5s ease',
        backgroundClip: 'content-box'
    },
    headeractiver: {
        background: 'yellow',
        padding: 5,
        transition: '0.5s ease',
        backgroundClip: 'padding-box'
    },

    activeRoot: {
        minWidth: 275,
        textAlign: 'left',
        transition: '0.5s ease',
        marginTop: 10,
        padding: '20px 10px',
        background: '#fff',
        transform: 'translateY(-5px) scale(1.005) translateZ(0)',
        boxShadow: '8.0px 16.0px 16.0px hsl(0deg 0% 0% / 0.25)'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    }
});

const useStepStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    button: {
        marginRight: theme.spacing(0)
    }
}));

function getSteps() {
    return ['FITTING TORQUE', 'RE-TORQUE', 'ADDITIONAL RE-TORQUE'];
}

let ftlb = 0.7376;

function HorizontalNonLinearStepper({ activeStep, setActiveStep, completed }) {
    const classes = useStepStyles();

    const steps = getSteps();

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    return (
        <Grid container justifyContent="center">
            <Grid item xs={9}>
                <Hidden only="xs">
                    <Stepper nonLinear activeStep={activeStep} style={{ background: 'none', padding: '0px 20px' }}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepButton onClick={handleStep(index)} color="secondary" completed={completed[index]}>
                                    {label}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </Hidden>
                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                    <Stepper nonLinear activeStep={activeStep}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepButton onClick={handleStep(index)} color="secondary" completed={completed[index]}></StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </Hidden>
            </Grid>
        </Grid>
    );
}

function OutlinedCard(props) {
    const {
        heading,
        activeStep,
        id,
        state,
        handleComplete,
        setTorque,
        torque,
        objName,
        setActiveStep,
        fromStep,
        navigateToDashboard,
        DefaultName,
        setShowDwnld
    } = props;
    const [errors, setErrors] = useState({});
    const classes = useStyles();
    const { showToast, showModal } = useContext(AppContext);

    const handleInput = (e) => {
        const { name, value } = e.target;
        setTorque((st) => {
            let newSt = { ...st };
            if (name == 'nm') {
                let Totallbft = 0.7376 * +value;
                newSt[objName] = { ...st[objName], [name]: value, lbft: +Totallbft?.toFixed(2) };
            } else {
                newSt[objName] = { ...st[objName], [name]: value };
            }
            return newSt;
        });
    };

    const fieldChange = (val) => {
        setTorque((st) => {
            let newSt = { ...st };
            newSt[objName] = { ...st[objName], ...val };
            return newSt;
        });
    };

    const setDefaultName = () => {
        setTorque((st) => {
            let newSt = { ...st };
            newSt[objName] = { ...st[objName], pn: DefaultName };
            return newSt;
        });
    };

    const clearSig = (val) => {
        setTorque((st) => {
            let newSt = { ...st };
            newSt[objName] = { ...st[objName], signature: null, sign: null };
            return newSt;
        });
    };
    let validation = () => {
        let errors = {};
        let isValid = true;
        if (!state.pn) {
            isValid = false;
            errors.pn = 'Name is required.';
        }
        if (!state.signature) {
            isValid = false;
            errors.sign = 'Signature is required';
        }

        setErrors(errors);
        return isValid;
    };

    const handleSubmit = async (param) => {
        if (validation()) {
            let res = await postWheelTorque(torque);
            if (res.success) {
                showToast(res?.data.message);
                setActiveStep(id + 1);
                handleComplete(activeStep);
                if (fromStep) {
                    setTimeout(() => {
                        window.top.postMessage({ type: navigateToDashboard ? 'navigate-dashboard' : 'close-modal' }, '*');
                    }, 1000);
                }
            }
            if (res.errors) {
                for (const [key, value] of Object.entries(res?.errors)) {
                    showToast(`${key}: ${value}`);
                }
            } else {
                showToast(res?.data.message);
            }
        }
    };

    useEffect(() => {
        if (state.signature) {
            setShowDwnld(true);
        }
    }, [state.signature]);

    let isSignDisabled = activeStep === id ? false : true;
    let isSignButtonDisplay = activeStep === id;

    return (
        <Card className={activeStep === id ? classes.activeRoot : classes.root} variant="outlined">
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography style={{ textAlign: 'center', fontWeight: 500 }} className={activeStep === id ? classes.headeractiver : classes.header}>
                            {' '}
                            {heading}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className="labelTextReq" style={{ fontSize: 12 }}>
                            Wheel Nuts Torqued to
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <TextBox placeholder="Nm" type="number" disabled={activeStep !== id} name="nm" value={state.nm} onChange={handleInput} />
                            </div>
                            <div>&nbsp;</div>
                            <div>
                                <TextBox
                                    placeholder="Lbft"
                                    type="number"
                                    // disabled={activeStep !== id}
                                    name="lbft"
                                    value={state.lbft}
                                    onChange={handleInput}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel>Vehicle Mileage</InputLabel>
                        <TextBox disabled={activeStep !== id} name="vm" type="number" value={state.vm} onChange={handleInput} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel style={{ marginBottom: 8 }}>Date</InputLabel>
                        <DateTimePicker disabled={activeStep !== id} name="date" value={state.date} onChange={handleInput} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography> {heading} COMPLETED BY : </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel error>
                            Print Name* &nbsp;
                            {isSignButtonDisplay && !state.pn && (
                                <BorderColor
                                    onClick={setDefaultName}
                                    toolTipTitle="Use User Name"
                                    fontSize="small"
                                    style={{ marginBottom: -4, cursor: 'pointer' }}
                                />
                            )}
                        </InputLabel>
                        <TextBox disabled={activeStep !== id} name="pn" value={state.pn} onChange={handleInput} />
                        <FormHelperText error style={{ marginTop: 0 }}>
                            {errors.pn}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomeSignPad
                            style={{ marginTop: -12 }}
                            onChange={fieldChange}
                            clearSig={clearSig}
                            content={<InputLabel error> Signature*</InputLabel>}
                            sign={state.sign}
                            name="signature"
                            hideBtn={isSignDisabled}
                            disabled={isSignDisabled}
                        />
                        <FormHelperText error shrink style={{ marginTop: 0 }}>
                            {errors.sign}
                        </FormHelperText>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <SecondaryButton fullWidth disabled={activeStep !== id} onClick={handleSubmit}>
                    Submit
                </SecondaryButton>
            </CardActions>
        </Card>
    );
}

export default function WheelTorque({ DefaultName, urlParams, fromStep, navigateToDashboard, headerId }) {
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = React.useState({});
    const [view, setView] = useState(false);
    const [loader, setLoader] = useState(false);
    const [torque, setTorque] = useState({
        headerRecordID: headerId,
        wheelRetorqueID: null,
        fittingTorque: {
            date: '',
            lbft: null,
            nm: null,
            pn: '',
            time: '',
            vm: null,
            signature: '',
            sign: ''
        },
        reTorque: {
            date: '',
            lbft: null,
            nm: null,
            pn: '',
            time: '',
            vm: null,
            signature: '',
            sign: ''
        },
        Add_ReTorque: {
            date: '',
            lbft: null,
            nm: null,
            pn: '',
            time: '',
            vm: null,
            signature: '',
            sign: ''
        },
        showLoader: true
    });

    const handleComplete = (activeStep) => {
        setCompleted((st) => ({
            ...st,
            [activeStep]: true
        }));
    };

    useEffect(async () => {
        let res = await getWheelTorque(torque.headerRecordID);
        let data = res?.data?.renaultWheelRetorque[0];
        if (res.success) {
            setCompleted((st) => ({
                ...st,
                [1]: data?.reTorqueSignature ? true : false,
                [0]: data?.fittingTorqueSignature ? true : false,
                [2]: data?.additionalReTorqueSignature ? true : false
            }));

            setActiveStep(data?.fittingTorqueSignature ? (data?.reTorqueSignature ? 2 : 1) : 0);

            setTorque((st) => {
                let newSt = { ...st };
                newSt.headerRecordID = data?.headerRecordID || st.headerRecordID;
                newSt.wheelRetorqueID = data?.wheelRetorqueID || null;
                newSt.wheelCreatedUserID = data?.wheelCreatedUserID || null;

                newSt.fittingTorque = {
                    ...st.fittingTorque,
                    date: data?.fittingTorqueDateTime || '',
                    lbft: data?.fittingTorqueNutslbft || null,
                    nm: data?.fittingTorqueNutsNN || null,
                    pn: data?.fittingTorqueCreatedBy || '',
                    vm: data?.fittingTorqueMileage || null,
                    sign: data?.fittingTorqueSignature || '',
                    signature: data?.fittingTorqueSignature || ''
                };
                newSt.reTorque = {
                    ...st.reTorque,
                    date: data?.reTorqueDateTime || '',
                    lbft: data?.reTorqueNutslbft || null,
                    nm: data?.reTorqueNutsNN || null,
                    pn: data?.reTorqueCreatedBy || '',
                    vm: data?.reTorqueMileage || null,
                    sign: data?.reTorqueSignature || '',
                    signature: data?.reTorqueSignature || ''
                };
                newSt.Add_ReTorque = {
                    ...st.Add_ReTorque,
                    date: data?.additionalReTorqueDateTime || '',
                    lbft: data?.additionalReTorqueNutslbft || null,
                    nm: data?.additionalReTorqueNutsNN || null,
                    pn: data?.additionalReTorqueCreatedBy || '',
                    vm: data?.additionalReTorqueMileage || null,
                    sign: data?.additionalReTorqueSignature || '',
                    signature: data?.additionalReTorqueSignature || ''
                };
                newSt.showLoader = false;
                return newSt;
            });
        }
    }, []);

    let state = { 0: torque.fittingTorque, 1: torque.reTorque, 2: torque.Add_ReTorque };
    let objName = { 0: 'fittingTorque', 1: 'reTorque', 2: 'Add_ReTorque' };

    const dwnldPdf = async () => {
        setLoader(true);
        let res = await downldTorqueGetDetails(headerId);
        setLoader(false);
    };

    return (
        <div style={{ padding: 10, position: 'relative' }}>
            {view && (
                <div style={{ position: 'absolute', top: -60, right: 20 }}>
                    {loader ? (
                        <CircularProgress size={20} />
                    ) : (
                        <CloudDownloadIcon color="primary" style={{ fontSize: 30, cursor: 'pointer' }} onClick={dwnldPdf} />
                    )}
                </div>
            )}
            <Grid container spacing={2} justifyContent="space-evenly">
                <Grid item xs={12}>
                    <HorizontalNonLinearStepper alternativeLabel activeStep={activeStep} setActiveStep={setActiveStep} completed={completed} />
                </Grid>
                {torque.showLoader ? (
                    <Grid container alignItems="center" justifyContent="center" style={{ height: 500 }}>
                        <Grid item>
                            <CircularIndeterminate />
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        <Hidden only={['xs', 'sm', 'md']}>
                            {getSteps().map((val, i) => (
                                <Grid item xs={3} kay={val}>
                                    <OutlinedCard
                                        heading={val}
                                        objName={objName[i]}
                                        state={state[i]}
                                        activeStep={activeStep}
                                        id={i}
                                        handleComplete={handleComplete}
                                        setActiveStep={setActiveStep}
                                        setTorque={setTorque}
                                        torque={torque}
                                        DefaultName={DefaultName}
                                        setShowDwnld={setView}
                                    />
                                </Grid>
                            ))}
                        </Hidden>
                        <Hidden only={['lg', 'xl']}>
                            {getSteps().map((val, i) => {
                                if (activeStep == i) {
                                    return (
                                        <Grid item xs={11} kay={val}>
                                            <OutlinedCard
                                                heading={val}
                                                objName={objName[i]}
                                                state={state[i]}
                                                activeStep={activeStep}
                                                id={i}
                                                handleComplete={handleComplete}
                                                setActiveStep={setActiveStep}
                                                setTorque={setTorque}
                                                torque={torque}
                                                fromStep={fromStep}
                                                navigateToDashboard={navigateToDashboard}
                                                DefaultName={DefaultName}
                                                setShowDwnld={setView}
                                            />
                                        </Grid>
                                    );
                                }
                            })}
                        </Hidden>
                    </>
                )}
            </Grid>
        </div>
    );
}
