import React, { useState } from 'react';

import { Grid, InputLabel } from '@material-ui/core';
import { SecondaryButton, TextBox } from '../../../../Core/FormInput';
import { PostMarkCreditReceived } from '../../../../Core/Service/PartReq_services/partReturnSteps-services';

const CreditReceived = ({ onClose, handleSubmit, partReturnPartReqID, partReturnID, showToast }) => {
    const [state, setState] = useState({
        partReturnCreditNo: ''
    });
    const [btnLoader, setBtnLoader] = useState(false);
    const Onsubmit = async () => {
        setBtnLoader(true);
        let data = {
            partReturnPartReqID: partReturnPartReqID,
            partReturnID: partReturnID,
            partReturnCreditNo: state.partReturnCreditNo
        };
        let res = await PostMarkCreditReceived(data);

        if (res.success) {
            showToast(res?.data?.message);
            handleSubmit();
            onClose();
        }
        setBtnLoader(false);
    };
    return (
        <div style={{ padding: 10 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel>Credit Number</InputLabel>
                    <TextBox
                        placeholder="Credit Number"
                        name="partReturnCreditNo"
                        value={state.partReturnCreditNo}
                        onChange={(e) => {
                            setState((st) => ({ ...st, partReturnCreditNo: e.target.value }));
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <SecondaryButton fullWidth onClick={Onsubmit} disabled={!state.partReturnCreditNo} isBtnLoader={btnLoader}>
                        Submit
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default CreditReceived;
