import { get, post, put } from './http-calls';
import { AppStorage } from './storage-service';

export const getUploaderConfig = async () => {
    let res = await get('FileUploader', { useAuthToken: true });
    if (res.success) {
        AppStorage.saveFileUploaderConfig(res.data);
    }
};

export const getFilesForConfig = async (configId, recordId, docIDPassed = false) => {
    if (docIDPassed == undefined || docIDPassed == null) {
        docIDPassed = false;
    }
    let res = await get(`FileUploader/${configId}/${recordId}?docIDPassed=${docIDPassed}`, { useAuthToken: true });
    return res;
};

export const uploadFileUsignUploaderConfig = async (configId, recordId, formData, docIDPassed) => {
    if (docIDPassed == undefined || docIDPassed == null) {
        docIDPassed = false;
    }
    let res = await post(`FileUploader/${configId}/${recordId}?docIDPassed=${docIDPassed}`, formData, { useAuthToken: true });
    return res;
};

export const updateName = async (configId, recordId, uniqueFileName, newName) => {
    let res = await post(
        `FileUploader/rename`,
        {
            configId,
            recordId,
            uniqueFileName,
            newName
        },
        { useAuthToken: true }
    );
    return res;
};

export const deleteUploadedFile = async (configId, recordId, uniqueFileName) => {
    let res = await post(
        `FileUploader/delete`,
        {
            configId,
            recordId,
            uniqueFileName
        },
        { useAuthToken: true }
    );
    return res;
};

export const downloadUploadedFile = async (configId, recordId, uniqueFileName, fileNameFull, docIDPassed, isDownload = false, fileType) => {
    if (docIDPassed == undefined || docIDPassed == null) {
        docIDPassed = false;
    }
    let res = await post(
        `FileUploader/download`,
        {
            configId,
            recordId,
            uniqueFileName,
            docIDPassed
        },
        { responseType: 'blob', returnOrgRes: true, useAuthToken: true }
    );

    if (res.status === 200 && isDownload) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.getElementById('tempDownloadLink') || document.createElement('a');
        link.id = 'tempDownloadLink';
        link.href = url;
        link.setAttribute('download', fileNameFull);
        document.body.appendChild(link);
        link.click();
        return true;
    } else {
        if (fileType == '.pdf') return window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf; charset=utf-8' }));
        else if (fileType == '.png') {
            return window.URL.createObjectURL(new Blob([res.data]));
        } else {
            return res.data;
        }
    }
    return false;
};
