import { Dashboard } from '@material-ui/icons';
import { get, post } from './http-calls';

function buildQueryString(params) {
    if (!params || typeof params !== 'object') {
        return ''; // Return empty string if params is not an object or is falsy
    }

    const queryStringParts = [];

    for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
            const value = params[key];
            const paramValue = typeof value === 'boolean' ? (value ? 'true' : 'false') : value;
            const encodedKey = encodeURIComponent(key);
            const encodedValue = encodeURIComponent(paramValue);
            if (encodedValue != 'false') {
                queryStringParts.push(`${encodedKey}=${encodedValue}`);
            }
        }
    }

    if (queryStringParts.length > 0) {
        return '?' + queryStringParts.join('&');
    } else {
        return '';
    }
}
export const getGetUserProcesses = async () => {
    let res = await get('Process/GetUserProcesses', { useAuthToken: true });
    return res;
};

export const getDashboardInfo = async () => {
    let res = await get('DashBoard/GetInternalDashBoardData', { useAuthToken: true });
    return res;
};
export const GetInternalDashHeaderRecords = async (IsHighPriority, IsFMOrWarranty, IsNightShift, IsBreakdown) => {
    const queryString = buildQueryString({
        IsHighPriority: IsHighPriority,
        IsFMOrWarranty: IsFMOrWarranty,
        IsNightShift: IsNightShift,
        IsBreakdown: IsBreakdown
    });

    let res = await get(`DashBoard/GetInternalDashHeaderRecords${queryString}`, { useAuthToken: true });
    return res;
};
export const GetInternalDashReturnReasons = async (IsHighPriority, IsFMOrWarranty, IsNightShift, IsBreakdown) => {
    const queryString = buildQueryString({
        IsHighPriority: IsHighPriority,
        IsFMOrWarranty: IsFMOrWarranty,
        IsNightShift: IsNightShift,
        IsBreakdown: IsBreakdown
    });
    let res = await get(`DashBoard/GetInternalDashReturnReasons${queryString}`, { useAuthToken: true });
    return res;
};
export const GetInternalDashTechnicianJobs = async (IsHighPriority, IsFMOrWarranty, IsNightShift, IsBreakdown) => {
    const queryString = buildQueryString({
        IsHighPriority: IsHighPriority,
        IsFMOrWarranty: IsFMOrWarranty,
        IsNightShift: IsNightShift,
        IsBreakdown: IsBreakdown
    });
    let res = await get(`DashBoard/GetInternalDashTechnicianJobs${queryString}`, { useAuthToken: true });
    return res;
};

export const GetInternalDashClockEntries = async (ProcessID, IsHighPriority, IsFMOrWarranty, IsNightShift, IsBreakdown) => {
    let res = await get(
        `DashBoard/GetInternalDashClockEntries?ProcessID=${ProcessID}${IsHighPriority ? `&IsHighPriority =${IsHighPriority}` : ''}${
            IsFMOrWarranty ? `&IsFMOrWarranty=${IsFMOrWarranty}` : ''
        }${IsBreakdown ? `&IsBreakdown=${IsBreakdown}` : ''}${IsNightShift ? `&IsNightShift=${IsNightShift}` : ''}`,
        { useAuthToken: true }
    );
    return res;
};

export const GetUserPreferenceWorkflowsList = async () => {
    let res = await get('UserGridColumn/UserPreferenceWorkflows', { useAuthToken: true });
    return res;
};

export const saveSwapUserPreferenceWorkflows = async (data) => {
    let res = await post(`UserGridColumn/SwapUserPreferenceWorkflows `, data, { useAuthToken: true });
    return res;
};

export const GetInternalReturnReasonData = async (startDate, endDate, bid) => {
    let BranchID = bid.length > 0 ? `BranchID=${bid.join('&BranchID=')}` : '';
    let res = await get(`dashboard/GetInternalReturnReasonsDashboard?startDate=${startDate}&endDate=${endDate}&${BranchID}`, { useAuthToken: true });
    return res;
};

export const GetInternalWorkflowsAvgTimesDashboard = async (startDate, endDate, bid) => {
    let BranchID = bid.length > 0 ? `BranchID=${bid.join('&BranchID=')}` : '';
    let res = await get(`DashBoard/GetInternalWorkflowsAvgTimesDashboard?startDate=${startDate}&endDate=${endDate}&${BranchID}`, { useAuthToken: true });
    return res;
};

export const GetPartsDashboard = async () => {
    let res = await get('PartsDashboard', { useAuthToken: true });
    return res;
};

export const GetUserPartGridColumn = async () => {
    let res = await get('UserPartGridColumn', { useAuthToken: true });
    return res;
};

export const saveUserPartGridColumn = async (data) => {
    let res = await post(`UserPartGridColumn`, data, { useAuthToken: true });
    return res;
};

export const GetNewVehicleDashboard = async (processID) => {
    let res = await get(`NewVehicleDashboard/GetSalesInternalNewVehicleSTock?ProcessID=${processID}`, { useAuthToken: true });
    return res;
};

export const GetInternalDashReturn = async (IsHighPriority, IsFMOrWarranty, IsNightShift, IsBreakdown) => {
    const queryString = buildQueryString({
        IsHighPriority: IsHighPriority,
        IsFMOrWarranty: IsFMOrWarranty,
        IsNightShift: IsNightShift,
        IsBreakdown: IsBreakdown
    });

    let res = await get(`RenaultPartReqs/GetInternalDashPartReturnCountsByReturnStatus${queryString}`, { useAuthToken: true });
    return res;
};
export const GetInternalDashPartReq = async (IsHighPriority, IsFMOrWarranty, IsNightShift, IsBreakdown) => {
    const queryString = buildQueryString({
        IsHighPriority: IsHighPriority,
        IsFMOrWarranty: IsFMOrWarranty,
        IsNightShift: IsNightShift,
        IsBreakdown: IsBreakdown
    });
    let res = await get(`RenaultPartReqs/GetInternalDashPartReqCountsByReqStatus${queryString}`, { useAuthToken: true });
    return res;
};
