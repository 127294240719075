import { Grid, FormLabel, Typography, InputLabel, FormHelperText, FormControlLabel, Checkbox, InputAdornment, Popover } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../App/AppContext';
import { DatePicker, SecondaryButton, SingelSelect, TextBox } from '../../../Core/FormInput';
import { getBranchCodes, getReportingTypeList, setBranchCodes } from '../../../Core/Service/branch-screen-service';
import TabComponent from '../../../Core/Controls/Tabs';
import CreateIcon from '@material-ui/icons/Create';
import AddBoxIcon from '@material-ui/icons/AddBox';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import moment from 'moment';
import DialogComp from '../../../Core/Controls/Dialog/DialogComp';
import EmailIcon from '@material-ui/icons/Email';
import CustomeEditor from '../../../Core/CkEditor';
import { CustomIconButton } from '../../../Core/FormInput/AppButton';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import HelpIcon from '@material-ui/icons/Help';

const MailCommonTitleComponent = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>{`E-Mail Template (${props.selectedRow?.label})`}</Grid>
            <Grid item title="Custom key words">
                <HelpIcon style={{ marginTop: '10px' }} onClick={handleClick} />
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <Grid container>
                        <Grid item>
                            <ul>
                                <li>{`Reg no - {regnumber}`}</li>
                                <li>{`Wip No - {wipNo}`}</li>
                                <li>{`Customer - {customer}`}</li>
                                <li>{`Part No - {partNumber}`}</li>
                                <li>{`Part Description - {partDescription}`}</li>
                                <li>{`User Name - {userName}`}</li>
                            </ul>
                        </Grid>
                    </Grid>
                </Popover>
            </Grid>
        </Grid>
    );
};

const MailCommonComponent = (props) => {
    return (
        <DialogComp title={MailCommonTitleComponent(props)} onClose={() => props.onClose(false)} fullWidth maxWidth="md">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel shrink>Email Subject</InputLabel>
                    <TextBox
                        type="text"
                        autoComplete="off"
                        placeholder="Email Subject"
                        name="emailSubject"
                        value={props.selectedRow?.emailSubject}
                        onChange={props.onChange(props.selectedIndex, props.ObjName)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <InputLabel shrink>Email Template</InputLabel>
                        </Grid>
                        <Grid item xs>
                            <CustomeEditor
                                data={props.selectedRow?.bodyTemplate}
                                onChange={props.handleCkeditorstate(props.selectedIndex, props.ObjName, 'bodyTemplate')}
                                placeholder="Add description here"
                                name="bodyTemplate"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <SecondaryButton fullWidth onClick={props.onClose}>
                        Close
                    </SecondaryButton>
                </Grid>
                <Grid item xs></Grid>
                <Grid item xs></Grid>
            </Grid>
        </DialogComp>
    );
};

const UpdateBranchCodesModal = (props) => {
    const [state, setState] = useState({
        BranchInvoiceEmail: '',
        BranchContractBranchName: '',
        BranchRandMCodes: '',
        BranchWarrantyCodes: '',
        BranchCashCodes: '',
        BranchRectificationAccountCode: '',
        BranchServiceWarrantyWriteOffAccountCode: '',
        BranchPartsWarrantyWriteOffAccountCode: '',
        BranchConsumablesAccountCode: '',
        BranchMobiloVanAccountCode: '',
        BranchExcludeLabourCode: '',
        ReportingTypeList: [],
        branchIncludeInEWIP: false,
        branchIncludeInReporting: false,
        branchIncludeInInvoicePortal: false,
        branchSeteWIPWorkflowID: false,
        excludeBranch: false,
        branchWorkflowChangeNotificationEmail: '',
        branchReturnReasonNotificationEmail: '',
        openEmailModal: false
    });

    const [reportingVM, setReportingVM] = useState([{ codeID: null, codeType: {}, code: '', isDeleted: 0 }]);
    const [partReqReturnStatusEmailList, setPartReqReturnStatusEmailList] = useState([]);
    const [partReqStatusEmailsList, setPartReqStatusEmailsList] = useState([]);

    const { showToast } = useContext(AppContext);

    const handleCheckbox = (event) => {
        const { name, checked } = event.target;
        setState((st) => {
            const nst = { ...st };
            nst[name] = checked;
            return nst;
        });
    };

    const handleSubmit = async () => {
        const data = {
            BranchID: props.branchID,
            BranchInvoiceEmail: state.BranchInvoiceEmail ? state.BranchInvoiceEmail : '',
            BranchContractBranchName: state.BranchContractBranchName ? state.BranchContractBranchName : '',
            BranchShortCode: state.branchShortCode || '',
            BranchShortName: state.branchShortName || '',
            BranchName: state.branchName || '',
            BranchIncludeInEWIP: state.branchIncludeInEWIP,
            BranchIncludeInReporting: state.branchIncludeInReporting,
            BranchIncludeInInvoicePortal: state.branchIncludeInInvoicePortal,
            BranchSeteWIPWorkflowID: state.branchSeteWIPWorkflowID,
            BranchGoLiveDate: state.branchGoLiveDate,
            ExcludeBranch: state.excludeBranch,
            branchWorkflowChangeNotificationEmail: state.branchWorkflowChangeNotificationEmail,
            branchReturnReasonNotificationEmail: state.branchReturnReasonNotificationEmail,
            // BranchRandMCodes: state.BranchRandMCodes ? state.BranchRandMCodes : '',
            // BranchWarrantyCodes: state.BranchWarrantyCodes ? state.BranchWarrantyCodes : '',
            // BranchCashCodes: state.BranchCashCodes ? state.BranchCashCodes : '',
            // BranchRectificationAccountCode: state.BranchRectificationAccountCode ? state.BranchRectificationAccountCode : '',
            // BranchServiceWarrantyWriteOffAccountCode: state.BranchServiceWarrantyWriteOffAccountCode ? state.BranchServiceWarrantyWriteOffAccountCode : '',
            // BranchPartsWarrantyWriteOffAccountCode: state.BranchPartsWarrantyWriteOffAccountCode ? state.BranchPartsWarrantyWriteOffAccountCode : '',
            // BranchConsumablesAccountCode: state.BranchConsumablesAccountCode ? state.BranchConsumablesAccountCode : '',
            // BranchMobiloVanAccountCode: state.BranchMobiloVanAccountCode ? state.BranchMobiloVanAccountCode : '',
            BranchExcludeLabourCode: state.BranchExcludeLabourCode ? state.BranchExcludeLabourCode : '',
            ReportingCodes: reportingVM.map((k) => {
                return {
                    ...k,
                    codeID: k.codeID ? k.codeID : null,
                    code: k.code ? k.code : '',
                    codeType: k.codeType.id
                };
            }),
            partReqReturnStatusEmailList: partReqReturnStatusEmailList,
            partReqStatusEmailsList: partReqStatusEmailsList
        };

        let res = await setBranchCodes(data);
        if (!res.success) {
            console.error(res);
            showToast('Error occured.');
        } else {
            showToast('Branch Codes updated successfully');
            props.onClose();
        }
    };

    useEffect(async () => {
        let res = await getReportingTypeList();
        if (res.success) {
            setState((st) => ({
                ...st,
                ReportingTypeList: res.data.reportingCode
            }));
        }
    }, []);

    const pullAndUpdateState = async () => {
        let res = await getBranchCodes(props.branchID);
        if (res.success) {
            const data = res.data.dt[0];

            const ReportData = res.data.reportingCodes;
            setState((st) => ({
                ...st,
                BranchInvoiceEmail: data.branchInvoiceEmail,
                BranchContractBranchName: data.branchContractBranchName,
                branchShortCode: data.branchShortCode,
                branchShortName: data.branchShortName,
                branchName: data.branchName,
                branchIncludeInEWIP: data.branchIncludeInEWIP,
                branchIncludeInReporting: data.branchIncludeInReporting,
                branchIncludeInInvoicePortal: data.branchIncludeInInvoicePortal,
                branchSeteWIPWorkflowID: data.branchSeteWIPWorkflowID,
                branchGoLiveDate: data.branchGoLiveDate ? moment(data.branchGoLiveDate).format('YYYY-MM-DD') : '', // data.branchGoLiveDate,
                excludeBranch: data.excludeBranch,
                branchWorkflowChangeNotificationEmail: data.branchWorkflowChangeNotificationEmail,
                branchReturnReasonNotificationEmail: data.branchReturnReasonNotificationEmail,
                // BranchRandMCodes: data.branchRandMCodes,
                // BranchWarrantyCodes: data.branchWarrantyCodes,
                // BranchCashCodes: data.branchCashCodes,
                // BranchRectificationAccountCode: data.branchRectificationAccountCode,
                // BranchServiceWarrantyWriteOffAccountCode: data.branchServiceWarrantyWriteOffAccountCode,
                // BranchPartsWarrantyWriteOffAccountCode: data.branchPartsWarrantyWriteOffAccountCode,
                // BranchConsumablesAccountCode: data.branchConsumablesAccountCode,
                // BranchMobiloVanAccountCode: data.branchMobiloVanAccountCode,
                BranchExcludeLabourCode: data.branchExcludeLabourCode
            }));
            setReportingVM(
                !ReportData?.length
                    ? [
                          {
                              codeID: null,
                              codeType: {},
                              code: '',
                              isDeleted: 0
                          }
                      ]
                    : ReportData.map((k) => {
                          return {
                              ...k,
                              codeID: k.codeID ? k.codeID : null,
                              code: k.code ? k.code : '',
                              codeType: state.ReportingTypeList.find((n) => n.id === k.codeType),
                              isDeleted: 0
                          };
                      })
            );
            setPartReqReturnStatusEmailList(res.data.partReqReturnStatusEmailList);
            setPartReqStatusEmailsList(res.data.partReqStatusEmailsList);
        }
    };

    useEffect(() => {
        if (state.ReportingTypeList.length > 0) {
            pullAndUpdateState();
        }
    }, [state.ReportingTypeList]);

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            return { ...st, [name]: value };
        });
    };

    const addTimeRow = () => {
        setReportingVM((st) => [
            ...st,
            {
                codeID: null,
                codeType: {},
                code: '',
                isDeleted: 0
            }
        ]);
    };

    const removeTimeRow = (indx) => {
        setReportingVM((st) => {
            const newSt = [...st];
            newSt.splice(indx, 1);
            return newSt;
        });
    };

    const singleSelectChange = (index) => (ev, val) => {
        setReportingVM((st) => {
            let newSt = [...st];
            let findIndex = [...st].find((n, i) => i === index);
            if (findIndex) {
                findIndex.codeType = val;
            }

            return newSt;
        });
    };

    const handlepart = (index) => (e) => {
        let { name, value } = e.target;
        setReportingVM((st) => {
            let newSt = [...st];
            let findIndex = [...st].find((n, i) => i === index);
            if (findIndex) {
                findIndex[name] = value;
            }

            return newSt;
        });
    };

    const handleStatus = (index, secName) => (e) => {
        let { name, value } = e.target;
        if (secName == 'partReqReturnStatusNotificationEmail') {
            setPartReqReturnStatusEmailList((st) => {
                let newSt = [...st];
                newSt[index][`${name}`] = value;
                return newSt;
            });
        }
        if (secName == 'partReqStatusNotificationEmail') {
            setPartReqStatusEmailsList((st) => {
                let newSt = [...st];
                newSt[index][`${name}`] = value;
                return newSt;
            });
        }
    };

    const handleDel = (index) => () => {
        setReportingVM((st) => {
            let newSt = [...st];
            let findIndex = [...st].find((n, i) => i === index);
            if (findIndex) {
                findIndex.isDeleted = 1;
            }

            return newSt;
        });
    };

    const onEmailHandled = (index, row) => {
        setState((st) => {
            const nst = { ...st };
            nst.openEmailModal = true;
            nst.selectedRow = row;
            nst.selectedIndex = index;
            return nst;
        });
    };

    const closeModal = (res) => {
        setState((state) => ({ ...state, openEmailModal: false, selectedRow: {}, selectedIndex: null }));
    };

    const handleCkeditorstate = (index, secName, name) => (event, editor) => {
        const data = editor.getData();
        if (secName == 'partReqReturnStatusNotificationEmail') {
            setPartReqReturnStatusEmailList((st) => {
                let newSt = [...st];
                newSt[index][`${name}`] = data;
                return newSt;
            });
        }
        if (secName == 'partReqStatusNotificationEmail') {
            setPartReqStatusEmailsList((st) => {
                let newSt = [...st];
                newSt[index][`${name}`] = data;
                return newSt;
            });
        }
    };

    const tabs = [
        {
            label: 'Detail',
            body: (
                <div style={{ padding: 10 }}>
                    {' '}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <InputLabel shrink>Invoice Email</InputLabel>
                            <TextBox value={state.BranchInvoiceEmail} name="BranchInvoiceEmail" onChange={handleFieldChange} inputProps={{ maxLength: 256 }} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel shrink>Contract Branch Name</InputLabel>
                            <TextBox
                                value={state.BranchContractBranchName}
                                name="BranchContractBranchName"
                                onChange={handleFieldChange}
                                inputProps={{ maxLength: 128 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel shrink>Workflow Email</InputLabel>
                            <TextBox
                                value={state.branchWorkflowChangeNotificationEmail}
                                name="branchWorkflowChangeNotificationEmail"
                                onChange={handleFieldChange}
                                type="email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel shrink>Return Reason Email</InputLabel>
                            <TextBox
                                value={state.branchReturnReasonNotificationEmail}
                                name="branchReturnReasonNotificationEmail"
                                onChange={handleFieldChange}
                                type="email"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel shrink>Invoiced Hours exclude labour code</InputLabel>
                            <TextBox
                                value={state.BranchExcludeLabourCode}
                                name="BranchExcludeLabourCode"
                                onChange={handleFieldChange}
                                inputProps={{ maxLength: 1 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel shrink>Branch Short Code</InputLabel>
                            <TextBox value={state.branchShortCode} name="branchShortCode" onChange={handleFieldChange} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel shrink>Branch Name</InputLabel>
                            <TextBox value={state.branchName} name="branchName" onChange={handleFieldChange} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel shrink>Branch Short Name</InputLabel>
                            <TextBox value={state.branchShortName} name="branchShortName" onChange={handleFieldChange} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel shrink>Branch Go Live Date</InputLabel>
                            <DatePicker
                                style={{ marginTop: 8 }}
                                placeholder="Start Date"
                                name="branchGoLiveDate"
                                fullWidth
                                value={state.branchGoLiveDate}
                                onChange={handleFieldChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}></Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                control={<Checkbox checked={state.branchIncludeInEWIP} onChange={handleCheckbox} name="branchIncludeInEWIP" />}
                                label="Include In EWIP?"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={state.branchIncludeInInvoicePortal} onChange={handleCheckbox} name="branchIncludeInInvoicePortal" />
                                }
                                label="Include In Invoice Portal?"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                control={<Checkbox checked={state.branchIncludeInReporting} onChange={handleCheckbox} name="branchIncludeInReporting" />}
                                label="Include In Reporting?"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                control={<Checkbox checked={state.branchSeteWIPWorkflowID} onChange={handleCheckbox} name="branchSeteWIPWorkflowID" />}
                                label="Set eWIP WorkflowID?"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControlLabel
                                control={<Checkbox checked={state.excludeBranch} onChange={handleCheckbox} name="excludeBranch" />}
                                label="Exclude Branch?"
                            />
                        </Grid>
                    </Grid>
                </div>
            )
        },
        {
            label: 'Reporting',
            body: (
                <div style={{ padding: 10 }}>
                    <Grid container spacing={1} justifyContent="space-evenly">
                        {reportingVM.map((row, index) => {
                            return (
                                !row.isDeleted && (
                                    <React.Fragment key={index}>
                                        <Grid item xs={12} sm={4} md={4} lg={4}>
                                            <InputLabel shrink>Reporting Type</InputLabel>

                                            <SingelSelect options={state.ReportingTypeList} value={row.codeType} onChange={singleSelectChange(index)} />
                                        </Grid>
                                        <Grid item xs={12} sm={7} md={7} lg={7}>
                                            <InputLabel shrink>Code</InputLabel>
                                            <TextBox type="text" placeholder="Code" name="code" value={row.code} onChange={handlepart(index)} />
                                        </Grid>
                                        <Grid item xs={12} sm={1} md={1} lg={1}>
                                            <div style={{ float: 'right', marginTop: 35 }}>
                                                {index !== 0 && (
                                                    <RemoveCircleIcon
                                                        style={{ color: 'red', cursor: 'pointer' }}
                                                        fontSize="medium"
                                                        // onClick={() => {
                                                        //     removeTimeRow(index);
                                                        // }}
                                                        onClick={handleDel(index)}
                                                    />
                                                )}
                                                &nbsp;&nbsp;
                                                {index === 0 && (
                                                    <AddBoxIcon style={{ color: 'green', cursor: 'pointer' }} fontSize="medium" onClick={addTimeRow} />
                                                )}
                                            </div>
                                        </Grid>
                                    </React.Fragment>
                                )
                            );
                        })}
                    </Grid>
                </div>
            )
        },
        {
            label: 'Return Status Notification',
            body: (
                <div style={{ padding: 10 }}>
                    <Grid container spacing={1} justifyContent="space-between">
                        {partReqReturnStatusEmailList.map((row, index) => {
                            return (
                                <Grid item xs={12} sm={6}>
                                    <form noValidate>
                                        <FormLabel shrink component="legend" style={{ marginTop: '5px' }}>
                                            {row.label}
                                        </FormLabel>

                                        {/* <FormLabel shrink>
                                            <EmailIcon style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => onEmailHandled(index, row)} />
                                        </FormLabel> */}

                                        <TextBox
                                            type="text"
                                            placeholder="Email"
                                            name="partReqReturnStatusNotificationEmail"
                                            value={row.partReqReturnStatusNotificationEmail}
                                            onChange={handleStatus(index, 'partReqReturnStatusNotificationEmail')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CustomIconButton toolTipTitle="Set Email Template" onClick={() => onEmailHandled(index, row)}>
                                                            <EmailIcon color={row.emailSubject || row.bodyTemplate ? 'primary' : 'action'} />
                                                        </CustomIconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </form>
                                </Grid>
                            );
                        })}
                    </Grid>
                    {state.openEmailModal && (
                        <MailCommonComponent
                            onClose={closeModal}
                            selectedRow={state.selectedRow}
                            selectedIndex={state.selectedIndex}
                            onChange={handleStatus}
                            handleCkeditorstate={handleCkeditorstate}
                            ObjName={'partReqReturnStatusNotificationEmail'}
                        />
                    )}
                </div>
            )
        },
        {
            label: 'Status Notification',
            body: (
                <div style={{ padding: 10 }}>
                    <Grid container spacing={1} justifyContent="space-between">
                        {partReqStatusEmailsList.map((row, index) => {
                            return (
                                <Grid item xs={12} sm={6}>
                                    <form noValidate>
                                        <FormLabel shrink component="legend" style={{ marginTop: '5px' }}>
                                            {row.label}
                                        </FormLabel>

                                        {/* <FormLabel shrink>
                                            <EmailIcon style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={() => onEmailHandled(index, row)} />
                                        </FormLabel> */}

                                        <TextBox
                                            type="text"
                                            autoComplete="off"
                                            placeholder="Email"
                                            name="partReqStatusNotificationEmail"
                                            value={row.partReqStatusNotificationEmail}
                                            onChange={handleStatus(index, 'partReqStatusNotificationEmail')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <CustomIconButton toolTipTitle="Set Email Template" onClick={() => onEmailHandled(index, row)}>
                                                            <EmailIcon color={row.emailSubject || row.bodyTemplate ? 'primary' : 'action'} />
                                                        </CustomIconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </form>
                                </Grid>
                            );
                        })}
                    </Grid>
                    {state.openEmailModal && (
                        <MailCommonComponent
                            onClose={closeModal}
                            selectedRow={state.selectedRow}
                            selectedIndex={state.selectedIndex}
                            onChange={handleStatus}
                            handleCkeditorstate={handleCkeditorstate}
                            ObjName={'partReqStatusNotificationEmail'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <div>
            <Grid item>
                <TabComponent tabList={tabs} TabHeight={600} />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 10 }}>
                <SecondaryButton fullWidth onClick={handleSubmit}>
                    Save
                </SecondaryButton>
            </Grid>
        </div>
    );
};
export default UpdateBranchCodesModal;
